import './ValueList.css';
import { FC, useContext, useState, useRef } from 'react';
import classNames from 'classnames';
import { BuildingTypeType } from '../utils/EstateDataType';
import { useTranslation } from 'react-i18next';
import { AppContext, DisplayMode, MyLanguage } from '../context/AppContext';
import CommonUtil from '../utils/CommonUtil';
import { ValueMapDataType, ValueMapDataName } from '../utils/ValueMapDataType';
import EstateDataUtil from '../utils/EstateDataUtil';
import DialogValueMap from './DialogValueMap';
import AuthUtil from '../utils/AuthUtil';
import { MemberType } from '../utils/MemberType';

import { InvestmentDataName, InvestmentDataType, InvestmentSectorType } from '../utils/InvestmentDataType';
import InvestmentDataUtil from '../utils/InvestmentDataUtil';



const ValueList: FC = () => {
  const ContextRoot = CommonUtil.get_context_root();

  const [ t ] = useTranslation();
  const { myLang } = useContext(AppContext);
  const { valueMapData } = useContext(AppContext);
  const { memberType, setIsOpenUserRegistrationDialog } = useContext(AppContext);

  // 表示件数
  const NumStep = 20;  // Moreボタン押下時に増やす件数
  const [ numDisplay, setNumDisplay ] = useState<number>(NumStep);

  // ソート対象のカラム名
  const [ orderBy, setOrderBy ] = useState<string>('');

  // 各カラムのソート方法(昇順／降順)
  const [ buildingTypePriorityIndex, setBuildingTypePriorityIndex ] = useState<number>(0);
  const [ isOrderAsc_AppraisalValue, setIsOrderAsc_AppraisalValue ] = useState<boolean>(true);
  const [ isOrderAsc_AppraisalValueUnitPrice, setIsOrderAsc_AppraisalValueUnitPrice ] = useState<boolean>(true);
  const [ isOrderAsc_LandValue, setIsOrderAsc_LandValue ] = useState<boolean>(true);
  const [ isOrderAsc_LandValueUnitPrice, setIsOrderAsc_LandValueUnitPrice ] = useState<boolean>(true);
  const [ isOrderAsc_LandFar, setIsOrderAsc_LandFar ] = useState<boolean>(true);//20231104 Add
  const [ isOrderAsc_Rosenka, setIsOrderAsc_Rosenka ] = useState<boolean>(true);//20240218 Add
  const [ isOrderAsc_Kouji, setIsOrderAsc_Kouji ] = useState<boolean>(true);//20240218 Add
  const [ isOrderAsc_AssumedRent, setIsOrderAsc_AssumedRent ] = useState<boolean>(true);
  const [ isOrderAsc_CapRate, setIsOrderAsc_CapRate ] = useState<boolean>(true);
  const [ isOrderAsc_LandArea, setIsOrderAsc_LandArea ] = useState<boolean>(true);
  const [ isOrderAsc_ArchitecturalArea, setIsOrderAsc_ArchitecturalArea ] = useState<boolean>(true);
  const [ isOrderAsc_Construction_YYYYMMDD, setIsOrderAsc_Construction_YYYYMMDD ] = useState<boolean>(true);
  const [ isOrderAsc_Distance, setIsOrderAsc_Distance ] = useState<boolean>(true);
  const [ isOrderAsc_BuildingCoverageRatio, setIsOrderAsc_BuildingCoverageRatio ] = useState<boolean>(true);
  const [ isOrderAsc_Far, setIsOrderAsc_Far ] = useState<boolean>(true);

  const BuildingTypeValues = Object.values(BuildingTypeType).filter((v): v is BuildingTypeType => typeof v === 'number') as BuildingTypeType[];

  const twrapperRef = useRef<HTMLDivElement>(null);
  const theadRef = useRef<HTMLTableSectionElement>(null);

  // ポップアップの表示位置
  interface PopupPosition {
    left: string;
    top: string;
  }
  const [ popupPosition, setPopupPosition ] = useState<PopupPosition>({ left: '0px', top: '0px' });
  // 評価額(単価)のセルにマウスをあわせたデータ。
  const [ data_mouseEnter_on_appraisal, setData_mouseEnter_on_appraisal ] = useState<ValueMapDataType | null>(null);
  // 土地価格(単価)のセルにマウスをあわせたデータ。
  const [ data_mouseEnter_on_land, setData_mouseEnter_on_land ] = useState<ValueMapDataType | null>(null);
  // 土地価格(一種単価)のセルにマウスをあわせたデータ。
  const [ data_mouseEnter_on_land_far, setData_mouseEnter_on_land_far ] = useState<ValueMapDataType | null>(null);
  // 路線価のセルにマウスをあわせたデータ。
  const [ data_mouseEnter_on_rosenka, setData_mouseEnter_on_rosenka ] = useState<ValueMapDataType | null>(null);
  // 地価公示のセルにマウスをあわせたデータ。
  const [ data_mouseEnter_on_kouji, setData_mouseEnter_on_kouji ] = useState<ValueMapDataType | null>(null);

  /**
   * 投資情報をソートする。
   * 
   * @returns ソートした投資情報
   */
  const sort_data = () => {
    if (!orderBy) return valueMapData;

    const my_compare = (a: ValueMapDataType, b: ValueMapDataType, key: keyof ValueMapDataType, isOrderAsc: boolean): number => {
      // null値の場合は後ろに並べる。
      if (!(key in a)) return 1;
      if (!(key in b)) return -1;
      if (a[key] === null) return 1;
      if (b[key] === null) return -1;

      if (isOrderAsc) {
        return Number(a[key]) - Number(b[key]);
      } else {
        return Number(b[key]) - Number(a[key]);
      }
    }

    const my_comapre_buildingType = (a: ValueMapDataType, b: ValueMapDataType, key: keyof ValueMapDataType): number => {
      // 優先して表示するタイプは前に並べる。
      if (Number(a[key]) === BuildingTypeValues[buildingTypePriorityIndex]) return -1;
      if (Number(b[key]) === BuildingTypeValues[buildingTypePriorityIndex]) return 1;

      return Number(a[key]) - Number(b[key]);
    }

    let sortedValueMapData = [...valueMapData];
    switch (orderBy) {
      case ValueMapDataName.BuildingType:
        sortedValueMapData.sort((a, b) => my_comapre_buildingType(a, b, 'building_type'));
        break;
      case ValueMapDataName.AppraisalValue:
        sortedValueMapData.sort((a, b) => my_compare(a, b, 'appraisal_value', isOrderAsc_AppraisalValue));
        break;
      case ValueMapDataName.AppraisalValueUnitPrice:
        sortedValueMapData.sort((a, b) => my_compare(a, b, 'appraisal_value_unit_price', isOrderAsc_AppraisalValueUnitPrice));
        break;
      case ValueMapDataName.LandValue:
        sortedValueMapData.sort((a, b) => my_compare(a, b, 'land_value', isOrderAsc_LandValue));
        break;
      case ValueMapDataName.LandValueUnitPrice:
        sortedValueMapData.sort((a, b) => my_compare(a, b, 'land_value_unit_price', isOrderAsc_LandValueUnitPrice));
        break;
      case ValueMapDataName.LandFar:
        sortedValueMapData.sort((a, b) => my_compare(a, b, 'land_far', isOrderAsc_LandFar)); //20231104 Add
        break;
      case ValueMapDataName.Rosenka:
        sortedValueMapData.sort((a, b) => my_compare(a, b, 'rosenka', isOrderAsc_Rosenka)); //20240218 Add
        break;
      case ValueMapDataName.Kouji:
        sortedValueMapData.sort((a, b) => my_compare(a, b, 'kouji', isOrderAsc_Kouji)); //20240218 Add
        break;
      case ValueMapDataName.AssumedRent:
        sortedValueMapData.sort((a, b) => my_compare(a, b, 'assumed_rent', isOrderAsc_AssumedRent));
        break;
      case ValueMapDataName.CapRate:
        sortedValueMapData.sort((a, b) => my_compare(a, b, 'cap_rate', isOrderAsc_CapRate));
        break;
      case ValueMapDataName.LandArea:
        sortedValueMapData.sort((a, b) => my_compare(a, b, 'land_area', isOrderAsc_LandArea));
        break;
      case ValueMapDataName.ArchitecturalArea:
        sortedValueMapData.sort((a, b) => my_compare(a, b, 'architectural_area', isOrderAsc_ArchitecturalArea));
        break;
      case ValueMapDataName.Construction_YYYYMMDD:
        sortedValueMapData.sort((a, b) => my_compare(a, b, 'construction_yyyymmdd', isOrderAsc_Construction_YYYYMMDD));
        break;
      case ValueMapDataName.Distance:
        sortedValueMapData.sort((a, b) => my_compare(a, b, 'distance', isOrderAsc_Distance));
        break;
      case ValueMapDataName.BuildingCoverageRatio:
        sortedValueMapData.sort((a, b) => my_compare(a, b, 'building_coverage_ratio', isOrderAsc_BuildingCoverageRatio));
        break;
      case ValueMapDataName.Far:
        sortedValueMapData.sort((a, b) => my_compare(a, b, 'far', isOrderAsc_Far));
        break;  
    }
    return sortedValueMapData;
  }

  /**
   * カラムのヘッダをクリックしたら、
   * ・のカラムをソートの対象とする。
   * ・すでにソート対象の場合は、ソート方法を変更する。
   * 
   * @param value_map_data_name ソート対象のデータ名
   */
  const handleClickHeader = (value_map_data_name: string) => {
    if (AuthUtil.restricted(memberType, MemberType.C)) {
      setIsOpenUserRegistrationDialog(true);
      return;
    }

    // クリックしたカラムがすでにソート対象の場合は、ソート方法を変更する。
    switch (value_map_data_name) {
      case ValueMapDataName.BuildingType:
        if (orderBy === value_map_data_name) {
          if (buildingTypePriorityIndex === (BuildingTypeValues.length - 1)) {
            setBuildingTypePriorityIndex(0);
          } else {
            setBuildingTypePriorityIndex(buildingTypePriorityIndex + 1);
          }
        }
        break;
      case ValueMapDataName.AppraisalValue:
        if (orderBy === value_map_data_name) setIsOrderAsc_AppraisalValue(!isOrderAsc_AppraisalValue);
        break;
      case ValueMapDataName.AppraisalValueUnitPrice:
        if (orderBy === value_map_data_name) setIsOrderAsc_AppraisalValueUnitPrice(!isOrderAsc_AppraisalValueUnitPrice);
        break;
      case ValueMapDataName.LandValue:
        if (orderBy === value_map_data_name) setIsOrderAsc_LandValue(!isOrderAsc_LandValue);
        break;
      case ValueMapDataName.LandValueUnitPrice:
        if (orderBy === value_map_data_name) setIsOrderAsc_LandValueUnitPrice(!isOrderAsc_LandValueUnitPrice);
        break;
      case ValueMapDataName.LandFar:
        if (orderBy === value_map_data_name) setIsOrderAsc_LandFar(!isOrderAsc_LandFar);//20231104 Add
        break;
      case ValueMapDataName.Rosenka:
        if (orderBy === value_map_data_name) setIsOrderAsc_Rosenka(!isOrderAsc_Rosenka);//20240218 Add
        break;
      case ValueMapDataName.Kouji:
        if (orderBy === value_map_data_name) setIsOrderAsc_Kouji(!isOrderAsc_Kouji);//20240218 Add
        break;
      case ValueMapDataName.AssumedRent:
        if (orderBy === value_map_data_name) setIsOrderAsc_AssumedRent(!isOrderAsc_AssumedRent);
        break;
      case ValueMapDataName.CapRate:
        if (orderBy === value_map_data_name) setIsOrderAsc_CapRate(!isOrderAsc_CapRate);
        break;
      case ValueMapDataName.LandArea:
        if (orderBy === value_map_data_name) setIsOrderAsc_LandArea(!isOrderAsc_LandArea);
        break;
      case ValueMapDataName.ArchitecturalArea:
        if (orderBy === value_map_data_name) setIsOrderAsc_ArchitecturalArea(!isOrderAsc_ArchitecturalArea);
        break;
      case ValueMapDataName.Construction_YYYYMMDD:
        if (orderBy === value_map_data_name) setIsOrderAsc_Construction_YYYYMMDD(!isOrderAsc_Construction_YYYYMMDD);
        break;
      case ValueMapDataName.Distance:
        if (orderBy === value_map_data_name) setIsOrderAsc_Distance(!isOrderAsc_Distance);
        break;
      case ValueMapDataName.BuildingCoverageRatio:
        if (orderBy === value_map_data_name) setIsOrderAsc_BuildingCoverageRatio(!isOrderAsc_BuildingCoverageRatio);
        break;
      case ValueMapDataName.Far:
        if (orderBy === value_map_data_name) setIsOrderAsc_Far(!isOrderAsc_Far);
        break;
                    
    }
    // ソート対象としてセットする。
    setOrderBy(value_map_data_name);
  }

  const render_na = (row: any[]) => {
    row.push(<td className={classNames('width_adjust', 'na')}>NA</td>);
  }


//1億単位に変換（100億未満は小数点1桁まで表示に修正 20230218）
  const render_billion_data = (price: number, row: any[]) => {
    const className = classNames('width_adjust', 'right');
    let text = '';
    price /= 100000000;
    switch (myLang) {
      case MyLanguage.JA: {
        if (price >= 100) {
          text = `${(price).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} 億円`;
        } else {
          text = `${(price).toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1})} 億円`;
        }
        row.push(<td className={className}>{text}</td>);
        break;
      }
      case MyLanguage.EN: {
        // billion表記なので、10で割る。小数点1桁まで表示する。
        if (price >= 1000) {
          text = `${(price / 10).toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1})}bn`;
        } else {
          text = `${(price / 10).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}bn`;
        }
        row.push(<td className={className}>&yen; {text}</td>);
        break;
      }
      default: {
        row.push(<td className={className}>{text}</td>);    
        break;
      }
    }
  }

  const render_thead = () => {
    const HasSecondHeadingClassName = 'has_second_heading';

    const classNameNoWrap = 'nowrap';
    const classNameWrap = 'wrap';

    return (
      <>
        <tr>
          {/* タイプ */}
          <th className={classNames(get_classNameSortable(ValueMapDataName.BuildingType), 'fixcell', 'building_type')} onClick={() => handleClickHeader(ValueMapDataName.BuildingType)}>
            <div>
              <span>{t('タイプ')}</span>
              {render_indicator_buildingType(ValueMapDataName.BuildingType)}
           </div>
          </th>
          {/* 物件名 */}
          <th className={classNames('fixcell', 'property_name')}>
            <div>
              <span>{t('物件名(Value)')}</span>
            </div>
          </th>
          {/* 住居表示 */}
          <th className={classNames('width_adjust')}>{t('住居表示')}</th>
          {/* 評価額 */}
          <th className={classNames(get_classNameSortable(ValueMapDataName.AppraisalValue), 'fixcell', 'appraisal_value')} onClick={() => handleClickHeader(ValueMapDataName.AppraisalValue)}>
            <div>
              <span>{t('評価額')}</span>
              {render_indicator(ValueMapDataName.AppraisalValue, isOrderAsc_AppraisalValue)}
              {render_balloon(get_balloon_text('市場価格'), 'center', classNameNoWrap)}
              {/*{render_balloon([t('市場価格（完全所有権を前提）')], 'center', classNameNoWrap)} */}
           </div>
          </th>
          {/* 評価額(単価) */}{/* 20231112Change */}
          <th className={classNames(get_classNameSortable(ValueMapDataName.AppraisalValueUnitPrice), 'fixcell', HasSecondHeadingClassName, 'no_left_border')} onClick={() => handleClickHeader(ValueMapDataName.AppraisalValueUnitPrice)}>
            <div className="second">
              <div className="left_border"></div>
              {render_indicator(ValueMapDataName.AppraisalValueUnitPrice, isOrderAsc_AppraisalValueUnitPrice)}
              {render_balloon([t('評価額(単価)_吹き出し')], 'center', classNameNoWrap)}
              <span>{t('（単価）')}</span>
            </div>
          </th>
          {/* 還元利回り */}
          <th className={classNames(get_classNameSortable(ValueMapDataName.CapRate), 'fixcell', 'cap_rate')} onClick={() => handleClickHeader(ValueMapDataName.CapRate)}>
            <div>
              <span>{t('還元利回り')}</span>
              {render_indicator(ValueMapDataName.CapRate, isOrderAsc_CapRate)}
              {render_balloon([t('還元利回り_吹き出し')], 'center', classNameNoWrap)}
           </div>
          </th>

          {/* 土地価格 */}
          <th className={classNames(get_classNameSortable(ValueMapDataName.LandValue), 'fixcell', 'land_value')} onClick={() => handleClickHeader(ValueMapDataName.LandValue)}>
            <div>
              <span>{t('土地価格')}</span>
              {render_indicator(ValueMapDataName.LandValue, isOrderAsc_LandValue)}
              {render_balloon(get_balloon_text('土地価格'), 'center', classNameNoWrap)}
              {/* {render_balloon([t('土地価格_吹き出し')], 'center', classNameNoWrap)} */}
           </div>
          </th>
          {/* 土地価格(単価) */}
          <th className={classNames(get_classNameSortable(ValueMapDataName.LandValueUnitPrice), 'fixcell', HasSecondHeadingClassName, 'no_left_border')} onClick={() => handleClickHeader(ValueMapDataName.LandValueUnitPrice)}>
            <div className="second">
              <div className="left_border"></div>
              {render_indicator(ValueMapDataName.LandValueUnitPrice, isOrderAsc_LandValueUnitPrice)}
              <span>{t('（単価）')}</span>
            </div>
          </th>
          {/* 土地価格(一種単価) */} {/* 20231104 Add */}{/* 20231112Change */}
          <th className={classNames(get_classNameSortable(ValueMapDataName.LandFar), 'fixcell', HasSecondHeadingClassName, 'no_left_border')} onClick={() => handleClickHeader(ValueMapDataName.LandFar)}>
            <div className="second">
              <div className="left_border"></div>
              {render_indicator(ValueMapDataName.LandFar, isOrderAsc_LandFar)}
              {render_balloon([t('土地価格(一種単価)_吹き出し')], 'center', classNameNoWrap)}
              <span>{t('（一種単価）')}</span>
            </div>
          </th>
          {/* 固定資産税路線価 */} {/* 20240218 Add */}
          <th className={classNames(get_classNameSortable(ValueMapDataName.Rosenka), 'fixcell', HasSecondHeadingClassName, 'no_left_border')} onClick={() => handleClickHeader(ValueMapDataName.Rosenka)}>
            <div className="second">
              <div className="left_border"></div>
              {render_indicator(ValueMapDataName.Rosenka, isOrderAsc_Rosenka)}
              {render_balloon([t('固定資産税')], 'center', classNameNoWrap)}
              <span>{t('（路線価）')}</span>
            </div>
          </th>
          {/* 地価公示 */} {/* 20240218 Add */}
          <th className={classNames(get_classNameSortable(ValueMapDataName.Kouji), 'fixcell', HasSecondHeadingClassName, 'no_left_border')} onClick={() => handleClickHeader(ValueMapDataName.Kouji)}>
            <div className="second">
              <div className="left_border"></div>
              {render_indicator(ValueMapDataName.Kouji, isOrderAsc_Kouji)}
              <span>{t('（地価公示）')}</span>
            </div>
          </th>
          {/* 想定賃料 */}
          {/* <th className={classNames(get_classNameSortable(ValueMapDataName.AssumedRent), 'fixcell', 'assumed_rent')} onClick={() => handleClickHeader(ValueMapDataName.AssumedRent)}>
            <div>
              <span>{t('実質賃料')}</span>
              {render_indicator(ValueMapDataName.AssumedRent, isOrderAsc_AssumedRent)}
              {render_balloon([t('実質賃料（フリーレント考慮後）')], 'center', classNameNoWrap)}
           </div>
          </th> */}
          {/* 詳細 */}
          <th className={classNames('fixcell')}>
            <div>
              <span>{t('詳細(Value)')}</span>
            </div>
          </th>
          {/* 土地面積 */}

          <th className={classNames(get_classNameSortable(ValueMapDataName.LandArea), 'fixcell', 'land_area')} onClick={() => handleClickHeader(ValueMapDataName.LandArea)}>
            <div>
              <span>{t('土地面積(Value)')}</span>
              {render_indicator(ValueMapDataName.LandArea, isOrderAsc_LandArea)}
              {render_balloon([t('土地面積_吹き出し')], 'center', classNameNoWrap)} 
           </div>
          </th>
          {/* 建物(延床面積（登記簿）) */}
          <th className={classNames(get_classNameSortable(ValueMapDataName.ArchitecturalArea), 'fixcell', HasSecondHeadingClassName, 'gfa')} onClick={() => handleClickHeader(ValueMapDataName.ArchitecturalArea)}>
            <div className="hidden"><span>{t('建物')}</span></div>
            <div className="second">
              {/* <div className="left_border"></div>  */}
              {render_indicator(ValueMapDataName.ArchitecturalArea, isOrderAsc_ArchitecturalArea)}
              <span>{t('延床面積（登記簿）')}</span>
            </div> {/* </tr> */}
          </th> 
          {/* 建物(階層) */}
          <th className={classNames('fixcell', HasSecondHeadingClassName, 'no_left_border')}>
            <div><span>{t('建物')}</span></div>
            <div className="second">
              <div className="left_border"></div>
              <div className={classNames('indicator', 'hidden')}></div>
              <span>{t('階層')}</span>
            </div>
          </th>

          {/* 建物(竣工年月) */}
          <th className={classNames(get_classNameSortable(ValueMapDataName.Construction_YYYYMMDD), 'fixcell', HasSecondHeadingClassName, 'no_left_border')} onClick={() => handleClickHeader(ValueMapDataName.Construction_YYYYMMDD)}>
            <div className="hidden"><span>{t('建物')}</span></div>
            <div className="second">
              <div className="left_border"></div>
              {render_indicator(ValueMapDataName.Construction_YYYYMMDD, isOrderAsc_Construction_YYYYMMDD)}
              <span>{t('竣工年月')}</span>
            </div>
          </th>

          {/* 最寄駅 */}
          <th className={classNames('fixcell')}>
            <div>
              <span>{t('最寄駅')}</span>
            </div>
          </th>

          {/* 距離 */}
          <th className={classNames(get_classNameSortable(ValueMapDataName.Distance), 'fixcell', HasSecondHeadingClassName, 'no_left_border')} onClick={() => handleClickHeader(ValueMapDataName.Distance)}>
            <div className="second">
              <div className="left_border"></div>
              {render_indicator(ValueMapDataName.Distance, isOrderAsc_Distance)}
              <span>{t('距離')}</span>
            </div>
          </th>

          {/* 建蔽率 */}
          <th className={classNames(get_classNameSortable(ValueMapDataName.BuildingCoverageRatio), 'fixcell')} onClick={() => handleClickHeader(ValueMapDataName.BuildingCoverageRatio)}>
            <div>
              <span>{t('建蔽率')}</span>
              {render_indicator(ValueMapDataName.BuildingCoverageRatio, isOrderAsc_BuildingCoverageRatio)}
            </div>
          </th>

          {/* 容積率 */}
          <th className={classNames(get_classNameSortable(ValueMapDataName.Far), 'fixcell')} onClick={() => handleClickHeader(ValueMapDataName.Far)}>
            <div>
              <span>{t('容積率')}</span>
              {render_indicator(ValueMapDataName.Far, isOrderAsc_Far)}
            </div>
          </th>

          {/* 用途地域 */}
          <th className={classNames('fixcell')}>
            <div>
              <span>{t('用途地域')}</span>
            </div>
          </th>
        </tr>
      </>
    )
  }

  const render_tbody = () => {
    let rendering = [];
    let sortedValueMapData = sort_data();
    const mergedValueMapData = sortedValueMapData;

    let count = 0;
    for (let d of mergedValueMapData) {
      // 表示する件数を制御する。
      count++;
      if (count > numDisplay) break;
      
      let row = [];

      // タイプ
      row.push(<td className={classNames('fixcell', 'building_type')}>{t(EstateDataUtil.convert_buildingType_name(d.building_type, 0))}</td>);

      // 物件名
      {
        const className = classNames('fixcell', 'property_name');
        switch (myLang) {
          case MyLanguage.JA: row.push(<td className={className}>{d.property_name}</td>); break;
          case MyLanguage.EN: row.push(<td className={className}>{d.property_name_en}</td>); break;
          default: row.push(<td className={className}></td>); break;
        }  
      }

      // 住居表示
      switch (myLang) {
        case MyLanguage.JA: row.push(<td className={classNames('width_adjust')}>{d.addr1}{d.addr2}</td>); break;
        case MyLanguage.EN: row.push(<td className={classNames('width_adjust')}>{d.addr2_en},&nbsp;{d.addr1_en}</td>); break;
        default: row.push(<td></td>); break;
      }
      
      // 評価額
      {
        if (d.appraisal_value === null) {
          render_na(row);
        } else {
          render_billion_data(d.appraisal_value, row);
        }
      }

      // 評価額(単価)
      {
        if (d.appraisal_value_unit_price === null) {
          render_na(row);
        } else {
          const className = classNames('width_adjust', 'right', 'popup_parent');
          let text = '';
      
          const onMouseEnterEvent = (e: any, d: ValueMapDataType) => {
            setPopupPosition(get_popup_position(e));
            setData_mouseEnter_on_appraisal(d);
          }
          const onMouseLeaveEvent = () => {
            setData_mouseEnter_on_appraisal(null);
          }
      
          switch (myLang) {
            case MyLanguage.JA: {
              text = `${(d.appraisal_value_unit_price / 1000).toLocaleString(undefined, {maximumSignificantDigits: 3})} 千円/㎡`;
              //text = `${(d.appraisal_value_unit_price / 1000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} 千円/㎡`;
              row.push(
                <td className={className} onMouseEnter={(e) => onMouseEnterEvent(e, d)} onMouseLeave={() => onMouseLeaveEvent()}>
                  {text}
                </td>
              );
              break;
            }
            case MyLanguage.EN: {
              text = `${(d.appraisal_value_unit_price / 1000).toLocaleString(undefined, {maximumSignificantDigits: 3})}k/㎡`;
              //text = `${(d.appraisal_value_unit_price / 1000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}k/㎡`;
              row.push(
                <td className={className} onMouseEnter={(e) => onMouseEnterEvent(e, d)} onMouseLeave={() => onMouseLeaveEvent()}>
                  &yen; {text}
                </td>
              );
              break;
            }
            default: {
              row.push(<td className={className}>{text}</td>);    
              break;
            }
          }      
        }
      }

      // 還元利回り
      {
        if (d.cap_rate === null) {
          render_na(row);
        } else {
          row.push(<td className={classNames('right', 'cap_rate')}><b>{EstateDataUtil.convert_rate_percentage(d.cap_rate, 1)}</b>%</td>);
          //row.push(<td className={classNames('right', 'fixcell', 'cap_rate')}><b>{EstateDataUtil.convert_rate_percentage(d.cap_rate, 1)}</b>%</td>);
        }
      }

      // 土地価格
      {
        if (d.land_value === null) {
          render_na(row);
        } else {
          render_billion_data(d.land_value, row);
        }
      }

      // 土地価格(単価)
      {
        if (d.land_value_unit_price === null) {
          render_na(row);
        } else {
          const className = classNames('width_adjust', 'right', 'popup_parent');
          let text = '';
      
          const onMouseEnterEvent = (e: any, d: ValueMapDataType) => {
            setPopupPosition(get_popup_position(e));
            setData_mouseEnter_on_land(d);
          }
          const onMouseLeaveEvent = () => {
            setData_mouseEnter_on_land(null);
          }
      
          switch (myLang) {
            case MyLanguage.JA: {
              text = `${(d.land_value_unit_price / 1000).toLocaleString(undefined, {maximumSignificantDigits: 3})} 千円/㎡`;
              //text = `${(d.land_value_unit_price / 1000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} 千円/㎡`;
              row.push(
                <td className={className} onMouseEnter={(e) => onMouseEnterEvent(e, d)} onMouseLeave={() => onMouseLeaveEvent()}>
                  {text}
                </td>
              );
              break;
            }
            case MyLanguage.EN: {
              text = `${(d.land_value_unit_price / 1000).toLocaleString(undefined, {maximumSignificantDigits: 3})}k/㎡`;
              //text = `${(d.land_value_unit_price / 1000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}k/㎡`;
              row.push(
                <td className={className} onMouseEnter={(e) => onMouseEnterEvent(e, d)} onMouseLeave={() => onMouseLeaveEvent()}>
                  &yen; {text}
                </td>
              );
              break;
            }
            default: {
              row.push(<td className={className}>{text}</td>);    
              break;
            }
          }
        }
      }

      // 土地価格(一種単価)　20231104 Add
      {
        if (d.land_far === null) {
          render_na(row);
        } else {
          const className = classNames('width_adjust', 'right', 'popup_parent');
          let text = '';
      
          const onMouseEnterEvent = (e: any, d: ValueMapDataType) => {
            setPopupPosition(get_popup_position(e));
            setData_mouseEnter_on_land_far(d);
          }
          const onMouseLeaveEvent = () => {
            setData_mouseEnter_on_land_far(null);
          }
      
          switch (myLang) {
            case MyLanguage.JA: {
              text = `${(d.land_far / 1000).toLocaleString(undefined, {maximumSignificantDigits: 3})} 千円/㎡`;
              //text = `${(d.land_far / 1000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} 千円/㎡`;
              row.push(
                <td className={className} onMouseEnter={(e) => onMouseEnterEvent(e, d)} onMouseLeave={() => onMouseLeaveEvent()}>
                  {text}
                </td>
              );
              break;
            }
            case MyLanguage.EN: {
              text = `${(d.land_far / 1000).toLocaleString(undefined, {maximumSignificantDigits: 3})}k/㎡`;
              //text = `${(d.land_far / 1000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}k/㎡`;
              row.push(
                <td className={className} onMouseEnter={(e) => onMouseEnterEvent(e, d)} onMouseLeave={() => onMouseLeaveEvent()}>
                  &yen; {text}
                </td>
              );
              break;
            }
            default: {
              row.push(<td className={className}>{text}</td>);    
              break;
            }
          }
        }
      }

      // 固定資産税路線価　20240218 Add
      {
        if (d.rosenka === null) {
          render_na(row);
        } else {
          const className = classNames('width_adjust', 'right', 'popup_parent');
          let text = '';
      
          const onMouseEnterEvent = (e: any, d: ValueMapDataType) => {
            setPopupPosition(get_popup_position(e));
            setData_mouseEnter_on_rosenka(d);
          }
          const onMouseLeaveEvent = () => {
            setData_mouseEnter_on_rosenka(null);
          }
      
          switch (myLang) {
            case MyLanguage.JA: {
              text = `${(d.rosenka /1000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} 千円/㎡`;
              row.push(
                <td className={className} onMouseEnter={(e) => onMouseEnterEvent(e, d)} onMouseLeave={() => onMouseLeaveEvent()}>
                  {text}
                </td>
              );
              break;
            }
            case MyLanguage.EN: {
              text = `${(d.rosenka /1000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}k/㎡`;
              row.push(
                <td className={className} onMouseEnter={(e) => onMouseEnterEvent(e, d)} onMouseLeave={() => onMouseLeaveEvent()}>
                  &yen; {text}
                </td>
              );
              break;
            }
            default: {
              row.push(<td className={className}>{text}</td>);    
              break;
            }
          }
        }
      }

      // 地価公示　20240218 Add
      {
        if (d.kouji === null) {
          render_na(row);
        } else {
          const className = classNames('width_adjust', 'right', 'popup_parent');
          let text = '';
      
          const onMouseEnterEvent = (e: any, d: ValueMapDataType) => {
            setPopupPosition(get_popup_position(e));
            setData_mouseEnter_on_kouji(d);
          }
          const onMouseLeaveEvent = () => {
            setData_mouseEnter_on_kouji(null);
          }
      
          switch (myLang) {
            case MyLanguage.JA: {
              text = `${(d.kouji /1000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} 千円/㎡`;
              row.push(
                <td className={className} onMouseEnter={(e) => onMouseEnterEvent(e, d)} onMouseLeave={() => onMouseLeaveEvent()}>
                  {text}
                </td>
              );
              break;
            }
            case MyLanguage.EN: {
              text = `${(d.kouji /1000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}k/㎡`;
              row.push(
                <td className={className} onMouseEnter={(e) => onMouseEnterEvent(e, d)} onMouseLeave={() => onMouseLeaveEvent()}>
                  &yen; {text}
                </td>
              );
              break;
            }
            default: {
              row.push(<td className={className}>{text}</td>);    
              break;
            }
          }
        }
      }

      /*// 想定賃料
      {
        if (d.assumed_rent === null) {
          render_na(row);
        } else {
          let text = '';
          switch (myLang) {
            case MyLanguage.JA:
              text = d.assumed_rent.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + ' 円/坪';
              row.push(<td>{text}</td>);
              break;
            case MyLanguage.EN:
              text = d.assumed_rent.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + '/tsubo';
              row.push(<td>&yen; {text}</td>);
              break;
            default:
              row.push(<td>{text}</td>);
              break;
          }
        }
      }
    */

      // 詳細
      {
        const handleOnClickData = (d: ValueMapDataType) => {
          if (AuthUtil.restricted(memberType, d.allowed_member_type_level)) {
            setIsOpenUserRegistrationDialog(true);
            return;
          }
      
          // 別タブで「DATA」のページを開く。
          const url = `${ContextRoot}/?mode=${DisplayMode.ValueData}&id=${d.id}&lang=${myLang}`;
          window.open(url, '_blank', 'noreferrer');
        };

        row.push(
          <td className={classNames('width_adjust', 'data_ir')}>
            <div className="button_area">
              { !CommonUtil.is_mobile() && <button onClick={() => handleOnClickData(d)}>DATA</button> }
            </div>
          </td>
        );
      }
      
      // 土地面積
      {
        if (d.land_area === null) {
          render_na(row);
        } else {
          const text = `${d.land_area.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} ㎡`;
          row.push(<td className={classNames('right')}>{text}</td>);
          //row.push(<td className={classNames('right', 'fixcell')}>{text}</td>);
        }
      }

      // 建物(延床面積（登記簿）) */}
      {
        if (d.architectural_area === null) {
          render_na(row);
        } else {
          const text = `${d.architectural_area.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} ㎡`;
          row.push(<td className={classNames('right', 'gfa')}>{text}</td>);
          //row.push(<td className={classNames('right', 'fixcell', 'gfa')}>{text}</td>);
        }
      }

      // 建物(階層)
      {
        let floor = '';
        switch (myLang) {
          case MyLanguage.JA: floor = d.floor; break;
          case MyLanguage.EN: floor = d.floor_en; break;
        }
          //row.push(<td>{floor}</td>);
          row.push(<td className={classNames('center')}>{floor}</td>);
          //row.push(<td className={classNames('center', 'fixcell')}>{floor}</td>);
      }

      // 建物(竣工年月)
      {
        if (d.construction_yyyymmdd) {
          row.push(<td className={classNames('width_adjust')}>{EstateDataUtil.convert_YearMonth(d.construction_yyyymmdd, myLang)}</td>);
        } else {
          row.push(<td className="na">NA</td>);
        }  
      }

      // 最寄駅
      {
        let station = '';
        switch (myLang) {
          case MyLanguage.JA: station = d.station; break;
          case MyLanguage.EN: station = d.station_en; break;
        }
        row.push(<td>{station}</td>);
      }

      // 距離
      {
        if (d.distance) {
          let distance = '';
          if (d.distance >= 1000) {
            // 小数点以下1桁
            distance = Number(d.distance / 1000).toFixed(1).toLocaleString() + 'km';
          } else {
            distance = String(d.distance) + 'm';
          }
          row.push(<td className={classNames('width_adjust')}>{distance}</td>);
        } else {
          row.push(<td className="na">NA</td>);
        }  
      }

      // 建蔽率
      {
        if (d.building_coverage_ratio === null) {
          render_na(row);
        } else {
          row.push(<td className={classNames('right', 'building_coverage_ratio')}><b>{EstateDataUtil.convert_rate_percentage(d.building_coverage_ratio, 0)}</b>%</td>);
          //row.push(<td className={classNames('right', 'fixcell', 'building_coverage_ratio')}><b>{EstateDataUtil.convert_rate_percentage(d.building_coverage_ratio, 0)}</b>%</td>);
        }
      }

      // 容積率
      {
        if (d.far === null) {
          render_na(row);
        } else {
          row.push(<td className={classNames('right', 'far')}><b>{EstateDataUtil.convert_rate_percentage(d.far, 0)}</b>%</td>);
          //row.push(<td className={classNames('right', 'fixcell', 'far')}><b>{EstateDataUtil.convert_rate_percentage(d.far, 0)}</b>%</td>);
        }
      }

      // 用途地域
      {
        let zooning = '';
        switch (myLang) {
          case MyLanguage.JA: zooning = d.zooning; break;
          case MyLanguage.EN: zooning = d.zooning_en; break;
        }
        row.push(<td>{zooning}</td>);
      }

      // 1行分
      rendering.push(<tr>{row}</tr>);
    }

    return rendering;
  }
 
  /**
   * ソート可能なカラムのヘッダに指定するclassを取得する。
   * 
   * @param estate_data_name データ名
   * @returns カラムのヘッダに指定するclass
   */
  const get_classNameSortable = (estate_data_name: string) => {
    let className = classNames({
      'order_by': orderBy === estate_data_name,  // ソート対象のカラムの場合は、背景色を変える。
    }, 'sortable');
    return className;
  }

  const render_indicator = (estate_data_name: string, is_order_asc: boolean) => {
    if (orderBy !== estate_data_name) return render_indicator_neutral();
    if (orderBy === estate_data_name) {
      if (is_order_asc) return render_indicator_asc();
      else return render_indicator_dsc();
    }
    return null;
  }

  const render_indicator_buildingType = (value_map_data_name: string) => {
    if (orderBy !== value_map_data_name) return render_indicator_neutral();
    else return render_indicator_orderBy_sector();
  }

  const render_indicator_neutral = () => {
    return (
      <div className="indicator">
        <svg className='indicator_neutral' width="10" height="12" viewBox="0 0 12 14" version="1.1" >
          <path fill-rule="evenodd" d="m 0,8.004969 6,6 6,-6 z" />
          <path fill-rule="evenodd" d="M 0.00993834,6.0650684 6.0099383,0.06506844 12.009938,6.0650684 Z" />
        </svg>
      </div>
    );
  }

  const render_indicator_asc = () => {
    return (
      <div className="indicator">
        <svg className='indicator_asc' width="10" height="5" viewBox="0 0 12 6" version="1.1" >
          <path fill-rule="evenodd" d="M 0.00993834,6.0650684 6.0099383,0.06506844 12.009938,6.0650684 Z" />
        </svg>
      </div>
    );
  }

  const render_indicator_dsc = () => {
    return (
      <div className="indicator">
        <svg className='indicator_dsc' width="10" height="5" viewBox="0 0 12 6" version="1.1" >
          <path fill-rule="evenodd" d="M 0.00993834,0.06506844 6.0099383,6.0650684 12.009938,0.06506844 Z" />
        </svg>
      </div>
    );
  }

  const render_indicator_orderBy_sector = () => {
    return (
      <div className="indicator">
        <svg className='indicator_orderBy_sector' width="10" height="12" viewBox="0 0 12 14" version="1.1" >
          <path fill-rule="evenodd" d="m 0,8.004969 6,6 6,-6 z" />
          <path fill-rule="evenodd" d="M 0.00993834,6.0650684 6.0099383,0.06506844 12.009938,6.0650684 Z" />
        </svg>
      </div>
    );
  }

  /**
   * ポップアップを表示する位置を取得する。
   * 
   * @param e マウスイベント
   * @returns ポップアップを表示する位置
   */
  const get_popup_position = (e: any): PopupPosition => {
    let pos: PopupPosition = { left: '0px', top: '0px'};

    if (theadRef.current === null) return pos;

    // // 左端に表示。
    //const leftPosition = e.target.offsetLeft;
    // 右側に表示。
    const leftPosition = e.target.offsetLeft + e.target.offsetWidth;
    // // 上側に表示。
    // const topPosition = e.target.offsetTop + theadRef.current.offsetHeight - e.target.offsetHeight;
    // 同一縦位置に表示。
    const topPosition = e.target.offsetTop + theadRef.current.offsetHeight;


    pos.left = `${leftPosition}px`;
    pos.top = `${topPosition}px`;
    console.log(pos);
    return pos;
  }

  /**
   * 坪単価を算出する。
   * @param price 価格(円)
   * @param rentable_area 賃貸可能面積
   * @returns 坪単価
   */
  const calc_unit_price_of_area = (price: number | null, rentable_area: number | null) => {
    if (rentable_area === null || rentable_area === 0) return null;
    if (price === null || price === 0) return null;
    
    const tubo = rentable_area * 0.3025;
    const unit_price_of_area = price / tubo;
    return unit_price_of_area;
  }


  const render_evaluation_date = () => {
    if (valueMapData.length === 0) return null;

    const d = valueMapData[0];
    const evaluation_date = CommonUtil.convert_YearMonthDay(d.updated_at, myLang);

    return `${t('評価日：')}${evaluation_date}`;
  }

  const render_popup_on_appraisal = () => {
    if (data_mouseEnter_on_appraisal === null) return null;

    const d = data_mouseEnter_on_appraisal;
    let unit_price_of_area = calc_unit_price_of_area(d.appraisal_value, d.rentable_area);
    if (unit_price_of_area === null) return null;

    // 千円単位で表示する。
    unit_price_of_area /= 1000;

    let rendering = [];
    switch (myLang) {
      case MyLanguage.JA:
        rendering.push(<>{unit_price_of_area.toLocaleString(undefined, {maximumSignificantDigits: 3})}千円/坪</>);
        //rendering.push(<>{unit_price_of_area.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}千円/坪</>);
        break;
      case MyLanguage.EN:
        rendering.push(<>&yen; {unit_price_of_area.toLocaleString(undefined, {maximumSignificantDigits: 3})}k/tsubo</>);
        //rendering.push(<>&yen; {unit_price_of_area.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}k/tsubo</>);
        break;
    }
    return (
      <div className="popup" style={popupPosition}>{rendering}</div>
    );
  }

  const render_popup_on_land = () => {
    if (data_mouseEnter_on_land === null) return null;

    const d = data_mouseEnter_on_land;
    let unit_price_of_area = calc_unit_price_of_area(d.land_value, d.land_area);
    if (unit_price_of_area === null) return null;

    // 千円単位で表示する。
    unit_price_of_area /= 1000;

    let rendering = [];
    switch (myLang) {
      case MyLanguage.JA:
        rendering.push(<>{unit_price_of_area.toLocaleString(undefined, {maximumSignificantDigits: 3})}千円/坪</>);
        //rendering.push(<>{unit_price_of_area.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}千円/坪</>);
        break;
      case MyLanguage.EN:
        rendering.push(<>&yen; {unit_price_of_area.toLocaleString(undefined, {maximumSignificantDigits: 3})}k/tsubo</>);
        //rendering.push(<>&yen; {unit_price_of_area.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}k/tsubo</>);
        break;
    }
    return (
      <div className="popup" style={popupPosition}>{rendering}</div>
    );
  }


  const render_popup_on_land_far = () => {
    if (data_mouseEnter_on_land_far === null) return null;

    const d = data_mouseEnter_on_land_far;
    let unit_price_of_area = d.land_far;
    if (unit_price_of_area === null) return null;

    // 坪単位に変換し、千円単位で表示する。
    unit_price_of_area /= 0.3025;
    unit_price_of_area /= 1000;

    let rendering = [];
    switch (myLang) {
      case MyLanguage.JA:
        rendering.push(<>{unit_price_of_area.toLocaleString(undefined, {maximumSignificantDigits: 3})}千円/坪</>);
        //rendering.push(<>{unit_price_of_area.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}千円/坪</>);
        break;
      case MyLanguage.EN:
        rendering.push(<>&yen; {unit_price_of_area.toLocaleString(undefined, {maximumSignificantDigits: 3})}k/tsubo</>);
        //rendering.push(<>&yen; {unit_price_of_area.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}k/tsubo</>);
        break;
    }
    return (
      <div className="popup" style={popupPosition}>{rendering}</div>
    );
  }

  const render_popup_on_rosenka = () => {
    if (data_mouseEnter_on_rosenka === null) return null;

    const d = data_mouseEnter_on_rosenka;
    let unit_price_of_area = d.rosenka;
    if (unit_price_of_area === null) return null;

    // 坪単位に変換し、千円単位で表示する。
    unit_price_of_area /= 0.3025;
    unit_price_of_area /= 1000;

    let rendering = [];
    switch (myLang) {
      case MyLanguage.JA:
        rendering.push(<>{unit_price_of_area.toLocaleString(undefined, {maximumSignificantDigits: 3})}千円/坪</>);
        //rendering.push(<>{unit_price_of_area.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}千円/坪</>);
        break;
      case MyLanguage.EN:
        rendering.push(<>&yen; {unit_price_of_area.toLocaleString(undefined, {maximumSignificantDigits: 3})}k/tsubo</>);
        //rendering.push(<>&yen; {unit_price_of_area.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}k/tsubo</>);
        break;
    }
    return (
      <div className="popup" style={popupPosition}>{rendering}</div>
    );
  }

  const render_popup_on_kouji = () => {
    if (data_mouseEnter_on_kouji === null) return null;

    const d = data_mouseEnter_on_kouji;
    let unit_price_of_area = d.kouji;
    if (unit_price_of_area === null) return null;

    // 坪単位に変換し、千円単位で表示する。
    unit_price_of_area /= 0.3025;
    unit_price_of_area /= 1000;

    let rendering = [];
    switch (myLang) {
      case MyLanguage.JA:
        rendering.push(<>{unit_price_of_area.toLocaleString(undefined, {maximumSignificantDigits: 3})}千円/坪</>);
        //rendering.push(<>{unit_price_of_area.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}千円/坪</>);
        break;
      case MyLanguage.EN:
        rendering.push(<>&yen; {unit_price_of_area.toLocaleString(undefined, {maximumSignificantDigits: 3})}k/tsubo</>);
        //rendering.push(<>&yen; {unit_price_of_area.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}k/tsubo</>);
        break;
    }
    return (
      <div className="popup" style={popupPosition}>{rendering}</div>
    );
  }


  const get_balloon_text = (dataName: string) => {
    let text: string[] = [];

    switch (dataName) {
      case '市場価格': {
        text.push(t('tooltip.市場価格.1'));
        text.push(t('tooltip.市場価格.2'));
        break;
      }
  
      case '土地価格': {
        text.push(t('tooltip.土地価格.1'));
        text.push(t('tooltip.土地価格.2'));
        break;
      }
    }
    return text;
  }




  /**
   * 吹き出しの文言を返却する。
   * @param balloon_text 吹き出しの文言(配列の1要素＝1行)
   * @param classname_align 吹き出しの位置を指定するクラス名
   * @returns 吹き出しの文言
   */
  const render_balloon = (balloon_text: string[], classname_align: string, classname_wrap: string) => {
    if (balloon_text.length === 0) return null;

    let rendering = [];
    for (let i = 0; i < balloon_text.length; i++) {
      if (i !== 0) rendering.push(<br/>);
      rendering.push(balloon_text[i]);
    }
    
    return (
      <span className={classNames("balloon", classname_align, classname_wrap)}>{rendering}</span>
    );
  }

  const render_more_button = () => {
    if (numDisplay >= valueMapData.length) return null;

    const onClickHandler = () => {
      if (AuthUtil.restricted(memberType, MemberType.C)) {
        setIsOpenUserRegistrationDialog(true);
        return;
      }
  
      setNumDisplay(numDisplay + NumStep);
    }

    return (<button className="more_button" onClick={() => onClickHandler()}>More...</button>);
  }

  return (
    <>
      <div id="ValueList">
        <div className="evaluation_date">{render_evaluation_date()}</div>
        <div className="twrapper" ref={twrapperRef}>
          <table>
            <thead ref={theadRef}>{render_thead()}</thead>
            <tbody>{render_tbody()}</tbody>
          </table>
          {render_popup_on_appraisal()}
          {render_popup_on_land()}
          {render_popup_on_land_far()}
          {render_popup_on_rosenka()}
          {render_popup_on_kouji()}
          {render_more_button()}
        </div>
      </div>
      <DialogValueMap/>
    </>
  );
}

export default ValueList;
