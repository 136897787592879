import './AppraisalValue.css';
import React, { useContext, MouseEvent } from "react";
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AppContext, MyLanguage } from '../../context/AppContext';
import { SubmitSearchButtonId } from '../../utils/SearchPanelConst';
import AuthUtil from '../../utils/AuthUtil';
import { MemberType } from '../../utils/MemberType';

type Props = {

};

const AppraisalValue: React.VFC<Props> = () => {
  const [ t ] = useTranslation();
  const { myLang } = useContext(AppContext);
  const { memberType, setIsOpenUserRegistrationDialog } = useContext(AppContext);
  const { register } = useFormContext();

  const local_appraisal_value = (appraisal_value: number) => {
    switch (myLang) {
      case MyLanguage.JA:
        return `${(appraisal_value / 100_000_000).toLocaleString()}億円`;
      case MyLanguage.EN:
        return `\xA5 ${(appraisal_value / 100_000_000 * 100).toLocaleString()}m`;
      default:
        return '';
    }
  }

  /**
   * 評価額のオプションを生成する。
   * 
   * @returns オプション要素
   */
  const render_option = () => {
    let rendering = [];

    const appraisal_value_list = [ 10, 50, 100, 500, 1000, 5000 ];
    for (let i = 0; i < appraisal_value_list.length; i++) {
      // 億
      appraisal_value_list[i] *= 100_000_000;
    }

    for (let appraisal_value of appraisal_value_list) {
      rendering.push(<option value={appraisal_value}>{local_appraisal_value(appraisal_value)}</option>);
    }

    return rendering;
  }

  const handleChange = () => {
    // 評価額変更時に再検索させる。
    const submit_search_button = document.getElementById(SubmitSearchButtonId);
    if (!submit_search_button) return;
    submit_search_button.click();
  }

  const handleMouseDown = (event: MouseEvent<HTMLSelectElement>): void => {
    if (AuthUtil.restricted(memberType, MemberType.C)) {
      setIsOpenUserRegistrationDialog(true);
      // optionの表示をキャンセルする。
      event.preventDefault();
      return;
    }
  }

  return (
    <div id="AppraisalValue">
      <div className="searchgroup">
        <span className="searchfieldlabel">{t('評価額')}</span>
        <select onMouseDown={handleMouseDown} {...register('appraisal_value_lower', { onChange: handleChange })}>
          <option value="0" selected disabled></option>
          <option value="0">{t('下限なし')}</option>
          {render_option()}
        </select>
        <span>&nbsp;～&nbsp;</span>
        <select onMouseDown={handleMouseDown} {...register('appraisal_value_upper', { onChange: handleChange })}>
          <option value="0" selected disabled></option>
          <option value="0">{t('上限なし')}</option>
          {render_option()}
        </select>
      </div>
    </div>
  );
}

export default AppraisalValue;
