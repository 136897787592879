import { FC, useContext, useEffect, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext, MyLanguage } from '../context/AppContext';
import classNames from 'classnames';
import Chart from 'react-apexcharts';
import ChartUtil from '../utils/ChartUtil';
import ToExcel from '../utils/ToExcel';

import './StockData.css';
import InvestmentDataUtil from '../utils/InvestmentDataUtil';
import CommonUtil from '../utils/CommonUtil';
import HistInvestmentDataUtil from '../utils/HistInvestmentDataUtil';
import AuthUtil from '../utils/AuthUtil';
import { MemberType } from '../utils/MemberType';

const StockData: FC = () => {
  const [ t, i18n ] = useTranslation();
  const { myLang, setMyLang, memberType, setIsOpenUserRegistrationDialog } = useContext(AppContext);

  const { investmentData } = useContext(AppContext);
  const { histInvestmentData } = useContext(AppContext);

  // 選択している銘柄の証券コード
  const { selectedStockCode, setSelectedStockCode } = useContext(AppContext);

  // Y軸の最大文字数
  const [ maxNumOfCharaYAxis, setMaxNumOfCharAxis ] = useState<number>(4);

  const useDelay = (msec: number) => {
    const [waiting, setWaiting] = useState(true);
    useEffect(()=>{
      setTimeout(()=> setWaiting(false), msec);
    }, []);
    return waiting
  }
  // レンダリングを遅延させる。
  // # 物件名や住所などの表の幅を自動調整することにより、その下の経費率や内訳のグラフの幅が伸びてしまう模様。
  // # これを回避するため、表の表示よりもレンダリングを遅らせて対応する。
  const render_waiting = useDelay(1000);

  // チャートの縦サイズ
  const ChartHeight = window.devicePixelRatio >= 1.5 ? 280 : 345;
  const PieChartHeight = window.devicePixelRatio >= 1.5 ? 300 : 380;

  // チャートのタイトルのフォントサイズ
  const ChartTitleFontSize = '16px';
  // チャートのサブタイトルのフォントサイズ
  const ChartSubTitleFontSize = '11px';

  const GridInterval = 5;

  const StrokeWidth = 2.8;
  const StrokeDash = 3.5;
  const MarkerSize = 3.8;

  const DeepBlueColor = '#333F50';

  const MinWidth = 50;

  // チャートに表示する期の数
  const NumPeriodOnChart = 20;
  // Excelに出力する期の数
  const NumPeriodToExcel = 40;

  const FontSize = {
    Title: window.devicePixelRatio >= 1.5 ? '12px' : '16px',
    Data: window.devicePixelRatio >= 1.5 ? '10px' : '12px',
    ExpRatio: window.devicePixelRatio >= 1.5 ? '14px' : '18px',
  };

  useEffect(() => {
  }, [investmentData, histInvestmentData]);

  const get_selected_data = () => {
    if (investmentData.length === 0) return null;

    for (let d of investmentData) {
      if (d.stock_code === selectedStockCode) return d;
    }
    return null;
  }

  const get_selected_hist_data = () => {
    if (histInvestmentData.length === 0) return [];

    let selected_hist_data = [];
    for (let d of histInvestmentData) {
      if (d.stock_code === selectedStockCode) {
        selected_hist_data.push(d);
      }
    }

    // 配列の後ろが新しい期のデータになるようにする。
    selected_hist_data.sort((a, b) => {
      if (a.period > b.period) return 1;
      else if (a.period < b.period) return -1;
      else return 0;
    });
    return selected_hist_data;
  }

  const render_select_box = () => {
    if (investmentData.length === 0) return null;

    let select_options = [];
    for (let d of investmentData) {
      let stock_name = '';
      switch (myLang) {
        case MyLanguage.JA: stock_name = d.stock_name; break;
        case MyLanguage.EN: stock_name = d.stock_name_en; break;
      }
      select_options.push(<option value={d.stock_code} selected={selectedStockCode === d.stock_code}>{stock_name}</option>);
    }

    const handleMouseDown = (event: MouseEvent<HTMLSelectElement>): void => {
      if (AuthUtil.restricted(memberType, MemberType.C)) {
        setIsOpenUserRegistrationDialog(true);
        // optionの表示をキャンセルする。
        event.preventDefault();
        return;
      }
    }
  
    return (
      <select id="" className="" onMouseDown={handleMouseDown} onChange={((e: any) => {setSelectedStockCode(Number(e.target.value))})}>
        {select_options}
      </select>
    );
  }

  const render_detail = () => {
    const d = get_selected_data();
    if (d === null) return null;

    let rows = [];

    // 投資法人名
    {
      let tds = [];
      tds.push(<td>{t('投資法人名')}</td>);
      switch (myLang) {
        case MyLanguage.JA: {
          if (!d.investment_company_name) tds.push(<td></td>);
          else tds.push(<td>{d.investment_company_name}</td>);
          break;
        }
        case MyLanguage.EN: {
          if (!d.investment_company_name_en) tds.push(<td></td>);
          else tds.push(<td>{d.investment_company_name_en}</td>);
          break;
        }
      }
      rows.push(<tr>{tds}</tr>);  
    }

    // セクター
    {
      let tds = [];
      tds.push(<td>{t('セクター')}</td>);
      tds.push(<td>{t(InvestmentDataUtil.convert_sector_name(d.sector))}</td>)
      rows.push(<tr>{tds}</tr>);
    }

    // 物件数
    {
      let tds = [];
      tds.push(<td>{t('物件数')}</td>);
      
      if (d.property_num === null) {
        tds.push(<td>NA</td>);  
      } else {
        let text = d.property_num.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
        switch (myLang) {
          case MyLanguage.JA:
            text += ' 物件';
            break;
        }
        tds.push(<td>{text}</td>);
      }
      rows.push(<tr>{tds}</tr>);
    }

    // 築年数
    {
      let tds = [];
      tds.push(<td>{t('築年数(投資)')}</td>);

      if (d.building_age === null) {
        tds.push(<td>NA</td>);  
      } else {
        let text = d.building_age.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1});
        switch (myLang) {
          case MyLanguage.JA: {
            text = `${text} 年`;
            break;
          }
          case MyLanguage.EN: {
            text = `${text} years`;
            break;
          }
        }
        tds.push(<td>{text}</td>);  
      }
      rows.push(<tr>{tds}</tr>);
    }

    // 取得価格合計
    {
      let tds = [];
      tds.push(<td>{t('取得価格合計')}</td>);

      if (d.purchase_price === null) {
        tds.push(<td>NA</td>);
      } else {
        let text = '';
        switch (myLang) {
          case MyLanguage.JA: {
            text = `${d.purchase_price.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} 億円`;
            tds.push(<td>{text}</td>);  
            break;
          }
          case MyLanguage.EN: {
            // billion表記なので、10で割る。
            text = `${(d.purchase_price / 10).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}bn`;
            tds.push(<td>&yen; {text}</td>);  
            break;
          }
          default:
            tds.push(<td>{text}</td>);  
        }
      }
      rows.push(<tr>{tds}</tr>);
    }

    // 鑑定評価額（時価）
    {
      let tds = [];
      tds.push(<td>{t('鑑定評価額（時価）')}</td>);

      if (d.appraisal_value === null) {
        tds.push(<td>NA</td>);
      } else {
        let text = '';
        switch (myLang) {
          case MyLanguage.JA: {
            text = d.appraisal_value.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + ' 億円';
            tds.push(<td>{text}</td>);
            break;
          }
          case MyLanguage.EN: {
            // billion表記なので、10で割る。
            text = (d.appraisal_value / 10).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + 'bn';
            tds.push(<td>&yen; {text}</td>);
            break;
          }
          default:
            tds.push(<td>{text}</td>);
        }
      }
      rows.push(<tr>{tds}</tr>);
    }

    // 発行済口数
    {
      let tds = [];
      tds.push(<td>{t('発行済口数')}</td>);

      if (d.issued_investment === null) {
        tds.push(<td>NA</td>);
      } else {
        let text = d.issued_investment.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
        switch (myLang) {
          case MyLanguage.JA: {
            text = `${text} 口`;
            break;
          }
          case MyLanguage.EN: {
            text = `${text} shares`;
            break;
          }
        }
        tds.push(<td>{text}</td>);
      }
      rows.push(<tr>{tds}</tr>);
    }

    // 決算期
    {
      let tds = [];
      tds.push(<td>{t('決算期')}</td>);
      tds.push(<td>{InvestmentDataUtil.convert_fiscal_period(d.fiscal_period, myLang)}</td>);
      rows.push(<tr>{tds}</tr>);
    }

    // 上場日
    {
      let tds = [];
      tds.push(<td>{t('上場日')}</td>);
      tds.push(<td>{CommonUtil.convert_YearMonth(d.listing_date, myLang)}</td>);
      rows.push(<tr>{tds}</tr>);
    }

    // 資産運用会社
    {
      let tds = [];
      tds.push(<td>{t('資産運用会社')}</td>);

      switch (myLang) {
        case MyLanguage.JA: {
          if (!d.asset_management_company_name) tds.push(<td></td>);
          else tds.push(<td>{d.asset_management_company_name}</td>);
          break;
        }
        case MyLanguage.EN: {
          if (!d.asset_management_company_name_en) tds.push(<td></td>);
          else tds.push(<td>{d.asset_management_company_name_en}</td>);
          break;
        }
      }
      rows.push(<tr>{tds}</tr>);
    }

    // スポンサー企業
    {
      let tds = [];
      tds.push(<td>{t('スポンサー企業')}</td>);
      
      let sponsors = '';
      switch (myLang) {
        case MyLanguage.JA: {
          if (d.sponsor !== null) sponsors = d.sponsor;
          break;
        }
        case MyLanguage.EN: {
          if (d.sponsor_en !== null) sponsors = d.sponsor_en;
          break;
        }
      }

      let rendering = [];
      const splits = sponsors.split('|');
      for (let i=0; i<splits.length; i++) {
        const sponsor = splits[i];
        if (i > 0) rendering.push(<br/>);
        rendering.push(sponsor);
      }
      tds.push(<td>{rendering}</td>);
      rows.push(<tr>{tds}</tr>);
    }

    // 証券コード
    {
      let tds = [];
      tds.push(<td>{t('証券コード')}</td>);
      tds.push(<td>{d.stock_code.toString()}</td>);
      rows.push(<tr>{tds}</tr>);
    }

    return (
      <>
        <table>
          {rows}
        </table>
      </>
    );
  }

  /**
   * 投資エリアの円グラフを描画する。
   * 
   * @returns 投資エリアの円グラフ
   */
  const render_pie_chart_investment_area = () => {
    const d = get_selected_data();
    if (d === null) return null;

    let series: number[] = [];
    let colors: string[] = [];
    let dataLabelFontColors: string[] = [];
    let labels: string[] = [];

    if (d.tokyo_5_main_ward !== null) {
      series.push(d.tokyo_5_main_ward * 100);
      colors.push('#222B35');
      dataLabelFontColors.push('#FFFFFF');
      labels.push(t('都心5区'));
    }
    if (d.tokyo_23_ward !== null) {
      series.push(d.tokyo_23_ward * 100);
      colors.push('#333F4F');
      dataLabelFontColors.push('#FFFFFF');
      labels.push(t('東京23区'));
    }
    if (d.tokyo_area !== null) {
      series.push(d.tokyo_area * 100);
      colors.push('#8497B0');
      dataLabelFontColors.push('#FFFFFF');
      labels.push(t('東京圏'));
    }
    if (d.osaka_area !== null) {
      series.push(d.osaka_area * 100);
      colors.push('#ACB9CA');
      dataLabelFontColors.push('#000000');
      labels.push(t('大阪圏'));
    }
    if (d.nagoya_area !== null) {
      series.push(d.nagoya_area * 100);
      colors.push('#D6DCE4');
      dataLabelFontColors.push('#000000');
      labels.push(t('名古屋圏'));
    }
    if (d.sapporo_city !== null) {
      series.push(d.sapporo_city * 100);
      colors.push('#D9E1F2');
      dataLabelFontColors.push('#000000');
      labels.push(t('札幌市'));
    }
    if (d.fukuoka_city !== null) {
      series.push(d.fukuoka_city * 100);
      colors.push('#DDEBF7');
      dataLabelFontColors.push('#000000');
      labels.push(t('福岡市'));
    }
    if (d.others_area !== null) {
      series.push(d.others_area * 100);
      colors.push('#EDEDED');
      dataLabelFontColors.push('#000000');
      labels.push(t('その他'));
    }

    const options = get_pie_chart_options(t('投資エリア'), labels, colors, dataLabelFontColors);

    return (
      <div className={classNames("Chart", "pie_chart")}>
        <Chart type="donut" options={options} series={series} height={ChartHeight + 15} />
      </div>
    );
  }

  /**
   * 物件タイプの円グラフを描画する。
   * 
   * @returns 物件タイプの円グラフ
   */
  const render_pie_chart_property_type = () => {
    const d = get_selected_data();
    if (d === null) return null;

    let series: number[] = [];
    let colors: string[] = [];
    const dataLabelFontColors: string[] = ['#000000'];
    let labels: string[] = [];

    if (d.property_ratio.super_high_office !== null) {
      series.push(d.property_ratio.super_high_office * 100);
      colors.push('#ED7D31');
      labels.push(t('超高層オフィス'));
    }
    if (d.property_ratio.high_office !== null) {
      series.push(d.property_ratio.high_office * 100);
      colors.push('#F4B084');
      labels.push(t('高層オフィス'));
    }
    if (d.property_ratio.middle_office !== null) {
      series.push(d.property_ratio.middle_office * 100);
      colors.push('#F8CBAD');
      labels.push(t('中低層オフィス'));
    }
    if (d.property_ratio.super_high_resi !== null) {
      series.push(d.property_ratio.super_high_resi * 100);
      colors.push('#FFD966');
      labels.push(t('超高層レジ'));
    }
    if (d.property_ratio.high_resi !== null) {
      series.push(d.property_ratio.high_resi * 100);
      colors.push('#FFE699');
      labels.push(t('高層レジ'));
    }
    if (d.property_ratio.middle_resi !== null) {
      series.push(d.property_ratio.middle_resi * 100);
      colors.push('#FFF2CC');
      labels.push(t('中低層レジ'));
    }
    if (d.property_ratio.large_senior_resi !== null) {
      series.push(d.property_ratio.large_senior_resi * 100);
      colors.push('#FFFFAF');
      labels.push(t('大型シニアレジ'));
    }
    if (d.property_ratio.middle_senior_resi !== null) {
      series.push(d.property_ratio.middle_senior_resi * 100);
      colors.push('#FFFFA9');
      labels.push(t('中型シニアレジ'));
    }
    if (d.property_ratio.urban_retail !== null) {
      series.push(d.property_ratio.urban_retail * 100);
      colors.push('#FFFF00');
      labels.push(t('都心型リテール'));
    }
    if (d.property_ratio.suburban_retail !== null) {
      series.push(d.property_ratio.suburban_retail * 100);
      colors.push('#FFFF66');
      labels.push(t('郊外型リテール'));
    }
    if (d.property_ratio.large_log !== null) {
      series.push(d.property_ratio.large_log * 100);
      colors.push('#A9D08E');
      labels.push(t('大型ロジ'));
    }
    if (d.property_ratio.middle_log !== null) {
      series.push(d.property_ratio.middle_log * 100);
      colors.push('#C6E0B4');
      labels.push(t('中型ロジ'));
    }
    if (d.property_ratio.factory !== null) {
      series.push(d.property_ratio.factory * 100);
      colors.push('#E2EFDA');
      labels.push(t('工場・インフラ施設(物件タイプ)'));
    }
    if (d.property_ratio.large_hotel !== null) {
      series.push(d.property_ratio.large_hotel * 100);
      colors.push('#BDD7EE');
      labels.push(t('大型ホテル'));
    }
    if (d.property_ratio.middle_hotel !== null) {
      series.push(d.property_ratio.middle_hotel * 100);
      colors.push('#DDEBF7');
      labels.push(t('中型ホテル'));
    }
    if (d.property_ratio.others_property !== null) {
      series.push(d.property_ratio.others_property * 100);
      colors.push('#E7E6E6');
      labels.push(t('その他'));
    }

    const options = get_pie_chart_options(t('物件タイプ'), labels, colors, dataLabelFontColors);

    return (
      <div className={classNames("Chart", "pie_chart")}>
        <Chart type="donut" options={options} series={series} height={ChartHeight}/>
      </div>
    );
  }

  const render_Chart_ExpRatio = () => {
    const d = get_selected_data();
    if (d === null) return null;
    if (d.exp.expense_ratio === null) return null;

    // 半円のため、2倍する。
    const series = [d.exp.expense_ratio * 100 * 2];
    const options: any = {
      chart: {
        type: 'radialBar',
        // offsetY: -10,
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#efefef",
            strokeWidth: '100%',
            margin: 5, // margin is in pixels
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -15,
              fontSize: FontSize.ExpRatio,
              fontWeight: 'bold',
              formatter: function (val: number) {
                if (val === null || val === undefined) return '';
                // 表示は元の数字にする。
                return (val / 2).toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';  // カンマ区切り＆小数点以下1桁
              }
            },
          }
        }
      },
      grid: {
        padding: {
          top: -10,
        }
      },
      fill: {
        colors: [ '#222B35' ],
        type: 'solid',
      },
      stroke: {
        lineCap: 'round'
      },
    };

    return (
      <div className={classNames('Chart', 'Chart_ExpRatio')}>
        <div className="title">
          <span className='balloon_parent'>
            {t('経費率')}
            {render_balloon([t('tooltip.Chart_ExpRatio.1'), t('tooltip.Chart_ExpRatio.2')], 'center')}
          </span>
        </div>
        <div className="chart"><Chart type="radialBar" options={options} series={series} /></div>
      </div>
    );
  }


  const render_Chart_ExpDetail = () => {
    const d = get_selected_data();
    if (d === null) return null;

    let data = [];
    let categories = [];
    let colors = [];

    const digits = 2;
    if (d.exp.pm_bm !== null) {
      data.push(ChartUtil.to_percentage(d.exp.pm_bm, digits));
      categories.push(t('外注委託費'));
      colors.push('#222B35');
    }
    if (d.exp.bm !== null) {
      data.push(ChartUtil.to_percentage(d.exp.bm, digits));
      categories.push(t('建物管理委託'));
      colors.push('#222B35');
    }
    if (d.exp.pm !== null) {
      data.push(ChartUtil.to_percentage(d.exp.pm, digits));
      categories.push(t('マネジメント報酬'));
      colors.push('#222B35');
    }
    if (d.exp.utility !== null) {
      data.push(ChartUtil.to_percentage(d.exp.utility, digits));
      categories.push(t('水光熱費'));
      colors.push('#333F4F');
    }
    if (d.exp.repair !== null) {
      data.push(ChartUtil.to_percentage(d.exp.repair, digits));
      categories.push(t('修繕費'));
      colors.push('#8497B0');
    }
    if (d.exp.tax !== null) {
      data.push(ChartUtil.to_percentage(d.exp.tax, digits));
      categories.push(t('公租公課'));
      colors.push('#ACB9CA');
    }
    if (d.exp.insurance !== null) {
      data.push(ChartUtil.to_percentage(d.exp.insurance, digits));
      categories.push(t('保険料'));
      colors.push('#D6DCE4');
    }
    if (d.exp.brokerage !== null) {
      data.push(ChartUtil.to_percentage(d.exp.brokerage, digits));
      categories.push(t('仲介手数料'));
      colors.push('#D9E1F2');
    }
    if (d.exp.trust !== null) {
      data.push(ChartUtil.to_percentage(d.exp.trust, digits));
      categories.push(t('信託報酬'));
      colors.push('#DDEBF7');
    }
    if (d.exp.others_exp !== null) {
      data.push(ChartUtil.to_percentage(d.exp.others_exp, digits));
      categories.push(t('その他経費'));
      colors.push('#EDEDED');
    }

    if (data.length === 0) return null;

    let series = [{ data: data }];

    // データラベルがバーの外側に表示できるように、バーの先端とチャート右端とに余裕を設ける。
    const grid_max = Math.trunc((Math.max(...data) + 20) / 10) * 10;
    // x軸が10間隔になるようにする。
    const tickAmount = grid_max / 10;

    const options: any = {
      chart: get_opt_chart('bar', undefined),
      plotOptions: {
        bar: {
          barHeight: '70%',
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'top'
          },
        }
      },
      colors: colors,
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        offsetX: 20,
        style: {
          fontSize: FontSize.Data,
          // fontFamily: 'Helvetica, Arial, sans-serif',
          // fontWeight: 'bold',
          colors: [ '#000']
        },
        formatter: function (val: number, opt: any) {
          return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';  // カンマ区切り＆小数点以下1桁
        }
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: categories,
        min: 0,
        max: grid_max,
        tickAmount: tickAmount,
        labels: {
          formatter: function(val: number, index: any) {
            if (val === null || val === undefined) return '';
            if (val > 100) return '';
            return val;
          },
          style: {
            fontSize: FontSize.Data,
          }  
        }
      },
      grid: {
        xaxis: { lines: { show: true } },   
        yaxis: { lines: { show: false } },
        padding: { right: 30 }
      },
      legend: { show: false, fontSize: FontSize.Data },
      tooltip: {
        style: {
          fontSize: FontSize.Data,
        },
        y: {
          title: {
            formatter: function (val: any, opts: any) {
              return '';
            }
          },
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';  // カンマ区切り＆小数点以下1桁
          }
        },
      }
    }
    return (
      <div className={classNames('Chart', 'Chart_ExpDetail')}><Chart type="bar" options={options} series={series} /></div>
    );
  }

  const render_Chart_Party = () => {
    const d = get_selected_data();
    if (d === null) return null;

    const colors = [DeepBlueColor, '#D0CECE'];

    const am_fee = d.fee.am_fee ? d.fee.am_fee * 100 : null;
    const related_party_exp_ratio = d.fee.related_party_exp_ratio ? d.fee.related_party_exp_ratio * 100 : null;

    // REIT平均
    let am_fee_reit_ave = InvestmentDataUtil.calc_average(investmentData, (d) => { return d.fee.am_fee });
    if (am_fee_reit_ave !== null) am_fee_reit_ave *= 100;

    let related_party_exp_ratio_reit_ave = InvestmentDataUtil.calc_average(investmentData, (d) => { return d.fee.related_party_exp_ratio });
    if (related_party_exp_ratio_reit_ave !== null) related_party_exp_ratio_reit_ave *= 100;

    const series = [{
      name: t('本投資法人'),
      data: [am_fee, related_party_exp_ratio]
    }, {
      name: `${t('REIT平均')}${t('（前期）')}`,
      data: [am_fee_reit_ave, related_party_exp_ratio_reit_ave]
    }];
    const options: any = {
      chart: {
        type: 'bar',
        toolbar: { show: false },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '60%',
          dataLabels: {
            position: 'top',
          },  
        },
      },
      colors: colors,
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          colors: ['#000'],
          fontSize: FontSize.Data,
          // fontWeight: 'bold',  
        },
        formatter: function (val: number) {
          if (val === null || val === undefined) return '';
          // 表示は元の数字にする。
          return CommonUtil.get_percent_text(val, 1) + '%';
        },
      },
      stroke: {
        show: true,
        width: 2,
      },
      xaxis: {
        categories: [t('運用報酬割合'), t('関連会社費用割合')],
      },
      yaxis: {
        max: Math.max(
          am_fee === null ? 0 : am_fee,
          related_party_exp_ratio === null ? 0 : related_party_exp_ratio,
          am_fee_reit_ave === null ? 0 : am_fee_reit_ave,
          related_party_exp_ratio_reit_ave === null ? 0 : related_party_exp_ratio_reit_ave
        ) * 1.1,
        forceNiceScale: true,
        labels: {
          formatter: function(val: number, index: any) {
            if (val === null || val === undefined) return '';
            return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + '%';
          },
          style: {
            fontSize: FontSize.Data,
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        style: {
          fontSize: FontSize.Data,
        },
        x: {
          formatter: function(val: string, opts: any) {
            if (val === null || val === undefined) return 'No data';
            console.log(val, opts);
            if (opts.dataPointIndex === 0) {
              return `${val}<br/><span class="tooltip">${t('tooltip.AM_Fee.1')}<br/>${t('tooltip.AM_Fee.2')}</span>`; 
            } else {
              return `${val}<br/><span class="tooltip">${t('tooltip.Related_Party_Exp_Ratio.1')}<br/>${t('tooltip.Related_Party_Exp_Ratio.2')}</span>`; 
            }
          }
        },
        y: {
          formatter: function(val: number) {
            if (val === null || val === undefined) return 'No data';
            if (val === 100) {
              // 100%の場合は小数点表記なし。
              return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + '%';  // カンマ区切り＆小数点以下0桁
            } else {
              return CommonUtil.get_percent_text(val, 1) + '%';
            }  
          }
        }
      }
    };

    const render_am_fee_pp = () => {
      const am_fee_pp = d.fee.am_fee_pp ? d.fee.am_fee_pp * 100 : null;

      let am_fee_pp_reit_ave = InvestmentDataUtil.calc_average(investmentData, (d) => { return d.fee.am_fee_pp });
      if (am_fee_pp_reit_ave !== null) am_fee_pp_reit_ave *= 100;
  
      let rendering = [];
      if (am_fee_pp !== null) {
        rendering.push(
          <span className='balloon_parent'>
            <b>{t('取得価格割合')}{t('：')}{CommonUtil.get_percent_text(am_fee_pp, 1) + '%'}</b>
            {render_balloon([t('tooltip.AM_Fee_PP.1'), t('tooltip.AM_Fee_PP.2')], 'left')}
          </span>
        )
      }
      if (am_fee_pp_reit_ave !== null) {
        rendering.push(<span><b>{t('REIT平均')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t('：')}{CommonUtil.get_percent_text(am_fee_pp_reit_ave, 1) + '%'}</b></span>);
      }

      return (<div className='am_fee_pp'>{rendering}</div>);
    }

    return (
      <div className={classNames('Chart', '')}>
        {render_am_fee_pp()}
        <Chart type="bar" options={options} series={series} height={ChartHeight} />
      </div>
    );
  }

  const render_Chart_Sourcing = () => {
    const d = get_selected_data();
    if (d === null) return null;

    let series: number[] = [];
    let colors: string[] = [];
    let dataLabelFontColors: string[] = [];
    let labels: string[] = [];

    // 本投資法人
    if (d.sourcing.related_party !== null) {
      series.push(d.sourcing.related_party * 100);
      colors.push('#222B35');
      dataLabelFontColors.push('#FFFFFF');
      labels.push(t('関連会社'));
    }
    if (d.sourcing.non_related_party !== null) {
      series.push(d.sourcing.non_related_party * 100);
      colors.push('#8497B0');
      dataLabelFontColors.push('#FFFFFF');
      labels.push(t('非関連会社'));
    }

    // データなし
    if (series.length === 0) return null;

    const options = get_pie_chart_options('', labels, colors, dataLabelFontColors);

    // REIT平均
    let related_party_ave = InvestmentDataUtil.calc_average(investmentData, (d) => { return d.sourcing.related_party });
    if (related_party_ave !== null) related_party_ave *= 100;

    let non_related_party_ave = InvestmentDataUtil.calc_average(investmentData, (d) => { return d.sourcing.non_related_party });
    if (non_related_party_ave !== null) non_related_party_ave *= 100;

    const render_related_party_ave = (related_party_ave: number | null) => {
      if (related_party_ave === null) return null;
      const num = CommonUtil.get_percent_text(related_party_ave, 1);
      const text = myLang === MyLanguage.JA ?  `関連　：${num}%` : t('関連会社：XX%', { num: num });
      return (
        <li>{text}</li>
      );
    }
    const render_non_related_party_ave = (non_related_party_ave: number | null) => {
      if (non_related_party_ave === null) return null;
      const num = CommonUtil.get_percent_text(non_related_party_ave, 1);
      const text = myLang === MyLanguage.JA ? `非関連：${num}%` : t('非関連会社：XX%', { num: num });
      return (
        <li>{text}</li>
      )
    }

    const render_reit_ave = () => {
      return (
        <div className="reit_ave">
          <div>&nbsp;&nbsp;&nbsp;&nbsp;{t('REIT平均')}</div>
          <ul>
            {render_related_party_ave(related_party_ave)}
            {render_non_related_party_ave(non_related_party_ave)}
          </ul>
        </div>
      );
    }

    return (
      <div className={classNames("Chart", "pie_chart", "Sourcing")}>
        { myLang !== MyLanguage.JA ? render_reit_ave() : null }
        <div className="title">
          <span className='balloon_parent'>
            {t('ソーシング')}
            {render_balloon([t('tooltip.Chart_Sourcing.1')], 'center')}
          </span>
        </div>
        <div className="wrapper">
          <div><Chart type="donut" options={options} series={series} height={PieChartHeight} /></div>
          { myLang === MyLanguage.JA ? render_reit_ave() : null }
        </div>
      </div>
    );
  }

  const render_Chart_Debt = () => {
    const d = get_selected_data();
    if (d === null) return null;

    let series: number[] = [];
    let colors: string[] = [];
    let dataLabelFontColors: string[] = [];
    let labels: string[] = [];

    // 本投資法人
    if (d.debt.long_term_debt !== null) {
      series.push(d.debt.long_term_debt * 100);
      colors.push('#222B35');
      dataLabelFontColors.push('#FFFFFF');
      labels.push(t('長期借入'));
    }
    if (d.debt.short_term_debt !== null) {
      series.push(d.debt.short_term_debt * 100);
      colors.push('#8497B0');
      dataLabelFontColors.push('#FFFFFF');
      labels.push(t('短期借入'));
    }

    // データなし
    if (series.length === 0) return null;

    const options = get_pie_chart_options(t('負債比率'), labels, colors, dataLabelFontColors);

    // REIT平均（前期）
    let long_term_debt_ave = InvestmentDataUtil.calc_average(investmentData, (d) => { return d.debt.long_term_debt });
    if (long_term_debt_ave !== null) long_term_debt_ave *= 100;

    let short_term_debt_ave = InvestmentDataUtil.calc_average(investmentData, (d) => { return d.debt.short_term_debt });
    if (short_term_debt_ave !== null) short_term_debt_ave *= 100;

    const render_long_term_debt_ave = (long_term_debt_ave: number | null) => {
      if (long_term_debt_ave === null) return null;
      const num = CommonUtil.get_percent_text(long_term_debt_ave, 1);
      const text = myLang === MyLanguage.JA ? `長期：${num}%` : t('長期借入：XX%', { num: num });
      return (
        <li>{text}</li>
      );
    }
    const render_short_term_debt_ave = (short_term_debt_ave: number | null) => {
      if (short_term_debt_ave === null) return null;
      const num = CommonUtil.get_percent_text(short_term_debt_ave, 1);
      const text = myLang === MyLanguage.JA ? `短期：${num}%` : t('短期借入：XX%', { num: num });
      return (
        <li>{text}</li>
      );
    }

    const render_reit_ave = () => {
      return (
        <div className="reit_ave">
          <div>&nbsp;&nbsp;{t('REIT平均')}</div>
          <ul>
            {render_long_term_debt_ave(long_term_debt_ave)}
            {render_short_term_debt_ave(short_term_debt_ave)}
          </ul>
        </div>
      );
    }

    return (
      <div className={classNames("Chart", "pie_chart")}>
        { myLang !== MyLanguage.JA ? render_reit_ave() : null }
        <div className="wrapper">
          <div><Chart type="donut" options={options} series={series} height={PieChartHeight} /></div>
          { myLang === MyLanguage.JA ? render_reit_ave() : null }
        </div>
      </div>
    );
  }

  const render_Chart_Investor = () => {
    const d = get_selected_data();
    if (d === null) return null;

    let series: number[] = [];
    let colors: string[] = [];
    let dataLabelFontColors: string[] = [];
    let labels: string[] = [];

    if (d.investor.financial_institution !== null) {
      series.push(d.investor.financial_institution * 100);
      colors.push('#222B35');
      dataLabelFontColors.push('#FFFFFF');
      labels.push(t('金融機関'));
    }
    if (d.investor.foreign_corporation !== null) {
      series.push(d.investor.foreign_corporation * 100);
      colors.push('#333F4F');
      dataLabelFontColors.push('#FFFFFF');
      labels.push(t('外国法人'));
    }
    if (d.investor.individual !== null) {
      series.push(d.investor.individual * 100);
      colors.push('#8497B0');
      dataLabelFontColors.push('#FFFFFF');
      labels.push(t('個人'));
    }
    if (d.investor.others_investor !== null) {
      series.push(d.investor.others_investor * 100);
      colors.push('#ACB9CA');
      dataLabelFontColors.push('#000000');
      labels.push(t('その他'));
    }

    // データなし
    if (series.length === 0) return null;

    const options = get_pie_chart_options(t('投資主'), labels, colors, dataLabelFontColors);

    // REIT平均（前期）

    let financial_institution_ave = InvestmentDataUtil.calc_average(investmentData, (d) => { return d.investor.financial_institution });
    if (financial_institution_ave !== null) financial_institution_ave *= 100;

    let foreign_corporation_ave = InvestmentDataUtil.calc_average(investmentData, (d) => { return d.investor.foreign_corporation });
    if (foreign_corporation_ave !== null) foreign_corporation_ave *= 100;
  
    let individual_ave = InvestmentDataUtil.calc_average(investmentData, (d) => { return d.investor.individual });
    if (individual_ave !== null) individual_ave *= 100;

    let others_investor_ave = InvestmentDataUtil.calc_average(investmentData, (d) => { return d.investor.others_investor });
    if (others_investor_ave !== null) others_investor_ave *= 100;


    const render_financial_institution_ave = (financial_institution_ave: number | null) => {
      if (financial_institution_ave === null) return null;
      const num = CommonUtil.get_percent_text(financial_institution_ave, 1);
      const head = myLang === MyLanguage.JA ? '金融' : t('金融機関');
      return (
        <li>{`${head}${t('：')}${num}%`}</li>
      );
    }
    const render_foreign_corporation_ave = (foreign_corporation_ave: number | null) => {
      if (foreign_corporation_ave === null) return null;
      const num = CommonUtil.get_percent_text(foreign_corporation_ave, 1);
      const head = myLang === MyLanguage.JA ? '外国' : t('外国法人');
      return (
        <li>{`${head}${t('：')}${num}%`}</li>
      );
    }
    const render_individual_ave = (individual_ave: number | null) => {
      if (individual_ave === null) return null;
      const num = CommonUtil.get_percent_text(individual_ave, 1);
      return (
        <li>{`${t('個人')}${t('：')}${num}%`}</li>
      );
    }
    const render_others_investor_ave = (others_investor_ave: number | null) => {
      if (others_investor_ave === null) return null;
      const num = CommonUtil.get_percent_text(others_investor_ave, 1);
      const head = myLang === MyLanguage.JA ? '他　' : t('その他');
      return (
        <li>{`${head}${t('：')}${num}%`}</li>
      );
    }

    const render_reit_ave = () => {
      return (
        <div className="reit_ave">
          <div>&nbsp;&nbsp;{t('REIT平均')}</div>
          <ul>
            {render_financial_institution_ave(financial_institution_ave)}
            {render_foreign_corporation_ave(foreign_corporation_ave)}
            {render_individual_ave(individual_ave)}
            {render_others_investor_ave(others_investor_ave)}
          </ul>
        </div>
      );
    }

    return (
      <div className={classNames("Chart", "pie_chart")}>
        { myLang !== MyLanguage.JA ? render_reit_ave() : null }
        <div className="wrapper">
          <Chart type="donut" options={options} series={series} height={PieChartHeight} />
          { myLang === MyLanguage.JA ? render_reit_ave() : null }
        </div>
      </div>
    );
  }


  const render_Chart_Price_Num_Age = () => {
    const selected_hist_data = get_selected_hist_data();
    if (selected_hist_data.length === 0) return [];

    const start_i = selected_hist_data.length > NumPeriodOnChart ? (selected_hist_data.length - NumPeriodOnChart) : 0;

    let period_list = [];

    let histData_PurchasePrice = [];
    let histData_PropertyNum = [];
    let histData_BuildingAge = [];
    let histData_PurchasePrice_NotNull: number[] = [];
    let histData_PropertyNum_NotNull: number[] = [];
    let histData_BuildingAge_NotNull: number[] = [];

    for (let i=start_i; i < selected_hist_data.length; i++) {
      const d = selected_hist_data[i];

      period_list.push(d.period);

      let purchase_price = d.purchase_price;
      if (purchase_price !== null) {
        switch (myLang) {
          // 英語版では、billionで表現するため、10で割る。
          case MyLanguage.EN: purchase_price /= 10; break;
        }  
      }
      const property_num = d.property_num;
      const building_age = d.building_age;

      histData_PurchasePrice.push(purchase_price);
      histData_PropertyNum.push(property_num);
      histData_BuildingAge.push(building_age);

      if (purchase_price !== null) histData_PurchasePrice_NotNull.push(purchase_price);
      if (property_num !== null) histData_PropertyNum_NotNull.push(property_num);
      if (building_age !== null) histData_BuildingAge_NotNull.push(building_age);
    }

    const series = [
      {
        name: t('取得価格'),
        type: 'column',
        data: histData_PurchasePrice
      },
      {
        name: t('物件数'),
        type: 'line',
        data: histData_PropertyNum
      },
      {
        name: t('築年数(投資)'),
        type: 'line',
        data: histData_BuildingAge
      }
    ];
    

    const options = create_Price_Num_Age_chart_opts(period_list, histData_PurchasePrice_NotNull, histData_PropertyNum_NotNull, histData_BuildingAge_NotNull);

    return (<div className="Chart"><Chart type='line' options={options} series={series} height={ChartHeight} width={get_right_chart_width()} /></div>);
  }

  const render_Chart_Appraisal_BookPrice = () => {
    const selected_hist_data = get_selected_hist_data();
    if (selected_hist_data.length === 0) return [];

    const start_i = selected_hist_data.length > NumPeriodOnChart ? (selected_hist_data.length - NumPeriodOnChart) : 0;

    let period_list = [];

    let histData_Appraisal = [];
    let histData_BookPrice = [];
    let histData_NotNull: number[] = [];

    for (let i=start_i; i < selected_hist_data.length; i++) {
      const d = selected_hist_data[i];

      period_list.push(d.period);

      let appraisal_value = d.appraisal_value;
      let book_value = d.book_value;
      switch (myLang) {
        // 英語版では、billionで表現するため、10で割る。
        case MyLanguage.EN: {
          if (appraisal_value !== null) {
            appraisal_value /= 10;
          }
          if (book_value !== null) {
            book_value /= 10;
          }
          break;
        }
      }

      histData_Appraisal.push(appraisal_value);
      histData_BookPrice.push(book_value);

      if (appraisal_value !== null) histData_NotNull.push(appraisal_value);
      if (book_value !== null) histData_NotNull.push(book_value);
    }

    const series = [
      {
        name: t('簿価'),
        // type: 'line',
        data: histData_BookPrice
      },
      {
        name: t('鑑定評価額'),
        // type: 'column',
        data: histData_Appraisal
      },
    ];
    

    const options = create_Appraisal_BookPrice_chart_opts(period_list, histData_NotNull);

    return (<div className="Chart"><Chart type='bar' options={options} series={series} height={ChartHeight} width={get_right_chart_width()} /></div>);
  }

  const render_Chart_AppraisalGainRatio = () => {
    const selected_hist_data = get_selected_hist_data();
    if (selected_hist_data.length === 0) return [];

    const start_i = selected_hist_data.length > NumPeriodOnChart ? (selected_hist_data.length - NumPeriodOnChart) : 0;

    let period_list = [];

    let histData_AppraisalGainRatio = [];
    let histData_AppraisalGainRatio_ReitAve = [];
    let histData_NotNull: number[] = [];
    let histData_dummy = [];

    for (let i=start_i; i < selected_hist_data.length; i++) {
      const d = selected_hist_data[i];

      period_list.push(d.period);

      let appraisal_gain_ratio = d.appraisal_gain_ratio;
      if (appraisal_gain_ratio !== null) {
        appraisal_gain_ratio *= 100;
      }

      let appraisal_gain_ratio_reitAve = HistInvestmentDataUtil.calc_average(histInvestmentData, d.period, (hist_d => { return hist_d.appraisal_gain_ratio}));
      if (appraisal_gain_ratio_reitAve !== null) appraisal_gain_ratio_reitAve *= 100;

      histData_AppraisalGainRatio.push(appraisal_gain_ratio);
      histData_AppraisalGainRatio_ReitAve.push(appraisal_gain_ratio_reitAve);
      histData_dummy.push(null);

      if (appraisal_gain_ratio !== null) histData_NotNull.push(appraisal_gain_ratio);
      if (appraisal_gain_ratio_reitAve !== null) histData_NotNull.push(appraisal_gain_ratio_reitAve);
    }

    let series = [
      {
        name: t('含み益率'),
        type: 'line',
        data: histData_AppraisalGainRatio
      },
      {
        name: t('REIT平均'),
        type: 'line',
        data: histData_AppraisalGainRatio_ReitAve
      },
    ];
    // x軸の両端に少し余裕を持たせるため、ダミーの棒グラフを用意する。
    series.push({
      name: '',
      type: 'column',
      data: histData_dummy
    });
    
    const options = create_AppraisalGainRatio_chart_opts(period_list, histData_NotNull);

    return (<div className="Chart"><Chart type='line' options={options} series={series} height={ChartHeight} width={get_right_chart_width()} /></div>);
  }

  const render_Chart_MarketCap_StockPrice = () => {
    const selected_hist_data = get_selected_hist_data();
    if (selected_hist_data.length === 0) return [];

    const start_i = selected_hist_data.length > NumPeriodOnChart ? (selected_hist_data.length - NumPeriodOnChart) : 0;

    let period_list = [];

    let histData_MarketCap = [];
    let histData_StockPrice = [];
    let histData_MarketCap_NotNull: number[] = [];
    let histData_StockPrice_NotNull: number[] = [];

    for (let i=start_i; i < selected_hist_data.length; i++) {
      const d = selected_hist_data[i];

      period_list.push(d.period);

      let market_cap = d.market_cap;
      if (market_cap !== null) {
        switch (myLang) {
          // 英語版では、billionで表現するため、10で割る。
          case MyLanguage.EN: market_cap /= 10; break;
        }  
      }
//      const stock_price_E = d.stock_price_E;    //20231105 Change
      const stock_price = d.stock_price;    //20231219 Change

      histData_MarketCap.push(market_cap);
//      histData_StockPrice.push(stock_price_E);    //20231105 Change
      histData_StockPrice.push(stock_price);    //20231229 Change

      if (market_cap !== null) histData_MarketCap_NotNull.push(market_cap);
//      if (stock_price_E !== null) histData_StockPrice_NotNull.push(stock_price_E);    //20231105 Change
      if (stock_price !== null) histData_StockPrice_NotNull.push(stock_price);    //20231229 Change
    }

    const series = [
      {
        name: t('時価総額'),
        type: 'column',
        data: histData_MarketCap
      },
      {
        name: t('株価'),
        type: 'line',
        data: histData_StockPrice
      },
    ];

    const options = create_MarketCap_StockPrice_chart_opts(period_list, histData_MarketCap_NotNull, histData_StockPrice_NotNull);

    return (<div className="Chart"><Chart type='line' options={options} series={series} height={ChartHeight} width={get_right_chart_width()} /></div>);
  }

  const render_Chart_Dividend_DividendYield = () => {
    const selected_hist_data = get_selected_hist_data();
    if (selected_hist_data.length === 0) return [];

    const start_i = selected_hist_data.length > NumPeriodOnChart ? (selected_hist_data.length - NumPeriodOnChart) : 0;

    let period_list = [];

    let histData_Dividend = [];
    let histData_DividendYield = [];
    let histData_DividendYield_ReitAve = [];
    let histData_Dividend_NotNull: number[] = [];
    let histData_DividendYield_NotNull: number[] = [];  // Null以外の分配金利回り(Reit平均含む)

    for (let i=start_i; i < selected_hist_data.length; i++) {
      const d = selected_hist_data[i];

      period_list.push(d.period);

      const dividend = d.dividend;

      let dividend_yield = d.dividend_yield;
      if (dividend_yield !== null) {
        dividend_yield *= 100;
      }

      // Reit平均
      let dividendYield_reitAve = HistInvestmentDataUtil.calc_average(histInvestmentData, d.period, (hist_d => { return hist_d.dividend_yield}));
      if (dividendYield_reitAve !== null) dividendYield_reitAve *= 100;

      histData_Dividend.push(dividend);
      histData_DividendYield.push(dividend_yield);
      histData_DividendYield_ReitAve.push(dividendYield_reitAve);

      if (dividend !== null) histData_Dividend_NotNull.push(dividend);
      if (dividend_yield !== null) histData_DividendYield_NotNull.push(dividend_yield);
      if (dividendYield_reitAve !== null) histData_DividendYield_NotNull.push(dividendYield_reitAve);
    }

    const series = [
      {
        name: t('分配金'),
        type: 'column',
        data: histData_Dividend
      },
      {
        name: t('分配金利回り'),
        type: 'line',
        data: histData_DividendYield
      },
      {
        name: t('REIT平均'),
        type: 'line',
        data: histData_DividendYield_ReitAve
      },
    ];

    const options = create_Dividend_DividendYield_chart_opts(period_list, histData_Dividend_NotNull, histData_DividendYield_NotNull);

    return (<div className="Chart"><Chart type='line' options={options} series={series} height={ChartHeight} width={get_right_chart_width()} /></div>);
  }
  
  const render_Chart_Occupancy_NOIY = () => {
    const selected_hist_data = get_selected_hist_data();
    if (selected_hist_data.length === 0) return null;

    const start_i = selected_hist_data.length > NumPeriodOnChart ? (selected_hist_data.length - NumPeriodOnChart) : 0;

    let period_list = [];

    let histData_Occupancy = [];
    let histData_Occupancy_ReitAve = [];
    let histData_NOIY = [];
    let histData_NOIY_ReitAve = [];
    let histData_Occupancy_NotNull: number[] = [];
    let histData_NOIY_NotNull: number[] = [];

    for (let i=start_i; i < selected_hist_data.length; i++) {
      const d = selected_hist_data[i];

      period_list.push(d.period);

      let occupancy = d.occupancy;
      if (occupancy !== null) {
        occupancy *= 100;
      }
      let noiy = d.noi_yield;
      if (noiy !== null) {
        noiy *= 100;
      }

      // Reit平均
      let occupancy_reitAve = HistInvestmentDataUtil.calc_average(histInvestmentData, d.period, (hist_d => { return hist_d.occupancy}));
      if (occupancy_reitAve !== null) occupancy_reitAve *= 100;

      let noiy_reitAve = HistInvestmentDataUtil.calc_average(histInvestmentData, d.period, (hist_d => { return hist_d.noi_yield}));
      if (noiy_reitAve !== null) noiy_reitAve *= 100;

      histData_Occupancy.push(occupancy);
      histData_NOIY.push(noiy);
      histData_Occupancy_ReitAve.push(occupancy_reitAve);
      histData_NOIY_ReitAve.push(noiy_reitAve);

      if (occupancy !== null) histData_Occupancy_NotNull.push(occupancy);
      if (occupancy_reitAve !== null) histData_Occupancy_NotNull.push(occupancy_reitAve);
      if (noiy !== null) histData_NOIY_NotNull.push(noiy);
      if (noiy_reitAve !== null) histData_NOIY_NotNull.push(noiy_reitAve);
    }

    const series = [
      {
        name: t('稼働率'),
        type: 'area',
        data: histData_Occupancy
      },
      {
        name: t('REIT平均'),
        type: 'area',
        data: histData_Occupancy_ReitAve
      },
      {
        name: t('NOI利回り'),
        type: 'line',
        data: histData_NOIY
      },
      {
        name: t('REIT平均'),
        type: 'line',
        data: histData_NOIY_ReitAve
      },
    ];

    const options = create_Occupancy_NOIY_chart_opts(period_list, histData_Occupancy_NotNull, histData_NOIY_NotNull);

    return (<div className="Chart"><Chart type='line' options={options} series={series} height={ChartHeight} width={get_right_chart_width()} /></div>);
  }

  const render_Chart_ImpliedCR = () => {
    const selected_hist_data = get_selected_hist_data();
    if (selected_hist_data.length === 0) return null;
    const selected_data = get_selected_data();
    if (selected_data === null) return null;

    const start_i = selected_hist_data.length > NumPeriodOnChart ? (selected_hist_data.length - NumPeriodOnChart) : 0;

    let period_list = [];

    let histData_ImpliedCR = [];
    let histData_ImpliedCR_ReitAve = [];
    let histData_ImpliedCR_SectorAve = [];
    let histData_NotNull: number[] = [];
    let histData_dummy = [];

    const selected_sector = HistInvestmentDataUtil.get_sector(selectedStockCode, investmentData);
    if (selected_sector === null) {
      return null;
    }

    for (let i=start_i; i < selected_hist_data.length; i++) {
      const d = selected_hist_data[i];

      period_list.push(d.period);

      let implied_cr = d.implied_cr;
      if (implied_cr !== null) {
        implied_cr *= 100;
      }

      let implied_cr_reitAve = HistInvestmentDataUtil.calc_average(histInvestmentData, d.period, (hist_d => { return hist_d.implied_cr}));
      if (implied_cr_reitAve !== null) implied_cr_reitAve *= 100;

      let implied_cr_sectorAve = HistInvestmentDataUtil.calc_average_by_sector(investmentData, histInvestmentData, d.period, selected_sector, (hist_d => { return hist_d.implied_cr}));
      if (implied_cr_sectorAve !== null) implied_cr_sectorAve *= 100;

      histData_ImpliedCR.push(implied_cr);
      histData_ImpliedCR_ReitAve.push(implied_cr_reitAve);
      histData_ImpliedCR_SectorAve.push(implied_cr_sectorAve);
      histData_dummy.push(null);

      if (implied_cr !== null) histData_NotNull.push(implied_cr);
      if (implied_cr_reitAve !== null) histData_NotNull.push(implied_cr_reitAve);
      if (implied_cr_sectorAve !== null) histData_NotNull.push(implied_cr_sectorAve);
    }

    const series = [
      {
        name: 'Implied CR',
        type: 'line',
        data: histData_ImpliedCR
      },
      {
        name: t('REIT平均'),
        type: 'line',
        data: histData_ImpliedCR_ReitAve
      },
      {
        name: t('セクター平均', { sector: t(InvestmentDataUtil.convert_sector_name(selected_sector)) }),
        type: 'line',
        data: histData_ImpliedCR_SectorAve
      },
    ];
    // x軸の両端に少し余裕を持たせるため、ダミーの棒グラフを用意する。
    series.push({
      name: '',
      type: 'column',
      data: histData_dummy
    });

    const options = create_ImpliedCR_chart_opts(period_list, histData_NotNull);

    return (<div className="Chart"><Chart type='line' options={options} series={series} height={ChartHeight} width={get_right_chart_width()} /></div>);
  }

  const render_Chart_NavRatio_FfoRatio = () => {
    const selected_hist_data = get_selected_hist_data();
    if (selected_hist_data.length === 0) return null;

    const start_i = selected_hist_data.length > NumPeriodOnChart ? (selected_hist_data.length - NumPeriodOnChart) : 0;

    let period_list = [];

    let histData_NavRatio = [];
    let histData_NavRatio_ReitAve = [];
    let histData_FfoRatio = [];
    let histData_FfoRatio_ReitAve = [];
    let histData_NavRatio_NotNull: number[] = [];
    let histData_FfoRatio_NotNull: number[] = [];

    for (let i=start_i; i < selected_hist_data.length; i++) {
      const d = selected_hist_data[i];

      period_list.push(d.period);

      const nav_ratio = d.nav_ratio;
      const ffo_ratio = d.ffo_ratio;

      // Reit平均
      let navRatio_reitAve = HistInvestmentDataUtil.calc_average(histInvestmentData, d.period, (hist_d => { return hist_d.nav_ratio}));
      let ffoRatio_reitAve = HistInvestmentDataUtil.calc_average(histInvestmentData, d.period, (hist_d => { return hist_d.ffo_ratio}));

      histData_NavRatio.push(nav_ratio);
      histData_FfoRatio.push(ffo_ratio);
      histData_NavRatio_ReitAve.push(navRatio_reitAve);
      histData_FfoRatio_ReitAve.push(ffoRatio_reitAve);

      if (nav_ratio !== null) histData_NavRatio_NotNull.push(nav_ratio);
      if (navRatio_reitAve !== null) histData_NavRatio_NotNull.push(navRatio_reitAve);
      if (ffo_ratio !== null) histData_FfoRatio_NotNull.push(ffo_ratio);
      if (ffoRatio_reitAve !== null) histData_FfoRatio_NotNull.push(ffoRatio_reitAve);
    }

    const series = [
      {
        name: t('NAV倍率'),
        type: 'area',
        data: histData_NavRatio
      },
      {
        name: t('REIT平均'),
        type: 'area',
        data: histData_NavRatio_ReitAve
      },
      {
        name: t('FFO倍率'),
        type: 'line',
        data: histData_FfoRatio
      },
      {
        name: t('REIT平均'),
        type: 'line',
        data: histData_FfoRatio_ReitAve
      },
    ];

    const options = create_NavRatio_FfoRatio_chart_opts(period_list, histData_NavRatio_NotNull, histData_FfoRatio_NotNull);

    return (<div className="Chart"><Chart type='line' options={options} series={series} height={ChartHeight} width={get_right_chart_width()} /></div>);
  }

  const render_Chart_PayoutRatio_ROE = () => {
    const selected_hist_data = get_selected_hist_data();
    if (selected_hist_data.length === 0) return null;

    const start_i = selected_hist_data.length > NumPeriodOnChart ? (selected_hist_data.length - NumPeriodOnChart) : 0;

    let period_list = [];

    let histData_PayoutRatio = [];
    let histData_PayoutRatio_ReitAve = [];
    let histData_ROE = [];
    let histData_ROE_ReitAve = [];
    let histData_PayoutRatio_NotNull: number[] = [];
    let histData_ROE_NotNull: number[] = [];

    for (let i=start_i; i < selected_hist_data.length; i++) {
      const d = selected_hist_data[i];

      period_list.push(d.period);

      let payout_ratio = d.payout_ratio;
      if (payout_ratio !== null) {
        payout_ratio *= 100;
      }
      let roe = d.roe;
      if (roe !== null) {
        roe *= 100;
      }

      // Reit平均
      let payoutRatio_reitAve = HistInvestmentDataUtil.calc_average(histInvestmentData, d.period, (hist_d => { return hist_d.payout_ratio}));
      if (payoutRatio_reitAve !== null) payoutRatio_reitAve *= 100;

      let roe_reitAve = HistInvestmentDataUtil.calc_average(histInvestmentData, d.period, (hist_d => { return hist_d.roe}));
      if (roe_reitAve !== null) roe_reitAve *= 100;

      histData_PayoutRatio.push(payout_ratio);
      histData_ROE.push(roe);
      histData_PayoutRatio_ReitAve.push(payoutRatio_reitAve);
      histData_ROE_ReitAve.push(roe_reitAve);

      if (payout_ratio !== null) histData_PayoutRatio_NotNull.push(payout_ratio);
      if (payoutRatio_reitAve !== null) histData_PayoutRatio_NotNull.push(payoutRatio_reitAve);
      if (roe !== null) histData_ROE_NotNull.push(roe);
      if (roe_reitAve !== null) histData_ROE_NotNull.push(roe_reitAve);
    }

    const series = [
      {
        name: t('配当性向'),
        type: 'area',
        data: histData_PayoutRatio
      },
      {
        name: t('REIT平均'),
        type: 'area',
        data: histData_PayoutRatio_ReitAve
      },
      {
        name: 'ROE',
        type: 'line',
        data: histData_ROE
      },
      {
        name: t('REIT平均'),
        type: 'line',
        data: histData_ROE_ReitAve
      },
    ];

    const options = create_PayoutRatio_ROE_chart_opts(period_list, histData_PayoutRatio_NotNull, histData_ROE_NotNull);

    return (<div className="Chart"><Chart type='line' options={options} series={series} height={ChartHeight} width={get_right_chart_width()} /></div>);
  }

  const render_Chart_LTV_ICR = () => {
    const selected_hist_data = get_selected_hist_data();
    if (selected_hist_data.length === 0) return null;

    const start_i = selected_hist_data.length > NumPeriodOnChart ? (selected_hist_data.length - NumPeriodOnChart) : 0;

    let period_list = [];

    let histData_LTV = [];
    let histData_LTV_ReitAve = [];
    let histData_ICR = [];
    let histData_ICR_ReitAve = [];
    let histData_LTV_NotNull: number[] = [];
    let histData_ICR_NotNull: number[] = [];

    for (let i=start_i; i < selected_hist_data.length; i++) {
      const d = selected_hist_data[i];

      period_list.push(d.period);

      let ltv = d.ltv;
      if (ltv !== null) {
        ltv *= 100;
      }
      const icr_dscr = d.icr_dscr;

      // Reit平均
      let ltv_reitAve = HistInvestmentDataUtil.calc_average(histInvestmentData, d.period, (hist_d => { return hist_d.ltv}));
      if (ltv_reitAve !== null) ltv_reitAve *= 100;

      let icr_reitAve = HistInvestmentDataUtil.calc_average(histInvestmentData, d.period, (hist_d => { return hist_d.icr_dscr}));

      histData_LTV.push(ltv);
      histData_ICR.push(icr_dscr);
      histData_LTV_ReitAve.push(ltv_reitAve);
      histData_ICR_ReitAve.push(icr_reitAve);

      if (ltv !== null) histData_LTV_NotNull.push(ltv);
      if (ltv_reitAve !== null) histData_LTV_NotNull.push(ltv_reitAve);
      if (icr_dscr !== null) histData_ICR_NotNull.push(icr_dscr);
      if (icr_reitAve !== null) histData_ICR_NotNull.push(icr_reitAve);
    }

    const series = [
      {
        name: 'LTV',
        type: 'area',
        data: histData_LTV
      },
      {
        name: t('REIT平均'),
        type: 'area',
        data: histData_LTV_ReitAve
      },
      {
        name: 'DSCR',
        type: 'line',
        data: histData_ICR
      },
      {
        name: t('REIT平均'),
        type: 'line',
        data: histData_ICR_ReitAve
      },
    ];

    const options = create_LTV_ICR_chart_opts(period_list, histData_LTV_NotNull, histData_ICR_NotNull);

    return (<div className="Chart"><Chart type='line' options={options} series={series} height={ChartHeight} width={get_right_chart_width()} /></div>);
  }


  const get_xaxis_period = (period_list: string[]) => {
    return {
      categories: period_list,
      labels: {
        formatter: function(val: string) {
          if (val === null || val === undefined) return '';
          const year = Number(val.slice(0, 4));
          const semester = val.slice(4);
          if (semester === '2H') return '';
          return year;
        },
        style: {
          fontSize: FontSize.Data,
        }  
      }
    }
  }

  const create_Price_Num_Age_chart_opts = (period_list: string[], histData_PurchasePrice_NotNull: number[], histData_PropertyNum_NotNull: number[], histData_BuildingAge_NotNull: number[]) => {
    const line_colors = [ '#8497B0', DeepBlueColor, DeepBlueColor ];
    const marker_colors = [ 'transparent', DeepBlueColor, '#FFF' ];
    const marker_strokeColors = [ 'transparent', DeepBlueColor, DeepBlueColor ];

    let min_purchasePrice = Math.min(...histData_PurchasePrice_NotNull);
    let max_purchasePrice = Math.max(...histData_PurchasePrice_NotNull);
    min_purchasePrice = max_purchasePrice / 1.75;  // 最大値の50%くらいを下限とする。(きっちい50%にすると最小値が0になってしまうことがあり、調整している)
    max_purchasePrice = ChartUtil.expand_max(min_purchasePrice, max_purchasePrice, 0.52);
    const grid_min_max_purchasePrice = ChartUtil.get_grid_min_max(min_purchasePrice, max_purchasePrice, GridInterval);

    let min_propertyNum = Math.min(...histData_PropertyNum_NotNull);
    let max_propertyNum = Math.max(...histData_PropertyNum_NotNull);
    if (min_propertyNum === max_propertyNum) max_propertyNum = max_propertyNum * 1.1;  // 最小値と最大値が同じ場合、最大値を1.1倍する。(同じだと折れ線が表示されないため)
    // 物件数の折れ線が稼働率の縦棒の上にくるようにY軸目盛の最小値／最大値を調整。
    const grid_min_propertyNum = ChartUtil.expand_min(min_propertyNum, max_propertyNum, 0.6);
    const grid_max_propertyNum = ChartUtil.expand_max(min_propertyNum, max_propertyNum, 0.1);

    let min_buildingAge = Math.min(...histData_BuildingAge_NotNull);
    let max_buildingAge = Math.max(...histData_BuildingAge_NotNull);
    if (min_buildingAge === max_buildingAge) max_buildingAge = max_buildingAge * 1.1;  // 最小値と最大値が同じ場合、最大値を1.1倍する。(同じだと折れ線が表示されないため)
    // 築年数の折れ線が取得価格の縦棒の上にくるようにY軸目盛の最小値／最大値を調整。
    const gird_min_buildingAge = ChartUtil.expand_min(min_buildingAge, max_buildingAge, 0.3);
    const grid_max_buildingAge = ChartUtil.expand_max(min_buildingAge, max_buildingAge, 0.7);

    const options: any = {
      chart: get_opt_chart('line', ChartHeight),
      colors: line_colors,
      plotOptions: {
        bar: {
          columnWidth: '60%',
        }
      },
      dataLabels: 
        get_opt_dataLabels(
          [0, 1, 2],
          function(val: number, opts: any) {
            if (val === null || val === undefined) return '';
            if (opts.seriesIndex === 0) {
              // 最後だけ表示する。
              if (opts.dataPointIndex < (period_list.length - 1)) return '';
              let text = val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
              switch (myLang) {
                case MyLanguage.JA: text = text + ' 億円'; break;
                case MyLanguage.EN: text = '￥' + text + 'bn'; break;  // &yen;が機能しないため、全角で表記。
              }
              return text;
            }
            else if (opts.seriesIndex === 1) {
              return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
            }
            else if (opts.seriesIndex === 2) {
              // 最後だけ表示する。
              if (opts.dataPointIndex < (period_list.length - 1)) return '';
              let text = val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1});
              switch (myLang) {
                case MyLanguage.JA: text = `${text} 年`; break;
                case MyLanguage.EN: text = `${text} years`; break;
              }
              return text;
            }
          }
        )
      , 
      fill: {
        opacity: 0.8,
        type: 'solid'
      },
      stroke: {
        width: [0, 0, StrokeWidth],
        curve: 'straight'
      },
      title: get_opt_title(t(`${t('取得価格')} / ${t('物件数')} / ${t('築年数(投資)')}`), 0),
      grid: get_opt_grid(),
      markers: {
        size: [0, 4.8, MarkerSize],
        colors: marker_colors,
        strokeColors: marker_strokeColors,
      },
      xaxis: get_xaxis_period(period_list),
      yaxis: [
        {
          tickAmount: GridInterval,
          // min: grid_min_max_purchasePrice.grid_min,
          max: grid_min_max_purchasePrice.grid_max,
          opposite: false,
          labels: {
            minWidth: MinWidth,
            formatter: function(val: number, index: any) {
              if (val === null || val === undefined) return '';
              // Y軸目盛の70%を超える部分は目盛りを表示しない。
              if (index > Math.floor(GridInterval * 0.7)) return '';
              return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});  // カンマ区切り＆小数点以下0桁
            },
            style: {
              fontSize: FontSize.Data,
            }  
          }
        },
        {
          tickAmount: GridInterval,
          min: grid_min_propertyNum,
          max: grid_max_propertyNum,
          opposite: true,
          labels: {
            show: false,
          }
        },
        {
          tickAmount: GridInterval,
          min: gird_min_buildingAge,
          max: Math.max(...histData_BuildingAge_NotNull),
          opposite: true,
          labels: {
            show: false,
          }
        }
      ],
      tooltip: {
        style: {
          fontSize: FontSize.Data,
        },
        x: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            const period = period_list[val - 1];
            const year = period.slice(2, 4);
            const semester = period.slice(4);
            return `${semester}-${year}`;
          }
        },
        y: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            if (opts.seriesIndex === 0) {
              // 取得価格
              let text = val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
              switch (myLang) {
                case MyLanguage.JA: return text + ' 億円';
                case MyLanguage.EN: return '&yen; ' + text + 'bn';
              }
              return '';
            }
            else if (opts.seriesIndex === 1) {
              // 物件数
              return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
            }
            else {
              // 築年数
              let text = val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1});
              switch (myLang) {
                case MyLanguage.JA: return text + ' 年';
                case MyLanguage.EN: return text + ' years';
              }
              return text;
            }
          }
        }
      },
      legend: { show: true, fontSize: FontSize.Data, showForNullSeries: false }
    };

    return options;
  }

  const create_Appraisal_BookPrice_chart_opts = (period_list: string[], histData_NotNull: number[]) => {
    const colors = [ '#8497B0', DeepBlueColor ];

    const grid_min_max = ChartUtil.get_grid_min_max(Math.min(...histData_NotNull), Math.max(...histData_NotNull), GridInterval);

    let dataLabels: any = get_opt_dataLabels(
      [1],  // 鑑定評価額のみ
      function(val: number, opts: any) {
        if (val === null || val === undefined) return '';
        if (opts.seriesIndex === 1) {
          // 最後だけ表示する。
          if (opts.dataPointIndex < (period_list.length - 1)) return '';
          let text = val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
          switch (myLang) {
            case MyLanguage.JA: text = text + ' 億円'; break;
            case MyLanguage.EN: text = '￥' + text + 'bn'; break;  // &yen;が機能しないため、全角で表記。
          }
          return text;
        }
      },
      -20
    );
    dataLabels['offsetX'] = -15;

    const options: any = {
      chart: get_opt_chart('bar', ChartHeight),
      colors: colors,
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '60%',
          dataLabels: {
            position: 'top',
          },  
        }
      },
      dataLabels, 
      fill: {
        opacity: 1,
        // type: 'solid'
      },
      stroke: {
        show: true,
        width: 2,
      },

      title: get_opt_title(`${t('鑑定評価額')} / ${t('簿価')}`, 0),
      grid: get_opt_grid(),
      xaxis: get_xaxis_period(period_list),
      yaxis: {
        tickAmount: GridInterval,
        // min: grid_min_max.grid_min,
        // max: grid_min_max.grid_max,
        opposite: false,
        labels: {
          minWidth: MinWidth,
          formatter: function(val: number, index: any) {
            if (val === null || val === undefined) return '';
            return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});  // カンマ区切り＆小数点以下0桁
          },
          style: {
            fontSize: FontSize.Data,
          }  
        },
      },
      tooltip: {
        shared: true,  // 鑑定評価額と簿価を1つの吹き出しに表示させる。(同時にintersectをfalseにする必要あり)
        intersect: false,
        style: {
          fontSize: FontSize.Data,
        },
        x: {
          formatter: function(val: string, opts: any) {
            const year = val.slice(2, 4);
            const semester = val.slice(4);
            return `${semester}-${year}`;
          }
        },
        y: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            let text = val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
            switch (myLang) {
              case MyLanguage.JA: return text + ' 億円';
              case MyLanguage.EN: return '&yen; ' + text + 'bn';
            }
            return '';
          }
        }
      },
      legend: { show: true, fontSize: FontSize.Data }
    };

    return options;
  }

  const create_AppraisalGainRatio_chart_opts = (period_list: string[], histData_NotNull: number[]) => {

    const grid_min_max = ChartUtil.get_grid_min_max(Math.min(...histData_NotNull), Math.max(...histData_NotNull), GridInterval);

    const line_colors = [ DeepBlueColor, '#A5A5A5' ];
    const marker_colors = [ '#fff', '#fff' ];
    const marker_strokeColors = [ DeepBlueColor, '#A5A5A5' ];

    const options: any = {
      chart: get_opt_chart('line', ChartHeight),
      colors: line_colors,
      dataLabels: 
        get_opt_dataLabels(
          [0],  // 本投資法人のみデータラベル表示
          function(val: number, opts: any) {
            if (val === null || val === undefined) return '';
            // 最後だけ表示する。
            if (opts.dataPointIndex < (period_list.length - 1)) return '';
            return CommonUtil.get_percent_text(val, 1) + '%';
            CommonUtil.get_percent_text(val, 1);
          }
        )
      , 
      stroke: {
        width: [StrokeWidth, StrokeWidth],
        dashArray: [0, StrokeDash]
      },
      title: get_opt_title(t('含み益率'), 0),
      grid: get_opt_grid(),
      markers: {
        size: MarkerSize,
        colors: marker_colors,
        strokeColors: marker_strokeColors,
      },
      xaxis: get_xaxis_period(period_list),
      yaxis: {
        tickAmount: GridInterval,
        min: Math.min(...histData_NotNull),
        max: Math.max(...histData_NotNull),
        labels: {
          minWidth: MinWidth,
          formatter: function(val: number, index: any) {
            if (val === null || val === undefined) return '';
            return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';
          },
          style: {
            fontSize: FontSize.Data,
          }
        }
      },
      tooltip: {
        enabledOnSeries: [0, 1],  // ダミーの棒グラフを対象外にする。
        style: {
          fontSize: FontSize.Data,
        },
        x: {
          formatter: function(val: number, opts: any) {
            const period = period_list[val - 1];
            const year = period.slice(2, 4);
            const semester = period.slice(4);
            return `${semester}-${year}`;
          }
        },
        y: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            return CommonUtil.get_percent_text(val, 1) + '%';
          }
        }
      },
      legend: { 
        show: true,
        showForNullSeries: false,  // ダミーの棒グラフを対象外にする。
        fontSize: FontSize.Data,
      }
    };

    return options;
  }

  const create_MarketCap_StockPrice_chart_opts = (period_list: string[], histData_MarketCap_NotNull: number[], histData_StockPrice_NotNull: number[]) => {
    const line_colors = [ '#8497B0', DeepBlueColor ];
    const marker_colors = [ 'transparent', '#FFF' ];
    const marker_strokeColors = [ 'transparent', DeepBlueColor ];

    let min_marketCap = Math.min(...histData_MarketCap_NotNull);
    let max_marketCap = Math.max(...histData_MarketCap_NotNull);
    min_marketCap = max_marketCap / 1.75;  // 最大値の50%くらいを下限とする。(きっちり50%にすると最小値が0になってしまうことがあり、調整している)
    max_marketCap = ChartUtil.expand_max(min_marketCap, max_marketCap, 0.52);
    const grid_min_max_marketCap = ChartUtil.get_grid_min_max(min_marketCap, max_marketCap, GridInterval);

    let min_stockPrice = Math.min(...histData_StockPrice_NotNull);
    let max_stockPrice = Math.max(...histData_StockPrice_NotNull);
    if (min_stockPrice === max_stockPrice) max_stockPrice = max_stockPrice * 1.1;  // 最小値と最大値が同じ場合、最大値を1.1倍する。(同じだと折れ線が表示されないため)
    // 株価の折れ線が時価総額の縦棒の上にくるようにY軸目盛の最小値／最大値を調整。
    const grid_min_marketCap = ChartUtil.expand_min(min_stockPrice, max_stockPrice, 0.3);
    const grid_max_marketCap = ChartUtil.expand_max(min_stockPrice, max_stockPrice, 0.7);

    const options: any = {
      chart: get_opt_chart('line', ChartHeight),
      colors: line_colors,
      plotOptions: {
        bar: {
          columnWidth: '60%',
        }
      },
      dataLabels: 
        get_opt_dataLabels(
          [0, 1],
          function(val: number, opts: any) {
            if (val === null || val === undefined) return '';
            // 最後だけ表示する。
            if (opts.dataPointIndex < (period_list.length - 1)) return '';
            if (opts.seriesIndex === 0) {
              // 時価総額
              let text = val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
              switch (myLang) {
                case MyLanguage.JA: text = text + ' 億円'; break;
                case MyLanguage.EN: text = '￥' + text + 'bn'; break;  // &yen;が機能しないため、全角で表記。
              }
              return text;
            } else {
              // 株価
              let text = val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
              switch (myLang) {
                case MyLanguage.JA: text = text + ' 円'; break;
                case MyLanguage.EN: text = '￥' + text; break;  // &yen;が機能しないため、全角で表記。
              }
              return text;
            }
          }
        )
      , 
      fill: {
        opacity: 0.8,
        type: 'solid'
      },
      stroke: {
        width: [0, StrokeWidth],
        curve: 'straight'
      },
      title: get_opt_title(`${t('時価総額')} / ${t('株価')}`, 0),
      grid: get_opt_grid(),
      markers: {
        size: [0, MarkerSize],
        colors: marker_colors,
        strokeColors: marker_strokeColors,
      },
      xaxis: get_xaxis_period(period_list),
      yaxis: [
        {
          tickAmount: GridInterval,
          // min: grid_min_max_marketCap.grid_min,
          max: grid_min_max_marketCap.grid_max,
          opposite: false,
          labels: {
            minWidth: MinWidth,
            formatter: function(val: number, index: any) {
              if (val === null || val === undefined) return '';
              // Y軸目盛の70%を超える部分は目盛りを表示しない。
              if (index > Math.floor(GridInterval * 0.7)) return '';
              return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});  // カンマ区切り＆小数点以下0桁
            },
            style: {
              fontSize: FontSize.Data,
            }  
          }
        },
        {
          tickAmount: GridInterval,
          min: grid_min_marketCap,
          max: Math.max(...histData_StockPrice_NotNull),
          opposite: true,
          labels: {
            show: false,
          }
        },
      ],
      tooltip: {
        style: {
          fontSize: FontSize.Data,
        },
        x: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            const period = period_list[val - 1];
            const year = period.slice(2, 4);
            const semester = period.slice(4);
            return `${semester}-${year}`;
          }
        },
        y: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            if (opts.seriesIndex === 0) {
              // 時価総額
              let text = val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
              switch (myLang) {
                case MyLanguage.JA: return text + ' 億円';
                case MyLanguage.EN: return '&yen; ' + text + 'bn';
              }
              return '';
            }
            else if (opts.seriesIndex === 1) {
              // 株価
              let text = val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
              switch (myLang) {
                case MyLanguage.JA: return text + ' 円';
                case MyLanguage.EN: return '&yen; ' + text;
              }
              return text;
            }
          }
        }
      },
      legend: { show: true, fontSize: FontSize.Data }
    };

    return options;
  }

  const create_Dividend_DividendYield_chart_opts = (period_list: string[], histData_Dividend_NotNull: number[], histData_DividendYield_NotNull: number[]) => {
    const line_colors = [ '#8497B0', DeepBlueColor, '#A5A5A5' ];
    const marker_colors = [ 'transparent', '#FFF', '#FFF' ];
    const marker_strokeColors = [ 'transparent', DeepBlueColor, '#A5A5A5' ];

    let min_dividend = Math.min(...histData_Dividend_NotNull);
    let max_dividend = Math.max(...histData_Dividend_NotNull);
    min_dividend = max_dividend / 1.75;  // 最大値の50%くらいを下限とする。(きっちり50%にすると最小値が0になってしまうことがあり、調整している)
    max_dividend = ChartUtil.expand_max(min_dividend, max_dividend, 0.52);
    const grid_min_max_dividend = ChartUtil.get_grid_min_max(min_dividend, max_dividend, GridInterval);

    let min_dividendYield = Math.min(...histData_DividendYield_NotNull);
    let max_dividendYield = Math.max(...histData_DividendYield_NotNull);
    if (min_dividendYield === max_dividendYield) max_dividendYield = max_dividendYield * 1.1;  // 最小値と最大値が同じ場合、最大値を1.1倍する。(同じだと折れ線が表示されないため)
    // 株価の折れ線が時価総額の縦棒の上にくるようにY軸目盛の最小値／最大値を調整。
    const grid_min_dividendYield = ChartUtil.expand_min(min_dividendYield, max_dividendYield, 0.3);
    const grid_max_dividendYield = ChartUtil.expand_max(min_dividendYield, max_dividendYield, 0.7);

    const options: any = {
      chart: get_opt_chart('line', ChartHeight),
      colors: line_colors,
      plotOptions: {
        bar: {
          columnWidth: '60%',
        }
      },
      dataLabels: 
        get_opt_dataLabels(
          [0, 1],  // 分配金、分配金利回りのみを表示。
          function(val: number, opts: any) {
            if (val === null || val === undefined) return '';
            if (opts.seriesIndex === 0) {
              // 最後だけ表示する。
              if (opts.dataPointIndex < (period_list.length - 1)) return '';
              let text = val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
              switch (myLang) {
                case MyLanguage.JA: text = text + ' 円'; break;
                case MyLanguage.EN: text = '￥' + text; break;  // &yen;が機能しないため、全角で表記。
              }
              return text;
            }
            else if (opts.seriesIndex === 1) {
              // 最後だけ表示する。
              if (opts.dataPointIndex < (period_list.length - 1)) return '';
              return CommonUtil.get_percent_text(val, 2) + '%';
            }
          }
        )
      , 
      fill: {
        opacity: 0.8,
        type: 'solid'
      },
      stroke: {
        width: [0, StrokeWidth, StrokeWidth],
        curve: 'straight',
        dashArray: [0, 0, StrokeDash]
      },
      title: get_opt_title(`${t('分配金')} / ${t('分配金利回り')}`, 0),
      grid: get_opt_grid(),
      markers: {
        size: [0, MarkerSize, MarkerSize],
        colors: marker_colors,
        strokeColors: marker_strokeColors,
      },
      xaxis: get_xaxis_period(period_list),
      yaxis: [
        {
          tickAmount: GridInterval,
          // min: grid_min_max_dividend.grid_min,
          max: grid_min_max_dividend.grid_max,
          opposite: false,
          labels: {
            minWidth: MinWidth,
            formatter: function(val: number, index: any) {
              if (val === null || val === undefined) return '';
              // Y軸目盛の70%を超える部分は目盛りを表示しない。
              if (index > Math.floor(GridInterval * 0.7)) return '';
              return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});  // カンマ区切り＆小数点以下0桁
            },
            style: {
              fontSize: FontSize.Data,
            }  
          }
        },
        {
          tickAmount: GridInterval,
          min: grid_min_dividendYield,
          max: Math.max(...histData_DividendYield_NotNull),
          opposite: true,
          labels: {
            show: false,
          }
        },
        {
          tickAmount: GridInterval,
          min: grid_min_dividendYield,
          max: Math.max(...histData_DividendYield_NotNull),
          opposite: true,
          labels: {
            show: false,
          }
        },
      ],
      tooltip: {
        style: {
          fontSize: FontSize.Data,
        },
        x: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            const period = period_list[val - 1];
            const year = period.slice(2, 4);
            const semester = period.slice(4);
            return `${semester}-${year}`;
          }
        },
        y: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            if (opts.seriesIndex === 0) {
              // 分配金
              let text = val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
              switch (myLang) {
                case MyLanguage.JA: return text + ' 円';
                case MyLanguage.EN: return '&yen; ' + text;
              }
              return '';
            }
            else {
              // 分配金利回り(REIT平均含む)
              if (val === null || val === undefined) return 'No data';
              return CommonUtil.get_percent_text(val, 2) + '%';
            }
          }
        }
      },
      legend: { show: true, fontSize: FontSize.Data }
    };

    return options;
  }

  const create_Occupancy_NOIY_chart_opts = (period_list: string[], histData_Occupancy_NotNull: number[], histData_NOIY_NotNull: number[]) => {
    const line_colors = [ '#8497B0', '#D2D2D2', DeepBlueColor, '#A5A5A5' ];
    const marker_colors = [ 'transparent', '#FFF', '#FFF', '#FFF' ];
    const marker_strokeColors = [ 'transparent', '#727E8F', DeepBlueColor, '#A5A5A5' ];

    const min_occupancy = 90;
    const max_occupancy = 105;
    const tickAmount_occupancy = 3;

    let min_noiy = Math.min(...histData_NOIY_NotNull);
    let max_noiy = Math.max(...histData_NOIY_NotNull);
    if (min_noiy === max_noiy) max_noiy = max_noiy * 1.1;  // 最小値と最大値が同じ場合、最大値を1.1倍する。(同じだと折れ線が表示されないため)
    // NOI利回りの折れ線が稼働率のエリアの上にくるようにY軸目盛の最小値／最大値を調整。
    const grid_min_noiy = ChartUtil.expand_min(min_noiy, max_noiy, 0.3);
    const grid_max_noiy = ChartUtil.expand_max(min_noiy, max_noiy, 0.7);

    const options: any = {
      chart: get_opt_chart('line', ChartHeight),
      colors: line_colors,
      plotOptions: {
        bar: {
          columnWidth: '60%',
        }
      },
      dataLabels: 
        get_opt_dataLabels(
          [0, 2],  // 稼働率、NOI利回り(本投資法人)のみデータラベル表示
          function(val: number, opts: any) {
            if (val === null || val === undefined) return '';
            if (opts.seriesIndex === 0) {
              // 最後だけ表示する。
              if (opts.dataPointIndex < (period_list.length - 1)) return '';
              return CommonUtil.get_percent_text(val, 1) + '%';
            }
            else if (opts.seriesIndex === 2) {
              // 最後だけ表示する。
              if (opts.dataPointIndex < (period_list.length - 1)) return '';
              return CommonUtil.get_percent_text(val, 2) + '%';
            }
          }
        )
      , 
      fill: {
        opacity: 0.8,
        type: 'solid'
      },
      stroke: {
        width: [0, 0, StrokeWidth, StrokeWidth],
        curve: 'straight',
        dashArray: [0, 0, 0, StrokeDash]
      },
      title: get_opt_title(`${t('稼働率')} / ${t('NOI利回り')}`, 0),
      grid: get_opt_grid(),
      markers: {
        size: [0, 0, MarkerSize, MarkerSize],
        colors: marker_colors,
        strokeColors: marker_strokeColors,
      },
      xaxis: get_xaxis_period(period_list),
      yaxis: [
        {
          tickAmount: GridInterval,
          min: Math.min(...histData_Occupancy_NotNull),
          max: Math.max(...histData_Occupancy_NotNull) + ((Math.max(...histData_Occupancy_NotNull) - Math.min(...histData_Occupancy_NotNull))),
          opposite: false,
          labels: {
            minWidth: MinWidth,
            formatter: function(val: number, index: any) {
              let ret = '';
              if (val === null || val === undefined) {
                ret = '';
              }
              else if (val > 100) {
                ret = '';  // 100%を超える部分は何も表示しない。
              }
              else {
                ret = CommonUtil.get_percent_text(val, 0) + '%';  // カンマ区切り＆小数点以下0桁
              }
              // Y軸の桁をそろえる。
              return ret.padStart(maxNumOfCharaYAxis, ' ');
            },
            style: {
              fontSize: FontSize.Data,
            }  
          }
        },
        {
          tickAmount: GridInterval,
          min: Math.min(...histData_Occupancy_NotNull),
          max: Math.max(...histData_Occupancy_NotNull) + ((Math.max(...histData_Occupancy_NotNull) - Math.min(...histData_Occupancy_NotNull))),
          opposite: false,
          labels: {
            show: false,
          }
        },
        {
          tickAmount: GridInterval,
          min: grid_min_noiy,
          max: Math.max(...histData_NOIY_NotNull),
          opposite: true,
          labels: {
            show: false,
          }
        },
        {
          tickAmount: GridInterval,
          min: grid_min_noiy,
          max: Math.max(...histData_NOIY_NotNull),
          opposite: true,
          labels: {
            show: false,
          }
        },
      ],
      tooltip: {
        style: {
          fontSize: FontSize.Data,
        },
        x: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            const period = period_list[val - 1];
            const year = period.slice(2, 4);
            const semester = period.slice(4);
            return `${semester}-${year}`;
          }
        },
        y: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            if (opts.seriesIndex === 0 || opts.seriesIndex === 1) {
              return CommonUtil.get_percent_text(val, 1) + '%';
            } else {
              return CommonUtil.get_percent_text(val, 2) + '%';
            }
          }
        }
      },
      legend: { show: true, fontSize: FontSize.Data }
    };

    return options;
  }

  const create_ImpliedCR_chart_opts = (period_list: string[], histData_NotNull: number[]) => {

    const grid_min_max = ChartUtil.get_grid_min_max(Math.min(...histData_NotNull), Math.max(...histData_NotNull), GridInterval);

    const line_colors = [ DeepBlueColor, '#AFABAB', '#8497B0' ];
    const marker_colors = [ '#fff', '#fff', '#fff' ];
    const marker_strokeColors = [ DeepBlueColor, '#AFABAB', '#8497B0' ];

    const options: any = {
      chart: get_opt_chart('line', ChartHeight),
      colors: line_colors,
      dataLabels: 
        get_opt_dataLabels(
          [0],  // 本投資法人のみデータラベル表示
          function(val: number, opts: any) {
            if (val === null || val === undefined) return '';
            // 最後だけ表示する。
            if (opts.dataPointIndex < (period_list.length - 1)) return '';
            return CommonUtil.get_percent_text(val, 2) + '%';
          }
        )
      , 
      stroke: {
        width: [StrokeWidth, StrokeWidth, StrokeWidth],
        dashArray: [0, StrokeDash, StrokeDash]
      },
      title: get_opt_title('Implied CR', 0),
      grid: get_opt_grid(),
      markers: {
        size: MarkerSize,
        colors: marker_colors,
        strokeColors: marker_strokeColors,
      },
      xaxis: get_xaxis_period(period_list),
      yaxis: {
        tickAmount: GridInterval,
        min: Math.min(...histData_NotNull),
        max: Math.max(...histData_NotNull),
        labels: {
          minWidth: MinWidth,
          formatter: function(val: number, index: any) {
            if (val === null || val === undefined) return '';
            return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';
          },
          style: {
            fontSize: FontSize.Data,
          }
        }
      },
      tooltip: {
        enabledOnSeries: [0, 1, 2],  // ダミーの棒グラフを対象外にする。
        style: {
          fontSize: FontSize.Data,
        },
        x: {
          formatter: function(val: number, opts: any) {
            const period = period_list[val - 1];
            const year = period.slice(2, 4);
            const semester = period.slice(4);
            return `${semester}-${year}`;
          }
        },
        y: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            return CommonUtil.get_percent_text(val, 2) + '%';
          }
        }
      },
      legend: { 
        show: true,
        showForNullSeries: false,  // ダミーの棒グラフを対象外にする。
        fontSize: FontSize.Data,
      }
    };

    return options;
  }

  const create_NavRatio_FfoRatio_chart_opts = (period_list: string[], histData_NavRatio_NotNull: number[], histData_FfoRatio_NotNull: number[]) => {
    const line_colors = [ '#8497B0', '#ECECEC', DeepBlueColor, '#D0CECE' ];
    const marker_colors = [ 'transparent', 'transparent', '#FFF', '#FFF' ];
    const marker_strokeColors = [ 'transparent', 'transparent', DeepBlueColor, '#D0CECE' ];

    let min_navRatio = Math.min(...histData_NavRatio_NotNull);
    let max_navRatio = Math.max(...histData_NavRatio_NotNull);
    min_navRatio = max_navRatio / 1.75;  // 最大値の50%くらいを下限とする。(きっちり50%にすると最小値が0になってしまうことがあり、調整している)
    max_navRatio = ChartUtil.expand_max(min_navRatio, max_navRatio, 0.6);
    const grid_min_max_navRatio = ChartUtil.get_grid_min_max(min_navRatio, max_navRatio, GridInterval);

    let min_ffoRatio = Math.min(...histData_FfoRatio_NotNull);
    let max_ffoRatio = Math.max(...histData_FfoRatio_NotNull);
    if (min_ffoRatio === max_ffoRatio) max_ffoRatio = max_ffoRatio * 1.1;  // 最小値と最大値が同じ場合、最大値を1.1倍する。(同じだと折れ線が表示されないため)
    // NOI利回りの折れ線が稼働率のエリアの上にくるようにY軸目盛の最小値／最大値を調整。
    const grid_min_ffoRatio = ChartUtil.expand_min(min_ffoRatio, max_ffoRatio, 0.3);
    const grid_max_ffoRatio = ChartUtil.expand_max(min_ffoRatio, max_ffoRatio, 0.7);

    const options: any = {
      chart: get_opt_chart('line', ChartHeight),
      colors: line_colors,
      plotOptions: {
        bar: {
          columnWidth: '60%',
        }
      },
      dataLabels: 
        get_opt_dataLabels(
          [0, 2],  // NAV倍率、FFO倍率(本投資法人)のみデータラベル表示
          function(val: number, opts: any) {
            if (val === null || val === undefined) return '';
            if (opts.seriesIndex === 0) {
              // 最後だけ表示する。
              if (opts.dataPointIndex < (period_list.length - 1)) return '';
              return val.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
            }
            else if (opts.seriesIndex === 2) {
              // 最後だけ表示する。
              if (opts.dataPointIndex < (period_list.length - 1)) return '';
              return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1});
            }
          }
        )
      , 
      fill: {
        opacity: 0.8,
        type: 'solid'
      },
      stroke: {
        width: [0, 0, StrokeWidth, StrokeWidth],
        curve: 'straight',
        dashArray: [0, 0, 0, StrokeDash]
      },
      title: get_opt_title(`${t('NAV倍率')} / ${t('FFO倍率')}`, 0),
      grid: get_opt_grid(),
      markers: {
        size: [0, 0, MarkerSize, MarkerSize],
        colors: marker_colors,
        strokeColors: marker_strokeColors,
      },
      xaxis: get_xaxis_period(period_list),
      yaxis: [
        {
          tickAmount: GridInterval,
          min: Math.min(...histData_NavRatio_NotNull),
          max: Math.max(...histData_NavRatio_NotNull) + ((Math.max(...histData_NavRatio_NotNull) - Math.min(...histData_NavRatio_NotNull))),
          opposite: false,
          labels: {
            formatter: function(val: number, index: any) {
              if (val === null || val === undefined) return '';
              // Y軸目盛の70%を超える部分は目盛りを表示しない。
              if (index > Math.floor(GridInterval * 0.7)) return '';
              return val.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
            }
          },
          style: {
            fontSize: FontSize.Data,
          }
        },
        {
          tickAmount: GridInterval,
          min: Math.min(...histData_NavRatio_NotNull),
          max: Math.max(...histData_NavRatio_NotNull) + ((Math.max(...histData_NavRatio_NotNull) - Math.min(...histData_NavRatio_NotNull))),
          opposite: false,
          labels: {
            show: false,
          }
        },
        {
          tickAmount: GridInterval,
          min: grid_min_ffoRatio,
          max: Math.max(...histData_FfoRatio_NotNull),
          opposite: true,
          labels: {
            show: false,
          }
        },
        {
          tickAmount: GridInterval,
          min: grid_min_ffoRatio,
          max: Math.max(...histData_FfoRatio_NotNull),
          opposite: true,
          labels: {
            show: false,
          }
        },
      ],
      tooltip: {
        style: {
          fontSize: FontSize.Data,
        },
        x: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            const period = period_list[val - 1];
            const year = period.slice(2, 4);
            const semester = period.slice(4);
            return `${semester}-${year}`;
          }
        },
        y: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            if (opts.seriesIndex === 0 || opts.seriesIndex === 1) {
              // NAV倍率
              return val.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
            }
            else {
              // FFO倍率
              return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1});
            }
          }
        }
      },
      legend: { show: true, fontSize: FontSize.Data }
    };

    return options;
  }

  const create_PayoutRatio_ROE_chart_opts = (period_list: string[], histData_PayoutRatio_NotNull: number[], histData_ROE_NotNull: number[]) => {
    const line_colors = [ '#8497B0', '#D2D2D2', DeepBlueColor, '#A5A5A5' ];
    const marker_colors = [ 'transparent', '#FFF', '#FFF', '#FFF' ];
    const marker_strokeColors = [ 'transparent', '#727E8F', DeepBlueColor, '#A5A5A5' ];

    let min_payoutRatio = Math.min(...histData_PayoutRatio_NotNull);
    let max_payoutRatio = Math.max(...histData_PayoutRatio_NotNull);
    min_payoutRatio = max_payoutRatio / 1.75;  // 最大値の50%くらいを下限とする。(きっちり50%にすると最小値が0になってしまうことがあり、調整している)
    max_payoutRatio = ChartUtil.expand_max(min_payoutRatio, max_payoutRatio, 0.6);
    const grid_min_max_payoutRatio = ChartUtil.get_grid_min_max(min_payoutRatio, max_payoutRatio, GridInterval);

    let min_roe = Math.min(...histData_ROE_NotNull);
    let max_roe = Math.max(...histData_ROE_NotNull);
    if (min_roe === max_roe) max_roe = max_roe * 1.1;  // 最小値と最大値が同じ場合、最大値を1.1倍する。(同じだと折れ線が表示されないため)
    // ROEの折れ線がPayout Ratioのエリアの上にくるようにY軸目盛の最小値／最大値を調整。
    const grid_min_roe = ChartUtil.expand_min(min_roe, max_roe, 0.3);
    const grid_max_roe = ChartUtil.expand_max(min_roe, max_roe, 0.7);

    const options: any = {
      chart: get_opt_chart('line', ChartHeight),
      colors: line_colors,
      plotOptions: {
        bar: {
          columnWidth: '60%',
        }
      },
      dataLabels: 
        get_opt_dataLabels(
          [0, 2],  // Payout Ratio、ROE(本投資法人)のみデータラベル表示
          function(val: number, opts: any) {
            if (val === null || val === undefined) return '';
            if (opts.seriesIndex === 0) {
              // 最後だけ表示する。
              if (opts.dataPointIndex < (period_list.length - 1)) return '';
              return CommonUtil.get_percent_text(val, 1) + '%';
            }
            else if (opts.seriesIndex === 2) {
              // 最後だけ表示する。
              if (opts.dataPointIndex < (period_list.length - 1)) return '';
              return CommonUtil.get_percent_text(val, 1) + '%';
            }
          }
        )
      , 
      fill: {
        opacity: 0.8,
        type: 'solid'
      },
      stroke: {
        width: [0, 0, StrokeWidth, StrokeWidth],
        curve: 'straight',
        dashArray: [0, 0, 0, StrokeDash]
      },
      title: get_opt_title(`${t('配当性向')} / ROE`, 0),
      grid: get_opt_grid(),
      markers: {
        size: [0, 0, MarkerSize, MarkerSize],
        colors: marker_colors,
        strokeColors: marker_strokeColors,
      },
      xaxis: get_xaxis_period(period_list),
      yaxis: [
        {
          tickAmount: GridInterval,
          min: Math.min(...histData_PayoutRatio_NotNull),
          max: Math.max(...histData_PayoutRatio_NotNull) + ((Math.max(...histData_PayoutRatio_NotNull) - Math.min(...histData_PayoutRatio_NotNull))),
          opposite: false,
          labels: {
            minWidth: MinWidth,
            formatter: function(val: number, index: any) {
              if (val === null || val === undefined) return '';
              // Y軸目盛の70%を超える部分は目盛りを表示しない。
              if (index > Math.floor(GridInterval * 0.7)) return '';

              let ret = val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + '%';  // カンマ区切り＆小数点以下0桁
              // Y軸の桁をそろえる。
              return ret.padStart(maxNumOfCharaYAxis, ' ');
            },
            style: {
              fontSize: FontSize.Data,
            }  
          }
        },
        {
          tickAmount: GridInterval,
          min: Math.min(...histData_PayoutRatio_NotNull),
          max: Math.max(...histData_PayoutRatio_NotNull) + ((Math.max(...histData_PayoutRatio_NotNull) - Math.min(...histData_PayoutRatio_NotNull))),
          opposite: false,
          labels: {
            show: false,
          }
        },
        {
          tickAmount: GridInterval,
          min: grid_min_roe,
          max: Math.max(...histData_ROE_NotNull),
          opposite: true,
          labels: {
            show: false,
          }
        },
        {
          tickAmount: GridInterval,
          min: grid_min_roe,
          max: Math.max(...histData_ROE_NotNull),
          opposite: true,
          labels: {
            show: false,
          }
        },
      ],
      tooltip: {
        style: {
          fontSize: FontSize.Data,
        },
        x: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            const period = period_list[val - 1];
            const year = period.slice(2, 4);
            const semester = period.slice(4);
            return `${semester}-${year}`;
          }
        },
        y: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            return CommonUtil.get_percent_text(val, 1) + '%';
          }
        }
      },
      legend: { show: true, fontSize: FontSize.Data }
    };

    return options;
  }

  const create_LTV_ICR_chart_opts = (period_list: string[], histData_LTV_NotNull: number[], histData_ICR_NotNull: number[]) => {
    const line_colors = [ '#8497B0', '#D2D2D2', DeepBlueColor, '#A5A5A5' ];
    const marker_colors = [ 'transparent', '#FFF', '#FFF', '#FFF' ];
    const marker_strokeColors = [ 'transparent', '#727E8F', DeepBlueColor, '#A5A5A5' ];

    let min_icr = Math.min(...histData_ICR_NotNull);
    let max_icr = Math.max(...histData_ICR_NotNull);
    if (min_icr === max_icr) max_icr = max_icr * 1.1;  // 最小値と最大値が同じ場合、最大値を1.1倍する。(同じだと折れ線が表示されないため)
    // 2軸目が上に収まるようにY軸目盛の最小値／最大値を調整。
    const grid_min_icr = ChartUtil.expand_min(min_icr, max_icr, 0.3);
    const grid_max_icr = ChartUtil.expand_max(min_icr, max_icr, 0.7);

    const options: any = {
      chart: get_opt_chart('line', ChartHeight),
      colors: line_colors,
      plotOptions: {
        bar: {
          columnWidth: '60%',
        }
      },
      dataLabels: 
        get_opt_dataLabels(
          [0, 2],  // DSCR(本投資法人)のみデータラベル表示
          function(val: number, opts: any) {
            if (val === null || val === undefined) return '';
            if (opts.seriesIndex === 0) {
              // 最後だけ表示する。
              if (opts.dataPointIndex < (period_list.length - 1)) return '';
              return CommonUtil.get_percent_text(val, 1) + '%';
            }
            else if (opts.seriesIndex === 2) {
              // 最後だけ表示する。
              if (opts.dataPointIndex < (period_list.length - 1)) return '';
              return CommonUtil.get_percent_text(val, 1);
            }
          }
        )
      , 
      fill: {
        opacity: 0.8,
        type: 'solid'
      },
      stroke: {
        width: [0, 0, StrokeWidth, StrokeWidth],
        curve: 'straight',
        dashArray: [0, 0, 0, StrokeDash]
      },
      title: get_opt_title('LTV / DSCR', 0),
      grid: get_opt_grid(),
      markers: {
        size: [0, 0, MarkerSize, MarkerSize],
        colors: marker_colors,
        strokeColors: marker_strokeColors,
      },
      xaxis: get_xaxis_period(period_list),
      yaxis: [
        {
          tickAmount: GridInterval,
          min: Math.min(...histData_LTV_NotNull),
          max: Math.max(...histData_LTV_NotNull) + ((Math.max(...histData_LTV_NotNull) - Math.min(...histData_LTV_NotNull))),
          opposite: false,
          labels: {
            minWidth: MinWidth,
            formatter: function(val: number, index: any) {
              if (val === null || val === undefined) return '';
              // Y軸目盛の70%を超える部分は目盛りを表示しない。
              if (index > Math.floor(GridInterval * 0.7)) return '';

              let ret = val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + '%';  // カンマ区切り＆小数点以下0桁
              // Y軸の桁をそろえる。
              return ret.padStart(maxNumOfCharaYAxis, ' ');
            },
            style: {
              fontSize: FontSize.Data,
            }  
          }
        },
        {
          tickAmount: GridInterval,
          min: Math.min(...histData_LTV_NotNull),
          max: Math.max(...histData_LTV_NotNull) + ((Math.max(...histData_LTV_NotNull) - Math.min(...histData_LTV_NotNull))),
          opposite: false,
          labels: {
            show: false,
          }
        },
        {
          tickAmount: GridInterval,
          min: grid_min_icr,
          max: Math.max(...histData_ICR_NotNull),
          opposite: true,
          labels: {
            show: false,
          }
        },
        {
          tickAmount: GridInterval,
          min: grid_min_icr,
          max: Math.max(...histData_ICR_NotNull),
          opposite: true,
          labels: {
            show: false,
          }
        },
      ],
      tooltip: {
        style: {
          fontSize: FontSize.Data,
        },
        x: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            const period = period_list[val - 1];
            const year = period.slice(2, 4);
            const semester = period.slice(4);
            return `${semester}-${year}`;
          }
        },
        y: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            if (opts.seriesIndex === 0 || opts.seriesIndex === 1) {
              return CommonUtil.get_percent_text(val, 1) + '%';
            } else {
              return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1});
            }
          }
        }
      },
      legend: { show: true, fontSize: FontSize.Data }
    };

    return options;
  }

  const get_opt_chart = (type: string, height: number|undefined) => {
    return {
      height: height,
      type: type,
      toolbar: {
        show: false
      },
      animations: {
        enabled: false
      },
      zoom: {
        enabled: false
      }
    };
  }

  const get_opt_grid = () => {
    return {
      borderColor: '#e7e7e7',
      row: {
        colors: [ 'transparent' ],
        opacity: 0.5
      },
      padding: {
        bottom: 0
      }
    };
  }

  const get_opt_title = (text: string, offsetY: number) => {
    return {
      text: text,
      align: 'center',
      offsetY: offsetY,
      style: {
        fontSize: FontSize.Title,
        fontWeight: 'normal'
      }
    }; 
  }

  const get_opt_dataLabels = (enabledOnSeries: number[], formatter: any, offsetY: number = -10) => {
    return { 
      enabled: true,
      enabledOnSeries: enabledOnSeries,
      textAnchor: 'middle',
      offsetY: offsetY,
      style: {
        fontSize: FontSize.Data,
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
        colors: ['#000', '#000', '#000']
      },
      background: {
        enabled: false,
        foreColor: '#000',
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: '#fff',
      },
      formatter: formatter
    };
  }

  const get_pie_chart_options = (title: string, labels: string[], colors: string[], dataLabelFontColors: string[]): any => {
    return {
      title: get_opt_title(title, 0),
      labels: labels,
      colors: colors,
      plotOptions: {
        pie: {
          donut: {
            size: '50%',  // 円グラフの穴の大きさ
          },
        },
      },
      dataLabels: {
        style: {
          colors: dataLabelFontColors,
        },
        dropShadow: {
          enabled: false
        },
        formatter: function (val: number) {
          if (val === null || val === undefined) return '';
          // 5%以下は表示しない。
          if (val <= 5) return '';
          return CommonUtil.get_percent_text(val, 1) + '%';
        },
      },
      tooltip: {
        fillSeriesColor: false,
        theme: 'light',
        style: {
          fontSize: FontSize.Data,
        },
        y: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            return CommonUtil.get_percent_text(val, 1) + '%';
          }
        }
      },
      legend: {
        position: 'bottom',
        fontSize: FontSize.Data,
      },  
    }
  };



  const get_right_chart_width = (): number => {
    // clientWidth * 0.X%は左側(物件データ)のサイズ(cssの「.column:first-child」参照)
    // 40pxはpaddingサイズ
    const left_width = window.devicePixelRatio >= 1.5 ? 0.3 : 0.36;
    let width = document.documentElement.clientWidth - (document.documentElement.clientWidth * left_width) - (40 * 2);  
    return width;
  }

  const handleClickExcelOutput = async () => {
    const selected_data = get_selected_data();
    const selected_hist_data = get_selected_hist_data();
    if (selected_data === null || selected_hist_data === null) return;

    const start_i = selected_hist_data.length > NumPeriodToExcel ? (selected_hist_data.length - NumPeriodToExcel) : 0;
    await ToExcel.out_histInvestmentData(t, myLang, selected_data, investmentData, selected_hist_data, histInvestmentData, start_i);
  }

  /**
   * 吹き出しの文言を返却する。
   * @param balloon_text 吹き出しの文言(配列の1要素＝1行)
   * @param classname_align 吹き出しの位置を指定するクラス名
   * @returns 吹き出しの文言
   */
  const render_balloon = (balloon_text: string[], classname_align: string) => {
    if (balloon_text.length === 0) return null;

    let rendering = [];
    for (let i = 0; i < balloon_text.length; i++) {
      if (i !== 0) rendering.push(<br/>);
      rendering.push(balloon_text[i]);
    }
    
    return (
      <span className={classNames("balloon", classname_align)}>{rendering}</span>
    );
  }

  // データ更新日
  const render_data_update_date = () => {
    const d = get_selected_data();
    if (d === null) return null;

    const data_update_date = CommonUtil.convert_YearMonthDay_with_slash(d.data_update_date, myLang);
    let text = '';
    switch (myLang) {
      case MyLanguage.JA:
        text = `${data_update_date} 時点`;
        break;
      case MyLanguage.EN:
        text = `As of ${data_update_date}`;
        break;
    }
    return <div>{text}</div>
  }

  const render_excel_button = () => {
    if (CommonUtil.is_mobile()) {
      return null;
    } else {
      return <button className="" onClick={() => handleClickExcelOutput()}>{t('Excel出力')}</button>;
    }
  }

  return (
    <div id="StockData">
      <div className="wrapper">
        <div className="button_area">
          {render_excel_button()}
          {render_select_box()}
        </div>
        {render_data_update_date()}
        <div className="row">
          <div className="column">
            {render_detail()}
            {!render_waiting && render_pie_chart_investment_area()}
            {!render_waiting && render_pie_chart_property_type()}
            {!render_waiting && render_Chart_ExpRatio()}
            {!render_waiting && render_Chart_ExpDetail()}
            {!render_waiting && render_Chart_Party()}
            {!render_waiting && render_Chart_Sourcing()}
            {!render_waiting && render_Chart_Debt()}
            {!render_waiting && render_Chart_Investor()}
          </div>
          <div className="column">
            {render_Chart_Price_Num_Age()}
            {render_Chart_Appraisal_BookPrice()}
            {render_Chart_AppraisalGainRatio()}
            {render_Chart_MarketCap_StockPrice()}
            {render_Chart_Dividend_DividendYield()}
            {render_Chart_Occupancy_NOIY()}
            {render_Chart_ImpliedCR()}
            {render_Chart_NavRatio_FfoRatio()}
            {render_Chart_PayoutRatio_ROE()}
            {render_Chart_LTV_ICR()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StockData;
