import './Map.css';
import './ValueMap.css';
import { FC, useCallback, useEffect, useState, useRef, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GoogleMap } from "@react-google-maps/api";
import ValueMapContent from './map/ValueMapContent';
import { AppContext, SearchType, MyLanguage, MaxLabel } from '../context/AppContext';
import { useAuthContext } from '../context/AuthContext';
import EstateDataUtil from '../utils/EstateDataUtil';
import CommonUtil from '../utils/CommonUtil';
import { t } from "i18next";
import { ValueMapDataType } from '../utils/ValueMapDataType';
import DialogValueMap from './DialogValueMap';
import InfoMenu from "./map/InfoMenu";

// [重要] centerの定義をMapの外側にしないと、タイプアイコンクリック時に地図の再読み込みが起きてしまう。
//const center = { lat: 38.3705524, lng: 137.5310131 };
const center = { lat: 35.6840000, lng: 139.7530000 };

const ValueMap: FC = () => { 
  const zoomControlOptions = {
    position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
  }
  // https://snazzymaps.com/style/65237/grey-with-transparent-red-main-arteries
  const map_styles = [
    {
      "featureType": "landscape",
      "elementType": "all",
      "stylers": [
          {
              "hue": "#FFBB00"
          },
          {
              "saturation": 43.400000000000006
          },
          {
              "lightness": 37.599999999999994
          },
          {
              "gamma": 1
          }
      ]
  },
  {
      "featureType": "landscape",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "saturation": "-100"
          },
          {
              "lightness": "1"
          },
          {
              "gamma": "0.65"
          }
      ]
  },
  {
      "featureType": "landscape",
      "elementType": "geometry.stroke",
      "stylers": [
          {
              "color": "#ccc8c1"
          }
      ]
  },
  {
      "featureType": "landscape",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "lightness": "1"
          }
      ]
  },
  {
      "featureType": "poi",
      "elementType": "all",
      "stylers": [
          {
              "hue": "#00FF6A"
          },
          {
              "saturation": -1.0989010989011234
          },
          {
              "lightness": 11.200000000000017
          },
          {
              "gamma": 1
          }
      ]
  },
  {
      "featureType": "poi",
      "elementType": "labels",
      "stylers": [
          {
              "visibility": "off"
          }
      ]
  },
  {
      "featureType": "road.highway",
      "elementType": "all",
      "stylers": [
          {
              "hue": "#FFC200"
          },
          {
              "saturation": -61.8
          },
          {
              "lightness": 45.599999999999994
          },
          {
              "gamma": 1
          }
      ]
  },
  {
      "featureType": "road.arterial",
      "elementType": "all",
      "stylers": [
          {
              "hue": "#FF0300"
          },
          {
              "saturation": -100
          },
          {
              "lightness": 51.19999999999999
          },
          {
              "gamma": 1
          }
      ]
  },
  {
      "featureType": "road.arterial",
      "elementType": "geometry.fill",
      "stylers": [
          {
              "color": "#f4baa7"
          }
      ]
  },
  {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
          {
              "color": "#3d3c3c"
          }
      ]
  },
  {
      "featureType": "road.local",
      "elementType": "all",
      "stylers": [
          {
              "hue": "#FF0300"
          },
          {
              "saturation": -100
          },
          {
              "lightness": 52
          },
          {
              "gamma": 1
          }
      ]
  },
  {
      "featureType": "water",
      "elementType": "all",
      "stylers": [
          {
              "hue": "#0078FF"
          },
          {
              "saturation": -13.200000000000003
          },
          {
              "lightness": 2.4000000000000057
          },
          {
              "gamma": 1
          }
      ]
  }
]
;

  const mapOptions = {
    minZoom: 4,
    // maxZoom: 17,
    zoomControlOptions: zoomControlOptions,
    controlSize: 32,
    styles: map_styles,
    // 「地図」「航空写真」ボタン非表示。
    mapTypeControl: false,
    // ストリートビュー非表示。
    streetViewControl: false,
    // フルスクリーンボタン非表示
    fullscreenControl: false,
    // GoogleMapが提供する飲食店などのアイコンをクリック不可にする。
    // 不動産マーカークリック時、1つだけ吹き出しを表示している状態で飲食店などのアイコンをクリックすると、吹き出しが消えてしまうため。
    // (2つ以上吹き出しを表示している状態だと再現しない)
    clickableIcons: false,
  };

  const { myLang } = useContext(AppContext);
  const [ mapWidth, setMapWidth] = useState<number>(0);
  const [ mapHeight, setMapHeight] = useState<number>(0);
  const { valueMapData } = useContext(AppContext);
  const { searchType } = useContext(AppContext);
  // Map画面でチェックを入れた不動産評価情報(複数)
  const { labeledValueMapData, setLabeledValueMapData } = useContext(AppContext);
  // チェックを入れた不動産だけを表示するボタンのON/OFF
  const [ showOnlyLabeled, setShowOnlyLabeled ] = useState<boolean>(false);
  // 不動産情報取得の一回目か否か
  const [ isFirstFetchEstateData, setIsFirstFetchEstateData ] = useState(true);
  // チェックを入れた不動産にフォーカスするのが一回目か否か
  const { isFirstFocusLabeled, setIsFirstFocusLabeled } = useContext(AppContext);
  // 地図がロードされたか。
  const [ isLoadedMap, setIsLoadedMap ] = useState(false);
  // 地図表示時にチェックを入れた不動産情報にフォーカスするか。
  const [ focusLabeled, setFocusLabeled ] = useState(false);
  
  // 地図の操作を許可するかどうか
  const [ isMapInteractive, setIsMapInteractive ] = useState(true);

  // 吹き出し表示する不動産
  const [ popupValueMaps, setPopupValueMaps ] = useState<ValueMapDataType[]>([]);
  
  // --------------------------------------------------
  // 情報追加用のメニュー
  // --------------------------------------------------
  // 地価公示・調査
  const [ isLandPricesActive, setLandPricesActive ] = useState(false);
  // 用途地域
  const [ isZoningActive, setZoningActive ] = useState(false);
  // ハザードマップ
  const [ isHazardMapActive, setHazardMapActive ] = useState(false);
  // 洪水浸水想定地域
  const [ isFloodActive, setFloodActive ] = useState(false);
  // 土砂災害警戒区域
  const [ isSedimentActive, setSedimentActive ] = useState(false);
  // 津波浸水想定区域
  const [ isTsunamiActive, setTsunamiActive ] = useState(false);
  // 高潮浸水想定区域
  const [ isStormSurgeActive, setStormSurgeActive ] = useState(false);


  const [searchParams] = useSearchParams();

  // API読み込み後に再レンダーを引き起こさないため、useStateを使わず、useRefとuseCallbackを使っている。
  const mapRef = useRef<any>();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    setIsLoadedMap(true);
  }, []);

  useEffect(() => {
    if (mapWidth === 0) {
      const width = document.documentElement.clientWidth;
      setMapWidth(width);
    }
    if (mapHeight === 0) {
      // ヘッダーとフッターの高さ(px)
      let headerHeight = 40;
      let footerHeight = 40;
      if (window.devicePixelRatio >= 1.5) {
        headerHeight = 32;
        footerHeight = 26;
      }

      // 地図領域の高さ = 画面サイズ - ヘッダーの高さ - フッターの高さ
      let height = document.documentElement.clientHeight - headerHeight - footerHeight;
      setMapHeight(height);
    }
  }, []);

  useEffect(() => {
    if (searchType === SearchType.Keyword) {
      // キーワード検索した場合は、当該物件にフォーカスする。
      fitBounds(valueMapData);
    }
  }, [valueMapData]);

  useEffect(() => {
    if (showOnlyLabeled) {
      // チェックした物件にフォーカスする。
      fitBounds(labeledValueMapData);
    }
  }, [showOnlyLabeled]);

  useEffect(() => {
    if (valueMapData.length === 0) return;
    if (!isFirstFetchEstateData) return;

    const ids = searchParams.getAll('id');
    if (ids.length === 0) return;

    // URLパラメータで指定されたidをチェック状態にする。
    let newLabeledValueMapData = [];
    for (let id of ids) {
      for (let d of valueMapData) {
        if (Number(id) === d.id) {
          newLabeledValueMapData.push(d);
          break;
        }
      }
      if (newLabeledValueMapData.length >= MaxLabel) {
        // ラベルの上限に達していたら以降は無視する。
        break;
      }
    }

    // データが揃っていない
    if (newLabeledValueMapData.length < MaxLabel && newLabeledValueMapData.length < ids.length) return;

    setLabeledValueMapData(newLabeledValueMapData);
    setFocusLabeled(true);
    setIsFirstFetchEstateData(false);    
  }, [valueMapData]);

  useEffect(() => {
    if (!isFirstFocusLabeled) return;
    // 地図がロードされる前にフォーカス処理をすると空振りするため、ロードされたかを監視する。
    if (!isLoadedMap) return;
    if (labeledValueMapData.length === 0) return;

    // URLパラメータによってチェック状態を再現した場合は、初期状態はチェックした物件だけを表示する。
    if (focusLabeled) {
      setShowOnlyLabeled(true);
      setIsFirstFocusLabeled(false);
    }
  }, [isLoadedMap, labeledValueMapData, focusLabeled]);


  /**
   * すべてのマーカーが地図画面に収まるように調整する。
   */
  const fitBounds = (fitEstateData: ValueMapDataType[]) => {
    if (fitEstateData.length === 0) return;

    if (window.google && mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();
      for (let d of fitEstateData) {
        bounds.extend(new window.google.maps.LatLng(d.lat, d.lng));
      }
      mapRef.current.fitBounds(bounds);  
    }
  }

  const render_button_area = () => {
    return (
      <div className="top_button_area">
        <div className="row">
          <InfoMenu
            isLandPricesActive={isLandPricesActive}
            setLandPricesActive={setLandPricesActive}
            isZoningActive={isZoningActive}
            setZoningActive={setZoningActive}
            isHazardMapActive={isHazardMapActive}
            setHazardMapActive={setHazardMapActive}
            isFloodActive={isFloodActive}
            setFloodActive={setFloodActive}
            isSedimentActive={isSedimentActive}
            setSedimentActive={setSedimentActive}
            isTsunamiActive={isTsunamiActive}
            setTsunamiActive={setTsunamiActive}
            isStormSurgeActive={isStormSurgeActive}
            setStormSurgeActive={setStormSurgeActive}
          />
        </div>
      </div>
    )
  }

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>, d: ValueMapDataType) => {
    if (e.target.checked) {
      // チェックを入れた場合(チェックをはずすとラベルが削除されるので、ここは動作しない)
    }
    else {
      // チェックをはずした場合
      let newLabeledValueMapData = labeledValueMapData.filter(labeled_d => {
        // チェックをはずした物件だけ除外する。
        return labeled_d.id !== d.id;
      });
      if (newLabeledValueMapData.length === 0) {
        // すべてチェックをはずした場合、チェックを入れた不動産だけを表示する機能をOFFにする。
        setShowOnlyLabeled(false);
      }
      setLabeledValueMapData(newLabeledValueMapData);

      // 吹き出しを閉じる。
      const newPopupEstate = popupValueMaps.filter(item => {
        return item.id !== d.id
      });
      setPopupValueMaps(newPopupEstate);
    }
  }
  
  const render_valueMapLabels = () => {
    let rendering = [];
    // ①～⑤
    const numbers = [ <>&#9312;</>, <>&#9313;</>, <>&#9314;</>, <>&#9315;</>, <>&#9316;</> ];

    for (let i = 0; i < labeledValueMapData.length; i++) {
      const d = labeledValueMapData[i];
      let info = [];

      // 評価額
      if (d.appraisal_value !== null) {
        let text = '';
        const appraisal_value = d.appraisal_value / 100000000;
        switch (myLang) {
          case MyLanguage.JA: {
            if (appraisal_value >= 100) {
              text = `${appraisal_value.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} 億円`;
            } else {
              text = `${appraisal_value.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1})} 億円`;
            }
                info.push(<>{text}</>);
            break;
          }
          case MyLanguage.EN: {
            // billion表記なので、10で割る。
            if (appraisal_value >= 100) {
              text = `${(appraisal_value / 10).toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1})}bn`;
            } else {
              text = `${(appraisal_value / 10).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}bn`;
            }
                info.push(<>&yen; {text}</>);
            break;
          }
        }
      }

      // 還元利回り
      if (d.cap_rate !== null) {
        if (info.length > 0) {
          info.push(<>{t('：')}</>);
        }
        info.push(<>{EstateDataUtil.convert_rate_percentage(d.cap_rate, 1)}%</>);
      }

      rendering.push(
        <li>
          <div className="checkbox"><input type="checkbox" onChange={(e) => handleCheckbox(e, d)} checked={true} /></div>
          <div className="info">
            {numbers[i]}&nbsp;{info}
          </div>
        </li>
      );
    }
    return (
      <div className="labeledEstateData">
        <ul>{rendering}</ul>
      </div>
    );
  }
  
  if (mapWidth === 0 || mapHeight === 0) return null;
  let containerStyle = {
    height: `${mapHeight}px`,
    width: `${mapWidth}px`,
  };

  return (
    <>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15} options={mapOptions} onLoad={onMapLoad}>
        <ValueMapContent
          valueMapData={ showOnlyLabeled ?  labeledValueMapData : valueMapData }
          mapRef={mapRef}
          showOnlyLabeled={showOnlyLabeled}
          setShowOnlyLabeled={setShowOnlyLabeled}
          popupEstates={popupValueMaps}
          setPopupEstates={setPopupValueMaps}
          setIsMapInteractive={setIsMapInteractive}
          isLandPricesActive={isLandPricesActive}
          isZoningActive={isZoningActive}
          isFloodActive={isFloodActive}
          isSedimentActive={isSedimentActive}
          isTsunamiActive={isTsunamiActive}
          isStormSurgeActive={isStormSurgeActive}
        />
      </GoogleMap>
      {render_button_area()}
      {render_valueMapLabels()}
      {!isMapInteractive && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <DialogValueMap/>
    </>
  );
}

export default ValueMap;
