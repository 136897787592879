import './Contact.css';
import React from 'react';
import { FC, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from "react-router-dom";
import classNames from 'classnames';
import { AppContext, MyLanguage, InquirySubject_Id } from '../context/AppContext';
import CommonUtil from '../utils/CommonUtil';

const Contact: FC = () => {
  const ContextRoot = CommonUtil.get_context_root();
  const navigate = useNavigate();

  const [ t ] = useTranslation();
  const { myLang } = useContext(AppContext);

  const FormStatus = {
    Inputting  : 0,  // ユーザーが入力中の状態。
    Confirming : 1,  // ユーザーが入力内容を確認中の状態。
    Submitted  : 2,  // フォームが送信完了した状態。
  }

  const [ inquirySubject, setInquirySubject ] = useState<string>(InquirySubject_Id.FreeUserRegistration);
  const [ name, setName ] = useState<string>('');
  const [ companyName, setCompanyName ] = useState<string>('');
  const [ emailAddress, setEmailAddress ] = useState<string>('');
  const [ comments, setComments ] = useState<string>('');

  const [ formStatus, setFormStatus ] = useState<number>(FormStatus.Inputting);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const id = searchParams.get('id');
    if (id !== null) {
      setInquirySubject(id);
    }
  }, []);


  const send_mail = () => {
    const formData = new FormData();
    formData.set('inquirySubject', get_inquirySubject_text());
    formData.set('name', name);
    formData.set('companyName', companyName);
    formData.set('emailAddress', emailAddress);
    formData.set('comments', comments);

    fetch(`${ContextRoot}/mail.php`, {
      method: 'POST',
      body: formData,
    }).then((res) => {
      if (!res.ok) {
        // エラー対応
      }
      return res.text;
    }).then((data) => {
      console.log(data);
    })
    .catch((reason) => {
      // エラー対応
      console.error(reason);
    });
  }

  const get_inquirySubject_text = () => {
    switch (inquirySubject) {
      case InquirySubject_Id.AppraisalRequest:
        return t('不動産評価（鑑定）依頼');
      case InquirySubject_Id.Registration:
        return t('Value.map利用');
      case InquirySubject_Id.NewsSubscription:
        return t('REITニュース配信');
      case InquirySubject_Id.Others:
        return t('その他');
      case InquirySubject_Id.FreeUserRegistration:
        return t('無料ユーザー登録');
      case InquirySubject_Id.PaidUserRegistration:
        return t('有料会員登録');
          }
    return '';
  }

  const render_headline = () => {
    let rendering = [];
    switch (myLang) {
      case MyLanguage.JA: {
        rendering.push(<span>ご記入頂いた情報は、弊社メールアドレス宛に送信されます。</span>);
        rendering.push(<span>後日、担当者よりご連絡差し上げますがお返事のご連絡が無い場合、お申し込みメールが届いていない場合がございますので、大変お手数ですがお電話で直接お問い合わせください。</span>);
        break;
      }
      case MyLanguage.EN: {
        rendering.push(<span>Your information will be sent to our e-mail address.</span>);
        rendering.push(<span>If you do not hear back from us, please contact us directly by phone.</span>);
        break;
      }
    }
    return <div className="headline">{rendering}</div>;
  }

  const render_inquiry_subject = () => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInquirySubject(e.target.value);
    }

    if (formStatus === FormStatus.Confirming) {
      return <span>{get_inquirySubject_text()}</span>;
    } else {
      return (
        <div className="inquiry_subject">
          <form>
            <table>
              <tr>
                <td>
                  <div>
                    <input type="radio" id={InquirySubject_Id.FreeUserRegistration} name="inquiry_subject" value={InquirySubject_Id.FreeUserRegistration} onChange={handleChange} checked={inquirySubject === InquirySubject_Id.FreeUserRegistration}></input>
                    <label htmlFor={InquirySubject_Id.FreeUserRegistration}>{t('無料ユーザー登録')}</label>
                  </div>
                </td>
                <td>
                  <div>
                    <input type="radio" id={InquirySubject_Id.PaidUserRegistration} name="inquiry_subject" value={InquirySubject_Id.PaidUserRegistration} onChange={handleChange} checked={inquirySubject === InquirySubject_Id.PaidUserRegistration}></input>
                    <label htmlFor={InquirySubject_Id.PaidUserRegistration}>{t('有料会員登録')}</label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                  <input type="radio" id={InquirySubject_Id.AppraisalRequest} name="inquiry_subject" value={InquirySubject_Id.AppraisalRequest} onChange={handleChange} checked={inquirySubject === InquirySubject_Id.AppraisalRequest}></input>
                    <label htmlFor={InquirySubject_Id.AppraisalRequest}>{t('不動産評価（無料）依頼')}</label>
                  </div>
                </td>
                <td>
                  <div>
                    <input type="radio" id={InquirySubject_Id.Others} name="inquiry_subject" value={InquirySubject_Id.Others} onChange={handleChange} checked={inquirySubject === InquirySubject_Id.Others}></input>
                    <label htmlFor={InquirySubject_Id.Others}>{t('その他')}</label>
                  </div>
                </td>
              </tr>
            </table>
          </form>
        </div>  
      );
    }
 
 
{/*    if (formStatus === FormStatus.Confirming) {
      return <span>{get_inquirySubject_text()}</span>;
    } else {
      return (
        <div className="inquiry_subject">
          <form>
            <table>
              <tr>
                <td>
                  <div>
                    <input type="radio" id={InquirySubject_Id.AppraisalRequest} name="inquiry_subject" value={InquirySubject_Id.AppraisalRequest} onChange={handleChange} checked={inquirySubject === InquirySubject_Id.AppraisalRequest}></input>
                    <label htmlFor={InquirySubject_Id.AppraisalRequest}>{t('不動産評価（鑑定）依頼')}</label>
                  </div>
                </td>
                <td>
                  <div>
                    <input type="radio" id={InquirySubject_Id.Registration} name="inquiry_subject" value={InquirySubject_Id.Registration} onChange={handleChange} checked={inquirySubject === InquirySubject_Id.Registration}></input>
                    <label htmlFor={InquirySubject_Id.Registration}>{t('Value.map利用')}</label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <input type="radio" id={InquirySubject_Id.NewsSubscription} name="inquiry_subject" value={InquirySubject_Id.NewsSubscription} onChange={handleChange} checked={inquirySubject === InquirySubject_Id.NewsSubscription}></input>
                    <label htmlFor={InquirySubject_Id.NewsSubscription}>{t('REITニュース配信')}</label>
                  </div>
                </td>
                <td>
                  <div>
                    <input type="radio" id={InquirySubject_Id.Others} name="inquiry_subject" value={InquirySubject_Id.Others} onChange={handleChange} checked={inquirySubject === InquirySubject_Id.Others}></input>
                    <label htmlFor={InquirySubject_Id.Others}>{t('その他')}</label>
                  </div>
                </td>
              </tr>
            </table>
          </form>
        </div>  
      );
    }
  */}
 
 
  }

  const render_name = () => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value);
    }

    if (formStatus === FormStatus.Confirming) {
      return <span>{name}</span>;
    } else {
      return (
        <div>
          <input type="text" onChange={handleChange} value={name}></input>
        </div>
      );
    }
  }

  const render_company_name = () => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setCompanyName(e.target.value);
    }

    if (formStatus === FormStatus.Confirming) {
      return <span>{companyName}</span>
    } else {
      return (
        <div>
          <input type="text" onChange={handleChange} value={companyName}></input>
        </div>
      );  
    }
  }

  const render_email_address = () => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmailAddress(e.target.value);
    }

    if (formStatus === FormStatus.Confirming) {
      return <span>{emailAddress}</span>;
    } else {
      return (
        <div>
          <input type="text" onChange={handleChange} value={emailAddress}></input>
        </div>
      );
    }
  }

{/*
  const render_phone_number = () => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setPhoneNumber(e.target.value);
    }

    if (formStatus === FormStatus.Confirming) {
      return <span>{phoneNumber}</span>;
    } else {
      return (
        <div>
          <input type="text" onChange={handleChange} value={phoneNumber}></input>
        </div>
      );  
    }
  }
*/}

  const render_comments = () => {
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setComments(e.target.value);
    }

    if (formStatus === FormStatus.Confirming) {
      return <span className="comments">{comments}</span>;
    } else {
      return (
        <div>
          <textarea name="" rows={6} cols={50} onChange={handleChange} value={comments}></textarea>
        </div>
      );  
    }
  }

  const render_button = () => {
    const handleClick_Review = () => {
      setFormStatus(FormStatus.Confirming);
    }
    const handleClick_Edit = () => {
      setFormStatus(FormStatus.Inputting);
    }
    const handleClick_Submit = () => {
      // フォームの内容をメール送信
      send_mail();
      setFormStatus(FormStatus.Inputting);
      // MAIN画面に遷移させる。
      navigate(`${ContextRoot}/`);  
    }

    switch (formStatus) {
      case FormStatus.Inputting:
        return <div className="button_area"><button onClick={handleClick_Review}>{t('入力内容の確認')}</button></div>

      case FormStatus.Confirming:
        return (
          <div className="button_area">
            <button onClick={handleClick_Edit}>{t('修正')}</button>
            <button onClick={handleClick_Submit}>{t('送信')}</button>
          </div>
        );

      case FormStatus.Submitted:
        break;
    }
    return null;
  }

  const mobile_className = CommonUtil.is_mobile() ? "mobile" : "";

  return (
    <div id="Contact" className={mobile_className}>
      {render_headline()}
      <div className="wrap_contact_form">
        <div className="title">{t('お問い合わせ')}</div>
        <table className="contact_form">
          <tr>
            <th>{t('お問い合わせ内容')}</th>
            <td>{render_inquiry_subject()}</td>
          </tr>
          <tr>
            <th>{t('氏名')}{t('必須')}</th>
            <td>{render_name()}</td>
          </tr>
          <tr>
            <th>{t('会社・団体名')}{t('必須')}</th>
            <td>{render_company_name()}</td>
          </tr>
          <tr>
            <th>{t('メールアドレス')}{t('必須')}</th>
            <td>{render_email_address()}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="comment_h">{t('コメント')}</div>
              {render_comments()}
            </td>
          </tr>
        </table>
        {render_button()}
      </div>
    </div>
  );
{/*
  return (
    <div id="Contact" className={mobile_className}>
      {render_headline()}
      <div className="wrap_contact_form">
        <div className="title">{t('お問い合わせ')}</div>
        <table className="contact_form">
          <tr>
            <th>{t('お問い合わせ内容')}</th>
            <td>{render_inquiry_subject()}</td>
          </tr>
          <tr>
            <th>{t('氏名')}</th>
            <td>{render_name()}</td>
          </tr>
          <tr>
            <th>{t('会社・団体名')}</th>
            <td>{render_company_name()}</td>
          </tr>
          <tr>
            <th>{t('メールアドレス')}</th>
            <td>{render_email_address()}</td>
          </tr>
          <tr>
            <th>{t('電話番号')}</th>
            <td>{render_phone_number()}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div className="comment_h">{t('コメント')}</div>
              {render_comments()}
            </td>
          </tr>
        </table>
        {render_button()}
      </div>
    </div>
  );
*/}

}

export default Contact;
