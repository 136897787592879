export const EstateDataName = {
  Lat: 'lat',
  Lng: 'lng',
  BuildingType: 'building_type',
  CapRate: 'cap_rate',
  PropertyName: 'property_name',
  Addr1: 'addr1',
  Addr2: 'addr2',
  Addr3: 'addr3',
  Transaction_YYYYMMDD: 'transaction_yyyymmdd',
  TransactionPrice: 'transaction_price',
  UnitPrice: 'unit_price',
  NumOfFloors: 'num_of_floors',
  Construction_YYYYMMDD: 'construction_yyyymmdd',
  ArchitecturalArea: 'architectural_area',
  OwnershipFormLand: 'ownership_form_land',
  OwnershipFormBuilding: 'ownership_form_building',
  AsOf_YYYYMMDD: 'as_of_yyyymmdd',
  NOI_Yield: 'noi_yield',
  OccupancyRate: 'occupancy_rate',
  Rent: 'rent',
  AppraisalCapRate: 'appraisal_cap_rate',
  AppraisalValue: 'appraisal_value',
  InvestmentCorpCode: 'investment_corp_code',
}

export enum InvestmentCorpType {
  InvestmentCorp = '1',
  Other          = '2',
  OutsideReit    = '3'
}

export enum ManagedByReitType {
  Managed    = '1',
  NotManaged = '2',
}

export enum SellOrBuyType {
  Sell          = '1',
  Buy           = '2',
  PartiallySell = '3',
}

export enum RelatedPartyTxType {
  Related    = '1',
  NonRelated = '2',
}

export enum BuildingTypeType {
  Office      = 1,
  Residential = 2,
  Retail      = 3,
  Logistics   = 4,
  Hotel       = 5,
  Others      = 99,
}

export enum BuildingType2Type {
  Senior_Residential = 1,
  RD_DC              = 2,
  Others             = 99
}

export interface EstateDataType {
  id: number,
  lat: number,
  lng: number,
  building_type: BuildingTypeType,
  cap_rate: number,
  disclose_cap_rate: number,
  note_cap_rate: number,
  property_name: string,
  property_name_en: string,
  addr1: string,
  addr2: string,
  addr3: string,
  addr1_en: string,
  addr2_en: string,
  addr3_en: string,
  transaction_yyyymmdd: number,
  transaction_price: number,
  unit_price: number,
  disclose_tx_price: number,
  num_of_floors: string,
  num_of_floors_en: string,
  construction_yyyymmdd: number,
  managed_reit_type: number,
  sell_or_buy: number,
  related_party_tx_type: number,
  land_area: number,
  architectural_area: number,
  rentable_area: number,
  total_units: number,
  _ownership: number,
  ownership_form_land: string,
  ownership_form_building: string,
  ownership_form_land_en: string,
  ownership_form_building_en: string,
  as_of_yyyymmdd: number,
  noi_y: number,
  occ: number,
  rent: number,
  cr: number,
  av: number,
  investment_corp_type: number,
  investment_corp_code: number,
  seller: string,
  seller_en: string,
  buyer: string,
  buyer_en: string,
  source_files: string,
  source_files_en: string,
  images_files: string,
  area_no: number,
  property_no: number,
  la: number,
  building_type2: number,
  station: string,
  station_en: string,
  distance: number,
}