import './Guide.css';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../context/AppContext';
import CommonUtil from '../utils/CommonUtil';

const Guide: FC = () => {
  const ContextRoot = CommonUtil.get_context_root();
  const [ t ] = useTranslation();
  const { myLang } = useContext(AppContext);

  const render_list_item = (title: string, descs: string[]) => {
    let rendering_descs = [];
    for (let i = 0; i < descs.length; i++) {
      const desc = descs[i];
      if (i > 0) rendering_descs.push(<br/>);
      rendering_descs.push(desc);
    }

    return (
      <div className="list-item">
        <div className="title">{title}</div>
        <div className="description">{rendering_descs}</div>
      </div>
    );
  }

  const render_Corporate = () => {
    return (
      <section>
        <h2>{t('コーポレート')}</h2>
        <p>
          {t('guide.corporate.desc.1')}<br/>
          {t('guide.corporate.desc.2')}<br/>
          {t('guide.corporate.desc.3')}
        </p>
        <div className="list">
          {render_list_item(t('guide.corporate.Sector.title'), [ t('guide.corporate.Sector.desc') ])}
          {render_list_item(t('guide.corporate.Expected_distribution.title'), [ t('guide.corporate.Expected_distribution.desc') ])}
          {render_list_item(t('guide.corporate.Distribution_Yield.title'), [ t('guide.corporate.Distribution_Yield.desc') ])}
          {render_list_item(t('guide.corporate.Market_Cap.title'), [ t('guide.corporate.Market_Cap.desc') ])}
          {render_list_item(t('guide.corporate.Appraisal_Value.title'), [ t('guide.corporate.Appraisal_Value.desc') ])}
          {render_list_item(t('guide.corporate.Age_of_Building.title'), [ t('guide.corporate.Age_of_Building.desc') ])}
          {render_list_item(t('guide.corporate.Investment_Area.title'), 
            [
              t('guide.corporate.Investment_Area.desc.1'),
              t('guide.corporate.Investment_Area.desc.2'),
              t('guide.corporate.Investment_Area.desc.3'),
              t('guide.corporate.Investment_Area.desc.4')
            ])}
          {render_list_item(t('guide.corporate.Property_Type.title'), 
            [
              t('guide.corporate.Property_Type.desc.1'),
              t('guide.corporate.Property_Type.desc.2'),
              t('guide.corporate.Property_Type.desc.3'),
              t('guide.corporate.Property_Type.desc.4'),
              t('guide.corporate.Property_Type.desc.5'),
              t('guide.corporate.Property_Type.desc.6'),
              t('guide.corporate.Property_Type.desc.7'),
              t('guide.corporate.Property_Type.desc.8')
            ])}
          {render_list_item(t('guide.corporate.NOI_Yield.title'), 
            [
              t('guide.corporate.NOI_Yield.desc.1'),
              t('guide.corporate.NOI_Yield.desc.2'),
              t('guide.corporate.NOI_Yield.desc.3'),
            ])}
          {render_list_item(t('guide.corporate.Implied_CR.title'), 
            [
              t('guide.corporate.Implied_CR.desc.1'),
              t('guide.corporate.Implied_CR.desc.2'),
            ])}
          {render_list_item(t('guide.corporate.NAV_multiple.title'), 
            [
              t('guide.corporate.NAV_multiple.desc.1'),
              t('guide.corporate.NAV_multiple.desc.2'),
            ])}
          {render_list_item(t('guide.corporate.FFO_multiple.title'), 
            [
              t('guide.corporate.FFO_multiple.desc.1'),
              t('guide.corporate.FFO_multiple.desc.2'),
            ])}
          {render_list_item(t('guide.corporate.Dividend_Payout_Ratio.title'), 
            [
              t('guide.corporate.Dividend_Payout_Ratio.desc.1'),
              t('guide.corporate.Dividend_Payout_Ratio.desc.2'),
            ])}
          {render_list_item(t('guide.corporate.DSCR.title'), 
            [
              t('guide.corporate.DSCR.desc.1'),
              t('guide.corporate.DSCR.desc.2'),
            ])}
          {render_list_item(t('guide.corporate.LTV.title'), 
            [
              t('guide.corporate.LTV.desc.1'),
              t('guide.corporate.LTV.desc.2'),
            ])}
          {render_list_item(t('guide.corporate.ROE.title'), 
            [
              t('guide.corporate.ROE.desc.1'),
              t('guide.corporate.ROE.desc.2'),
            ])}
          {render_list_item(t('guide.corporate.Interest_bearing_debt.title'), [ t('guide.corporate.Interest_bearing_debt.desc') ])}
          {render_list_item(t('guide.corporate.Cash_and_cash_equivalents.title'), [ t('guide.corporate.Cash_and_cash_equivalents.desc') ])}
          {render_list_item(t('guide.corporate.Depreciation.title'), [ t('guide.corporate.Depreciation.desc') ])}
          {render_list_item(t('guide.corporate.Security_Deposits_and_Guarantee.title'), [ t('guide.corporate.Security_Deposits_and_Guarantee.desc') ])}
          {render_list_item(t('guide.corporate.Long_term_debt.title'), [ t('guide.corporate.Long_term_debt.desc') ])}
          {render_list_item(t('guide.corporate.Short_term_debt.title'), [ t('guide.corporate.Short_term_debt.desc') ])}
          {render_list_item(t('guide.corporate.Percentage_of_acquisition_price.title'), [ t('guide.corporate.Percentage_of_acquisition_price.desc') ])}
          {render_list_item(t('guide.corporate.Ratio_of_management_fees.title'), [ t('guide.corporate.Ratio_of_management_fees.desc') ])}
          {render_list_item(t('guide.corporate.Ratio_of_Affiliate_Fees_and_Expenses.title'), [ t('guide.corporate.Ratio_of_Affiliate_Fees_and_Expenses.desc') ])}
          {render_list_item(t('guide.corporate.Sourcing.title'), [ t('guide.corporate.Sourcing.desc') ])}
          {render_list_item(t('guide.corporate.Expense_Ratio.title'), [ t('guide.corporate.Expense_Ratio.desc') ])}
        </div>
      </section>
    );   
  }

  const render_Property = () => {
    return (
      <section>
        <h2>{t('プロパティ')}</h2>
        <p>
          {t('guide.property.desc.1')}<br/>
          {t('guide.property.desc.2')}<br/>
          {t('guide.value.desc.3')}<a href="https://www.reinfolib.mlit.go.jp/">https://www.reinfolib.mlit.go.jp/</a>{t('guide.value.desc.4')}<br/>
          {t('guide.value.desc.5')}<br/>
        </p>
        <div className="list">
          {render_list_item(t('guide.property.NOI_CR.title'), [ t('guide.property.NOI_CR.desc') ])}
          {render_list_item(t('guide.property.Transaction_unit_price.title'), [ t('guide.property.Transaction_unit_price.desc') ])}
          {render_list_item(t('guide.property.GFA.title'), [ t('guide.property.GFA.desc') ])}
          {render_list_item(t('guide.property.NOI_Yield.title'), [ t('guide.property.NOI_Yield.desc') ])}
          {render_list_item(t('guide.property.Rent.title'), [ t('guide.property.Rent.desc') ])}
        </div>
      </section>
    )
  }

  const render_Value = () => {
    return (
      <section>
        <h2>{t('バリュー')}</h2>
        <p>
          {t('guide.value.desc.1')}<br/>
          {t('guide.value.desc.2')}<br/>
          {t('guide.value.desc.3')}<a href="https://www.reinfolib.mlit.go.jp/">https://www.reinfolib.mlit.go.jp/</a>{t('guide.value.desc.4')}<br/>
          {t('guide.value.desc.5')}<br/>
        </p>
        <div className="list">
          {render_list_item(t('guide.value.Market_Value.title'), [ t('guide.value.Market_Value.desc') ])}
          {render_list_item(t('guide.value.Market_Value_unit.title'), [ t('guide.value.Market_Value_unit.desc') ])}
          {render_list_item(t('guide.value.Land_Value.title'), [ t('guide.value.Land_Value.desc') ])}
          {render_list_item(t('guide.value.Land_Value_unit.title'), [ t('guide.value.Land_Value_unit.desc') ])}
          {render_list_item(t('guide.value.Land_Value_Per_FAR.title'),
            [
              t('guide.value.Land_Value_Per_FAR.desc.1'),
              t('guide.value.Land_Value_Per_FAR.desc.2')
            ])}
          {render_list_item(t('guide.value.Assumed_Rent.title'),
            [
              t('guide.value.Assumed_Rent.desc.1'),
              t('guide.value.Assumed_Rent.desc.2')
            ])}
          {render_list_item(t('guide.value.Cap_Rate.title'), [ t('guide.value.Cap_Rate.desc') ])}
        </div>
      </section>
    );
  }

  return (
    <div id="Guide">
      <h1>{t('guide.title')}</h1>
      {render_Corporate()}
      {render_Property()}
      {render_Value()}
    </div>
  );
}

export default Guide;
