import './MapMlit.css';
import { FC, useContext, useState, useEffect } from "react";
import { InfoWindow, OverlayView } from "@react-google-maps/api";
import { t } from "i18next";
import { AppContext, DisplayMode } from '../../context/AppContext';
import classNames from 'classnames';
import CommonUtil from '../../utils/CommonUtil';
import MapUtil from "../../utils/MapUtil";
import { MlitType } from "../../utils/MlitType";

interface Props {
  mapRef: any,
  setIsMapInteractive: React.Dispatch<React.SetStateAction<boolean>>,
  // 情報追加用のメニュー
  isLandPricesActive: boolean, 
  isZoningActive: boolean, 
};

interface Clicked_OfficialLandPricesType {
  latLng: google.maps.LatLng;
  feature: google.maps.Data.Feature;
  xy_key: string;
}

interface Clicked_Zoning {
  latLng: google.maps.LatLng;
  youto_id: string;
  use_area_ja: string;
  u_floor_area_ratio_ja: string;
  u_building_coverage_ratio_ja: string;
}

interface XY_Tile_Type {
  added: boolean;
  features: google.maps.Data.Feature[];
}

interface Mlit_Type {
  [key: string]: XY_Tile_Type;
}

type MapState = {
  center: google.maps.LatLng;
  zoom: number;
};

const MapMlit: FC<Props> = (props) => {
  const ContextRoot = CommonUtil.get_context_root();
  const { myLang } = useContext(AppContext);
  const { labeledEstateData } = useContext(AppContext);

  const [ XPT002, setXPT002 ] = useState<Mlit_Type>({});
  const [ XKT002, setXKT002 ] = useState<Mlit_Type>({});
  const MinimumZoomLevel_XPT002 = 16;
  const MinimumZoomLevel_XKT002 = 15;
  const ZoomLevel_Mlit = 13;

  // クリックした地価公示
  const [ clicked_OfficialLandPrices, setClicked_OfficialLandPrices ] = useState<Clicked_OfficialLandPricesType | null>(null);

  // クリックした用途地域
  const [ clicked_Zoning, setClicked_Zoning ] = useState<Clicked_Zoning | null>(null);

  // 地図の中心座標とズームレベルを監視するための状態変数。
  const [mapState, setMapState] = useState<MapState>({
    center: new google.maps.LatLng(0, 0),
    zoom: 10,
  });

  let prev_center = new google.maps.LatLng(0, 0);
  let prev_zoom = 10;

  /**
   * 
   */
  useEffect(() => {
    if (props.mapRef.current) {
      const map = props.mapRef.current;

      // スタイルを適用
      props.mapRef.current.data.setStyle(setFeatureStyle);

      // マップの移動が完了した際に動作する処理
      const idle_listener = map.addListener('idle', () => {
        const center = map.getCenter();
        const zoom = map.getZoom();
        if (center.lat() === prev_center.lat() && center.lng() === prev_center.lng() && zoom === prev_zoom) {
          // クラスタをクリックした場合に、idleイベントが2回発生し、同時に動作した。
          // これにより多重に地図に追加してしまうため、中心座標とズームレベルが変化していなければスキップして回避する。
          return;
        } else {
          prev_center = center;
          prev_zoom = zoom;
        }
        setMapState({ center, zoom });
      });

      // クリックイベントを設定
      const click_listener = map.data.addListener('click', (event: any) => {
        const latLng = event.latLng;
        const feature = event.feature;

        const api_type =feature.getProperty('api_type');

        // 地価公示のクリックイベント
        if (api_type === 'XPT002') {
          // OverlayView側で処理するため、ここでは何もしない。
        }
        // 用途地域のクリックイベント
        else if (api_type === 'XKT002') {
          const youtoId = feature.getProperty('youto_id');
          const use_area_ja = feature.getProperty('use_area_ja');

          setClicked_Zoning({
            latLng,
            youto_id: youtoId,
            use_area_ja,
            u_floor_area_ratio_ja: feature.getProperty('u_floor_area_ratio_ja'),
            u_building_coverage_ratio_ja: feature.getProperty('u_building_coverage_ratio_ja'),
          });
        }
      });

      // クリーンアップ関数を返すことでリスナーを解除
      return () => {
        google.maps.event.removeListener(idle_listener);
        google.maps.event.removeListener(click_listener);
      }
    }
  }, [props.mapRef]);

  /**
   * 地図の移動や拡縮をした際に、地価公示・調査や用途地域の表示を更新する。
   */
  useEffect(() => {
    update_OfficialLandPrices();
    update_Zoning();
  }, [mapState]);

  /**
   * 
   */
  useEffect(() => {
    // 「地価公示」ON
    if (props.isLandPricesActive) {
      const currentZoom = props.mapRef.current.getZoom();
      const updatedData = { ...XPT002 };  
      if (currentZoom < MinimumZoomLevel_XPT002) {
        return;
      } else {
        toggleMapInteraction(false);
        // 非同期呼び出し
        fetchGeoJson_XPT002(updatedData);
      }
    }
    // 「地価公示」OFF
    else {
      // 全て地図から削除する。
      const updatedData = { ...XPT002 };
      for (let xy_key in updatedData) {
        if (updatedData[xy_key].added) {
          updatedData[xy_key].features.forEach((feature: google.maps.Data.Feature) => {
            props.mapRef.current.data.remove(feature);
          });
          updatedData[xy_key].added = false;
        }
      }
      setXPT002(updatedData);
    }

  }, [props.isLandPricesActive]);

  /**
   * 
   */
  useEffect(() => {
    // 「用途地域」ON
    if (props.isZoningActive) {
      const currentZoom = props.mapRef.current.getZoom();
      const updatedData = { ...XKT002 };  
      if (currentZoom < MinimumZoomLevel_XKT002) {
        return;
      } else {
        toggleMapInteraction(false);
        // 非同期呼び出し
        fetchGeoJson_XKT002(updatedData);
      }
    }
    // 「用途地域」OFF
    else {
      // 全て地図から削除する。
      const updatedData = { ...XKT002 };
      for (let xy_key in updatedData) {
        if (updatedData[xy_key].added) {
          updatedData[xy_key].features.forEach((feature: google.maps.Data.Feature) => {
            props.mapRef.current.data.remove(feature);
          });
          updatedData[xy_key].added = false;
        }
      }
      setXKT002(updatedData);
    }

  }, [props.isZoningActive]);


  /**
   * 地図の操作を許可するかどうか
   * @param is_active 
   */
  const toggleMapInteraction = (is_active: boolean) => {
    if (props.mapRef.current) {
      props.mapRef.current.setOptions({
        draggable: is_active,
        zoomControl: is_active,
        scrollwheel: is_active,
        disableDoubleClickZoom: !is_active,
        gestureHandling: is_active ? 'auto' : 'none'
      });
      props.setIsMapInteractive(is_active);
    }
  };
  
  /**
   * スタイルを設定する関数
   * @param feature 
   * @returns 
   */
  const setFeatureStyle = (feature: google.maps.Data.Feature) => {
    const api_type = feature.getProperty('api_type');
    switch (api_type) {
      case 'XPT002': {
        return {
          visible: false  // デフォルトのピンアイコンを表示させないようにする。
        };
      }
      case 'XKT002': {
        const youtoId = feature.getProperty('youto_id');
        let color;
        // 用途区域ごとの色は以下を参照した。
        // https://www.mlit.go.jp/crd/city/plan/03_mati/04/
        switch (youtoId) {
          case 1:  color = '#33CC99'; break;   // 第一種低層住居専用地域
          case 2:  color = '#009966'; break;   // 第二種低層住居専用地域
          case 3:  color = '#66CC66'; break;   // 第一種中高層住居専用地域
          case 4:  color = '#CCFF99'; break;   // 第二種中高層住居専用地域
          case 5:  color = '#FFFF99'; break;   // 第一種住居地域
          case 6:  color = '#FFCC99'; break;   // 第二種住居地域
          case 7:  color = '#FFCC66'; break;   // 準住居地域
          case 8:  color = '#gray';   break;   // 田園住居地域(色定義なし)
          case 9:  color = '#FF99CC'; break;   // 近隣商業地域
          case 10: color = '#FF6699'; break;   // 商業地域
          case 11: color = '#CC99FF'; break;   // 準工業地域
          case 12: color = '#CCFFFF'; break;   // 工業地域
          case 13: color = '#66CCFF'; break;   // 工業専用地域
          default: color = 'gray'; break;
        }
        return {
          fillColor: color,
          fillOpacity: 0.4,
          strokeColor: 'gray',
          strokeOpacity: 1,
          strokeWeight: 1,
        };
      }
      default: {
        return {};
      }
    }
  };
  
  /**
   * 
   * @param updatedData 
   * @param x 
   * @param y 
   * @param features 
   */
  const register_Mlit = (updatedData: Mlit_Type, x: number, y: number, features: google.maps.Data.Feature[]) => {
    const xy_key = `${x}_${y}`;
    updatedData[xy_key] = {
      added: true,
      features: features,
    }
  }

  /**
   * 
   * @param mlitData 
   * @param x 
   * @param y 
   * @returns 
   */
  const get_features = (mlitData: Mlit_Type, x: number, y: number): google.maps.Data.Feature[] | null => {
    const xy_key = `${x}_${y}`;
    if (!(xy_key in mlitData)) {
      return null;
    }
    return mlitData[xy_key].features;
  }

  /**
   * 
   * @param mlitData 
   * @param x 
   * @param y 
   * @returns 
   */
  const is_map_added = (mlitData: Mlit_Type, x: number, y: number): boolean => {
    const xy_key = `${x}_${y}`;
    if (!(xy_key in mlitData)) {
      return false;
    }
    return mlitData[xy_key].added;
  }

  /**
   * 
   * @param updatedData 
   */
  const fetchGeoJson_XPT002 = async (updatedData: Mlit_Type) => {
    const bounds = props.mapRef.current.getBounds();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const z = ZoomLevel_Mlit;
    const x_min = MapUtil.lonToTile(sw.lng(), z);
    const x_max = MapUtil.lonToTile(ne.lng(), z);
    const y_min = MapUtil.latToTile(ne.lat(), z);
    const y_max = MapUtil.latToTile(sw.lat(), z);
    
    for (let x=x_min; x<=x_max; x++) {
      for (let y=y_min; y<=y_max; y++) {
        if (is_map_added(updatedData, x, y)) {
          // 地図に追加済みであればスキップ。
          continue;
        }

        let features = get_features(updatedData, x, y);
        if (features) {
          // すでに取得済みのデータがあれば地図に追加する。
          for (let feature of features) {
            props.mapRef.current.data.add(feature);
          }
          updatedData[`${x}_${y}`].added = true;
          continue;
        }

        // まだ取得していないデータの場合は取得する。
        const params = {
          "api_type": "XPT002",
          "z": z,
          "x": x,
          "y": y,
        };
        try {
          const response = await fetch(`${ContextRoot}/mlit.php`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
          });
    
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
    
          const data = await response.json();
          let json_features = data["features"];
          for (let json_feature of json_features) {
            if (json_feature["properties"]) {
              json_feature["properties"]["api_type"] = params["api_type"];
            }
          }
          features = props.mapRef.current.data.addGeoJson(data);
          if (features === null) {
            features = [];
          }
          
          register_Mlit(updatedData, x, y, features);
    
        } catch (error) {
          console.error(error);
        }
      }
    }

    setXPT002(updatedData);
    toggleMapInteraction(true);
  };

  /**
   * 
   * @param updatedData 
   */
  const fetchGeoJson_XKT002 = async (updatedData: Mlit_Type) => {
    const bounds = props.mapRef.current.getBounds();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();
    const z = ZoomLevel_Mlit;
    const x_min = MapUtil.lonToTile(sw.lng(), z);
    const x_max = MapUtil.lonToTile(ne.lng(), z);
    const y_min = MapUtil.latToTile(ne.lat(), z);
    const y_max = MapUtil.latToTile(sw.lat(), z);
    
    for (let x=x_min; x<=x_max; x++) {
      for (let y=y_min; y<=y_max; y++) {
        if (is_map_added(updatedData, x, y)) {
          // 地図に追加済みであればスキップ。
          continue;
        }

        let features = get_features(updatedData, x, y);
        if (features) {
          // すでに取得済みのデータがあれば地図に追加する。
          for (let feature of features) {
            props.mapRef.current.data.add(feature);
          }
          updatedData[`${x}_${y}`].added = true;
          continue;
        }

        // まだ取得していないデータの場合は取得する。
        const params = {
          "api_type": "XKT002",
          "z": z,
          "x": x,
          "y": y,
        };
        try {
          const response = await fetch(`${ContextRoot}/mlit.php`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
          });
    
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
    
          const data = await response.json();
          let json_features = data["features"];
          for (let json_feature of json_features) {
            if (json_feature["properties"]) {
              json_feature["properties"]["api_type"] = params["api_type"];
            }
          }
          features = props.mapRef.current.data.addGeoJson(data);
          if (features === null) {
            features = [];
          }

          register_Mlit(updatedData, x, y, features);
    
        } catch (error) {
          console.error(error);
        }
      }
    }

    setXKT002(updatedData);
    toggleMapInteraction(true);
  };

  /**
   * 
   * @returns 
   */
  const update_OfficialLandPrices = () => {
    if (props.isLandPricesActive === false) {
      return;
    }

    const currentZoom = props.mapRef.current.getZoom();
    const updatedData = { ...XPT002 };
    if (currentZoom < MinimumZoomLevel_XPT002) {
      // 地価公示の表示縮尺外の場合は全て地図から削除する。
      for (let xy_key in updatedData) {
        if (updatedData[xy_key].added) {
          updatedData[xy_key].features.forEach((feature: google.maps.Data.Feature) => {
            props.mapRef.current.data.remove(feature);
          });
          updatedData[xy_key].added = false;
        }
      }  
      setXPT002(updatedData);
    } else {
      toggleMapInteraction(false);
      // 非同期呼び出し
      fetchGeoJson_XPT002(updatedData);
    }
  }

  /**
   * 
   * @returns 
   */
  const update_Zoning = () => {
    if (props.isZoningActive === false) {
      return;
    }

    const currentZoom = props.mapRef.current.getZoom();
    const updatedData = { ...XKT002 };
    if (currentZoom < MinimumZoomLevel_XKT002) {
      // 用途地域の表示縮尺外の場合は全て地図から削除する。
      for (let xy_key in updatedData) {
        if (updatedData[xy_key].added) {
          updatedData[xy_key].features.forEach((feature: google.maps.Data.Feature) => {
            props.mapRef.current.data.remove(feature);
          });
          updatedData[xy_key].added = false;
        }
      }  
      setXKT002(updatedData);
    } else {
      toggleMapInteraction(false);
      // 非同期呼び出し
      fetchGeoJson_XKT002(updatedData);
    }
  }


  /**
   * 
   * @returns 
   */
  const render_infoWindow_OfficialLandPrices = () => {
    if (!clicked_OfficialLandPrices) return null;

    const infoWindowOptions = {
      pixelOffset: new window.google.maps.Size(0, 0),
      disableAutoPan: true,  // パンさせると吹き出しに合わせて地図が移動してマウスアウトしてしまうため抑止。
      zIndex: labeledEstateData.length + 1  // 最前面に表示する。
    }

    return (
      <InfoWindow key={clicked_OfficialLandPrices.feature.getProperty('point_id')}
        options={infoWindowOptions}
        position={clicked_OfficialLandPrices.latLng}
        onCloseClick={() => {
          // 吹き出しを閉じたら、クリア
          setClicked_OfficialLandPrices(null);
        }}
      >
        {render_infoWindow_OfficialLandPrices_Content(clicked_OfficialLandPrices)}
      </InfoWindow>
    );
  }

  /**
   * 
   * @param clicked_OfficialLandPrices 
   * @returns 
   */
  const render_infoWindow_OfficialLandPrices_Content = (clicked_OfficialLandPrices: Clicked_OfficialLandPricesType) => {
    const feature = clicked_OfficialLandPrices.feature;

    const same_point_feature = get_same_point_feature(
      clicked_OfficialLandPrices.feature.getProperty('point_id'),
      clicked_OfficialLandPrices.feature.getProperty('land_price_type'),
      XPT002[clicked_OfficialLandPrices.xy_key].features
    );

    // ×ボタン
    const render_close_button = () => {
      return (
        <div className="close_button" onClick={() => {setClicked_OfficialLandPrices(null)}}>
          <svg x="0px" y="0px" viewBox="0 0 512 512" width="9" height="9" version="1.1">
            <g>
              <polygon points="512,52.535 459.467,0.002 256.002,203.462 52.538,0.002 0,52.535 203.47,256.005 0,459.465 52.533,511.998 256.002,308.527 459.467,511.998 512,459.475 308.536,256.005"></polygon>
            </g>
          </svg>
        </div>
      );
    }
    
    const render_land_price_type = (land_price_type: number) => {
      switch (land_price_type) {
        case MlitType.LandPrice.Mlit: return t('mlit.地価公示');
        case MlitType.LandPrice.Prefectural: return t('mlit.地価調査');
        default: return '';
      }
    }

    const render_content = (feature: google.maps.Data.Feature) => {
      return (
        <div className="content">
          <div className="land_price_type">{render_land_price_type(feature.getProperty('land_price_type'))}</div>
          <div className="target_year_name_ja">{replace_target_year_name_ja(feature.getProperty('target_year_name_ja'))}</div>
          <div>{feature.getProperty('use_category_name_ja')}</div>
          <div>{feature.getProperty('standard_lot_number_ja')}</div>
          <div className="u_current_years_price_ja">{feature.getProperty('u_current_years_price_ja')}</div>
        </div>
      )
    }

    const replace_target_year_name_ja = (target_year_name_ja: string) => {
      if (!target_year_name_ja) return '';
      // 元号を英字に変換する。
      target_year_name_ja = target_year_name_ja.replace('令和', 'R');
      target_year_name_ja = target_year_name_ja.replace('平成', 'H');
      // 元年を1に変換する。
      target_year_name_ja = target_year_name_ja.replace('元年', '1');
      // 年月をスラッシュ区切りに変換する。
      target_year_name_ja = target_year_name_ja.replace('年', '/');
      target_year_name_ja = target_year_name_ja.replace('月', '/'); 
      // 日は削除する。
      target_year_name_ja = target_year_name_ja.replace('日', ''); 

      return target_year_name_ja;
    }

    const onClickHandler = () => {
      const point_id = feature.getProperty('point_id');
      const xy_key = clicked_OfficialLandPrices.xy_key;
      const url = `${ContextRoot}/?mode=${DisplayMode.OfficialLandPricesDetail}&point_id=${point_id}&xy_key=${xy_key}&z=${ZoomLevel_Mlit}&lang=${myLang}`;

      const width = 800;
      const height = window.innerHeight * 0.8;
      const features = `width=${width},height=${height},top=${0},left=${0},noreferrer=yes`;

      window.open(url, '_blank', features);
    }

    return (
      <div className="mlit_info_window">
        <div className="window_header">
          {/* 以下のタイトルは非表示。吹き出しの幅を「タイトル＋×ボタン」に揃えるための処理。 */}
          <div className="land_price_type_hidden">{render_land_price_type(feature.getProperty('land_price_type'))}</div>
          {render_close_button()}
        </div>
        <div className={classNames('content_OfficialLandPrices')}>
          {render_content(feature)}
          {same_point_feature && render_content(same_point_feature)}
          <div className="show_details"><a onClick={() => onClickHandler()}>{t('mlit.詳細表示')}</a></div>
        </div>
      </div>
    )
  }

  /**
   * 
   * @returns 
   */
  const render_infoWindow_Zoning = () => {
    if (!clicked_Zoning) return null;

    const infoWindowOptions = {
      pixelOffset: new window.google.maps.Size(0, 0),
      disableAutoPan: true,  // パンさせると吹き出しに合わせて地図が移動してマウスアウトしてしまうため抑止。
      zIndex: labeledEstateData.length + 1  // 最前面に表示する。
    }

    return (
      <InfoWindow key={clicked_Zoning.youto_id}
        options={infoWindowOptions}
        position={clicked_Zoning.latLng}
        onCloseClick={() => {
          // 吹き出しを閉じたら、クリア
          setClicked_Zoning(null);
        }}
      >
        {render_infoWindow_Zoning_Content(clicked_Zoning)}
      </InfoWindow>
    );
  }

  /**
   * 
   * @param clicked_Zoning 
   * @returns 
   */
  const render_infoWindow_Zoning_Content = (clicked_Zoning: Clicked_Zoning) => {
    // ×ボタン
    const render_close_button = () => {
      return (
        <div className="close_button" onClick={() => {setClicked_Zoning(null)}}>
          <svg x="0px" y="0px" viewBox="0 0 512 512" width="9" height="9" version="1.1">
            <g>
              <polygon points="512,52.535 459.467,0.002 256.002,203.462 52.538,0.002 0,52.535 203.47,256.005 0,459.465 52.533,511.998 256.002,308.527 459.467,511.998 512,459.475 308.536,256.005"></polygon>
            </g>
          </svg>
        </div>
      );
    }
    
    return (
      <div className="mlit_info_window">
        <div className="window_header">
          <div className="zoning_title">{t('mlit.用途地域')}</div>
          {render_close_button()}
        </div>
        <div className={classNames('content_zoning')}>
          <div>{clicked_Zoning.use_area_ja}</div>
          <div>{t('mlit.容積率：')}{clicked_Zoning.u_floor_area_ratio_ja ? clicked_Zoning.u_floor_area_ratio_ja : "ー"}</div>
          <div>{t('mlit.建蔽率：')}{clicked_Zoning.u_building_coverage_ratio_ja ? clicked_Zoning.u_building_coverage_ratio_ja : "ー"}</div>
        </div>
      </div>
    )
  }


  /**
   * 国土交通省地価公示と都道府県地価調査の両方があるかどうかを判定する。
   * @param point_id 
   * @param land_price_type 
   * @param features 
   * @returns 
   */
  const get_same_point_feature = (point_id: number, land_price_type: number, features: google.maps.Data.Feature[]) => {
    const search_land_price_type = (land_price_type === MlitType.LandPrice.Mlit) ? MlitType.LandPrice.Prefectural : MlitType.LandPrice.Mlit;
    for (let feature of features) {
      if (feature.getProperty('point_id') === point_id && feature.getProperty('land_price_type') === search_land_price_type) {
        return feature;
      }
    }
    return null;
  }

  /**
   * 
   * @returns 
   */
  const render_OfficialLandPrices = () => {
    const clickEventHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, latLng: google.maps.LatLng, feature: google.maps.Data.Feature, xy_key: string) => {
      // 用途地域のクリックイベントも動作してしまわないようにイベントの伝播を止める。
      e.stopPropagation();

      setClicked_OfficialLandPrices({
        latLng,
        feature,
        xy_key
      });
    }

    const get_icon = (land_price_type: number | null, has_mlit_and_prefectural: boolean) => {
      if (has_mlit_and_prefectural) {
        // 国土交通省地価公示と都道府県地価調査の両方がある場合は、四角のアイコンを表示する。
        return '■';
      }
      switch (land_price_type) {
        case MlitType.LandPrice.Mlit:        return '●';
        case MlitType.LandPrice.Prefectural: return '▲';
        default: return '';
      }
    }

    const get_icon_color = (use_category_name_ja: string | null) => {
      switch (use_category_name_ja) {
        case '住宅地': return '#FFC000';
        case '宅地見込地': return '#E6B8B7';
        case '商業地': return '#948A54';
        case '準工業地': return '#8064A2';
        case '工業地': return '#00B0F0';
        case '市街地調整区域内の現況宅地': return '#92D050';
        case '市街地調整区域内の現況林地': return '#00B050';
        case '林地': return '#00B050';
        case '休止地点': return '#000000';
        default: return 'black';
      }
    }

    let rendering: any = [];
    for (let xy_key in XPT002) {
      if (XPT002[xy_key].added) {
        XPT002[xy_key].features.forEach((feature: google.maps.Data.Feature) => {
          const geometory = feature.getGeometry() as google.maps.Data.Point;
          if (geometory && geometory.getType() === 'Point') {
            const point_id = feature.getProperty('point_id');
            const land_price_type = feature.getProperty('land_price_type');
            const same_point_feature = get_same_point_feature(point_id, land_price_type, XPT002[xy_key].features);
            // 国土交通省地価公示と都道府県地価調査の両方がある場合は、片方(国土交通省地価公示)に寄せる。
            if ((same_point_feature === null) || land_price_type === MlitType.LandPrice.Mlit) {
              const point = geometory.get();
              rendering.push(
                <OverlayView
                  key={point_id}
                  position={{ lat: point.lat(), lng: point.lng() }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  <div className={"point_icon_text"} onClick={(e) => clickEventHandler(e, point, feature, xy_key)}>
                    <span style={{fontSize: '10px', marginLeft: '-5px', marginRight: '1px', color: get_icon_color(feature.getProperty('use_category_name_ja')) }}>
                      {get_icon(land_price_type, same_point_feature !== null)}
                    </span>
                    <div className="text">
                      <span>{feature.getProperty('standard_lot_number_ja')}&nbsp;{feature.getProperty('u_current_years_price_ja').replace('(円/㎡)', '')}</span>
                      {same_point_feature && (
                        <span>{same_point_feature.getProperty('standard_lot_number_ja')}&nbsp;{same_point_feature.getProperty('u_current_years_price_ja').replace('(円/㎡)', '')}</span>
                      )}
                    </div>
                  </div>
                </OverlayView>
              );  
            }
          }
        });
      }
    }
    return rendering;
  }

  return (
    <>
      {render_infoWindow_OfficialLandPrices()}
      {render_infoWindow_Zoning()}
      {render_OfficialLandPrices()}
    </>
  );
}

export default MapMlit;
