import { FC } from 'react';
import CommonUtil from '../utils/CommonUtil';
import './UsReit_Background.css';

const UsReit_Background: FC = () => {
  const ContextRoot = CommonUtil.get_context_root();

  return (
    <div id="UsReit_Background">
      <div>
        <h2>US-REIT is scheduled to launch in Nov 2024.</h2>
        <div id="video-area">
          <video playsInline autoPlay loop muted poster={`${ContextRoot}/images/background/New-york_H264HD1080_5_min.jpg`}>
            <source src={`${ContextRoot}/images/background/New-york_H264HD1080_4_2.mp4`} type="video/mp4" />
            <source src={`${ContextRoot}/images/background/New-york_H264HD1080_2.webm`} type="video/webm" />
            <source src={`${ContextRoot}/images/background/New-york_H264HD1080_5.jpg`} />
          </video>
        </div>
      </div>
    </div>
  );
}

export default UsReit_Background;
