import './UserRegistrationDialog.css';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { AppContext, InquirySubject_Id } from '../context/AppContext';
import { MemberType } from '../utils/MemberType';
import CommonUtil from '../utils/CommonUtil';

const UserRegistrationDialog: FC = () => {
  const [ t ] = useTranslation(); 
  const { isOpenUserRegistrationDialog, setIsOpenUserRegistrationDialog, memberType } = useContext(AppContext);
  const navigate = useNavigate();

  const ContextRoot = CommonUtil.get_context_root();

  if (!isOpenUserRegistrationDialog) return null;

  const get_dialogMsg = () => {
    switch (memberType) {
      case MemberType.A:
        return t('dialog.ユーザー登録へ（無料）');
      case MemberType.B:
        return t('dialog.有料会員登録へ');
      default:
        return '';
    }      
  }

  // ×ボタン
  const render_close_button = () => {
    return (
      <div className="close_button" onClick={() => { setIsOpenUserRegistrationDialog(false) }}>
        <svg x="0px" y="0px" viewBox="0 0 512 512" width="10" height="10" version="1.1">
          <g>
            <polygon points="512,52.535 459.467,0.002 256.002,203.462 52.538,0.002 0,52.535 203.47,256.005 0,459.465 52.533,511.998 256.002,308.527 459.467,511.998 512,459.475 308.536,256.005"></polygon>
          </g>
        </svg>
      </div>
    );
  }

  const render_register_button = () => {
    const handleClick = () => {
      setIsOpenUserRegistrationDialog(false);

      let id_params = '';
      switch (memberType) {
        case MemberType.A:
          id_params = `id=${InquirySubject_Id.FreeUserRegistration}`;
          break;
        case MemberType.B:
          id_params = `id=${InquirySubject_Id.PaidUserRegistration}`;
          break
      }
      navigate(`${ContextRoot}/contact?${id_params}`);
    }
  
    return (
      <div className="register_button" onClick={() => {handleClick()}}>
        {get_dialogMsg()}
      </div>
    );
  }
  
  return (
    <div id="UserRegistrationDialog">
      {render_close_button()}
      {render_register_button()}
    </div>
  );
}

export default UserRegistrationDialog;
