import React, { useState } from 'react';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SubmitSearchButtonId } from '../../utils/SearchPanelConst';
import { AppContext } from '../../context/AppContext';
import AuthUtil from '../../utils/AuthUtil';
import { MemberType } from '../../utils/MemberType';
import classNames from 'classnames';

import './BuildingType.css';

interface BuildingTypes {
  [key: string]: boolean;
}

// ラベル用の型定義
interface LabelNames {
  building_type1: string;
  building_type2: string;
  building_type3: string;
  building_type4: string;
  building_type5: string;
  building_type99: string;
}

const BuildingType: React.VFC = () => {
  const [ t ] = useTranslation();
  const { register } = useFormContext();
  const { searchCond, memberType, setIsOpenUserRegistrationDialog } = useContext(AppContext);

  const initialBuildingTypes: BuildingTypes = {
    building_type1: searchCond['building_type1'] === '1',
    building_type2: searchCond['building_type2'] === '2',
    building_type3: searchCond['building_type3'] === '3',
    building_type4: searchCond['building_type4'] === '4',
    building_type5: searchCond['building_type5'] === '5',
    building_type99: searchCond['building_type99'] === '99',
  };

  const [buildingTypes, setBuildingTypes] = useState<BuildingTypes>(initialBuildingTypes);

  const labelNames: LabelNames = {
    building_type1: t('オフィス'),
    building_type2: t('レジデンシャル'),
    building_type3: t('リテール'),
    building_type4: t('ロジスティクス'),
    building_type5: t('ホテル'),
    building_type99: t('その他')
  };

  const handleChange = () => {
    // タイプ変更時に再検索させる。
    const submit_search_button = document.getElementById(SubmitSearchButtonId);
    if (!submit_search_button) return;
    submit_search_button.click();
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement>, type: string): void => {
    if (AuthUtil.restricted(memberType, MemberType.C)) {
      setIsOpenUserRegistrationDialog(true);
      return;
    }    

    setBuildingTypes(prev => ({ ...prev, [type]: !prev[type] }));
    const input = (event.currentTarget.querySelector('input[type="checkbox"]') as HTMLInputElement);
    if (input) {
      input.click();  // 子要素のinputをクリック
    }
  }

  const handleInputClick = (event: React.MouseEvent<HTMLInputElement>): void => {
    // 親要素divで定義したonClickイベントが発火しないようにする。(2重でhandleClickが呼ばれないようにする)
    event.stopPropagation();
  }

  return (
    <div id="BuildingType">
      <div className="searchgroup">
        <div className="building_type_box">
          <div className="col">
            {(['building_type1', 'building_type2', 'building_type3'] as (keyof LabelNames)[]).map(type => (
              <div key={type} className={classNames('building_type_common', type)} onClick={(e) => handleClick(e, type)}>
                <input type="checkbox" id={type} value={type.replace('building_type', '')} checked={buildingTypes[type]} {...register(type, { onChange: handleChange })} onClick={(e) => handleInputClick(e)} />
                <label htmlFor={type}>{labelNames[type]}</label>
              </div>
            ))}
          </div>
          <div className="col">
            {(['building_type4', 'building_type5', 'building_type99'] as (keyof LabelNames)[]).map(type => (
              <div key={type} className={classNames('building_type_common', type)} onClick={(e) => handleClick(e, type)}>
                <input type="checkbox" id={type} value={type.replace('building_type', '')} checked={buildingTypes[type]} {...register(type, { onChange: handleChange })} onClick={(e) => handleInputClick(e)} />
                <label htmlFor={type}>{labelNames[type]}</label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuildingType;
