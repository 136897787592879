import './OfficialLandPricesDetail.css';
import { FC, useEffect, useState, useContext } from "react";
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';
import { AppContext, MyLanguage, DisplayMode } from '../context/AppContext';
import CommonUtil from '../utils/CommonUtil';
import ChartUtil from '../utils/ChartUtil';
import { MlitType } from "../utils/MlitType";

const OfficialLandPricesDetail: FC = () => { 

  const ContextRoot = CommonUtil.get_context_root();
  
  const [ t ] = useTranslation();
  const { myLang } = useContext(AppContext);
  const [ searchParams ] = useSearchParams();

  /**
   * 国土交通省地価公示(標準地)のデータリスト
   */
  const [ dataList_mlit, setDataList_mlit ] = useState<any[]>([]);
  /**
   * 都道府県地価調査(基準地)のデータリスト
   */
  const [ dataList_prefectural, setDataList_prefectural ] = useState<any[]>([]);
  /**
   * 国土交通省地価公示(標準地)のデータリスト(過去)
   */
  const [ dataList_mlit_hist, setDataList_mlit_hist ] = useState<any[]>([]);
  /**
   * 都道府県地価調査(基準地)のデータリスト(過去)
   */
  const [ dataList_prefectural_hist, setDataList_prefectural_hist ] = useState<any[]>([]);

  const GridInterval = 5;

  useEffect(() => {
    const mode = searchParams.get('mode');
    if (mode !== DisplayMode.OfficialLandPricesDetail) return;

    const point_id = searchParams.get('point_id') ?? '0';
    const xy_key = searchParams.get('xy_key') ?? '0_0';
    const z = searchParams.get('z') ?? '0';

    fetch_XPT002(Number(point_id), xy_key, z);
    fetch_XPT002_hist(Number(point_id));
  }, []);

  const fetch_XPT002_hist = async (point_id: number) => {
    const fetch_data = async (point_id: number, land_price_type: number, callback: (data: any[]) => void) => {
      const params = {
        "point_id": point_id,
        "land_price_type": land_price_type,
      };
  
      try {
        const response = await fetch(`${ContextRoot}/mlit_xpt002_hist.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(params),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        console.log(data);
        callback(data);

      } catch (error) {
        console.error(error);
      }
    }

    fetch_data(point_id, MlitType.LandPrice.Mlit, setDataList_mlit_hist);
    fetch_data(point_id, MlitType.LandPrice.Prefectural, setDataList_prefectural_hist);
  }

  const fetch_XPT002 = async (point_id: number, xy_key: string, z: string) => {
    const x = Number(xy_key.split('_')[0]);
    const y = Number(xy_key.split('_')[1]);

    let new_dataList_mlit: any[] = [];
    let new_dataList_prefectural: any[] = [];

    const params = {
      "api_type": "XPT002",
      "z": z,
      "x": x,
      "y": y,
    };
    try {
      const response = await fetch(`${ContextRoot}/mlit.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (!data.features || data.features.length === 0) {
        console.error('No features');
      }
      else {
        for (let feature of data.features) {
          if (point_id === feature.properties.point_id) {
            const land_price_type = feature.properties.land_price_type;
            switch (land_price_type) {
              case MlitType.LandPrice.Mlit:
                new_dataList_mlit.push(feature.properties);
                break;
              case MlitType.LandPrice.Prefectural:
                new_dataList_prefectural.push(feature.properties);
                break;
            }
          }
        }  
      }

    } catch (error) {
      console.error(error);
    }

    setDataList_mlit(new_dataList_mlit);
    setDataList_prefectural(new_dataList_prefectural);
  }


  const render_content = (dataList: any[], dataList_hist: any[], land_price_type: number) => {
    if (dataList.length === 0 || dataList_hist.length === 0) {
      return null;
    }
    return (
      <div>
        <h2>{render_title(dataList)}</h2>
        {render_table(dataList)}
        <h3>{t('mlit.official_land_prices_detail.過去の地価、対前年変動一覧')}</h3>
        {render_past(dataList_hist)}
        {render_chart(dataList_hist, land_price_type)}
      </div>
    );
  }


  const render_title = (dataList: any[]) => {
    if (dataList.length === 0) {
      return '';
    }
    const data = dataList[0];
    switch (data.land_price_type) {
      case MlitType.LandPrice.Mlit:
        return t('mlit.official_land_prices_detail.国土交通省地価公示(標準地)');
      case MlitType.LandPrice.Prefectural:
        return t('mlit.official_land_prices_detail.都道府県地価調査(基準地)');
      default:
        return '';
    }
  }


  const render_table = (dataList: any[]) => {
    let data: any = {};
    if (dataList.length > 0) {
      data = dataList[0];
    }

    const get_TransportFacilitiesDistance = () => {
      let text = '';
      if (data.nearest_station_name_ja) {
        text += data.nearest_station_name_ja;
      }
      if (data.proximity_to_transportation_facilities) {
        if (text.length > 0) { text += '、'; }
        text += data.proximity_to_transportation_facilities;
      }
      return text;
    }

    const get_UsageClassificationAndStructure = () => {
      let text = '';
      if (data.usage_category_name_ja) {
        text += data.usage_category_name_ja;
      }
      if (data.building_structure_name_ja) {
        if (text.length > 0) { text += '、'; }
        text += data.building_structure_name_ja;
      }
      if (data.u_ground_hierarchy_ja) {
        if (text.length > 0) { text += ' '; }
        text += data.u_ground_hierarchy_ja;
      }
      if (data.u_underground_hierarchy_ja) {
        if (text.length > 0) { text += ' '; }
        text += data.u_underground_hierarchy_ja;
      }
      return text;
    }

    const get_WaterSupplyAndDrainageConditions = () => {
      let text = '';
      if (data.gas_supply_availability || data.gas_supply_availablity) {
        // 仕様書はgas_supply_availabilityだが、実際のデータはgas_supply_availablityになっているので、両方判定する。
        text += t('mlit.official_land_prices_detail.ガス');
      } else {
        text += '－';
      }
      text += '　・　';

      if (data.water_supply_availability || data.water_supply_availablity) {
        // 同上
        text += t('mlit.official_land_prices_detail.水道');
      } else {
        text += '－';
      }
      text += '　・　';

      if (data.sewer_supply_availability || data.sewer_supply_availablity) {
        // 同上
        text += t('mlit.official_land_prices_detail.下水');
      } else {
        text += '－';
      }

      return text;
    }

    const get_UsageDistrictsAndFireZones = () => {
      let text = '';
      // 法規制・用途区分
      if (data.regulations_use_category_name_ja) {
        if (text.length > 0) { text += '、'; }
        text += data.regulations_use_category_name_ja;
      }
      // 法規制・高度地区
      if (data.regulations_altitude_district_name_ja) {
        if (text.length > 0) { text += '、'; }
        text += data.regulations_altitude_district_name_ja;
      }
      // 法規制・防火・準防火
      if (data.regulations_fireproof_name_ja) {
        if (text.length > 0) { text += '、'; }
        text += data.regulations_fireproof_name_ja;
      }
      return text;
    }

    const get_BuildingCoverageAndFloorAreaRatio = () => {
      let text = '';
      // 法規制・建蔽率
      if (data.u_regulations_building_coverage_ratio_ja) {
        // マイナス値の場合、マイナスを削除する。
        text += data.u_regulations_building_coverage_ratio_ja.replace('-', '');
      }
      // 法規制・容積率
      if (data.u_regulations_floor_area_ratio_ja) {
        if (text.length > 0) { text += ' '; }
        // マイナス値の場合、マイナスを削除する。
        text += data.u_regulations_floor_area_ratio_ja.replace('-', '');
      }
      return text;
    }

    const render_row = (label: string, value: string) => {
      if (!value) {
        return null;
      }
      return (
        <tr>
          <th>{label}</th>
          <td>{value}</td>
        </tr>
      );
    }

    return (
      <table className="land_price_survey_details">
        <tbody>
          {render_row(t('mlit.official_land_prices_detail.基準地番号'), data.standard_lot_number_ja)}
          {render_row(t('mlit.official_land_prices_detail.調査基準日'), data.target_year_name_ja)}
          {render_row(t('mlit.official_land_prices_detail.所在及び地番'), data.location)}
          {render_row(t('mlit.official_land_prices_detail.住居表示'), data.residence_display_name_ja)}
          {render_row(t('mlit.official_land_prices_detail.用途区分'), data.use_category_name_ja)}
          {render_row(t('mlit.official_land_prices_detail.交通施設、距離'), get_TransportFacilitiesDistance())}
          {render_row(t('mlit.official_land_prices_detail.価格(円/㎡)'), data.u_current_years_price_ja)}
          {render_row(t('mlit.official_land_prices_detail.対前年変動率(％)'), data.year_on_year_change_rate ? `${data.year_on_year_change_rate}(%)` : '')}
          {render_row(t('mlit.official_land_prices_detail.地積(㎡)'), data.u_cadastral_ja)}
          {render_row(t('mlit.official_land_prices_detail.形状(間口：奥行)'), data.shape)}
          {render_row(t('mlit.official_land_prices_detail.利用区分、構造'), get_UsageClassificationAndStructure())}
          {render_row(t('mlit.official_land_prices_detail.利用現況'), data.usage_status_name_ja)}
          {render_row(t('mlit.official_land_prices_detail.給排水等状況'), get_WaterSupplyAndDrainageConditions())}
          {render_row(t('mlit.official_land_prices_detail.周辺の土地利用現況'), data.current_usage_status_of_surrounding_land_name_ja)}
          {render_row(t('mlit.official_land_prices_detail.前面道路の状況'), data.front_road_condition)}
          {render_row(t('mlit.official_land_prices_detail.その他の接面道路'), data.side_road_condition)}
          {render_row(t('mlit.official_land_prices_detail.都市計画区域区分'), data.area_division_name_ja)}
          {render_row(t('mlit.official_land_prices_detail.用途区分、高度地区、防火・準防火'), get_UsageDistrictsAndFireZones())}
          {render_row(t('mlit.official_land_prices_detail.建蔽率(%)、容積率(%)'), get_BuildingCoverageAndFloorAreaRatio())}
          {render_row(t('mlit.official_land_prices_detail.森林法、公園法、自然環境等'), data.park_forest_law)}
        </tbody>
      </table>
    )
  }

  const render_past = (dataList: any[]) => {
    const render_rows = () => {
      let rendering = [];
      for (let data of dataList) {
        rendering.push(
          <tr key={data.target_year_name_ja}>
            <td>{data.target_year_name_ja.match(/^\D+\d*年/)}</td>
            <td>{data.standard_lot_number_ja}</td>
            <td>{data.u_current_years_price_ja.replace('(円/㎡)', '')}</td>
            <td>{data.year_on_year_change_rate}</td>
          </tr>
        );
      }
      return rendering;
    }

    return (
      <table className="past_land_prices_table">
        <thead>
          <tr>
            <th>{t('mlit.official_land_prices_detail.年')}</th>
            <th>{t('mlit.official_land_prices_detail.標準地番号')}</th>
            <th>{t('mlit.official_land_prices_detail.価格(円/㎡)')}</th>
            <th>{t('mlit.official_land_prices_detail.対前年変動率(%)')}</th>
          </tr>
        </thead>
        <tbody>
          {render_rows()}
        </tbody>
      </table>
    )
  }

  const render_chart = (dataList: any[], land_price_type: number) => {
    let categories = create_categories(dataList);

    let color = '';
    switch (land_price_type) {
      case MlitType.LandPrice.Mlit: color = '#FF0000'; break;
      case MlitType.LandPrice.Prefectural: color = '#0000FF'; break;
    }

    let price_list: (number | null)[] = [];
    let price_list_no_null: number[] = [];

    for (let i = dataList.length - 1; i >= 0; i--) {
      let data = dataList[i];
      let price = null;
      if (data.u_current_years_price_ja) {
        // 「(円/㎡)」とカンマを削除して数値に変換する。
        price = Number(data.u_current_years_price_ja.replace('(円/㎡)', '').replace(/,/g, ''));
      }
      price_list.push(price);
      if (price !== null) {
        price_list_no_null.push(price);
      }
    }

    let max_price = Math.max(...price_list_no_null);
    max_price = ChartUtil.expand_max(0, max_price, 0.8);
    const grid_min_max = ChartUtil.get_grid_min_max(0, max_price, GridInterval);

    const series = [
      {
        name: get_series_name(land_price_type),
        type: 'column',
        data: price_list
      },
    ];

    const options: any = {
      chart: {
        toolbar: { show: false },
        animations: { enabled: false },
        zoom: { enabled: false }
      },
      title: {
        text: get_graph_title(land_price_type),
        align: 'center',
        offsetY: 0,
        style: {
          fontWeight: 'bold'
        }  
      },
      colors: [color],
      stroke: {
        width: [0],
        curve: 'straight'
      },
      xaxis: {
        categories: categories,
        labels: {
          rotate: -90, // 縦書き
          rotateAlways: true, // 常に回転させる
        }
      },
      yaxis: {
        tickAmount: GridInterval,
        min: 0,
        max: grid_min_max.grid_max,
        labels: {
          show: true,
          formatter: function(val: number, index: any) {
            if (val === null || val === undefined) return '';
            return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
          }
        }
      },
      tooltip: {
        y: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            if (opts.seriesIndex === 0) {
              // 地価調査価格
              let text = val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
              switch (myLang) {
                case MyLanguage.JA: return text + ' 円';
                case MyLanguage.EN: return '&yen; ' + text;
              }
              return '';
            }
            else {
              return '';
            }
          }
        }
      },
    };

    return (<div className="Chart"><Chart type='line' options={options} series={series} /></div>);
  }

  const create_categories = (dataList: any[]) => {
    let categories: string[] = [];
    for (let i = dataList.length - 1; i >= 0; i--) {
      let data = dataList[i];
      // 年より後ろは表示しない。
      const year = data.target_year_name_ja.match(/^\D+\d*年/);
      categories.push(year ? year[0] : '');
    }
    return categories;
  }

  const get_series_name = (land_price_type: number) => {
    switch (land_price_type) {
      case MlitType.LandPrice.Mlit:
        return t('mlit.official_land_prices_detail.地価公示価格');
      case MlitType.LandPrice.Prefectural:
        return t('mlit.official_land_prices_detail.地価調査価格');
      default:
        return '';
    }
  }

  const get_graph_title = (land_price_type: number) => {
    switch (land_price_type) {
      case MlitType.LandPrice.Mlit:
        return t('mlit.official_land_prices_detail.地価公示価格グラフ');
      case MlitType.LandPrice.Prefectural:
        return t('mlit.official_land_prices_detail.地価調査価格グラフ');
      default:
        return '';
    }
  }

  const render_close_button = () => {
    const handleClick = () => {
      window.close();
    }

    return (
      <div className="close_button">
        <button onClick={handleClick}>
          <div>
            <svg x="0px" y="0px" viewBox="0 0 512 512" width="9" height="9" version="1.1">
              <g>
                <polygon points="512,52.535 459.467,0.002 256.002,203.462 52.538,0.002 0,52.535 203.47,256.005 0,459.465 52.533,511.998 256.002,308.527 459.467,511.998 512,459.475 308.536,256.005"></polygon>
              </g>
            </svg>
            {t('閉じる')}
          </div>
        </button>
      </div>
    );
  }

  return (
    <div id="OfficialLandPricesDetail">
      <div className="container">
        <div>
          {render_content(dataList_mlit, dataList_mlit_hist, MlitType.LandPrice.Mlit)}
          {render_content(dataList_prefectural, dataList_prefectural_hist, MlitType.LandPrice.Prefectural)}
          {render_close_button()}
        </div>
      </div>
    </div>
  );
}

export default OfficialLandPricesDetail;
