import React from 'react';
import { useContext, MouseEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SubmitSearchButtonId } from '../../utils/SearchPanelConst';
import { AppContext, DisplayMode } from '../../context/AppContext';
import AuthUtil from '../../utils/AuthUtil';
import { MemberType } from '../../utils/MemberType';
import './CapRate.css';

type Props = {

};

const CapRate: React.VFC<Props> = () => {
  const [ t ] = useTranslation();
  const { register } = useFormContext();

  const { displayMode, memberType, setIsOpenUserRegistrationDialog } = useContext(AppContext);

  const handleChange = () => {
    // CR上限／下限変更時に再検索させる。
    const submit_search_button = document.getElementById(SubmitSearchButtonId);
    if (!submit_search_button) return;
    submit_search_button.click();
  }

  const handleMouseDown = (event: MouseEvent<HTMLSelectElement>): void => {
    if (AuthUtil.restricted(memberType, MemberType.C)) {
      setIsOpenUserRegistrationDialog(true);
      // optionの表示をキャンセルする。
      event.preventDefault();
      return;
    }
  }

  const render_cap_rate = () => {
    let cap_rate = [];
    let min_rate = 0;
    const max_rate = 8;

    if (displayMode === DisplayMode.ValueList || displayMode === DisplayMode.ValueMap) {
      min_rate = 2;
    } else {
      min_rate = 3;
    }

    for (let i = min_rate; i <= max_rate; i++) {
      let value = `${i}` + '.0';
      cap_rate.push(<option value={value}>{value}%</option>);
    }
    return cap_rate;
  }

  let label = '';
  if (displayMode === DisplayMode.ValueList || displayMode === DisplayMode.ValueMap) {
    label = t('還元利回り');
  } else {
    label = t('CR（NOI）(検索パネル)');
  }

  return (
    <div id="CapRate">
      <div className="searchgroup">
        <span className="searchfieldlabel">{label}</span>
        <select onMouseDown={handleMouseDown} {...register('cap_rate_lower', { onChange: handleChange })}>
          <option value="0" selected disabled></option>
          <option value="0">{t('下限なし')}</option>
          {render_cap_rate()}
        </select>
        &nbsp;～&nbsp;
        <select onMouseDown={handleMouseDown} {...register('cap_rate_upper', { onChange: handleChange })}>
          <option value="0" selected disabled></option>
          <option value="0">{t('上限なし')}</option>
          {render_cap_rate()}
        </select>
      </div>
    </div>
  );
}

export default CapRate;
