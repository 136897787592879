import './InfoMenu.css';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const InfoMenu: React.FC<{
  isLandPricesActive: boolean, 
  isZoningActive: boolean, 
  isHazardMapActive: boolean,
  isFloodActive: boolean,
  isSedimentActive: boolean,
  isTsunamiActive: boolean,
  isStormSurgeActive: boolean,
  setLandPricesActive: React.Dispatch<React.SetStateAction<boolean>>,
  setZoningActive: React.Dispatch<React.SetStateAction<boolean>>,
  setHazardMapActive: React.Dispatch<React.SetStateAction<boolean>>
  setFloodActive: React.Dispatch<React.SetStateAction<boolean>>,
  setSedimentActive: React.Dispatch<React.SetStateAction<boolean>>,
  setTsunamiActive: React.Dispatch<React.SetStateAction<boolean>>,
  setStormSurgeActive: React.Dispatch<React.SetStateAction<boolean>>
}> = ({
  isLandPricesActive, 
  isZoningActive, 
  isHazardMapActive, 
  isFloodActive,
  isSedimentActive,
  isTsunamiActive,
  isStormSurgeActive,
  setLandPricesActive, 
  setZoningActive, 
  setHazardMapActive,
  setFloodActive,
  setSedimentActive,
  setTsunamiActive,
  setStormSurgeActive,
}) => {

  const [ t ] = useTranslation();

  const toggleHazardMap = () => {
    setHazardMapActive(!isHazardMapActive);
    setFloodActive(!isHazardMapActive);
    setSedimentActive(!isHazardMapActive);
    setTsunamiActive(!isHazardMapActive);
    setStormSurgeActive(!isHazardMapActive);
  };

  return (
    <div id="InfoMenu">
      <div className="menu-container">
        <button
          className={`menu-button ${isLandPricesActive ? 'active' : ''}`}
          onClick={() => setLandPricesActive(!isLandPricesActive)}
        >
          {t('map.地価公示・調査')}
        </button>
        <button
          className={`menu-button ${isZoningActive ? 'active' : ''}`}
          onClick={() => setZoningActive(!isZoningActive)}
        >
          {t('map.用途地域')}
        </button>
        <div className="dropdown">
          <button
            className={`menu-button ${isHazardMapActive ? 'active' : ''}`}
            onClick={toggleHazardMap}
          >
            {t('map.ハザードマップ')}
          </button>
          {isHazardMapActive && (
            <div className="dropdown-content">
              <button
                className={`submenu-button ${isFloodActive ? 'active' : ''}`}
                onClick={() => setFloodActive(!isFloodActive)}
              >
                {t('map.洪水浸水想定地域')}
              </button>
              <button
                className={`submenu-button ${isSedimentActive ? 'active' : ''}`}
                onClick={() => setSedimentActive(!isSedimentActive)}
              >
                {t('map.土砂災害警戒区域')}
              </button>
              <button
                className={`submenu-button ${isTsunamiActive ? 'active' : ''}`}
                onClick={() => setTsunamiActive(!isTsunamiActive)}
              >
                {t('map.津波浸水想定区域')}
              </button>
              <button
                className={`submenu-button ${isStormSurgeActive ? 'active' : ''}`}
                onClick={() => setStormSurgeActive(!isStormSurgeActive)}
              >
                {t('map.高潮浸水想定区域')}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoMenu;
