import './HamburgerMenu.css';
import { FC, useContext, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { AppContext, LoginState, DisplayMode, MyLanguage } from '../context/AppContext';
import { useAuthContext } from '../context/AuthContext';
import { signOut, getAuth } from "firebase/auth";

import CommonUtil from '../utils/CommonUtil';
import classNames from 'classnames';

const HamburgerMenu: FC = () => {
  const ContextRoot = CommonUtil.get_context_root();

  const [ t ] = useTranslation(); 
  const { myLang, setMyLang } = useContext(AppContext);
  const { setDisplayMode } = useContext(AppContext);
  const { currentUser } = useAuthContext();
  const { setIsOpenLoginDialog } = useContext(AppContext);
  const { setLoginState } = useContext(AppContext);
  
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  
  const render_auth_button = () => {
    if (currentUser === undefined) return null;

    if (currentUser) {
      return <a className="" onClick={handleClickLogout}>{t('LOG OUT')}</a>
    } else {
      return <a className="" onClick={handleClickLogin}>{t('LOG IN')}</a>
    }
  }

  const handleClickLogin = () => {
    setIsOpenLoginDialog(true);
  }
  const handleClickLogout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
      setLoginState(LoginState.LoggedIn_Without_Auth);
      navigate(`${ContextRoot}`);
    }).catch((error) => {
      // An error happened.
    });
  }

  const handleClose = () => setIsOpen(false);

  const handleChangeLanguage = () => {
    switch (myLang) {
      case MyLanguage.JA:
        setMyLang(MyLanguage.EN);
        break;
      case MyLanguage.EN:
        setMyLang(MyLanguage.JA);
        break;
    }
  }

  const is_mobile = CommonUtil.is_mobile();

  const nav_className = classNames('nav-menu', isOpen ? 'active' : '');
  return (
    <div id="HamburgerMenu">
      <div className="menu-toggle" onClick={() => setIsOpen(!isOpen)}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      <nav className={nav_className}>
        <button className={classNames("close-button", CommonUtil.is_mobile() ? "mobile" : "")} onClick={handleClose}>&times;</button>
        <ul>
          <li><img src={`${ContextRoot}/images/icon/login.png`}></img>{render_auth_button()}</li>
          <li><img src={`${ContextRoot}/images/icon/us_reit.png`}></img><Link to={`${ContextRoot}/us-reit`}>US-REIT</Link></li>
          <li><img src={`${ContextRoot}/images/icon/guide.png`}></img><Link to={`${ContextRoot}/guide`}>{t('GUIDE')}</Link></li>
          {
            is_mobile &&
            <li>
              <img className={classNames("value-icon")} src={`${ContextRoot}/images/icon/value.png`}></img>
              <div className={classNames("dropdown", "dropdown-value")}>
                <span className="dropdown-button">{t('バリュー')}</span>
                <div className="dropdown-content">
                  <Link to={`${ContextRoot}/value.map/MAP`} onClick={() => { setDisplayMode(DisplayMode.ValueMap) }}>MAP</Link>
                  <Link to={`${ContextRoot}/value.map/LIST`} onClick={() => { setDisplayMode(DisplayMode.ValueList) }}>LIST</Link>
                </div>
              </div>
            </li>
          }
          { is_mobile && <li><div></div></li> }
          { is_mobile &&
            <li>
              <Link className="contact" to={`${ContextRoot}/contact`} onClick={() => { setDisplayMode(DisplayMode.Contact) }}><img className={classNames("mail-icon")} src={`${ContextRoot}/images/icon/mail.png`} ></img></Link>
            </li>
          }
          { is_mobile && <li><span onClick={handleChangeLanguage}>{CommonUtil.get_language_value(myLang)}</span></li> }
          { is_mobile && <li><span onClick={() => CommonUtil.open_TermOfUse(myLang)}>{t('利用規約')}</span></li> }
          { is_mobile && <li><span onClick={() => CommonUtil.open_PrivacyPolicy(myLang)}>{t('プライバシーポリシー')}</span></li> }
          { is_mobile && <li><span onClick={() => CommonUtil.open_Company(myLang)}>{t('運営会社')}</span></li> }
        </ul>
      </nav>
    </div>
  );
}

export default HamburgerMenu;
