import React, { createContext } from 'react';
import { EstateDataType } from '../utils/EstateDataType';
import { InvestmentDataType } from '../utils/InvestmentDataType';
import { HistInvestmentDataType } from '../utils/HistInvestmentDataType';
import { InvestmentCalendarType } from '../utils/InvestmentCalendarType';
import { ValueMapDataType } from '../utils/ValueMapDataType';

export const MyLanguage = {
  JA: 'ja',
  EN: 'en'
}

export const LoginState = {
  Not_LoggedIn          : 'Not_LoggedIn',           // 未ログイン
  LoggedIn_With_Auth    : 'LoggedIn_With_Auth',     // 認証なしでログイン
  LoggedIn_Without_Auth : 'LoggedIn_Without_Auth',  // 認証ありでログイン
}

export const DisplayMode = {
  Stock      : 'Stock',
  StockData  : 'StockData',
  StockChart : 'StockChart',
  Map        : 'Map',
  List       : 'List',
  Chart      : 'Chart',
  Data       : 'Data',
  ValueMap   : 'ValueMap',
  ValueList  : 'ValueList',
  ValueData  : 'ValueData',
  UsReit     : 'UsReit',
  Guide      : 'Guide',
  Contact    : 'Contact',
  OfficialLandPricesDetail: 'OfficialLandPricesDetail',

}

export const SearchType = {
  Keyword: 'Keyword',  // キーワード検索
  Panel  : 'Panel',    // 検索パネル(キャップレートなど)
  Id     : 'Id',       // ID指定
}

export const InquirySubject_Id = {
  FreeUserRegistration : 'FreeUserRegistration',
  PaidUserRegistration : 'PaidUserRegistration',
  AppraisalRequest : 'appraisal_request',
  Registration     : 'registration',
  NewsSubscription : 'news_subscription',
  Others           : 'others',
};


export const MaxLabel = 5;
export const MaxStockChecked = 5;

export const AppContext = createContext({} as {
  myLang: string,
  setMyLang: React.Dispatch<React.SetStateAction<string>>

  loginState: string,
  setLoginState: React.Dispatch<React.SetStateAction<string>>

  dialogMsg: string,
  setDialogMsg: React.Dispatch<React.SetStateAction<string>>

  isOpenLoginDialog: boolean,
  setIsOpenLoginDialog: React.Dispatch<React.SetStateAction<boolean>>

  isOpenUserRegistrationDialog: boolean,
  setIsOpenUserRegistrationDialog: React.Dispatch<React.SetStateAction<boolean>>,

  memberType: number,
  setMemberType: React.Dispatch<React.SetStateAction<number>>,

  displayMode: string,
  setDisplayMode: React.Dispatch<React.SetStateAction<string>>

  searchType: string,
  setSearchType: React.Dispatch<React.SetStateAction<string>>

  searchCond: any,
  setSearchCond: React.Dispatch<React.SetStateAction<any>>

  estateData: EstateDataType[],
  setEstateData: React.Dispatch<React.SetStateAction<EstateDataType[]>>,

  labeledEstateData: EstateDataType[],
  setLabeledEstateData: React.Dispatch<React.SetStateAction<EstateDataType[]>>,

  appData: any,
  setAppData: React.Dispatch<React.SetStateAction<any>>,

  investmentData: InvestmentDataType[],
  setInvestmentData: React.Dispatch<React.SetStateAction<InvestmentDataType[]>>,

  checkedInvestmentData: InvestmentDataType[],
  setCheckedInvestmentData: React.Dispatch<React.SetStateAction<InvestmentDataType[]>>,

  histInvestmentData: HistInvestmentDataType[],
  setHistInvestmentData: React.Dispatch<React.SetStateAction<HistInvestmentDataType[]>>,

  histInvestmentDataOnlyLatest: HistInvestmentDataType[],
  setHistInvestmentDataOnlyLatest: React.Dispatch<React.SetStateAction<HistInvestmentDataType[]>>,

  investmentCalendar: InvestmentCalendarType[],
  setInvestmentCalendar: React.Dispatch<React.SetStateAction<InvestmentCalendarType[]>>,

  selectedStockCode: number | null,
  setSelectedStockCode: React.Dispatch<React.SetStateAction<number | null>>,

  selectedId: number | null,
  setSelectedId: React.Dispatch<React.SetStateAction<number | null>>,

  selectedPropertyNo: number | null,
  setSelectedPropertyNo: React.Dispatch<React.SetStateAction<number | null>>,

  isFirstFocusLabeled: boolean,
  setIsFirstFocusLabeled: React.Dispatch<React.SetStateAction<boolean>>,

  valueMapData: ValueMapDataType[],
  setValueMapData: React.Dispatch<React.SetStateAction<ValueMapDataType[]>>,

  labeledValueMapData: ValueMapDataType[],
  setLabeledValueMapData: React.Dispatch<React.SetStateAction<ValueMapDataType[]>>,

  searchTypeForValue: string,
  setSearchTypeForValue: React.Dispatch<React.SetStateAction<string>>,

  searchCondForValue: any,
  setSearchCondForValue: React.Dispatch<React.SetStateAction<any>>,

  confirmedValueMap: boolean,
  setConfirmedValueMap: React.Dispatch<React.SetStateAction<any>>,
});
