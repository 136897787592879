import './Map.css';
import { t } from "i18next";
import classNames from "classnames";
import { FC, useCallback, useEffect, useState, useRef, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GoogleMap } from "@react-google-maps/api";
import MapContent from './map/MapContent';
import { AppContext, SearchType, MyLanguage, MaxLabel } from '../context/AppContext';
import { useAuthContext } from '../context/AuthContext';
import { EstateDataType } from '../utils/EstateDataType';
import EstateDataUtil from '../utils/EstateDataUtil';
import CommonUtil from '../utils/CommonUtil';
import AuthUtil from '../utils/AuthUtil';
import { MemberType } from '../utils/MemberType';
import HistDataUtil from '../utils/HistDataUtil';
import InfoMenu from "./map/InfoMenu";

// [重要] centerの定義をMapの外側にしないと、タイプアイコンクリック時に地図の再読み込みが起きてしまう。
//const center = { lat: 38.3705524, lng: 137.5310131 };
const center = { lat: 35.6840000, lng: 139.7530000 };

const Map: FC = () => { 
  const ContextRoot = CommonUtil.get_context_root();

  const zoomControlOptions = {
    position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
  }
  // https://snazzymaps.com/style/27/shift-worker
  const map_styles = [{
    "stylers": [
        { "saturation": -100 },
        { "gamma": 1 }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        { "visibility": "off" }
      ]
    },
    {
      "featureType": "poi.business",
      "elementType": "labels.text",
      "stylers": [
        { "visibility": "off" }
      ]
    },
    {
      "featureType": "poi.business",
      "elementType": "labels.icon",
      "stylers": [
        { "visibility": "off" }
      ]
    },
    {
      "featureType": "poi.place_of_worship",
      "elementType": "labels.text",
      "stylers": [
        { "visibility": "off" }
      ]
    },
    {
      "featureType": "poi.place_of_worship",
      "elementType": "labels.icon",
      "stylers": [
        { "visibility": "off" }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        { "visibility": "simplified" }
      ]
    },
    {
      "featureType": "water",
      "stylers": [
        { "visibility": "on" },
        { "saturation": 50 },
        { "gamma": 0 },
        { "hue": "#50a5d1" }
      ]
    },
    {
      "featureType": "administrative.neighborhood",
      "elementType": "labels.text.fill",
      "stylers": [
        { "color": "#333333" }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text",
      "stylers": [
        { "weight": 0.5 },
        { "color": "#333333" }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "labels.icon",
      "stylers": [
        { "gamma": 1 },
        { "saturation": 50 }
      ]
    }
  ];

  const mapOptions = {
    minZoom: 4,
    // maxZoom: 17,
    zoomControlOptions: zoomControlOptions,
    controlSize: 32,
    styles: map_styles,
    // 「地図」「航空写真」ボタン非表示。
    mapTypeControl: false,
    // ストリートビュー非表示。
    streetViewControl: false,
    // フルスクリーンボタン非表示
    fullscreenControl: false,
    // GoogleMapが提供する飲食店などのアイコンをクリック不可にする。
    // 不動産マーカークリック時、1つだけ吹き出しを表示している状態で飲食店などのアイコンをクリックすると、吹き出しが消えてしまうため。
    // (2つ以上吹き出しを表示している状態だと再現しない)
    clickableIcons: false,
  };

  const navigate = useNavigate();
  const { appData, memberType, setIsOpenUserRegistrationDialog } = useContext(AppContext);
  const { myLang } = useContext(AppContext);
  const [ mapWidth, setMapWidth] = useState<number>(0);
  const [ mapHeight, setMapHeight] = useState<number>(0);
  const { estateData } = useContext(AppContext);
  const { searchType } = useContext(AppContext);
  // List／Map画面でチェックを入れた不動産情報(複数)
  const { labeledEstateData, setLabeledEstateData } = useContext(AppContext);
  // チェックを入れた不動産だけを表示するボタンのON/OFF
  const [ showOnlyLabeled, setShowOnlyLabeled ] = useState<boolean>(false);
  // 不動産情報取得の一回目か否か
  const [ isFirstFetchEstateData, setIsFirstFetchEstateData ] = useState(true);
  // チェックを入れた不動産にフォーカスするのが一回目か否か
  const { isFirstFocusLabeled, setIsFirstFocusLabeled } = useContext(AppContext);
  // 地図がロードされたか。
  const [ isLoadedMap, setIsLoadedMap ] = useState(false);
  // 地図表示時にチェックを入れた不動産情報にフォーカスするか。
  const [ focusLabeled, setFocusLabeled ] = useState(false);
  
  // 地図の操作を許可するかどうか
  const [ isMapInteractive, setIsMapInteractive ] = useState(true);

  // 吹き出し表示する不動産
  const [ popupEstates, setPopupEstates ] = useState<EstateDataType[]>([]);

  // --------------------------------------------------
  // 情報追加用のメニュー
  // --------------------------------------------------
  // 地価公示・調査
  const [ isLandPricesActive, setLandPricesActive ] = useState(false);
  // 用途地域
  const [ isZoningActive, setZoningActive ] = useState(false);
  // ハザードマップ
  const [ isHazardMapActive, setHazardMapActive ] = useState(false);
  // 洪水浸水想定地域
  const [ isFloodActive, setFloodActive ] = useState(false);
  // 土砂災害警戒区域
  const [ isSedimentActive, setSedimentActive ] = useState(false);
  // 津波浸水想定区域
  const [ isTsunamiActive, setTsunamiActive ] = useState(false);
  // 高潮浸水想定区域
  const [ isStormSurgeActive, setStormSurgeActive ] = useState(false);


  const [searchParams] = useSearchParams();

  // API読み込み後に再レンダーを引き起こさないため、useStateを使わず、useRefとuseCallbackを使っている。
  const mapRef = useRef<any>();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    setIsLoadedMap(true);
  }, []);

  useEffect(() => {
    if (mapWidth === 0) {
      const width = document.documentElement.clientWidth;
      setMapWidth(width);
    }
    if (mapHeight === 0) {
      // ヘッダーとフッターの高さ(px)
      let headerHeight = 40;
      let footerHeight = 40;
      if (window.devicePixelRatio >= 1.5) {
        headerHeight = 32;
        footerHeight = 26;
      }

      // 地図領域の高さ = 画面サイズ - ヘッダーの高さ - フッターの高さ
      let height = document.documentElement.clientHeight - headerHeight - footerHeight;
      setMapHeight(height);
    }
  }, []);

  useEffect(() => {
    if (searchType === SearchType.Keyword) {
      // キーワード検索した場合は、当該物件にフォーカスする。
      fitBounds(estateData);
    }
  }, [estateData]);

  useEffect(() => {
    if (showOnlyLabeled) {
      // チェックした物件にフォーカスする。
      fitBounds(labeledEstateData);
    }
  }, [showOnlyLabeled]);

  useEffect(() => {
    if (estateData.length === 0) return;
    if (!isFirstFetchEstateData) return;

    const ids = searchParams.getAll('id');
    if (ids.length === 0) return;

    // URLパラメータで指定されたidをチェック状態にする。
    let newLabeledEstateData = [];
    for (let id of ids) {
      for (let d of estateData) {
        if (Number(id) === d.id) {
          newLabeledEstateData.push(d);
          break;
        }
      }
      if (newLabeledEstateData.length >= MaxLabel) {
        // ラベルの上限に達していたら以降は無視する。
        break;
      }
    }

    // データが揃っていない
    if (newLabeledEstateData.length < MaxLabel && newLabeledEstateData.length < ids.length) return;

    setLabeledEstateData(newLabeledEstateData);
    setFocusLabeled(true);
    setIsFirstFetchEstateData(false);    
  }, [estateData]);

  useEffect(() => {
    if (!isFirstFocusLabeled) return;
    // 地図がロードされる前にフォーカス処理をすると空振りするため、ロードされたかを監視する。
    if (!isLoadedMap) return;
    if (labeledEstateData.length === 0) return;

    // URLパラメータによってチェック状態を再現した場合は、初期状態はチェックした物件だけを表示する。
    if (focusLabeled) {
      setShowOnlyLabeled(true);
      setIsFirstFocusLabeled(false);
    }
  }, [isLoadedMap, labeledEstateData, focusLabeled]);


  /**
   * すべてのマーカーが地図画面に収まるように調整する。
   */
  const fitBounds = (fitEstateData: EstateDataType[]) => {
    if (fitEstateData.length === 0) return;

    if (window.google && mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();
      for (let d of fitEstateData) {
        bounds.extend(new window.google.maps.LatLng(d.lat, d.lng));
      }
      mapRef.current.fitBounds(bounds);  
    }
  }

  const render_button_area = () => {
    const handleClickExcelOutput = async () => {
      if (AuthUtil.restricted(memberType, MemberType.C)) {
        setIsOpenUserRegistrationDialog(true);
        return;
      }  
      HistDataUtil.to_excel(t, myLang, appData, labeledEstateData);
    }

    const render_excel_button = () => {
      if (labeledEstateData.length === 0) return null;
      if (CommonUtil.is_mobile()) return null;
      return <button onClick={handleClickExcelOutput}>{t('Excel出力')}</button>;
    }

    return (
      <div className="top_button_area">
        <div className="row">
          <InfoMenu
            isLandPricesActive={isLandPricesActive}
            setLandPricesActive={setLandPricesActive}
            isZoningActive={isZoningActive}
            setZoningActive={setZoningActive}
            isHazardMapActive={isHazardMapActive}
            setHazardMapActive={setHazardMapActive}
            isFloodActive={isFloodActive}
            setFloodActive={setFloodActive}
            isSedimentActive={isSedimentActive}
            setSedimentActive={setSedimentActive}
            isTsunamiActive={isTsunamiActive}
            setTsunamiActive={setTsunamiActive}
            isStormSurgeActive={isStormSurgeActive}
            setStormSurgeActive={setStormSurgeActive}
          />
        </div>
        <div className={classNames("row", "estate")}>
          {render_excel_button()}
          {render_onlyLabeledButton()}
          {render_chartButton()}
        </div>
      </div>
    )
  }

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>, d: EstateDataType) => {
    if (e.target.checked) {
      // チェックを入れた場合(チェックをはずすとラベルが削除されるので、ここは動作しない)
    }
    else {
      // チェックをはずした場合
      let newLabeledEstateData = labeledEstateData.filter(labeled_d => {
        // チェックをはずした物件だけ除外する。
        return labeled_d.id !== d.id;
      });
      if (newLabeledEstateData.length === 0) {
        // すべてチェックをはずした場合、チェックを入れた不動産だけを表示する機能をOFFにする。
        setShowOnlyLabeled(false);
      }
      setLabeledEstateData(newLabeledEstateData);

      // 吹き出しを閉じる。
      const newPopupEstate = popupEstates.filter(item => {
        return item.id !== d.id
      });
      setPopupEstates(newPopupEstate);
    }
  }
  
  const render_estateLabels = () => {
    let rendering = [];
    for (let d of labeledEstateData) {
      let property_name = '';
      switch (myLang) {
        case MyLanguage.JA: property_name = d.property_name; break;
        case MyLanguage.EN: property_name = d.property_name_en; break;
      }

      let cap_rate = '';
      if (!EstateDataUtil.convert_disclose_cap_rate(d.disclose_cap_rate)) {
        cap_rate = t('非開示');
      } else {
        cap_rate = EstateDataUtil.convert_capRate_percentage(d.cap_rate) + '%';
      }

      rendering.push(
        <li>
          <div className="checkbox"><input type="checkbox" onChange={(e) => handleCheckbox(e, d)} checked={true} /></div>
          {property_name}：{cap_rate}
        </li>
      );
    }
    return (
      <div className="labeledEstateData">
        <ul>{rendering}</ul>
      </div>
    );
  }

  /**
   * チェックを入れた不動産情報のみを表示するボタン
   */
  const render_onlyLabeledButton = () => {
    const handleClick = () => {
      if (!showOnlyLabeled) {
        // 「PIN」をONにする場合、吹き出しを消す。
        setPopupEstates([]);
      }
      setShowOnlyLabeled(!showOnlyLabeled);
    }

    if (labeledEstateData.length === 0) return null;
    return <button onClick={handleClick}>{t('PIN')}</button>;
  }

  /**
   * CHARTボタン
   * @returns CHARTボタン
   */
  const render_chartButton = () => {
    const handleOnClick = async () => {
      if (AuthUtil.restricted(memberType, MemberType.C)) {
        setIsOpenUserRegistrationDialog(true);
        return;
      }
      navigate(`${ContextRoot}/chart`);  
    }

    if (CommonUtil.is_mobile()) return null;
    if (labeledEstateData.length === 0) return null;

    // ラベル化した取引に1件もヒストリカルデータがない場合はボタンを表示しない。
    let has_data = false;
    for (let d of labeledEstateData) {
      if (EstateDataUtil.has_hist_data(d)) {
        has_data = true;
        break;
      }
    }
    if (!has_data) return null;

    return <button onClick={() => handleOnClick()}>{t('CHART')}</button>;
  }

  
  if (mapWidth === 0 || mapHeight === 0) return null;
  let containerStyle = {
    height: `${mapHeight}px`,
    width: `${mapWidth}px`,
  };

  return (
    <div className="map-container">
{/*      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={6} options={mapOptions} onLoad={onMapLoad}>  */}
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15} options={mapOptions} onLoad={onMapLoad}>
        <MapContent
          estateData={ showOnlyLabeled ?  labeledEstateData : estateData }
          mapRef={mapRef}
          showOnlyLabeled={showOnlyLabeled}
          setShowOnlyLabeled={setShowOnlyLabeled}
          popupEstates={popupEstates}
          setPopupEstates={setPopupEstates}
          setIsMapInteractive={setIsMapInteractive}
          isLandPricesActive={isLandPricesActive}
          isZoningActive={isZoningActive}
          isFloodActive={isFloodActive}
          isSedimentActive={isSedimentActive}
          isTsunamiActive={isTsunamiActive}
          isStormSurgeActive={isStormSurgeActive}
        />
      </GoogleMap>
      {render_button_area()}
      {render_estateLabels()}
      {!isMapInteractive && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
}

export default Map;
