import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import './BuildingTypeSelect.css';

const BuildingTypeSelect: React.VFC = () => {
  const [ t ] = useTranslation();
  const { register } = useFormContext();

  const handleChange = () => {
    // タイプ変更時に再検索させる。
    const submit_search_button = document.getElementById('submit_search_button_chart');
    if (!submit_search_button) return;
    submit_search_button.click();
  }

  return (
    <div id="BuildingTypeSelect">
      <div className="searchgroup">
        <span className="searchfieldlabel">{t('タイプ')}</span>
        <select {...register('building_type', { onChange: handleChange })}>
          <option value="0" selected disabled></option>
          <option value="0">{t('指定なし')}</option>
          <option value="1">{t('オフィス')}</option>
          <option value="2">{t('レジデンシャル')}</option>
          <option value="3">{t('リテール')}</option>
          <option value="4">{t('ロジスティクス')}</option>
          <option value="5">{t('ホテル')}</option>
          <option value="99">{t('その他')}</option>
        </select>
      </div>
    </div>
  );
}

export default BuildingTypeSelect;
