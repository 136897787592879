import { FC, useContext, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Dialog from '../components/Dialog';
import LoginDialog from '../components/LoginDialog';
import UserRegistrationDialog from '../components/UserRegistrationDialog';
import { AppContext, DisplayMode } from '../context/AppContext';
import ValueSearchPanel from '../components/ValueSearchPanel';
import ValueList from '../components/ValueList';

import './ValueListPage.css';

const ValueListPage: FC = () => {
  // ダイアログ
  const { dialogMsg, setDisplayMode } = useContext(AppContext);
  // ログインダイアログ
  const { isOpenLoginDialog } = useContext(AppContext);
  // ユーザー登録ダイアログ
  const { isOpenUserRegistrationDialog } = useContext(AppContext);

  useEffect(() => {
    setDisplayMode(DisplayMode.ValueList);
  }, []);

  return (
    <>
      <Header />
      <div id="ValueListPage">
        <ValueSearchPanel /><ValueList />
      </div>
      <Footer />
      { dialogMsg && <Dialog /> }
      { isOpenLoginDialog && <LoginDialog /> }
      { isOpenUserRegistrationDialog && <UserRegistrationDialog /> }
    </>
  );

}

export default ValueListPage