import React, { useEffect } from "react";
import { useGoogleMap } from "@react-google-maps/api";

interface Props {
  url: string;
  opacity?: number;
}

const TileLayer: React.FC<Props> = ({ url, opacity = 1.0 }) => {
  const map = useGoogleMap();

  useEffect(() => {
    if (!map) return;

    const tileLayer = new google.maps.ImageMapType({
      getTileUrl: (coord, zoom) => {
        return url
          .replace("{z}", zoom.toString())
          .replace("{x}", coord.x.toString())
          .replace("{y}", coord.y.toString());
      },
      tileSize: new google.maps.Size(256, 256),
      opacity,
    });

    map.overlayMapTypes.push(tileLayer);

    return () => {
      const overlayMapTypes = map.overlayMapTypes;
      for (let i = 0; i < overlayMapTypes.getLength(); i++) {
        if (overlayMapTypes.getAt(i) === tileLayer) {
          overlayMapTypes.removeAt(i);
          break;
        }
      }
    };
  }, [map, url, opacity]);

  return null;
};

export default TileLayer;
