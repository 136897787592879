import Chart from 'react-apexcharts';
import { FC, useContext, useState, useEffect, useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import classNames from 'classnames';
import { AppContext, MyLanguage } from "../context/AppContext";
import { useTranslation } from 'react-i18next';
import { EstateDataType } from '../utils/EstateDataType';
import { HistDataType } from '../utils/HistDataType';
import EstateDataUtil from '../utils/EstateDataUtil';
import ChartUtil from '../utils/ChartUtil';
import BuildingTypeSelect from './search/BuildingTypeSelect';
import Addr from './search/Addr';
import HistDataUtil from '../utils/HistDataUtil';
import { RangeSlider } from 'rsuite';

import './HistChart.css';

interface ChartColor {
  id: number,
  color: string,
};

const HistChart: FC = () => {
  const ChartId = {
    NOI_Y : 'noi_y',
    OCC   : 'occ',
    RENT  : 'rent',
    CR    : 'cr'
  };

  const ScatterX = {
    AV  : 'av',
    Age : 'age',
    LA  : 'la',    
  }

  // チャートの縦サイズ
  const ChartHeight = 350;

  const GridInterval = 5;

  // チャートのタイトルのフォントサイズ
  const ChartTitleFontSize = '16px';
  // チャートのサブタイトルのフォントサイズ
  const ChartSubTitleFontSize = '11px';

  const { appData } = useContext(AppContext);
  const { labeledEstateData } = useContext(AppContext);
  const { setDialogMsg } = useContext(AppContext);
  const [ histData, setHistData ] = useState<HistDataType[]>([]);

  const [ t ] = useTranslation();
  const { myLang } = useContext(AppContext);
  const [ chartId, setChartId ] = useState<string>(ChartId.NOI_Y);

  const [ scatterX, setScatterX ] = useState<string>(ScatterX.AV);
  const now = new Date();

  const [ estateDataForScatter, setEstateDataForScatter ] = useState<EstateDataType[]>([]);
  const methods2 = useForm();

  // X軸とY軸の最小値／最大値の範囲設定(0%～100%)
  const [ xMinPct, setXMinPct ] = useState<number>(0);
  const [ xMaxPct, setXMaxPct ] = useState<number>(100);
  const [ yMinPct, setYMinPct ] = useState<number>(0);
  const [ yMaxPct, setYMaxPct ] = useState<number>(100);

  const [ chartColors, setChartColors] = useState<ChartColor[]>([]);

  const xSliderRef = useRef<HTMLDivElement>(null);
  const ySliderRef = useRef<HTMLDivElement>(null);
  const scatterRef = useRef<HTMLDivElement>(null);
  const [ scatterChartWidth, setScatterChartWidth ] = useState<number>(0);

  useEffect(() => {
    if (labeledEstateData.length === 0) {
      setDialogMsg(t('物件が選択されていません。'));
      return
    };
    let property_no_arr = '';
    for (let d of labeledEstateData) {
      if (property_no_arr === '') {
        property_no_arr = String(d.property_no);
      } else {
        property_no_arr = property_no_arr + ',' + String(d.property_no);
      }
    }

    const resultZeroHist = () => {
      setDialogMsg(t('ヒストリカルデータがありません。'));
    }
    
    HistDataUtil.fetch_hist_data({ property_no: property_no_arr }, setHistData, resultZeroHist);
  }, []);

  useEffect(() => {

    // 物件idとチャートカラーを紐づける。
    const colors = [ '#4472C4', '#ED7D31', '#A5A5A5', '#FFC000', '#16a632' ];
    let colorArr: ChartColor[] = [];
    for (let d of labeledEstateData) {
      if (colorArr.length >= colors.length) break;

      colorArr.push({
        id: d.id,
        color: colors[colorArr.length]
      });
    }
    setChartColors(colorArr);

    EstateDataUtil.fetch_estate_data({id : get_ids()}, setEstateDataForScatter, () => {});
  }, []);  // []を指定することで、DOMマウント時に1回だけ実行される。


  useEffect(() => {
    calc_ScatterChartWidth();
  }, [ySliderRef.current]);

  useEffect(() => {
    const onResize = () => {
      calc_ScatterChartWidth();
    }
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  useEffect(() => {
    reset_padding_scatter();
  }, [ySliderRef.current, scatterRef.current]);

  useEffect(() => {
    reset_padding_xSlider();
  }, [ySliderRef.current, xSliderRef.current]);

  const reset_padding_scatter = () => {
    if (ySliderRef.current === null) return;
    if (scatterRef.current === null) return;
    // プロットのpaddingをY軸目盛の横幅で決定する。
    scatterRef.current.style.paddingLeft = `${ySliderRef.current.clientWidth + 10}px`;
  }

  const reset_padding_xSlider = () => {
    if (ySliderRef.current === null) return;
    if (xSliderRef.current === null) return;
    // X軸目盛のpaddingをY軸目盛の横幅で決定する。
    xSliderRef.current.style.paddingLeft = `${ySliderRef.current.clientWidth + 20}px`;
  }

  const calc_ScatterChartWidth = () => {
    // #HistChartに設定した左右のpadding
    let padding = 120;
    if (window.innerWidth < 1080) {
      padding = 10;
    }

    let chartWidth = window.innerWidth - padding * 2 - 20 - 60;  // margin 10px x 2、60はX軸の目盛りが入るように余裕を設ける。
    if (ySliderRef.current !== null) {
      // Y軸のスレイダーの幅を差し引く。
      chartWidth -= ySliderRef.current.clientWidth;
    }

    setScatterChartWidth(chartWidth);
  }

  const get_ids = () => {
    let ids = '';
    for (let d of labeledEstateData) {
      if (ids === '') {
        ids = String(d.id);
      } else {
        ids = ids + ',' + String(d.id);
      }
    }
    return ids;
  }

  const get_color = (id: number) => {
    for (let c of chartColors) {
      if (c.id === id) return c.color;
    }
    return '#cccccc';
  }

  const handleChangeChart = (e: any) => {
    setChartId(e.target.value);
  }

  const getClassName = (chart_id: string) => {
    if (chart_id === chartId) return 'checked';
    return ''
  }

  const render_LineChart = () => {
    let date_list = create_date_list();

    let histData_byId: any = {}
    let all_histData: number[] = [];
    
    for (let d of labeledEstateData) {
      histData_byId[d.id] = [];
      for (let date of date_list) {
        const chart_data = get_chart_data(d.property_no, date);

        histData_byId[d.id].push(chart_data);

        if (chart_data !== null) {
          all_histData.push(chart_data);
        }
      }
    }

    const series = [];
    for (let d of labeledEstateData) {
      // nullデータのみの場合はチャート表示しない。
      if (is_only_null_data(histData_byId[d.id])) continue;

      let property_name = '';
      switch (myLang) {
        case MyLanguage.JA: property_name = d.property_name; break;
        case MyLanguage.EN: property_name = d.property_name_en; break;
      }  

      series.push({
        name : property_name,
        data: histData_byId[d.id]
      });
    }

    const grid_min_max = ChartUtil.get_grid_min_max(Math.min(...all_histData), Math.max(...all_histData), GridInterval);

    const options = create_chart_opts(date_list, grid_min_max.grid_min, grid_min_max.grid_max, GridInterval);

    return (<div className="chart"><Chart type='line' options={options} series={series} height={ChartHeight} /></div>);
  }

  /**
   * 分散チャートをレンダリングする。
   * @returns レンダリング内容
   */
  const render_scatterChart = () => {
    interface TmpSeries {
      id: number,
      name: string,
      data: number[][]
    }
    let series: any[] = [];
    let tmp_series: TmpSeries[] = [];
    let colors: string[] = [];

    let all_xData = [];
    let all_yData = [];

    for (let d of estateDataForScatter) {
      let xData = null;

      switch (scatterX) {
        case ScatterX.AV: xData = d.av; break;
        case ScatterX.Age: xData = get_age(d.construction_yyyymmdd, now); break;
        case ScatterX.LA: xData = d.la; break;
      }
      if (xData === null || d.cr === null) continue;

      all_xData.push(xData);

      const cr = 100 * d.cr;  // %表記にする。
      all_yData.push(cr);

      let property_name = '';
      switch (myLang) {
        case MyLanguage.JA: property_name = d.property_name; break;
        case MyLanguage.EN: property_name = d.property_name_en; break;
      }
      tmp_series.push({
        id: d.id,
        name: property_name,
        data: [[xData, cr]],
      });
    }

    const grid_x_interval = 10;
    const grid_x_min_max = ChartUtil.get_grid_min_max(Math.min(...all_xData), Math.max(...all_xData), grid_x_interval);
    const grid_y_min_max = ChartUtil.get_grid_min_max(Math.min(...all_yData), Math.max(...all_yData), GridInterval);

    const grid_x_step = (grid_x_min_max.grid_max - grid_x_min_max.grid_min) / 100;
    const grid_y_step = (grid_y_min_max.grid_max - grid_y_min_max.grid_min) / 100;
    const grid_x_min = grid_x_min_max.grid_min + (xMinPct * grid_x_step);
    const grid_x_max = grid_x_min_max.grid_max - ((100 - xMaxPct) * grid_x_step);
    const grid_y_min = grid_y_min_max.grid_min + (yMinPct * grid_y_step);
    const grid_y_max = grid_y_min_max.grid_max - ((100 - yMaxPct) * grid_y_step);

    for (let s of tmp_series) {
      // 最小値／最大値の範囲外のデータははじく。
      if (s.data[0][0] < grid_x_min) continue;
      if (s.data[0][0] > grid_x_max) continue;
      if (s.data[0][1] < grid_y_min) continue;
      if (s.data[0][1] > grid_y_max) continue;

      series.push({
        name: s.name,
        data: s.data,
      });

      colors.push(get_color(s.id));
    }

    const options: any = {
      chart: {
        height: ChartHeight,
        type: 'scatter',
        zoom: {
          enabled: false,
          type: 'xy'
        },
        animations: {
          enabled: false
        },
        toolbar: { show: false },
      },
      tooltip: {
        x: { show: false },
      },
      fill: {
        // colors: ['#056BF6', '#D2376A'],
        opacity: 0.7,
      },
      colors: colors,
      stroke: { width: 2, curve: 'straight' },  // パフォーマンス向上のため。https://github.com/apexcharts/apexcharts.js/issues/214
      xaxis: {
        tickAmount: grid_x_interval,
        min: grid_x_min,
        max: grid_x_max,
        labels: {
          formatter: function(val: number) {
            if (val === null || val === undefined) return '';
            switch (scatterX) {
              // 「&yen;」が有効にならず、半角の￥マークもバックスラッシュで表示されてしまうので、全角で表示させる。
              case ScatterX.AV:  return '￥' + Number(val).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + 'm';
              case ScatterX.Age: return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + 'yr';
              case ScatterX.LA:  return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + '㎡';
            }
            return val;
          }
        },
      },
      yaxis: {
        tickAmount: GridInterval,
        min: grid_y_min,
        max: grid_y_max,
        labels: {
          formatter: function(val: number) {
            return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';
          }
        }
      },
      legend: { show: false },
    };

    const search_button_value = t('検索') || '';
    const submit_button_id = 'submit_search_button_chart';

    return (
      <div>
        <div className="form_wrapper">
          <FormProvider {...methods2}>
            <form name="search_form2" onSubmit={methods2.handleSubmit(onSubmit2)}>
              <div className="search_button"><input type="submit" id={submit_button_id} value={search_button_value}></input></div>
              <BuildingTypeSelect />
              <Addr use_for="chart" />
            </form>
          </FormProvider >
        </div>
        <div className="chart">
          <div className="title">{t('プロット')}</div>
          <div className='y_slider_and_scatter'>
            {render_y_slider()}
            <div className="scatter" ref={scatterRef}><Chart type='scatter' options={options} series={series} height={ChartHeight} width={scatterChartWidth} /></div>
          </div>
          {render_x_slider()}
          {render_select_xaxis()}
        </div>
      </div>
    );
  }

  const onSubmit2 = (cond: any) => {

    cond['building_type1']  = false;
    cond['building_type2']  = false;
    cond['building_type3']  = false;
    cond['building_type4']  = false;
    cond['building_type5']  = false;
    cond['building_type99'] = false;

    switch (cond['building_type']) {
      case '1':  cond['building_type1']  = '1'; break;
      case '2':  cond['building_type2']  = '2'; break;
      case '3':  cond['building_type3']  = '3'; break;
      case '4':  cond['building_type4']  = '4'; break;
      case '5':  cond['building_type5']  = '5'; break;
      case '99': cond['building_type99'] = '99'; break;
    }

    if (cond['building_type'] === '0' || cond['addr1'] === '0') {
      // タイプまたは都道府県の指定がない場合は、選択した物件だけに絞る。
      cond['id'] = get_ids();
    }

    // building_typeは仮カラムなので削除。
    delete cond['building_type'];

    // X軸Y軸の最小値／最大値の範囲をリセットする。
    reset_slider();

    EstateDataUtil.fetch_estate_data(cond, setEstateDataForScatter, () => {});
  }

  const is_labeled = (id: number) => {
    for (let l of labeledEstateData) {
      if (id === l.id) return true;
    }
    return false;
  }

  const render_y_slider = () => {
    return (
      <div className="y_range_slider" ref={ySliderRef}>
        <RangeSlider
          value={[yMinPct, yMaxPct]}
          tooltip={false}
          vertical={true}
          style={{height: ChartHeight - 90}}  // チャートのグリッドの高さに合わせる。
          onChange={([start, end]) => {
            setYMinPct(start);
            setYMaxPct(end);
          }}
        />
        <div className="y_title">{t('鑑定CR（NCF）')}</div>
      </div>
    );
  }

  const render_x_slider = () => {
    return (
      <div className="x_range_slider" ref={xSliderRef}>
        <RangeSlider
          value={[xMinPct, xMaxPct]}
          tooltip={false}
          onChange={([start, end]) => {
            setXMinPct(start);
            setXMaxPct(end);
          }}
        />
      </div>
    );
  }

  const reset_slider = () => {
    // X軸Y軸の最小値／最大値の範囲をリセットする。
    setXMinPct(0);
    setXMaxPct(100);
    setYMinPct(0);
    setYMaxPct(100);
  }

  const render_select_xaxis = () => {
    const handleChangeXAxis = (e: any) => {
      // X軸Y軸の最小値／最大値の範囲をリセットする。
      reset_slider();

      setScatterX(e.target.value);
    }

    return (
      <div className="xChange">
        <select onChange={((e) => handleChangeXAxis(e))}>
          <option value={ScatterX.AV}>{t('鑑定評価額')}</option>
          <option value={ScatterX.Age}>{t('築年数')}</option>
          <option value={ScatterX.LA}>{t('建物全体面積')}</option>
        </select>
      </div>
    );
  } 

  /**
   * 築年数を取得する。
   * 
   * @param construction_yyyymmdd 竣工 
   * @param now 現在時刻
   * @returns 築年数
   */
  const get_age = (construction_yyyymmdd: number, now: Date) => {
    if (construction_yyyymmdd === null) return null;

    const year = Number(String(construction_yyyymmdd).substring(0, 4));
    const month = Number(String(construction_yyyymmdd).substring(4, 6));
    const day = Number(String(construction_yyyymmdd).substring(6, 8));

    // 本年の竣工月日
    const thisYearConstructionDate = new Date(now.getFullYear(), month-1, day);

    // まずは単純に本年と竣工年の差を求める。
    let age = now.getFullYear() - year;
    if (now < thisYearConstructionDate) {
      // 本年の竣工月日に到達していなかったら、築年数-1。
      age--;
    }
    if (age < 0) {
      age = 0;
    }
    return age;
  }

  const is_only_null_data = (data: any) => {
    for (let d of data) {
      if (d !== null) return false;
    }
    return true;
  }

  const create_date_list = () => {
    if (histData.length === 0) return [];

    // 最新の日時を取得する。
    let latest_yyyymmdd: number = 0;
    for (let h of histData) {
      latest_yyyymmdd = Math.max(latest_yyyymmdd, h.as_of_yyyymmdd);
    }
    const latest_year = Number(String(latest_yyyymmdd).substring(0, 4));
    const latest_month = Number(String(latest_yyyymmdd).substring(4, 6)) - 1;

    // 半年ごとで5年分
    let date_list: number[] = [];
    for (let i=0; i<10; i++) {
      let date = new Date(latest_year, latest_month, 1);
      date.setMonth(date.getMonth() - (6 * i));

      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, '0');  // 0埋め
      let day = '01';
      date_list.push(Number(`${year}${month}${day}`));
    }

    // 日付を昇順にソートする。
    date_list.sort((a, b) => {
      if (a > b) return 1;
      else if (a < b) return -1;
      else return 0;
    })

    return date_list;
  }

  const get_chart_data = (property_no: number, date: number): number|null => {
    for (let h of histData) {
      if (property_no !== h.property_no) continue;
      if (date !== h.as_of_yyyymmdd) continue;

      switch (chartId) {
        case ChartId.NOI_Y: {
          if (h.noi_y === null || h.noi_y === undefined) return null;
          return 100 * h.noi_y;  // %値に変換する。
        }
        case ChartId.OCC: {
          if (h.occ === null || h.occ === undefined) return null;
          return 100 * h.occ;  // %値に変換する。
        } 
        case ChartId.RENT: {
          return h.rent;
        }
        case ChartId.CR: {
          if (h.cr === null || h.cr === undefined) return null;
          return 100 * h.cr;   // %値に変換する。
        }
      }
    }
    return null;
  }

  const create_chart_categories = (date_list: number[]): string[] => {
    let categories = [];
    for (let date of date_list) {
      const year = Number(String(date).substring(0, 4));
      const month = Number(String(date).substring(4, 6));
  
      if (month >= 1 && month <= 6) {
        categories.push(`${year}1H`);
      } else {
        categories.push(`${year}2H`);
      }
    }
    return categories;
  }

  const create_chart_title = () => {
    switch (chartId) {
      case ChartId.NOI_Y: return get_opt_title('NOI Yield');
      case ChartId.OCC:   return get_opt_title(t('稼働率'));
      case ChartId.RENT:  return get_opt_title(t('賃料（円/坪）'));
      case ChartId.CR:    return get_opt_title(t('鑑定CR（NCF）'));
    }
    return get_opt_title('');
  }

  const get_opt_title = (text: string) => {
    return {
      text: text,
      align: 'center',
      style: {
        fontSize: ChartTitleFontSize,
        fontWeight: 'normal'
      }
    }; 
  }

  const create_chart_subtitle = () => {
    switch (chartId) {
      case ChartId.NOI_Y: return get_opt_subtitle(t(''), -5, 5);
      case ChartId.OCC:   return get_opt_subtitle(t(''), -5, 5);
      case ChartId.RENT:  return get_opt_subtitle(t('*賃貸収入/(NLA × 稼働率)'), -5, 5);
      case ChartId.CR:    return get_opt_subtitle(t(''), -5, 5);
    }
    return get_opt_subtitle(t(''), -5, 5);
  }

  const get_opt_subtitle = (text: string, offsetX: number, offsetY: number) => {
    return {
      text: text,
      align: 'right',
      offsetX: offsetX,
      offsetY: offsetY,
      style: {
        fontSize: ChartSubTitleFontSize,
        fontWeight: 'normal'
      }
    }; 
  }

  const create_chart_opts = (date_list: number[], grid_min: number, grid_max: number, grid_interval: number) => {
    let categories = create_chart_categories(date_list);

    const line_colors = [ '#4472C4', '#ED7D31', '#A5A5A5', '#FFC000', '#16a632' ];

    switch (chartId) {
      case ChartId.OCC: {
        // 稼働率の目盛り最小最大値の調整。
        if (grid_max > 100) grid_max = 100;
        if (grid_min < 0) grid_min = 0;
        break;
      }
    }

    const options: any = {
      chart: {
        height: ChartHeight,
        type: 'line',
        toolbar: {
          show: false
        },
        animations: {
          enabled: false
        },
        zoom: {
          enabled: false
        }
      },
      colors: line_colors,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2.8,
      },
      title: create_chart_title(),
      subtitle: create_chart_subtitle(),
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: [ 'transparent' ],
          opacity: 0.5
        },
      },
      markers: {
        size: 4.8,
        colors: [ '#fff', '#fff', '#fff', '#fff', '#fff' ],
        strokeColors: line_colors,
      },
      xaxis: {
        categories: categories,
        labels: {
          formatter: function(val: string) {
            if (val === null || val === undefined) return '';
            const year = Number(val.slice(0, 4));
            const semester = val.slice(4);
            if (semester === '2H') return '';
            return year;
          },
        }  
      },
      yaxis: {
        tickAmount: grid_interval,
        min: grid_min,
        max: grid_max,
        labels: {
          formatter: function(val: number, index: any) {
            if (val === null || val === undefined) return '';
            switch (chartId) {
              case ChartId.NOI_Y: return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';  // カンマ区切り＆小数点以下1桁
              case ChartId.OCC:   return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + '%';  // カンマ区切り＆小数点以下0桁
              case ChartId.RENT:  return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});  // カンマ区切り＆小数点以下0桁
              case ChartId.CR:    return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';  // カンマ区切り＆小数点以下1桁
            }
            return val;
          }
        }
      },
      tooltip: {
        x: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            const period = categories[val - 1];
            const year = period.slice(2, 4);
            const semester = period.slice(4);
            return `${semester}-${year}`;
          }
        },
        y: {
          formatter: function(val: number, index: any) {
            if (val === null || val === undefined) return 'No data';
            switch (chartId) {
              case ChartId.NOI_Y: return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 2}) + '%';  // カンマ区切り＆小数点以下0桁
              case ChartId.OCC: {
                if (val === 100) {
                  // 100%の場合は小数点表記なし。
                  return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + '%';  // カンマ区切り＆小数点以下0桁
                } else {
                  return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';  // カンマ区切り＆小数点以下1桁
                }
              }   
              case ChartId.RENT: {
                let text = '';
                switch (myLang) {
                  case MyLanguage.JA:
                    text = val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + ' 円/坪';  // カンマ区切り＆小数点以下0桁
                    break;
                  case MyLanguage.EN:
                    text = '&yen; ' + val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + '/tsubo';  // カンマ区切り＆小数点以下0桁
                    break;
                }
                return text;
              }
              case ChartId.CR:    return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';  // カンマ区切り＆小数点以下0桁
            }
            return val;
          }
        }
      },
      legend: {
        showForSingleSeries: true,  // 1物件のみでも凡例表示する。
        onItemClick: {
          toggleDataSeries: true,  // true：クリックした物件の表示／非表示を切り替えられるようにする。
        },
      },
    };

    return options;
  }

  return (
    <div id="HistChart">
      <div className="content">
        <div className="chart_tab" onChange={(e) => {handleChangeChart(e)}}>
          <label htmlFor={ChartId.NOI_Y} className={getClassName(ChartId.NOI_Y)}>NOI Yield</label>
          <input type="radio" name="charts" value={ChartId.NOI_Y} id={ChartId.NOI_Y} checked={chartId === ChartId.NOI_Y} />
          <label htmlFor={ChartId.OCC} className={classNames(getClassName(ChartId.OCC), myLang)}>{t('稼働率')}</label>
          <input type="radio" name="charts" value={ChartId.OCC} id={ChartId.OCC} checked={chartId === ChartId.OCC} />
          <label htmlFor={ChartId.RENT} className={getClassName(ChartId.RENT)}>{t('賃料')}</label>
          <input type="radio" name="charts" value={ChartId.RENT} id={ChartId.RENT} checked={chartId === ChartId.RENT} />
          <label htmlFor={ChartId.CR} className={getClassName(ChartId.CR)}>{t('鑑定CR（NCF）')}</label>
          <input type="radio" name="charts" value={ChartId.CR} id={ChartId.CR} checked={chartId === ChartId.CR} />
        </div>
        {render_LineChart()}
        {render_scatterChart()}
      </div>
    </div>
  );
}

export default HistChart;
