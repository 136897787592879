import React from 'react';
import { useState, useEffect, useContext, MouseEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AppContext, MyLanguage } from '../../context/AppContext';
import { SubmitSearchButtonId } from '../../utils/SearchPanelConst';
import CommonUtil from '../../utils/CommonUtil';
import AuthUtil from '../../utils/AuthUtil';
import { MemberType } from '../../utils/MemberType';
import './Addr.css';

type Props = {
  use_for: string
};

const Addr: React.VFC<Props> = (props) => {
  const ContextRoot = CommonUtil.get_context_root();

  const [ t ] = useTranslation();
  const { myLang, memberType, setIsOpenUserRegistrationDialog } = useContext(AppContext);
  const { register } = useFormContext();
  const [ addrJson, setAddrJson ] = useState<any>({});
  const [ selectAddr1, setSelectAddr1 ] = useState<string>('');

  let addr_id = 'Addr';
  let addr2_select_id = 'select_addr2';
  let submit_search_button_id = SubmitSearchButtonId;
  if (props.use_for) {
    addr_id += '_' + props.use_for;
    addr2_select_id += '_' + props.use_for;
    submit_search_button_id += '_' + props.use_for;
  }

  useEffect(() => {
    fetch(`${ContextRoot}/jsons/addr.json`, { method: 'GET' })
    .then(res => res.json())
    .then(data => {
      setAddrJson(data);
    });
  }, []);

  const render_addr1 = () => {
    if (!('addr1' in addrJson)) return null;

    let rendering = [];
    const addr1_option_list = addrJson['addr1'];
    for (let addr1_option of addr1_option_list) {
      let name = '';
      switch (myLang) {
        case MyLanguage.JA: name = addr1_option['name']; break;
        case MyLanguage.EN: name = addr1_option['name_en']; break;
      }
      rendering.push(<option value={addr1_option['code']}>{name}</option>);
    }
    return rendering;
  }

  const render_addr2 = () => {
    if (selectAddr1 === '') return null;
    if (!('addr1' in addrJson)) return null;

    const addr2_json = addrJson['addr2'];
    const addr2_option_list = addr2_json[selectAddr1];
    // 都道府県を指定なしに選び直した場合の対応。
    if (!addr2_option_list) return null;

    let rendering = [];
    for (let addr2_option of addr2_option_list) {
      let name = '';
      switch (myLang) {
        case MyLanguage.JA: name = addr2_option['name']; break;
        case MyLanguage.EN: name = addr2_option['name_en']; break;
      }
      rendering.push(<option value={addr2_option['code']}>{name}</option>);
    }
    return rendering;
  }

  const onChange_Addr1: React.ChangeEventHandler<HTMLSelectElement> = (e: any) => {
    const idx = e.target.selectedIndex;
    const value = e.target.options[idx].value;
    setSelectAddr1(value);

    // 「都道府県」を変更したら、「市区」を「  」に変更する。
    // 「市区」のonChangeイベントを呼び出し(以下のtrigger)、再検索を行う。
    let select_addr2: any = document.getElementById(addr2_select_id);
    if (select_addr2) {
      select_addr2.trigger = (event_name: any) => {
        let e: any  = document.createEvent("HTMLEvents");
        e.initEvent(event_name, true, true );
        select_addr2.dispatchEvent(e);
      }

      // 「市区」を「  」に変更。
      let options = select_addr2.options;
      options[0].selected = true;
      // 「市区」のonChangeイベントを呼び出す。
      select_addr2.trigger("change");
    }
  }

  const handleChange = () => {
    // 都道府県／市区の変更時に再検索させる。
    const submit_search_button = document.getElementById(submit_search_button_id);
    if (!submit_search_button) return;
    submit_search_button.click();
  }

  const handleMouseDown = (event: MouseEvent<HTMLSelectElement>): void => {
    if (AuthUtil.restricted(memberType, MemberType.C)) {
      setIsOpenUserRegistrationDialog(true);
      // optionの表示をキャンセルする。
      event.preventDefault();
      return;
    }
  }

  return (
    <div id={addr_id}>
      <div className="searchgroup">
        {/* 都道府県 */}
        <div className="Addr1">
          <span className="searchfieldlabel">{t('都道府県')}</span>
          <select onMouseDown={handleMouseDown} {...register('addr1', { onChange: onChange_Addr1 })}>
            <option value="0" selected disabled></option>
            <option value="0">{t('指定なし')}</option>
            {render_addr1()}
          </select>
        </div>

        {/* 市区 */}
        <div className="Addr2">
          <span className="searchfieldlabel">{t('市区')}</span>
          <select id={addr2_select_id} onMouseDown={handleMouseDown} {...register('addr2', { onChange: handleChange })}>
            <option value="0" selected disabled></option>
            <option value="0">{t('指定なし')}</option>
            {render_addr2()}
          </select> 
        </div>
      </div>
    </div>
  );
}

export default Addr;
