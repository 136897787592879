import React, { useContext, MouseEvent } from "react";
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import './TransactionPrice.css';
import { AppContext, MyLanguage } from '../../context/AppContext';
import { SubmitSearchButtonId } from '../../utils/SearchPanelConst';
import AuthUtil from '../../utils/AuthUtil';
import { MemberType } from '../../utils/MemberType';

type Props = {

};

const TransactionPrice: React.VFC<Props> = () => {
  const [ t ] = useTranslation();
  const { myLang, memberType, setIsOpenUserRegistrationDialog } = useContext(AppContext);
  const { register } = useFormContext();

  const local_transaction_price = (transaction_price: number) => {
    switch (myLang) {
      case MyLanguage.JA:
        return `${transaction_price.toLocaleString()}億円`;
      case MyLanguage.EN:
        return `\xA5 ${(transaction_price * 100).toLocaleString()}m`;
      default:
        return '';
    }
  }

  /**
   * 取引価格のオプションを生成する。
   * 
   * @returns オプション要素
   */
  const render_option = () => {
    let rendering = [];

    const transaction_price_list = [ 5, 10, 50, 100, 500 ];

    for (let transaction_price of transaction_price_list) {
      rendering.push(<option value={transaction_price}>{local_transaction_price(transaction_price)}</option>);
    }

    return rendering;
  }

  const handleChange = () => {
    // 取引価格変更時に再検索させる。
    const submit_search_button = document.getElementById(SubmitSearchButtonId);
    if (!submit_search_button) return;
    submit_search_button.click();
  }

  const handleMouseDown = (event: MouseEvent<HTMLSelectElement>): void => {
    if (AuthUtil.restricted(memberType, MemberType.C)) {
      setIsOpenUserRegistrationDialog(true);
      // optionの表示をキャンセルする。
      event.preventDefault();
      return;
    }
  }

  return (
    <div id="TransactionPrice">
      <div className="searchgroup">
        <span className="searchfieldlabel">{t('取引価格')}</span>
        <select onMouseDown={handleMouseDown} {...register('transaction_price_lower', { onChange: handleChange })}>
          <option value="0" selected disabled></option>
          <option value="0">{t('下限なし')}</option>
          {render_option()}
        </select>
        <span>&nbsp;～&nbsp;</span>
        <select onMouseDown={handleMouseDown} {...register('transaction_price_upper', { onChange: handleChange })}>
          <option value="0" selected disabled></option>
          <option value="0">{t('上限なし')}</option>
          {render_option()}
        </select>
      </div>
    </div>
  );
}

export default TransactionPrice;
