import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import { AppContext, LoginState } from './AppContext';
import { MemberType } from '../utils/MemberType';

const AuthContext = createContext({} as {
  currentUser: User | null | undefined
});

const useAuthContext = () => {
  return useContext(AuthContext);
}

const AuthProvider = (props: any) => {
  const [ currentUser, setCurrentUser ] = useState<User | null | undefined>(undefined);
  const { setLoginState, setMemberType } = useContext(AppContext);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribed = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      if (user) {
        setLoginState(LoginState.LoggedIn_With_Auth);
        user.getIdTokenResult()
          .then((idTokenResult) => {
            setMemberType(Number(idTokenResult.claims.memberType));
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // signout
        setMemberType(MemberType.A);
      }
    });
    return () => {
      unsubscribed();
    }
  }, []);

  return (
    <AuthContext.Provider value={{currentUser: currentUser}}>
      {props.children}
    </AuthContext.Provider>
  );
}

export {
  useAuthContext,
  AuthProvider
}