import { BuildingTypeType } from './EstateDataType';

export const ValueMapDataName = {
  BuildingType: 'building_type',
  AppraisalValue: 'appraisal_value',
  AppraisalValueUnitPrice: 'appraisal_value_unit_price',
  LandValue: 'land_value',
  LandValueUnitPrice: 'land_value_unit_price',
  LandFar: 'land_far',    //20231104 Add
  Rosenka: 'rosenka',     //20240218 Add
  KoujiNo: 'kouji_no',     //20240218 Add
  Kouji: 'kouji',     //20240218 Add
  AssumedRent: 'assumed_rent',
  CapRate: 'cap_rate',
  LandArea: 'land_area',
  ArchitecturalArea: 'architectural_area',
  Construction_YYYYMMDD: 'construction_yyyymmdd',
  Distance: 'distance',
  BuildingCoverageRatio: 'building_coverage_ratio',
  Far: 'far',
}

export interface ValueMapDataType {
  id: number,
  updated_at: string,
  lat: number,
  lng: number,
  allowed_member_type_level: number,
  building_type: BuildingTypeType,
  property_name: string,
  property_name_en: string,
  image_file: string,
  addr1: string,
  addr2: string,
  addr3: string,
  addr1_en: string,
  addr2_en: string,
  addr3_en: string,
  railway_line: string,
  railway_line_en: string,
  station: string,
  station_en: string,
  distance: number,
  land_area: number,
  architectural_area: number,
  rentable_area: number,
  construction_yyyymmdd: number,
  structure: string,
  structure_en: string,
  floor: string,
  floor_en: string,
  building_coverage_ratio: number,
  far: number,
  zooning: string,
  zooning_en: string,
  fireproof: string,
  fireproof_en: string,
  remarks: string,
  remarks_en: string,
  land_far: number,     //20231104 Add
  rosenka: number,     //20240218 Add
  kouji_no: string,     //20240218 Add
  kouji: number,     //20240218 Add
  assumed_rent: number,  
  appraisal_value: number,
  cap_rate: number,
  rent: number,
  parking_income: number,
  key_money_right_fee_renewal_fee_income: number,
  utility_income: number,
  other_income: number,
  gross_potential_income: number,
  vacancy_loss_etc: number,
  operating_income: number,
  maintenance_fee: number,
  utility_fee: number,
  repair_fee: number,
  pm_fee: number,
  tenant_recruitment_fee: number,
  land_tax: number,
  building_tax: number,
  depreciable_tax: number,
  insurance: number,
  other_fee: number,
  operating_fee: number,
  noi: number,
  lump_sum_investment_income: number,
  capital_exp: number,
  ncf: number,
  land_value: number,
  building_value: number,
  comment_appraisal_value: string,
  comment_en_appraisal_value: string,
  comment_cap_rate: string,
  comment_en_cap_rate: string,
  comment_rent: string,
  comment_en_rent: string,
  comment_parking_income: string,
  comment_en_parking_income: string,
  comment_key_money_right_fee_renewal_fee_income: string,
  comment_en_key_money_right_fee_renewal_fee_income: string,
  comment_utility_income: string,
  comment_en_utility_income: string,
  comment_other_income: string,
  comment_en_other_income: string,
  comment_vacancy_loss_etc: string,
  comment_en_vacancy_loss_etc: string,
  comment_maintenance_fee: string,
  comment_en_maintenance_fee: string,
  comment_utility_fee: string,
  comment_en_utility_fee: string,
  comment_repair_fee: string,
  comment_en_repair_fee: string,
  comment_pm_fee: string,
  comment_en_pm_fee: string,
  comment_tenant_recruitment_fee: string,
  comment_en_tenant_recruitment_fee: string,
  comment_land_tax: string,
  comment_en_land_tax: string,
  comment_building_tax: string,
  comment_en_building_tax: string,
  comment_depreciable_tax: string,
  comment_en_depreciable_tax: string,
  comment_insurance: string,
  comment_en_insurance: string,
  comment_other_fee: string,
  comment_en_other_fee: string,
  comment_operating_fee: string,
  comment_en_operating_fee: string,
  comment_noi: string,
  comment_en_noi: string,
  comment_lump_sum_investment_income: string,
  comment_en_lump_sum_investment_income: string,
  comment_capital_exp: string,
  comment_en_capital_exp: string,
  comment_land_value: string,
  comment_en_land_value: string,
  comment_building_value: string,
  comment_en_building_value: string,
  // 以下は計算して求めるデータ
  appraisal_value_unit_price: number,
  land_value_unit_price: number,
}