import { EstateDataType } from './EstateDataType';
import EstateDataUtil from '../utils/EstateDataUtil';
import { MyLanguage, DisplayMode } from '../context/AppContext';
import ExcelJS from "exceljs";
import { InvestmentDataType } from './InvestmentDataType';
import InvestmentDataUtil from '../utils/InvestmentDataUtil';
import { HistInvestmentDataType } from './HistInvestmentDataType';
import HistInvestmentDataUtil from '../utils/HistInvestmentDataUtil';
import CommonUtil from '../utils/CommonUtil';
import ChartUtil from '../utils/ChartUtil';
import { ChartType, ChartAdrRevParType, ChartAvCrType, ChartExpDetailType, ChartExpRatioType, ChartNoiyOccType, ChartRentType } from '../utils/ToExcelType';

// ファイル名
const ExcelFilename_HData = 'H_Data.xlsx';
const ExcelFilename_InvestmentData = 'I_Data.xlsx';
// シート名
const SheetName_HData = 'H Data';
const SheetName_InvestmentData = 'I Data';

// 出力するExcelのフォント
const FontStyle = {
  name : 'Yu Gothic',
  size : 11
};

// ワークシートの設定
const WorkSheet_View = {
  zoomScale: 50,  // ズーム率50%
}
// タイトルの列の幅
const Title_Col_Width = 35;
// 物件の列の幅
const Property_Col_Width = 35;
// 写真のサイズ
const Img_Width = 198;
const Img_Height = 244;
// 写真の行の高さ
const Img_Row_Height = 200;
// ブランクセルのテキスト
const Blank_Text = 'NA';

// 罫線定義
const BorderStyle = {
  top:    { style: "thin" },
  left:   { style: "thin" },
  bottom: { style: "thin" },
  right:  { style: "thin" }
};


class ToExcel {
  
  static out = async (t: any, myLang: string, appData: any, labeledEstateData: EstateDataType[], ContextRoot: string) => {
    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet(SheetName_HData);
    const worksheet = workbook.getWorksheet(SheetName_HData);
    worksheet.views = [ WorkSheet_View ];

    let columns: Partial<ExcelJS.Column>[] = [];
    columns.push({ header: '', key: 'title', style: { font: FontStyle } });
    for (let d of labeledEstateData) {
      let property_name = '';
      switch (myLang) {
        case MyLanguage.JA: property_name = d.property_name; break;
        case MyLanguage.EN: property_name = d.property_name_en; break;
      }
      columns.push({ header: property_name, key: String(d.id), style: { font: FontStyle } });
    }
    worksheet.columns = columns;

    let rows = [];
    // 写真
    rows.push(ToExcel.gen_row_image());
    // タイプ
    rows.push(ToExcel.gen_row_buildingType(t, labeledEstateData));
    // 所在地
    rows.push(ToExcel.gen_row_addr(t, myLang, labeledEstateData));
    // 最寄り駅
    rows.push(ToExcel.get_row_station(t, myLang, labeledEstateData));
    // 取得価格(百万円)
    rows.push(ToExcel.gen_row_transaction_price(t, myLang, labeledEstateData));
    // キャップレート（NOI）
    rows.push(ToExcel.gen_row_cap_rate(t, labeledEstateData));
    // 取引年月
    rows.push(ToExcel.gen_row_transaction_yyyymmdd(t, labeledEstateData));
    // 土地面積
    rows.push(ToExcel.gen_row_land_area(t, labeledEstateData));
    // 階層
    rows.push(ToExcel.gen_row_num_of_floors(t, myLang, labeledEstateData));
    // 総戸数
    if (ToExcel.need_out_total_units(labeledEstateData)) {
      rows.push(ToExcel.gen_row_total_units(t, labeledEstateData));
    }
    // 建物全体面積
    rows.push(ToExcel.gen_row_total_area_of_buidling(t, labeledEstateData));
    // 延床面積（GFL）
    rows.push(ToExcel.gen_row_architectural_area(t, labeledEstateData));
    // 賃貸可能面積（NLA）
    rows.push(ToExcel.gen_row_rentable_area(t, labeledEstateData));
    // 取引単価（取引価格/NLA）
    rows.push(ToExcel.gen_row_transaction_unit_price(t, labeledEstateData));
    // 竣工
    rows.push(ToExcel.gen_row_construction_yyyymmdd(t, labeledEstateData));
    // 投資運用法人
    rows.push(ToExcel.gen_row_investment_corp(t, myLang, appData, labeledEstateData));
    // 権利
    rows.push(ToExcel.gen_row_ownership(t, labeledEstateData));
    worksheet.addRows(rows);
   
    // 写真の埋め込み
    await ToExcel.fetch_images(workbook, worksheet, labeledEstateData, ContextRoot);

    // スタイル設定
    ToExcel.set_style(myLang, worksheet, rows, labeledEstateData);

    // リンクを生成＆クリックしてファイルをダウンロードさせる。
    await ToExcel.download_excel(ExcelFilename_HData, workbook);
  }

  static download_excel = async (filename: string, workbook: ExcelJS.Workbook) => {
    // リンクを生成＆クリックしてファイルをダウンロードさせる。
    const uint8Array = await workbook.xlsx.writeBuffer();
    const blob = new Blob([uint8Array], { type: "application/octet-binary" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    a.remove();    
  }

  static out_charts = async (t: any, myLang: string, appData: any, labeledEstateData: EstateDataType[], charts: ChartType[], ContextRoot: string) => {
    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet(SheetName_HData);
    const worksheet = workbook.getWorksheet(SheetName_HData);
    worksheet.views = [ WorkSheet_View ];

    await ToExcel.out_common(workbook, worksheet, t, myLang, appData, labeledEstateData, ContextRoot);

    ToExcel.out_chart_series(worksheet, t, labeledEstateData, charts);

    await ToExcel.download_excel(ExcelFilename_HData, workbook);
  } 

  static out_hist = async (t: any, myLang: string, appData: any, labeledEstateData: EstateDataType[], chart_noiy_occ: ChartNoiyOccType, chart_adr_revPar: ChartAdrRevParType, chart_rent: ChartRentType, chart_av_cr: ChartAvCrType, chart_expRatio: ChartExpRatioType, chart_expDetail: ChartExpDetailType, ContextRoot: string) => {
    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet(SheetName_HData);
    const worksheet = workbook.getWorksheet(SheetName_HData);
    worksheet.views = [ WorkSheet_View ];

    await ToExcel.out_common(workbook, worksheet, t, myLang, appData, labeledEstateData, ContextRoot);

    let startRow = 4;
    startRow = ToExcel.out_chart_noiy_occ(startRow, worksheet, t, chart_noiy_occ);
    startRow = ToExcel.out_chart_adr_revPar(startRow, worksheet, t, chart_adr_revPar);
    startRow = ToExcel.out_chart_rent(startRow, worksheet, t, chart_rent);
    startRow = ToExcel.out_chart_av_cr(startRow, worksheet, t, chart_av_cr);

    startRow = ToExcel.out_chart_expRatio(startRow, worksheet, t, chart_expRatio);
    startRow = ToExcel.out_chart_expDetail(startRow, worksheet, t, chart_expDetail);

    await ToExcel.download_excel(ExcelFilename_HData, workbook);
  }

  static out_common = async (workbook: ExcelJS.Workbook, worksheet: ExcelJS.Worksheet, t: any, myLang: string, appData: any, labeledEstateData: EstateDataType[], ContextRoot: string) => {
    let columns: Partial<ExcelJS.Column>[] = [];
    columns.push({ header: '', key: 'title', style: { font: FontStyle } });
    for (let i=0; i<labeledEstateData.length; i++) {
      const d = labeledEstateData[i];
      columns.push({ header: `${i+1}`, key: String(d.id), style: { font: FontStyle } });
    }
    worksheet.columns = columns;

    let rows = [];
    // 物件名
    rows.push(ToExcel.gen_row_property_name(myLang, labeledEstateData));
    // 写真
    rows.push(ToExcel.gen_row_image());
    // タイプ
    rows.push(ToExcel.gen_row_buildingType(t, labeledEstateData));
    // 所在地
    rows.push(ToExcel.gen_row_addr(t, myLang, labeledEstateData));
    // 最寄り駅
    rows.push(ToExcel.get_row_station(t, myLang, labeledEstateData));
    // 取得価格(百万円)
    rows.push(ToExcel.gen_row_transaction_price(t, myLang, labeledEstateData));
    // キャップレート（NOI）
    rows.push(ToExcel.gen_row_cap_rate(t, labeledEstateData));
    // 取引年月
    rows.push(ToExcel.gen_row_transaction_yyyymmdd(t, labeledEstateData));
    // 土地面積
    rows.push(ToExcel.gen_row_land_area(t, labeledEstateData));
    // 階層
    rows.push(ToExcel.gen_row_num_of_floors(t, myLang, labeledEstateData));
    // 総戸数
    if (ToExcel.need_out_total_units(labeledEstateData)) {
      rows.push(ToExcel.gen_row_total_units(t, labeledEstateData));
    }
    // 建物全体面積
    rows.push(ToExcel.gen_row_total_area_of_buidling(t, labeledEstateData));
    // 延床面積（GFL）
    rows.push(ToExcel.gen_row_architectural_area(t, labeledEstateData));
    // 賃貸可能面積（NLA）
    rows.push(ToExcel.gen_row_rentable_area(t, labeledEstateData));
    // 取引単価（取引価格/NLA）
    rows.push(ToExcel.gen_row_transaction_unit_price(t, labeledEstateData));
    // 竣工
    rows.push(ToExcel.gen_row_construction_yyyymmdd(t, labeledEstateData));
    // 投資運用法人
    rows.push(ToExcel.gen_row_investment_corp(t, myLang, appData, labeledEstateData));
    // 権利
    rows.push(ToExcel.gen_row_ownership(t, labeledEstateData));
    worksheet.addRows(rows);
   
    // スタイル設定
    ToExcel.set_style(myLang, worksheet, rows, labeledEstateData);

    // 選択した物件だけを地図に表示するURLを設定(上記の後に1行目に行を追加してURLを設定する)
    if (labeledEstateData.length > 0) {
      let url = `${window.location.origin}${ContextRoot}?mode=${DisplayMode.Map}&lang=${myLang}`;
      for (let d of labeledEstateData) {
        url += `&id=${d.id}`;
        // URLアクセス時、同じタイプの物件を取得する。(指定しないとオフィスだけしか取得しない)
        url += `&building_type=${d.building_type}`;
      }

      // 行を追加。
      worksheet.insertRow(1, null);
      worksheet.getCell(1, 1).value = url;
    }

    // 写真の埋め込み
    await ToExcel.fetch_images(workbook, worksheet, labeledEstateData, ContextRoot);

    if (!ToExcel.has_image(labeledEstateData)) {
      // 写真がない場合、行ごと(4行目から1行分)削除する。
      worksheet.spliceRows(4, 1);
    }
  }

  static has_image = (labeledEstateData: EstateDataType[]) => {
    for (let d of labeledEstateData) {
      if (d.images_files) {
        return true;
      }
    }
    return false;
  }

  static gen_row_property_name = (myLang: string, labeledEstateData: EstateDataType[]) => {
    let row: any = { title: '' };
    for (let d of labeledEstateData) {
      let property_name = '';
      switch (myLang) {
        case MyLanguage.JA: property_name = d.property_name; break;
        case MyLanguage.EN: property_name = d.property_name_en; break;
      }
      row[String(d.id)] = property_name;
    }
    return row;
  }

  static gen_row_image = () => {
    // 写真
    let row: any = {}
    return row;
  }

  static gen_row_buildingType = (t: any, labeledEstateData: EstateDataType[]) => {
    let row: any = { title: t('タイプ') };
    for (let d of labeledEstateData) {
      row[String(d.id)] = t(EstateDataUtil.convert_buildingType_name(d.building_type, d.building_type2));
    }
    return row;
  }

  static gen_row_addr = (t: any, myLang: string, labeledEstateData: EstateDataType[]) => {
    let row: any = { title: t('所在地') };
    for (let d of labeledEstateData) {
      let addr = '';
      switch (myLang) {
        case MyLanguage.JA: {
          if (d.addr1 || d.addr2 || d.addr3) {
            addr = d.addr1 + d.addr2 + d.addr3;
          }
          break;
        }
        case MyLanguage.EN: {
          if (d.addr1_en || d.addr2_en || d.addr3_en) {
            if (d.addr3_en) addr += d.addr3_en + ', ';
            if (d.addr2_en) addr += d.addr2_en + ', ';
            if (d.addr1_en) addr += d.addr1_en;
          }
          break;
        }
      }
      row[String(d.id)] = addr;
    }
    return row;
  }

  static get_row_station = (t: any, myLang: string, labeledEstateData: EstateDataType[]) => {
    let row: any = { title: t('最寄り駅') };
    for (let d of labeledEstateData) {
      let station = 'NA';
      let distance = '';
      switch (myLang) {
        case MyLanguage.JA: {
          if (d.station) {
            station = d.station;
          }
          break;
        }
        case MyLanguage.EN: {
          if (d.station_en) {
            station = d.station_en;
          }
          break;
        }
      }
      if (d.distance !== null) {
        if (d.distance >= 1000) {
          // 小数点以下1桁
          distance = Number(d.distance / 1000).toFixed(1).toLocaleString() + 'km';
        } else {
          distance = String(d.distance) + 'm';
        }
      }
      row[String(d.id)] = station + '  ' + distance;
    }
    return row;
  }

  static gen_row_transaction_price = (t: any, myLang: string, labeledEstateData: EstateDataType[]) => {
    let row: any = { title: t('取引価格(Hist)') };
    for (let d of labeledEstateData) {
      if (!EstateDataUtil.convert_disclose_tx_price(d.disclose_tx_price)) {
        row[String(d.id)] = t('非開示');
        continue;
      }
      if (d.transaction_price === null) {
        row[String(d.id)] = Blank_Text;
        continue;
      }

      let text = '';
      switch (myLang) {
        case MyLanguage.JA: {
          text = `${Number(d.transaction_price).toLocaleString()} 百万円`;
          break;
        }
        case MyLanguage.EN: {
          text = `\\ ${Number(d.transaction_price).toLocaleString()}m`;
          break;
        }
      }
      row[String(d.id)] = text;
    }
    return row;
  }

  static gen_row_cap_rate = (t: any, labeledEstateData: EstateDataType[]) => {
    let row: any = { title: t('キャップレート（NOI）') };
    for (let d of labeledEstateData) {
      if (!EstateDataUtil.convert_disclose_cap_rate(d.disclose_cap_rate)) {
        row[String(d.id)] = t('非開示');
        continue;
      }
      if (d.cap_rate === null) {
        row[String(d.id)] = Blank_Text;
        continue;
      }
      row[String(d.id)] = d.cap_rate;
    }
    return row;
  }

  static gen_row_transaction_yyyymmdd = (t: any, labeledEstateData: EstateDataType[]) => {
    let row: any = { title: t('取引年月') };
    for (let d of labeledEstateData) {
      if (d.transaction_yyyymmdd === null) {
        row[String(d.id)] = Blank_Text;
        continue;
      }
      const year = String(d.transaction_yyyymmdd).substring(0, 4);
      // 月は、1文字目が0の場合は省略するため、数値に変換する。
      const month = Number(String(d.transaction_yyyymmdd).substring(4, 6));
      const day = Number(String(d.transaction_yyyymmdd).substring(6, 8));
      // タイムゾーンの影響を受けないよう、Date.UTC()を使用する。(https://github.com/exceljs/exceljs/issues/486)
      row[String(d.id)] = new Date(Date.UTC(Number(year), Number(month)-1, Number(day)));
    }
    return row;
  }

  static gen_row_land_area = (t: any, labeledEstateData: EstateDataType[]) => {
    let row: any = { title: t('土地面積') };
    for (let d of labeledEstateData) {
      if (d.land_area === null) {
        row[String(d.id)] = Blank_Text;
        continue;
      }
      row[String(d.id)] = d.land_area;
    }
    return row;
  }

  static gen_row_num_of_floors = (t: any, myLang: string, labeledEstateData: EstateDataType[]) => {
    let row: any = { title: t('階数') };
    for (let d of labeledEstateData) {
      let num_of_floors = Blank_Text;
      switch (myLang) {
        case MyLanguage.JA: if (d.num_of_floors) num_of_floors = d.num_of_floors; break;
        case MyLanguage.EN: if (d.num_of_floors_en) num_of_floors = d.num_of_floors_en; break;
      }
      row[String(d.id)] = num_of_floors;
    }
    return row;
  }

  static gen_row_total_units = (t: any, labeledEstateData: EstateDataType[]) => {
    let row: any = { title: t('総戸数') };
    for (let d of labeledEstateData) {
      if (d.total_units === null) {
        row[String(d.id)] = Blank_Text;
        continue;
      }
      row[String(d.id)] = d.total_units;
    }
    return row;
  }

  static gen_row_total_area_of_buidling = (t: any, labeledEstateData: EstateDataType[]) => {
    let row: any = { title: t('建物全体面積') };
    for (let d of labeledEstateData) {
      if (d.la === null) {
        row[String(d.id)] = Blank_Text;
        continue;
      }
      row[String(d.id)] = d.la;
    }
    return row;
  }

  static gen_row_architectural_area = (t: any, labeledEstateData: EstateDataType[]) => {
    let row: any = { title: t('延床面積（GFL）') };
    for (let d of labeledEstateData) {
      if (d.architectural_area === null) {
        row[String(d.id)] = Blank_Text;
        continue;
      }
      row[String(d.id)] = d.architectural_area;
    }
    return row;
  }

  static gen_row_rentable_area = (t: any, labeledEstateData: EstateDataType[]) => {
    let row: any = { title: t('賃貸可能面積（NLA）') };
    for (let d of labeledEstateData) {
      if (d.rentable_area === null) {
        row[String(d.id)] = Blank_Text;
        continue;
      }
      row[String(d.id)] = d.rentable_area;
    }
    return row;
  }

  static gen_row_transaction_unit_price = (t: any, labeledEstateData: EstateDataType[]) => {
    let row: any = { title: t('取引単価（取引価格/NLA）') };
    for (let d of labeledEstateData) {
      if (d.rentable_area === null || d.rentable_area === 0 || d.transaction_price === null || d.transaction_price === 0) {
        row[String(d.id)] = Blank_Text;
        continue;
      }
      const tubo = d.rentable_area * 0.3025;
      const tx_unit_price = d.transaction_price * 1000000 / tubo;  // 単価が100万円のため円単価に戻して計算する。
      row[String(d.id)] = tx_unit_price;
    }
    return row;
  }

  static gen_row_construction_yyyymmdd = (t: any, labeledEstateData: EstateDataType[]) => {
    let row: any = { title: t('竣工') };
    for (let d of labeledEstateData) {
      if (d.construction_yyyymmdd === null) {
        row[String(d.id)] = Blank_Text;
        continue;
      }
      const year = String(d.construction_yyyymmdd).substring(0, 4);
      // 月は、1文字目が0の場合は省略するため、数値に変換する。
      const month = Number(String(d.construction_yyyymmdd).substring(4, 6));
      const day = Number(String(d.construction_yyyymmdd).substring(6, 8));
      // タイムゾーンの影響を受けないよう、Date.UTC()を使用する。(https://github.com/exceljs/exceljs/issues/486)
      row[String(d.id)] = new Date(Date.UTC(Number(year), Number(month)-1, Number(day)));
    }
    return row;
  }

  static gen_row_investment_corp = (t: any, myLang: string, appData: any, labeledEstateData: EstateDataType[]) => {
    let row: any = { title: t('投資法人') };
    for (let d of labeledEstateData) {
      let corp = Blank_Text;
      if (d.investment_corp_type !== null && d.investment_corp_code !== null) {
        corp = EstateDataUtil.get_investment_corp(appData['investment_corp'], d.investment_corp_type, d.investment_corp_code, myLang);
      }
      row[String(d.id)] = corp;
    }
    return row;
  }

  static gen_row_ownership = (t: any, labeledEstateData: EstateDataType[]) => {
    let row: any = { title: t('権利') };
    for (let d of labeledEstateData) {
      if (d._ownership === null) {
        row[String(d.id)] = Blank_Text;
        continue;
      }
      row[String(d.id)] = t(EstateDataUtil.convert_ownership(d._ownership));
    }
    return row;
  }

  static fetch_images = async (workbook: any, worksheet: any, labeledEstateData: EstateDataType[], ContextRoot: string) => {
    let col = 1;
    for (let d of labeledEstateData) {
      if (d.images_files) {
        try {
          const path = `${ContextRoot}/images/properties/` + d.images_files + '.jpg';
          const extension = 'jpeg'

          let res = await fetch(path);
          let blob = await res.blob();
          let file = new File([blob], `${d.images_files}.jpg`);
          const buffer = await file.arrayBuffer();

          const imageId = workbook.addImage({
            buffer,
            extension
          });

          worksheet.addImage(imageId, {
            tl: { col: col + 0.6, row: 3 + 0.5 },
            ext: { width: Img_Width, height: Img_Height }
          });
        } catch (err) {
          console.error(err);
        }
      }

      worksheet.columns[col].width = Property_Col_Width;
      col++;
    }
  }

  static set_style = (myLang: string, worksheet: any, rows: any[], labeledEstateData: EstateDataType[]) => {
    worksheet.columns[0].width = Title_Col_Width;

    // 写真の行の高さ
    worksheet.getRow(3).height = Img_Row_Height;

    // テキストを中央寄せにし、折り返しありにする。
    for (let i=1; i<rows.length+2; i++) {
      worksheet.getRow(i).getCell(1).alignment = { vertical: 'middle', wrapText: true };
      for (let j=2; j<labeledEstateData.length+2; j++) {
        worksheet.getRow(i).getCell(j).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      }
    }
    // 罫線を引く。
    ToExcel.draw_border(worksheet, 1, 1, rows.length+2, labeledEstateData.length+2);

    ToExcel.set_numFmt(myLang, worksheet, labeledEstateData);
  }

  static set_numFmt = (myLang: string, worksheet: any, labeledEstateData: EstateDataType[]) => {
    const num_labeledEstateData = labeledEstateData.length;

    let row = 1;

    // 番号(フォーマット指定不要)
    row++;

    // 物件名(フォーマット指定不要)
    row++;

    // 写真(フォーマット指定不要)
    row++;

    // タイプ(フォーマット指定不要)
    row++;

    // 所在地(フォーマット指定不要)
    row++;

    // 最寄り駅(フォーマット指定不要)
    row++;

    // 「取得価格(百万円)」はカンマ区切りにする。
    for (let j=2; j<num_labeledEstateData+2; j++) {
      worksheet.getRow(row).getCell(j).numFmt = '#,##0';
    }
    row++;

    // CR (NOI)
    for (let j=2; j<num_labeledEstateData+2; j++) {
      worksheet.getRow(row).getCell(j).numFmt = '0.00%';
    }
    row++;

    // 取引年月
    for (let j=2; j<num_labeledEstateData+2; j++) {
      let numFmt = '';
      switch (myLang) {
        case MyLanguage.JA: numFmt = 'yyyy年m月'; break;
        case MyLanguage.EN: numFmt = 'mmm. yyyy'; break;
      }
      worksheet.getRow(row).getCell(j).numFmt = numFmt;
    }
    row++;

    // 土地面積
    for (let j=2; j<num_labeledEstateData+2; j++) {
      worksheet.getRow(row).getCell(j).numFmt = '#,##0 ㎡';
    }
    row++;

    // 階層(フォーマット指定不要)
    row++;

    // 総戸数
    if (ToExcel.need_out_total_units(labeledEstateData)) {
      for (let j=2; j<num_labeledEstateData+2; j++) {
        let numFmt = '';
        switch (myLang) {
          case MyLanguage.JA: numFmt = '#,##0"戸"'; break;
          case MyLanguage.EN: numFmt = '#,##0" units"'; break;
        }
        worksheet.getRow(row).getCell(j).numFmt = numFmt;
      }
      row++;  
    }

    // 建物全体面積
    for (let j=2; j<num_labeledEstateData+2; j++) {
      worksheet.getRow(row).getCell(j).numFmt = '#,##0 ㎡';
    }
    row++;

    // 延床面積（GFL）
    for (let j=2; j<num_labeledEstateData+2; j++) {
      worksheet.getRow(row).getCell(j).numFmt = '#,##0 ㎡';
    }
    row++;

    // 賃貸可能面積（NLA）
    for (let j=2; j<num_labeledEstateData+2; j++) {
      worksheet.getRow(row).getCell(j).numFmt = '#,##0 ㎡';
    }
    row++;

    // 取引単価（取引価格/NLA）
    for (let j=2; j<num_labeledEstateData+2; j++) {
      let numFmt = '';
      switch (myLang) {
        case MyLanguage.JA: numFmt = '#,##0" 円/坪"'; break;
        case MyLanguage.EN: numFmt = '"\\ "#,##0"/tsubo"'; break;
      }
      worksheet.getRow(row).getCell(j).numFmt = numFmt;
    }
    row++;

    // 竣工
    for (let j=2; j<num_labeledEstateData+2; j++) {
      let numFmt = '';
      switch (myLang) {
        case MyLanguage.JA: numFmt = 'yyyy年m月'; break;
        case MyLanguage.EN: numFmt = 'mmm. yyyy'; break;
      }
      worksheet.getRow(row).getCell(j).numFmt = numFmt;
    }
    row++;

    // 投資運用法人(フォーマット指定不要)
    row++;

    // 権利(フォーマット指定不要)
    row++;
  }

  static out_chart_series = (worksheet: ExcelJS.Worksheet, t: any, labeledEstateData: EstateDataType[], charts: ChartType[]) => {

    for (let chart_idx=0; chart_idx<charts.length; chart_idx++) {
      const chart = charts[chart_idx];

      // チャートなし(ヒストリカルデータなし)の場合は何もしない。
      if (chart.series.length === 0) continue;

      // 2列ずつ配置する。
      const StartRow = 4 + (Math.floor(chart_idx / 2) * (labeledEstateData.length + 3));  // +3は、空行、タイトル、日付
      const StartCol = 3 + labeledEstateData.length + ((chart_idx % 2) * (chart.date_list.length + 2));  // +2は、空列、物件名
      // タイトル
      worksheet.getCell(StartRow, StartCol).value = chart.title;
      // 期
      ToExcel.out_period_list(worksheet, StartRow + 1, StartCol + 1, chart.date_list);

      for (let series_idx=0; series_idx<chart.series.length; series_idx++) {
        const series = chart.series[series_idx];
        // 物件名
        worksheet.getCell(StartRow + (2+series_idx), StartCol).value = chart.series[series_idx].name;

        for (let j=0; j<series.data.length; j++) {
          const value = series.data[j];
          if (value === null) {
            worksheet.getCell(StartRow + (2+series_idx), StartCol + (j+1)).value = Blank_Text;
            continue;
          }
          worksheet.getCell(StartRow + (2+series_idx), StartCol + (j+1)).value = value;
          if (chart.numFmt) {
            worksheet.getCell(StartRow + (2+series_idx), StartCol + (j+1)).numFmt = chart.numFmt;
          }
        }
      }

      // 罫線を引く。
      ToExcel.draw_border(worksheet, StartRow+1, StartCol, StartRow+chart.series.length+2, StartCol+chart.date_list.length+1);

      // フォントを適用する。
      ToExcel.set_font(worksheet, StartRow, StartCol, StartRow+chart.series.length+2, StartCol+chart.date_list.length+1)
    }
  }

  /**
   * 「総戸数」を出力する必要があるか。
   * 
   * @param labeledEstateData 洗濯した物件情報
   * @returns 「総戸数」を出力する必要があるか。
   */
  static need_out_total_units = (labeledEstateData: EstateDataType[]) => {
    for (let d of labeledEstateData) {
      // 1つでも「総戸数」を持つ物件がある場合は出力する。
      if (EstateDataUtil.has_total_units(d)) return true;
    }
    return false;
  }

  /**
   * NOI Yield ＆ 稼働率を出力する
   * @param workbook 
   * @param worksheet 
   * @param t 
   * @param chart_noiy_occ 
   */
  static out_chart_noiy_occ = (startRow: number, worksheet: ExcelJS.Worksheet, t: any, chart_noiy_occ: ChartNoiyOccType) => {
    // チャートなし(ヒストリカルデータなし)の場合は何もしない。
    if (!chart_noiy_occ.has_chart_image) return startRow;

    const StartCol = 4;

    // タイトル
    worksheet.getCell(startRow, StartCol).value = t('NOI Yield ＆ 稼働率');
    // 日付
    ToExcel.out_date_list(worksheet, startRow + 1, StartCol + 1, chart_noiy_occ.date_list);
    // NOI Yield
    worksheet.getCell(startRow + 2, StartCol).value = 'NOI Yield';
    for (let i=0; i<chart_noiy_occ.histData_NOIY.length; i++) {
      const value = chart_noiy_occ.histData_NOIY[i];
      if (value == null) {
        worksheet.getCell(startRow + 2, StartCol + (i+1)).value = Blank_Text;
        continue;
      }
      worksheet.getCell(startRow + 2, StartCol + (i+1)).value = value;
      worksheet.getCell(startRow + 2, StartCol + (i+1)).numFmt = '0.00%';
    }
    // 稼働率
    worksheet.getCell(startRow + 3, StartCol).value = t('稼働率');
    for (let i=0; i<chart_noiy_occ.histData_Occ.length; i++) {
      const value = chart_noiy_occ.histData_Occ[i];
      if (value == null) {
        worksheet.getCell(startRow + 3, StartCol + (i+1)).value = Blank_Text;
        continue;
      }
      worksheet.getCell(startRow + 3, StartCol + (i+1)).value = value;
      worksheet.getCell(startRow + 3, StartCol + (i+1)).numFmt = '0.0%';
    }

    // 罫線を引く。
    ToExcel.draw_border(worksheet, startRow+1, StartCol, startRow+4, StartCol+chart_noiy_occ.date_list.length+1);

    // フォントを適用する。
    ToExcel.set_font(worksheet, startRow, StartCol, startRow+4, StartCol+chart_noiy_occ.date_list.length+1)

    return startRow + 6;
  }

  static out_chart_adr_revPar = (startRow: number, worksheet: ExcelJS.Worksheet, t: any, chart_adr_revPar: ChartAdrRevParType) => {
    // チャートなし(ヒストリカルデータなし)の場合は何もしない。
    if (!chart_adr_revPar.has_chart_image) return startRow;

    const StartCol = 4;

    // タイトル
    worksheet.getCell(startRow, StartCol).value = t('ADR & RevPAR');
    // 日付
    ToExcel.out_date_list(worksheet, startRow + 1, StartCol + 1, chart_adr_revPar.date_list);
    // ADR
    worksheet.getCell(startRow + 2, StartCol).value = 'ADR';
    for (let i=0; i<chart_adr_revPar.histData_Adr.length; i++) {
      const value = chart_adr_revPar.histData_Adr[i];
      if (value == null) {
        worksheet.getCell(startRow + 2, StartCol + (i+1)).value = Blank_Text;
        continue;
      }
      worksheet.getCell(startRow + 2, StartCol + (i+1)).value = value;
      worksheet.getCell(startRow + 2, StartCol + (i+1)).numFmt = '#,##0';
    }
    // RevPAR
    worksheet.getCell(startRow + 3, StartCol).value = 'RevPAR';
    for (let i=0; i<chart_adr_revPar.histData_RevPar.length; i++) {
      const value = chart_adr_revPar.histData_RevPar[i];
      if (value == null) {
        worksheet.getCell(startRow + 3, StartCol + (i+1)).value = Blank_Text;
        continue;
      }
      worksheet.getCell(startRow + 3, StartCol + (i+1)).value = value;
      worksheet.getCell(startRow + 3, StartCol + (i+1)).numFmt = '#,##0';
    }

    // 罫線を引く。
    ToExcel.draw_border(worksheet, startRow+1, StartCol, startRow+4, StartCol+chart_adr_revPar.date_list.length+1);

    // フォントを適用する。
    ToExcel.set_font(worksheet, startRow, StartCol, startRow+4, StartCol+chart_adr_revPar.date_list.length+1)

    return startRow + 6;
  }

  static out_chart_rent = (startRow: number, worksheet: ExcelJS.Worksheet, t: any, chart_rent: ChartRentType) => {
    // チャートなし(ヒストリカルデータなし)の場合は何もしない。
    if (!chart_rent.has_chart_image) return startRow;

    const StartCol = 4;

    // タイトル
    worksheet.getCell(startRow, StartCol).value = t('賃料（円/坪）');
    // 日付
    ToExcel.out_date_list(worksheet, startRow + 1, StartCol + 1, chart_rent.date_list);
    // 賃料（円/坪）
    worksheet.getCell(startRow + 2, StartCol).value = t('賃料（円/坪）');
    for (let i=0; i<chart_rent.histData_Rent.length; i++) {
      const value = chart_rent.histData_Rent[i];
      if (value == null) {
        worksheet.getCell(startRow + 2, StartCol + (i+1)).value = Blank_Text;
        continue;
      }
      worksheet.getCell(startRow + 2, StartCol + (i+1)).value = value;
      worksheet.getCell(startRow + 2, StartCol + (i+1)).numFmt = '#,##0';
    }
    // エリア平均（円/坪）
    worksheet.getCell(startRow + 3, StartCol).value = chart_rent.area_name;
    for (let i=0; i<chart_rent.histData_Rent_Area.length; i++) {
      const value = chart_rent.histData_Rent_Area[i];
      if (value == null) {
        worksheet.getCell(startRow + 3, StartCol + (i+1)).value = Blank_Text;
        continue;
      }
      worksheet.getCell(startRow + 3, StartCol + (i+1)).value = value;
      worksheet.getCell(startRow + 3, StartCol + (i+1)).numFmt = '#,##0';
    }

    // 罫線を引く。
    ToExcel.draw_border(worksheet, startRow+1, StartCol, startRow+4, StartCol+chart_rent.date_list.length+1);

    // フォントを適用する。
    ToExcel.set_font(worksheet, startRow, StartCol, startRow+4, StartCol+chart_rent.date_list.length+1);

    return startRow + 6;
  }

  static out_chart_av_cr = (startRow: number, worksheet: ExcelJS.Worksheet, t: any, chart_av_cr: ChartAvCrType) => {
    // チャートなし(ヒストリカルデータなし)の場合は何もしない。
    if (!chart_av_cr.has_chart_image) return startRow;

    const StartCol = 4;

    // タイトル
    worksheet.getCell(startRow, StartCol).value = t('鑑定評価額 ＆ 鑑定CR（NCF）');
    // 日付
    ToExcel.out_date_list(worksheet, startRow + 1, StartCol + 1, chart_av_cr.date_list);
    // 鑑定評価額
    worksheet.getCell(startRow + 2, StartCol).value = t('鑑定評価額');
    for (let i=0; i<chart_av_cr.histData_AV.length; i++) {
      const value = chart_av_cr.histData_AV[i];
      if (value == null) {
        worksheet.getCell(startRow + 2, StartCol + (i+1)).value = Blank_Text;
        continue;
      }
      worksheet.getCell(startRow + 2, StartCol + (i+1)).value = value;
      worksheet.getCell(startRow + 2, StartCol + (i+1)).numFmt = '#,##0';
    }
    // 鑑定CR（NCF）
    worksheet.getCell(startRow + 3, StartCol).value = t('鑑定CR（NCF）');
    for (let i=0; i<chart_av_cr.histData_CR.length; i++) {
      const value = chart_av_cr.histData_CR[i];
      if (value == null) {
        worksheet.getCell(startRow + 3, StartCol + (i+1)).value = Blank_Text;
        continue;
      }
      worksheet.getCell(startRow + 3, StartCol + (i+1)).value = value;
      worksheet.getCell(startRow + 3, StartCol + (i+1)).numFmt = '0.0%';
    }

    // 罫線を引く。
    ToExcel.draw_border(worksheet, startRow+1, StartCol, startRow+4, StartCol+chart_av_cr.date_list.length+1);

    // フォントを適用する。
    ToExcel.set_font(worksheet, startRow, StartCol, startRow+4, StartCol+chart_av_cr.date_list.length+1);

    return startRow + 6;
  }

  static out_chart_expRatio = (startRow: number, worksheet: ExcelJS.Worksheet, t: any, chart_expRatio: ChartExpRatioType) => {
    // チャートなし(ヒストリカルデータなし)の場合は何もしない。
    if (!chart_expRatio.has_chart_image) return startRow;

    const StartCol = 4;

    // タイトル
    worksheet.getCell(startRow, StartCol).value = t('経費率');
    // 経費率
    worksheet.getCell(startRow + 1, StartCol).value = t('経費率');
    worksheet.getCell(startRow + 1, StartCol + 1).value = chart_expRatio.exp_ratio;
    worksheet.getCell(startRow + 1, StartCol + 1).numFmt = '0.0%';

    // 罫線を引く。
    ToExcel.draw_border(worksheet, startRow+1, StartCol, startRow+2, StartCol+2);

    // フォントを適用する。
    ToExcel.set_font(worksheet, startRow, StartCol, startRow+2, StartCol+2);

    return startRow + 4;
  }

  static out_chart_expDetail = (startRow: number, worksheet: ExcelJS.Worksheet, t: any, chart_expDetail: ChartExpDetailType) => {
    // チャートなし(ヒストリカルデータなし)の場合は何もしない。
    if (!chart_expDetail.has_chart_image) return startRow;

    const StartCol = 4;

    const numFmt = '0.0%';

    let row_cnt = 0;
    if (chart_expDetail.bm != null) {
      worksheet.getCell(startRow + row_cnt, StartCol).value = 'BM';
      worksheet.getCell(startRow + row_cnt, StartCol + 1).value = chart_expDetail.bm;
      row_cnt++;
    }
    if (chart_expDetail.pm != null) {
      worksheet.getCell(startRow + row_cnt, StartCol).value = 'PM';
      worksheet.getCell(startRow + row_cnt, StartCol + 1).value = chart_expDetail.pm;
      row_cnt++;
    }
    if (chart_expDetail.bm_pm != null) {
      worksheet.getCell(startRow + row_cnt, StartCol).value = 'BM & PM';
      worksheet.getCell(startRow + row_cnt, StartCol + 1).value = chart_expDetail.bm_pm;
      row_cnt++;
    }
    if (chart_expDetail.utility != null) {
      worksheet.getCell(startRow + row_cnt, StartCol).value = t('水光熱費');
      worksheet.getCell(startRow + row_cnt, StartCol + 1).value = chart_expDetail.utility;
      row_cnt++;
    }
    if (chart_expDetail.repair != null) {
      worksheet.getCell(startRow + row_cnt, StartCol).value = t('修繕費');
      worksheet.getCell(startRow + row_cnt, StartCol + 1).value = chart_expDetail.repair;
      row_cnt++;
    }
    if (chart_expDetail.tax != null) {
      worksheet.getCell(startRow + row_cnt, StartCol).value = t('公租公課');
      worksheet.getCell(startRow + row_cnt, StartCol + 1).value = chart_expDetail.tax;
      row_cnt++;
    }
    if (chart_expDetail.insurance != null) {
      worksheet.getCell(startRow + row_cnt, StartCol).value = t('保険料');
      worksheet.getCell(startRow + row_cnt, StartCol + 1).value = chart_expDetail.insurance;
      row_cnt++;
    }
    if (chart_expDetail.leased_land != null) {
      worksheet.getCell(startRow + row_cnt, StartCol).value = t('賃借料');
      worksheet.getCell(startRow + row_cnt, StartCol + 1).value = chart_expDetail.leased_land;
      row_cnt++;
    }
    if (chart_expDetail.maintenance != null) {
      worksheet.getCell(startRow + row_cnt, StartCol).value = t('定期保守');
      worksheet.getCell(startRow + row_cnt, StartCol + 1).value = chart_expDetail.maintenance;
      row_cnt++;
    }
    if (chart_expDetail.leasing_fee != null) {
      worksheet.getCell(startRow + row_cnt, StartCol).value = t('仲介手数料、宣伝広告');
      worksheet.getCell(startRow + row_cnt, StartCol + 1).value = chart_expDetail.leasing_fee;
      row_cnt++;
    }
    if (chart_expDetail.trust_fee != null) {
      worksheet.getCell(startRow + row_cnt, StartCol).value = t('信託報酬');
      worksheet.getCell(startRow + row_cnt, StartCol + 1).value = chart_expDetail.trust_fee;
      row_cnt++;
    }
    if (chart_expDetail.restore != null) {
      worksheet.getCell(startRow + row_cnt, StartCol).value = t('原状回復');
      worksheet.getCell(startRow + row_cnt, StartCol + 1).value = chart_expDetail.restore;
      row_cnt++;
    }
    if (chart_expDetail.management_union_fee != null) {
      worksheet.getCell(startRow + row_cnt, StartCol).value = t('管理組合費');
      worksheet.getCell(startRow + row_cnt, StartCol + 1).value = chart_expDetail.management_union_fee;
      row_cnt++;
    }
    if (chart_expDetail.others != null) {
      worksheet.getCell(startRow + row_cnt, StartCol).value = t('その他');
      worksheet.getCell(startRow + row_cnt, StartCol + 1).value = chart_expDetail.others;
      row_cnt++;
    }

    for (let i=0; i<row_cnt; i++) {
      worksheet.getCell(startRow + i, StartCol + 1).numFmt = numFmt;
    }

    // 罫線を引く。
    ToExcel.draw_border(worksheet, startRow, StartCol, startRow+row_cnt, StartCol+2);

    // フォントを適用する。
    ToExcel.set_font(worksheet, startRow, StartCol, startRow+row_cnt, StartCol+2);

    return startRow + row_cnt + 2;
  }

  static out_period_list = (worksheet: ExcelJS.Worksheet, startRow: number, startCol: number, date_list: number[]) => {
    for (let i=0; i<date_list.length; i++) {
      const date_yyyymmdd = date_list[i];
      const year = String(date_yyyymmdd).substring(2, 4);
      // 月
      const month = Number(String(date_yyyymmdd).substring(4, 6));
      // 期
      let period = '';
      if (month >= 1 && month <= 6) {
        period = `1H-${year}`;
      } else {
        period = `2H-${year}`;
      }

      worksheet.getCell(startRow, startCol+i).value = period;
    }
  }

  static out_date_list = (worksheet: ExcelJS.Worksheet, startRow: number, startCol: number, date_list: number[]) => {
    for (let i=0; i<date_list.length; i++) {
      const date_yyyymmdd = date_list[i];
      const year = String(date_yyyymmdd).substring(0, 4);
      // 月は、1文字目が0の場合は省略するため、数値に変換する。
      const month = Number(String(date_yyyymmdd).substring(4, 6));
      const day = Number(String(date_yyyymmdd).substring(6, 8));
      // タイムゾーンの影響を受けないよう、Date.UTC()を使用する。(https://github.com/exceljs/exceljs/issues/486)
      worksheet.getCell(startRow, startCol+i).value = new Date(Date.UTC(Number(year), Number(month)-1, Number(day)));
      worksheet.getCell(startRow, startCol+i).numFmt = 'mmm-yy';
    }
  }
  
  static set_font = (worksheet: ExcelJS.Worksheet, startRow: number, startCol: number, endRow: number, endCol: number) => {
    // フォントを適用する。
    for (let i=startRow; i<endRow; i++) {
      for (let j=startCol; j<endCol; j++) {
        worksheet.getCell(i, j).font = FontStyle;
      }
    }
  }

  static draw_border = (worksheet: ExcelJS.Worksheet, startRow: number, startCol: number, endRow: number, endCol: number) => {
    // 罫線を引く。
    for (let i=startRow; i<endRow; i++) {
      for (let j=startCol; j<endCol; j++) {
        worksheet.getRow(i).getCell(j).border = {
          top:    { style: "thin" },
          left:   { style: "thin" },
          bottom: { style: "thin" },
          right:  { style: "thin" }
        };
      }
    }
  }

  // --------------------------------------------------------------------------------------------------
  // 投資情報
  // --------------------------------------------------------------------------------------------------

  static out_investmentData = async (t: any, myLang: string, investmentData: InvestmentDataType[], stock_price_update_date: string) => {
    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet(SheetName_InvestmentData);
    const worksheet = workbook.getWorksheet(SheetName_InvestmentData);
    worksheet.views = [ WorkSheet_View ];

    // 株価の更新日時
    ToExcel.set_cell_updatedAt(worksheet, 1, 1, stock_price_update_date);

    const FirstRow = 3;
    let current_col = 1;
    worksheet.getCell(FirstRow, current_col).value = t('セクター');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('銘柄名');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('株価');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('前日比');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('分配金利回り');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('予想分配金');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('時価総額');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('シェア');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('取得価格');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('シェア');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('鑑定評価額');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('含み益');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('物件数');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('築年数(投資)');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('稼働率');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('NOI利回り');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = 'Implied CR';
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('NAV/口数');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('NAV倍率');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('FFO/口数');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('FFO倍率');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('配当性向');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = 'DSCR';
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = 'LTV';
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = 'ROE';
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('発行済口数');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('格付');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('決算期');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('上場日');
    current_col++;
    worksheet.getCell(FirstRow, current_col).value = t('証券コード');
    
    for (let i=0; i<investmentData.length; i++) {
      const d = investmentData[i];
      const current_row = FirstRow + i + 1;

      // セクター
      current_col = 1;
      ToExcel.set_cell_sector(worksheet, current_row, current_col, d, t);
      // 銘柄名
      current_col++;
      ToExcel.set_cell_stock_name(worksheet, current_row, current_col, d, myLang);
      // 株価
      current_col++;
      ToExcel.set_cell_stock_price(worksheet, current_row, current_col, d);
      // 前日比
      current_col++;
      ToExcel.set_cell_stock_price_change(worksheet, current_row, current_col, d);
      // 分配金利回り
      current_col++;
      ToExcel.set_cell_dividend_yield(worksheet, current_row, current_col, d);
      // 予想分配金
      current_col++;
      ToExcel.set_cell_latest_forecast_dividend(worksheet, current_row, current_col, d);
      // 時価総額
      current_col++;
      ToExcel.set_cell_market_cap(worksheet, current_row, current_col, d);
      // 時価総額(シェア)
      current_col++;
      ToExcel.set_cell_market_cap_share(worksheet, current_row, current_col, d);
      // 取得価格
      current_col++;
      ToExcel.set_cell_purchase_price(worksheet, current_row, current_col, d);
      // 取得価格(シェア)
      current_col++;
      ToExcel.set_cell_purchase_price_share(worksheet, current_row, current_col, d);
      // 鑑定評価額
      current_col++;
      ToExcel.set_cell_appraisal_value(worksheet, current_row, current_col, d);
      // 鑑定評価額(含み益率)
      current_col++;
      ToExcel.set_cell_appraisal_gain_ratio(worksheet, current_row, current_col, d);
      // 物件数
      current_col++;
      ToExcel.set_cell_property_num(worksheet, current_row, current_col, d);
      // 築年数
      current_col++;
      ToExcel.set_cell_building_age(worksheet, current_row, current_col, d);
      // 稼働率
      current_col++;
      ToExcel.set_cell_occupancy(worksheet, current_row, current_col, d);
      // NOI利回り
      current_col++;
      ToExcel.set_cell_noi_yield(worksheet, current_row, current_col, d);    
      // Implied CR
      current_col++;
      ToExcel.set_cell_implied_cr(worksheet, current_row, current_col, d);
      // NAV/口数
      current_col++;
      ToExcel.set_cell_nav_per_unit(worksheet, current_row, current_col, d);
      // NAV倍率
      current_col++;
      ToExcel.set_cell_nav_ratio(worksheet, current_row, current_col, d);
      // FFO/口数
      current_col++;
      ToExcel.set_cell_ffo_per_unit(worksheet, current_row, current_col, d);
      // FFO倍率
      current_col++;
      ToExcel.set_cell_ffo_ratio(worksheet, current_row, current_col, d);
      // 配当性向
      current_col++;
      ToExcel.set_cell_payout_ratio(worksheet, current_row, current_col, d);
      // DSCR
      current_col++;
      ToExcel.set_cell_icr_dscr(worksheet, current_row, current_col, d);
      // LTV
      current_col++;
      ToExcel.set_cell_ltv(worksheet, current_row, current_col, d);
      // ROE
      current_col++;
      ToExcel.set_cell_roe(worksheet, current_row, current_col, d);
      // 発行済口数
      current_col++;
      ToExcel.set_cell_issued_investment(worksheet, current_row, current_col, d);
      // 格付
      current_col++;
      ToExcel.set_cell_rating(worksheet, current_row, current_col, d);
      // 決算期
      current_col++;
      ToExcel.set_cell_fiscal_period(worksheet, current_row, current_col, d, myLang);
      // 上場日
      current_col++;
      ToExcel.set_cell_listing_date(worksheet, current_row, current_col, d, myLang);
      // 証券コード
      current_col++;
      ToExcel.set_cell_stock_code(worksheet, current_row, current_col, d);
    }

    // セル幅の設定
    worksheet.getColumn(1).width = 16;
    worksheet.getColumn(2).width = 35;
    for (let i = 3; i < current_col + 1; i++) {
      worksheet.getColumn(i).width = 12;
    }

    // フォント設定
    for (let i = 1; i < current_col + 1; i++) {
      worksheet.getColumn(i).font = FontStyle;
    }

    await ToExcel.download_excel(ExcelFilename_InvestmentData, workbook);
  }

  static set_cell_updatedAt = (worksheet: any, row: number, col: number, stock_price_update_date: string) => {
    worksheet.getCell(row, col).value = stock_price_update_date;
  }

  static set_cell_sector = (worksheet: any, row: number, col: number, d: InvestmentDataType, t: any) => {
    worksheet.getCell(row, col).value = t(InvestmentDataUtil.convert_sector_name(d.sector));
  }

  static set_cell_stock_name = (worksheet: any, row: number, col: number, d: InvestmentDataType, myLang: string) => {
    let stock_name = '';
      switch (myLang) {
        case MyLanguage.JA: stock_name = d.stock_name; break;
        case MyLanguage.EN: stock_name = d.stock_name_en; break;
      }
      worksheet.getCell(row, col).value = stock_name;
  }

  static set_cell_stock_price = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.stock_price !== null) {
      worksheet.getCell(row, col).value = d.stock_price;
      worksheet.getCell(row, col).numFmt = '#,##0';
    }
  }

  static set_cell_stock_price_change = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.stock_price_change !== null) {
      worksheet.getCell(row, col).value = d.stock_price_change;
      worksheet.getCell(row, col).numFmt = '0.00%';
    }
  }

  static set_cell_dividend_yield = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.dividend_yield !== null) {
      worksheet.getCell(row, col).value = d.dividend_yield;
      worksheet.getCell(row, col).numFmt = '0.00%';
    }
  }

  static set_cell_latest_forecast_dividend = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.latest_forecast_dividend !== null) {
      worksheet.getCell(row, col).value = d.latest_forecast_dividend;
      worksheet.getCell(row, col).numFmt = '#,##0';
    }
  }

  static set_cell_market_cap = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.market_cap !== null) {
      worksheet.getCell(row, col).value = d.market_cap;
      worksheet.getCell(row, col).numFmt = '#,##0';
    }
  }

  static set_cell_market_cap_share = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.market_cap_share !== null) {
      worksheet.getCell(row, col).value = d.market_cap_share;
      worksheet.getCell(row, col).numFmt = '0.0%';
    }
  }

  static set_cell_purchase_price = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.purchase_price !== null) {
      worksheet.getCell(row, col).value = d.purchase_price;
      worksheet.getCell(row, col).numFmt = '#,##0';
    }
  }

  static set_cell_purchase_price_share = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.purchase_price_share !== null) {
      worksheet.getCell(row, col).value = d.purchase_price_share;
      worksheet.getCell(row, col).numFmt = '0.0%';
    }
  }

  static set_cell_appraisal_value = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.appraisal_value !== null) {
      worksheet.getCell(row, col).value = d.appraisal_value;
      worksheet.getCell(row, col).numFmt = '#,##0';
    }
  }

  static set_cell_appraisal_gain_ratio = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.appraisal_gain_ratio !== null) {
      worksheet.getCell(row, col).value = d.appraisal_gain_ratio;
      worksheet.getCell(row, col).numFmt = '0.0%';
    }
  }

  static set_cell_property_num = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.property_num !== null) {
      worksheet.getCell(row, col).value = d.property_num;
    }
  }

  static set_cell_building_age = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.building_age !== null) {
      worksheet.getCell(row, col).value = d.building_age;
      worksheet.getCell(row, col).numFmt = '#,##0.0';
    }
  }

  static set_cell_occupancy = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.occupancy !== null) {
      worksheet.getCell(row, col).value = d.occupancy;
      worksheet.getCell(row, col).numFmt = '0.0%';
    }
  }

  static set_cell_noi_yield = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.noi_yield !== null) {
      worksheet.getCell(row, col).value = d.noi_yield;
      worksheet.getCell(row, col).numFmt = '0.00%';
    }
  }

  static set_cell_implied_cr = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.implied_cr !== null) {
      worksheet.getCell(row, col).value = d.implied_cr;
      worksheet.getCell(row, col).numFmt = '0.00%';
    }
  }

  static set_cell_nav_per_unit = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.nav_per_unit !== null) {
      worksheet.getCell(row, col).value = d.nav_per_unit;
      worksheet.getCell(row, col).numFmt = '#,##0';
    }
  }

  static set_cell_nav_ratio = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.nav_ratio !== null) {
      worksheet.getCell(row, col).value = d.nav_ratio;
      worksheet.getCell(row, col).numFmt = '#,##0.00';
    }
  }

  static set_cell_ffo_per_unit = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.ffo_per_unit !== null) {
      worksheet.getCell(row, col).value = d.ffo_per_unit;
      worksheet.getCell(row, col).numFmt = '#,##0';
    }
  }

  static set_cell_ffo_ratio = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.ffo_ratio !== null) {
      worksheet.getCell(row, col).value = d.ffo_ratio;
      worksheet.getCell(row, col).numFmt = '#,##0.0';
    }
  }

  static set_cell_payout_ratio = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.payout_ratio !== null) {
      worksheet.getCell(row, col).value = d.payout_ratio;
      worksheet.getCell(row, col).numFmt = '0.0%';
    }
  }

  static set_cell_icr_dscr = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.icr_dscr !== null) {
      worksheet.getCell(row, col).value = d.icr_dscr;
      worksheet.getCell(row, col).numFmt = '#,##0.0';
    }
  }

  static set_cell_ltv = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.ltv !== null) {
      worksheet.getCell(row, col).value = d.ltv;
      worksheet.getCell(row, col).numFmt = '0.0%';
    }
  }

  static set_cell_roe = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.roe !== null) {
      worksheet.getCell(row, col).value = d.roe;
      worksheet.getCell(row, col).numFmt = '0.0%';
    }
  }

  static set_cell_issued_investment = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.issued_investment !== null) {
      worksheet.getCell(row, col).value = d.issued_investment;
      worksheet.getCell(row, col).numFmt = '#,##0';
    }
  }

  static set_cell_rating = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.rating !== null) {
      worksheet.getCell(row, col).value = InvestmentDataUtil.convert_rating(d.rating);
    }
  }

  static set_cell_fiscal_period = (worksheet: any, row: number, col: number, d: InvestmentDataType, myLang: string) => {
    if (d.fiscal_period !== null) {
      worksheet.getCell(row, col).value = InvestmentDataUtil.convert_fiscal_period(d.fiscal_period, myLang);
    }
  }

  static set_cell_listing_date = (worksheet: any, row: number, col: number, d: InvestmentDataType, myLang: string) => {
    if (d.listing_date !== null) {
      worksheet.getCell(row, col).value = CommonUtil.convert_YearMonth(d.listing_date, myLang);
    }
  }

  static set_cell_stock_code = (worksheet: any, row: number, col: number, d: InvestmentDataType) => {
    if (d.stock_code !== null) {
      worksheet.getCell(row, col).value = d.stock_code;
    }
  }

  static out_histInvestmentData = async (t: any, myLang: string, selected_data: InvestmentDataType, all_data: InvestmentDataType[], selected_histData: HistInvestmentDataType[], all_histData: HistInvestmentDataType[], start_i: number) => {
    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet(SheetName_InvestmentData);
    const worksheet = workbook.getWorksheet(SheetName_InvestmentData);
    worksheet.views = [ WorkSheet_View ];
    
    // 法人情報を設定
    ToExcel.set_cell_corporation(worksheet, 2, 2, selected_data, t, myLang);

    // ヒストリカルデータを設定
    ToExcel.set_cell_hist_investment(worksheet, 2, 5, selected_data, all_data, selected_histData, all_histData, start_i, t, myLang);

    await ToExcel.download_excel(ExcelFilename_InvestmentData, workbook);
  }

  static set_cell_corporation = (worksheet: any, start_row: number, start_col: number, selected_data: InvestmentDataType, t: any, myLang: string) => {

    // セルの幅を設定
    worksheet.getColumn(start_col).width = 35;
    worksheet.getColumn(start_col + 1).width = 35;

    let current_row = start_row;

    // データ更新日
    ToExcel.set_cell_data_update_date(worksheet, start_row, start_col, selected_data, myLang);

    // 投資法人名
    current_row++;
    worksheet.getCell(current_row, start_col).value = t('投資法人名');
    ToExcel.set_cell_investment_corporation(worksheet, current_row, start_col + 1, selected_data, t, myLang);

    // セクター
    current_row++;
    worksheet.getCell(current_row, start_col).value = t('セクター');
    ToExcel.set_cell_sector(worksheet, current_row, start_col + 1, selected_data, t);

    // 物件数
    current_row++;
    worksheet.getCell(current_row, start_col).value = t('物件数');
    ToExcel.set_cell_property_num(worksheet, current_row, start_col + 1, selected_data);

    // 築年数
    current_row++;
    worksheet.getCell(current_row, start_col).value = t('築年数(投資)');
    ToExcel.set_cell_building_age_with_unit(worksheet, current_row, start_col + 1, selected_data, myLang);

    // 取得価格合計
    current_row++;
    worksheet.getCell(current_row, start_col).value = t('取得価格合計');
    let purchase_price = selected_data.purchase_price;
    if (purchase_price !== null) {
      let text = '';
      switch (myLang) {
        case MyLanguage.JA:
          text = purchase_price.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + ' 億円';
          break;
        case MyLanguage.EN:
          text = (purchase_price / 10).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + 'bn';
          break;
      }
      worksheet.getCell(current_row, start_col + 1).value = text;
    }

    // 鑑定評価額（時価）
    current_row++;
    worksheet.getCell(current_row, start_col).value = t('鑑定評価額（時価）');
    let appraisal_value = selected_data.appraisal_value;
    if (appraisal_value !== null) {
      let text = '';
      switch (myLang) {
        case MyLanguage.JA:
          text = appraisal_value.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + ' 億円';
          break;
        case MyLanguage.EN:
          text = (appraisal_value / 10).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + 'bn';
          break;
      }
      worksheet.getCell(current_row, start_col + 1).value = text;
    }

    // 発行済口数
    current_row++;
    worksheet.getCell(current_row, start_col).value = t('発行済口数');
    let issued_investment = selected_data.issued_investment;
    if (issued_investment !== null) {
      let text = issued_investment.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
      switch (myLang) {
        case MyLanguage.JA: {
          text = `${text} 口`;
          break;
        }
        case MyLanguage.EN: {
          text = `${text} shares`;
          break;
        }
      }
      worksheet.getCell(current_row, start_col + 1).value = text;
    }

    // 決算期
    current_row++;
    worksheet.getCell(current_row, start_col).value = t('決算期');
    ToExcel.set_cell_fiscal_period(worksheet, current_row, start_col + 1, selected_data, myLang);

    // 上場日
    current_row++;
    worksheet.getCell(current_row, start_col).value = t('上場日');
    ToExcel.set_cell_listing_date(worksheet, current_row, start_col + 1, selected_data, myLang);

    // 資産運用会社
    current_row++;
    worksheet.getCell(current_row, start_col).value = t('資産運用会社');
    ToExcel.set_cell_asset_management_company_name(worksheet, current_row, start_col + 1, selected_data, myLang);

    // スポンサー企業
    current_row++;
    worksheet.getCell(current_row, start_col).value = t('スポンサー企業');
    ToExcel.set_cell_sponsor(worksheet, current_row, start_col + 1, selected_data, myLang);

    // 証券コード
    current_row++;
    worksheet.getCell(current_row, start_col).value = t('証券コード');
    ToExcel.set_cell_stock_code(worksheet, current_row, start_col + 1, selected_data);

    for (let i=start_row + 1; i<(current_row + 1); i++) {
      // テキストを上下中央寄せにし、折り返しありにする。
      worksheet.getRow(i).getCell(start_col).alignment = { vertical: 'middle', wrapText: true };
      // テキストを上下左右中央寄せにし、折り返しありにする。
      worksheet.getRow(i).getCell(start_col + 1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

      // 罫線を引く。
      worksheet.getRow(i).getCell(start_col).border = BorderStyle;
      worksheet.getRow(i).getCell(start_col + 1).border = BorderStyle;
    }

    // フォントを設定
    for (let i = 1; i < start_col + 1 + 1; i++) {
      worksheet.getColumn(i).font = FontStyle;
    }
  }

  static set_cell_hist_investment = (worksheet: any, start_row: number, start_col: number, selected_data: InvestmentDataType, all_data: InvestmentDataType[], selected_histData: HistInvestmentDataType[], all_histData: HistInvestmentDataType[], start_i: number, t: any, myLang: string) => {
    let current_row = start_row;

    // セルの幅を設定
    worksheet.getColumn(start_col).width = 24;
    for (let i = start_col + 1; i < (selected_histData.length - start_i + start_col + 1); i++) {
      worksheet.getColumn(i).width = 12;
    }

    // 期
    ToExcel.set_cells_period(worksheet, current_row, start_col + 1, selected_histData, start_i);

    // 取得価格
    current_row++;
    ToExcel.set_cells_billion_value(worksheet, current_row, start_col, selected_histData, start_i, t('取得価格'), (hist_d) => { return hist_d.purchase_price; }, '#,##0', myLang);

    // 物件数
    current_row++;
    ToExcel.set_cells_numerical_value(worksheet, current_row, start_col, selected_histData, start_i, t('物件数'), (hist_d) => { return hist_d.property_num; }, '#,##0');

    // 築年数
    current_row++;
    ToExcel.set_cells_numerical_value(worksheet, current_row, start_col, selected_histData, start_i, t('築年数(投資)'), (hist_d) => { return hist_d.building_age; }, '#,##0.0');

    // 鑑定評価額
    current_row++;
    ToExcel.set_cells_billion_value(worksheet, current_row, start_col, selected_histData, start_i, t('鑑定評価額(投資)'), (hist_d) => { return hist_d.appraisal_value; }, '#,##0', myLang);

    // 簿価
    current_row++;
    ToExcel.set_cells_billion_value(worksheet, current_row, start_col, selected_histData, start_i, t('簿価'), (hist_d) => { return hist_d.book_value; }, '#,##0', myLang);
    
    // 含み益
    current_row++;
    ToExcel.set_cells_billion_value(worksheet, current_row, start_col, selected_histData, start_i, t('含み益'), (hist_d) => { return hist_d.appraisal_gain; }, '#,##0', myLang);

    // 含み益率
    current_row++;
    ToExcel.set_cells_numerical_value(worksheet, current_row, start_col, selected_histData, start_i, t('含み益率'), (hist_d) => { return hist_d.appraisal_gain_ratio; }, '0.0%');

    // 含み益率(REIT平均)
    current_row++;
    ToExcel.set_cells_average(worksheet, current_row, start_col, selected_histData, all_histData, start_i, t('含み益率(平均)'), (hist_d) => { return hist_d.appraisal_gain_ratio; }, '0.0%')

    // 株価
    current_row++;
    ToExcel.set_cells_numerical_value(worksheet, current_row, start_col, selected_histData, start_i, t('株価'), (hist_d) => { return hist_d.stock_price; }, '#,##0');

    // 時価総額
    current_row++;
    ToExcel.set_cells_billion_value(worksheet, current_row, start_col, selected_histData, start_i, t('時価総額'), (hist_d) => { return hist_d.market_cap; }, '#,##0', myLang);

    // 分配金
    current_row++;
    ToExcel.set_cells_numerical_value(worksheet, current_row, start_col, selected_histData, start_i, t('分配金'), (hist_d) => { return hist_d.dividend; }, '#,##0');

    // 分配金利回り
    current_row++;
    ToExcel.set_cells_numerical_value(worksheet, current_row, start_col, selected_histData, start_i, t('分配金利回り'), (hist_d) => { return hist_d.dividend_yield; }, '0.00%');
    
    // 分配金利回り(REIT平均)
    current_row++;
    ToExcel.set_cells_average(worksheet, current_row, start_col, selected_histData, all_histData, start_i, t('分配金利回り(平均)'), (hist_d) => { return hist_d.dividend_yield; }, '0.00%')

    // 稼働率
    current_row++;
    ToExcel.set_cells_numerical_value(worksheet, current_row, start_col, selected_histData, start_i, t('稼働率'), (hist_d) => { return hist_d.occupancy; }, '0.0%');

    // 稼働率(REIT平均)
    current_row++;
    ToExcel.set_cells_average(worksheet, current_row, start_col, selected_histData, all_histData, start_i, t('稼働率(平均)'), (hist_d) => { return hist_d.occupancy; }, '0.0%')

    // NOI利回り
    current_row++;
    ToExcel.set_cells_numerical_value(worksheet, current_row, start_col, selected_histData, start_i, t('NOI利回り'), (hist_d) => { return hist_d.noi_yield; }, '0.00%');

    // NOI利回り(REIT平均)
    current_row++;
    ToExcel.set_cells_average(worksheet, current_row, start_col, selected_histData, all_histData, start_i, t('NOI利回り(平均)'), (hist_d) => { return hist_d.noi_yield; }, '0.00%')

    // Implied CR
    current_row++;
    ToExcel.set_cells_numerical_value(worksheet, current_row, start_col, selected_histData, start_i, 'Implied CR', (hist_d) => { return hist_d.implied_cr; }, '0.00%');

    // Implied CR(REIT平均)
    current_row++;
    ToExcel.set_cells_average(worksheet, current_row, start_col, selected_histData, all_histData, start_i, t('Implied CR(平均)'), (hist_d) => { return hist_d.implied_cr; }, '0.00%')

    // Implied CR(セクター平均)
    current_row++;
    ToExcel.set_cells_average_by_sector(worksheet, current_row, start_col, selected_data.sector, all_data, selected_histData, all_histData, start_i, t('Implied CR(セクター平均)', { sector: t(InvestmentDataUtil.convert_sector_name(selected_data.sector)) }), (hist_d) => { return hist_d.implied_cr; }, '0.00%')

    // NAV倍率
    current_row++;
    ToExcel.set_cells_numerical_value(worksheet, current_row, start_col, selected_histData, start_i, t('NAV倍率'), (hist_d) => { return hist_d.nav_ratio; }, '0.00');

    // NAV倍率(REIT平均)
    current_row++;
    ToExcel.set_cells_average(worksheet, current_row, start_col, selected_histData, all_histData, start_i, t('NAV倍率(平均)'), (hist_d) => { return hist_d.nav_ratio; }, '0.00')

    // FFO倍率
    current_row++;
    ToExcel.set_cells_numerical_value(worksheet, current_row, start_col, selected_histData, start_i, t('FFO倍率'), (hist_d) => { return hist_d.ffo_ratio; }, '0.0');

    // FFO倍率(REIT平均)
    current_row++;
    ToExcel.set_cells_average(worksheet, current_row, start_col, selected_histData, all_histData, start_i, t('FFO倍率(平均)'), (hist_d) => { return hist_d.ffo_ratio; }, '0.0')

    // 配当性向
    current_row++;
    ToExcel.set_cells_numerical_value(worksheet, current_row, start_col, selected_histData, start_i, t('配当性向'), (hist_d) => { return hist_d.payout_ratio; }, '0.0%');

    // 配当性向(REIT平均)
    current_row++;
    ToExcel.set_cells_average(worksheet, current_row, start_col, selected_histData, all_histData, start_i, t('配当性向(平均)'), (hist_d) => { return hist_d.payout_ratio; }, '0.0%')

    // ROE
    current_row++;
    ToExcel.set_cells_numerical_value(worksheet, current_row, start_col, selected_histData, start_i, 'ROE', (hist_d) => { return hist_d.roe; }, '0.0%');

    // ROE(REIT平均)
    current_row++;
    ToExcel.set_cells_average(worksheet, current_row, start_col, selected_histData, all_histData, start_i, t('ROE(平均)'), (hist_d) => { return hist_d.roe; }, '0.0%')

    // LTV
    current_row++;
    ToExcel.set_cells_numerical_value(worksheet, current_row, start_col, selected_histData, start_i, 'LTV', (hist_d) => { return hist_d.ltv; }, '0.0%');

    // LTV(REIT平均)
    current_row++;
    ToExcel.set_cells_average(worksheet, current_row, start_col, selected_histData, all_histData, start_i, t('LTV(平均)'), (hist_d) => { return hist_d.ltv; }, '0.0%')

    // DSCR
    current_row++;
    ToExcel.set_cells_numerical_value(worksheet, current_row, start_col, selected_histData, start_i, 'DSCR', (hist_d) => { return hist_d.icr_dscr; }, '0.0');

    // DSCR(REIT平均)
    current_row++;
    ToExcel.set_cells_average(worksheet, current_row, start_col, selected_histData, all_histData, start_i, t('DSCR(平均)'), (hist_d) => { return hist_d.icr_dscr; }, '0.0')

    // 罫線を引く。
    for (let i = start_row; i < current_row + 1; i++) {
      for (let j = 0; j < (selected_histData.length - start_i + 1); j++) {
        worksheet.getRow(i).getCell(start_col + j).border = BorderStyle;
      }
    }

    // 2行空ける。
    current_row += 3;

    // 投資エリア
    ToExcel.set_cell_investment_area(worksheet, current_row, start_col, selected_data, t);

    // 物件タイプ
    ToExcel.set_cell_property_type(worksheet, current_row, start_col + 3, selected_data, t);

    // 経費率
    ToExcel.set_cell_expense_ratio(worksheet, current_row, start_col + 6, selected_data, all_data, t);

    // 経費項目
    ToExcel.set_cell_expense_item(worksheet, current_row, start_col + 9, selected_data, all_data, t);

    // 運用会社及び関連会社経費
    ToExcel.set_cell_management_company_and_related_party_expense(worksheet, current_row, start_col + 12, selected_data, all_data, t);

    // ソーシング
    ToExcel.set_cell_sourcing(worksheet, current_row, start_col + 17, selected_data, all_data, t);

    // 負債比率
    ToExcel.set_cell_debt_ratio(worksheet, current_row, start_col + 21, selected_data, all_data, t);

    // 投資主構成
    ToExcel.set_cell_investor_composition(worksheet, current_row, start_col + 25, selected_data, all_data, t);

    // フォントを設定
    for (let i = 1; i < start_col + 25 + 2; i++) {
      worksheet.getColumn(i).font = FontStyle;
    }
  }

  static set_cell_data_update_date = (worksheet: any, row: number, col: number, selected_data: InvestmentDataType, myLang: string) => {
    const data_update_date = CommonUtil.convert_YearMonthDay_with_slash(selected_data.data_update_date, myLang);
    let text = '';
    switch (myLang) {
      case MyLanguage.JA:
        text = `${data_update_date} 時点`;
        break;
      case MyLanguage.EN:
        text = `As of ${data_update_date}`;
        break;
    }
    worksheet.getCell(row, col).value = text;
  }

  static set_cell_investment_corporation = (worksheet: any, row: number, col: number, selected_data: InvestmentDataType, t: any, myLang: string) => {
    let investment_company_name = '';
    switch (myLang) {
      case MyLanguage.JA: investment_company_name = selected_data.investment_company_name; break;
      case MyLanguage.EN: investment_company_name = selected_data.investment_company_name_en; break;
    }
    worksheet.getCell(row, col).value = investment_company_name;
  }

  static set_cell_building_age_with_unit = (worksheet: any, row: number, col: number, selected_data: InvestmentDataType, myLang: string) => {
    if (selected_data.building_age !== null) {
      let text = selected_data.building_age.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1});
      switch (myLang) {
        case MyLanguage.JA: text = text + ' 年'; break;
        case MyLanguage.EN: text = text + ' years'; break;
      }  
      worksheet.getCell(row, col).value = text;
    }
  }

  static set_cell_asset_management_company_name = (worksheet: any, row: number, col: number, selected_data: InvestmentDataType, myLang: string) => {
    let asset_management_company_name = '';
    switch (myLang) {
      case MyLanguage.JA: asset_management_company_name = selected_data.asset_management_company_name; break;
      case MyLanguage.EN: asset_management_company_name = selected_data.asset_management_company_name_en; break;
    }
    worksheet.getCell(row, col).value = asset_management_company_name;
  }

  static set_cell_sponsor = (worksheet: any, row: number, col: number, selected_data: InvestmentDataType, myLang: string) => {
    let sponsors = '';
    switch (myLang) {
      case MyLanguage.JA: {
        if (selected_data.sponsor !== null) sponsors = selected_data.sponsor;
        break;
      }
      case MyLanguage.EN: {
        if (selected_data.sponsor_en !== null) sponsors = selected_data.sponsor_en;
        break;
      }
    }

    sponsors = sponsors.replaceAll('|', '\n');
    worksheet.getCell(row, col).value = sponsors;
    // 折り返す。
    worksheet.getCell(row, col).alignment = { wrapText: true };
  }

  static set_cells_period = (worksheet: any, row: number, col: number, selected_histData: HistInvestmentDataType[], start_i: number) => {
    for (let i=start_i; i<selected_histData.length; i++) {
      const period = selected_histData[i].period;
      const year = period.slice(2, 4);
      const semester = period.slice(4);
      worksheet.getCell(row, col).value = `${semester}-${year}`;
      col++;
    }
  }

  static set_cells_billion_value = (worksheet: any, row: number, col: number, selected_histData: HistInvestmentDataType[], start_i: number, headword: string, callback: (hist_d: HistInvestmentDataType) => number | null, numFmt: string, myLang: string) => {
    worksheet.getCell(row, col).value = headword;
    col++;
    for (let i=start_i; i<selected_histData.length; i++) {
      const hist_d = selected_histData[i];
      let value = callback(hist_d);
      if (value !== null) {
        switch (myLang) {
          // 英語版では、billionで表現するため、10で割る。
          case MyLanguage.EN: value /= 10; break;
        }
        worksheet.getCell(row, col).value = value;
        worksheet.getCell(row, col).numFmt = numFmt;
      }
      col++;
    }
  }

  static set_cells_numerical_value = (worksheet: any, row: number, col: number, selected_histData: HistInvestmentDataType[], start_i: number, headword: string, callback: (hist_d: HistInvestmentDataType) => number | null, numFmt: string) => {
    worksheet.getCell(row, col).value = headword;
    col++;
    for (let i=start_i; i<selected_histData.length; i++) {
      const hist_d = selected_histData[i];
      let value = callback(hist_d);
      if (value !== null) {
        worksheet.getCell(row, col).value = value;
        worksheet.getCell(row, col).numFmt = numFmt;
      }
      col++;
    }
  }

  static set_cells_average = (worksheet: any, row: number, col: number, selected_histData: HistInvestmentDataType[], all_histData: HistInvestmentDataType[], start_i: number, headword: string, callback: (hist_d: HistInvestmentDataType) => number | null, numFmt: string) => {
    worksheet.getCell(row, col).value = headword;
    col++;
    for (let i=start_i; i<selected_histData.length; i++) {
      const hist_d = selected_histData[i];
      const period = hist_d.period;
      let ave = HistInvestmentDataUtil.calc_average(all_histData, period, callback);
      if (ave !== null) {
        worksheet.getCell(row, col).value = ave;
        worksheet.getCell(row, col).numFmt = numFmt;
      }
      col++;
    }
  }

  static set_cells_average_by_sector = (worksheet: any, row: number, col: number, selected_sector: number, all_data: InvestmentDataType[], selected_histData: HistInvestmentDataType[], all_histData: HistInvestmentDataType[], start_i: number, headword: string, callback: (hist_d: HistInvestmentDataType) => number | null, numFmt: string) => {
    worksheet.getCell(row, col).value = headword;
    col++;
    for (let i=start_i; i<selected_histData.length; i++) {
      const hist_d = selected_histData[i];
      const period = hist_d.period;
      let ave = HistInvestmentDataUtil.calc_average_by_sector(all_data, all_histData, period, selected_sector, callback);
      if (ave !== null) {
        worksheet.getCell(row, col).value = ave;
        worksheet.getCell(row, col).numFmt = numFmt;
      }
      col++;
    }
  }

  static set_cell_investment_area = (worksheet: any, start_row: number, start_col: number, selected_data: InvestmentDataType, t: any) => {
    let current_row = start_row;

    worksheet.getCell(current_row, start_col).value = t('投資エリア');

    const set_cell = (row: number, col: number, headword: string, selected_data: InvestmentDataType, callback: (d: InvestmentDataType) => number) => {
      const value = callback(selected_data);
      worksheet.getCell(row, col).value = headword;
      worksheet.getCell(row, col + 1).value = value;
      worksheet.getCell(row, col + 1).numFmt = '0.0%';
    };

    if (selected_data.tokyo_5_main_ward !== null) {
      current_row++;
      set_cell(current_row, start_col, t('都心5区'), selected_data, (d) => { return d.tokyo_5_main_ward});
    }
    if (selected_data.tokyo_23_ward !== null) {
      current_row++;
      set_cell(current_row, start_col, t('東京23区'), selected_data, (d) => { return d.tokyo_23_ward});
    }
    if (selected_data.tokyo_area !== null) {
      current_row++;
      set_cell(current_row, start_col, t('東京圏'), selected_data, (d) => { return d.tokyo_area});
    }
    if (selected_data.osaka_area !== null) {
      current_row++;
      set_cell(current_row, start_col, t('大阪圏'), selected_data, (d) => { return d.osaka_area});
    }
    if (selected_data.nagoya_area !== null) {
      current_row++;
      set_cell(current_row, start_col, t('名古屋圏'), selected_data, (d) => { return d.nagoya_area});
    }
    if (selected_data.sapporo_city !== null) {
      current_row++;
      set_cell(current_row, start_col, t('札幌市'), selected_data, (d) => { return d.sapporo_city});
    }
    if (selected_data.fukuoka_city !== null) {
      current_row++;
      set_cell(current_row, start_col, t('福岡市'), selected_data, (d) => { return d.fukuoka_city});
    }
    if (selected_data.others_area !== null) {
      current_row++;
      set_cell(current_row, start_col, t('その他'), selected_data, (d) => { return d.others_area});
    }

    for (let i=(start_row + 1); i<(current_row + 1); i++) {
      // 罫線を引く。
      worksheet.getRow(i).getCell(start_col).border = BorderStyle;
      worksheet.getRow(i).getCell(start_col + 1).border = BorderStyle;
    }
  }

  static set_cell_property_type = (worksheet: any, start_row: number, start_col: number, selected_data: InvestmentDataType, t: any) => {
    let current_row = start_row;

    worksheet.getCell(current_row, start_col).value = t('物件タイプ');

    const set_cell = (row: number, col: number, headword: string, selected_data: InvestmentDataType, callback: (d: InvestmentDataType) => number) => {
      const value = callback(selected_data);
      worksheet.getCell(row, col).value = headword;
      worksheet.getCell(row, col + 1).value = value;
      worksheet.getCell(row, col + 1).numFmt = '0.0%';
    };

    if (selected_data.property_ratio.super_high_office !== null) {
      current_row++;
      set_cell(current_row, start_col, t('超高層オフィス'), selected_data, (d) => { return d.property_ratio.super_high_office});
    }
    if (selected_data.property_ratio.high_office !== null) {
      current_row++;
      set_cell(current_row, start_col, t('高層オフィス'), selected_data, (d) => { return d.property_ratio.high_office});
    }
    if (selected_data.property_ratio.middle_office !== null) {
      current_row++;
      set_cell(current_row, start_col, t('中低層オフィス'), selected_data, (d) => { return d.property_ratio.middle_office});
    }
    if (selected_data.property_ratio.super_high_resi !== null) {
      current_row++;
      set_cell(current_row, start_col, t('超高層レジ'), selected_data, (d) => { return d.property_ratio.super_high_resi});
    }
    if (selected_data.property_ratio.high_resi !== null) {
      current_row++;
      set_cell(current_row, start_col, t('高層レジ'), selected_data, (d) => { return d.property_ratio.high_resi});
    }
    if (selected_data.property_ratio.middle_resi !== null) {
      current_row++;
      set_cell(current_row, start_col, t('中低層レジ'), selected_data, (d) => { return d.property_ratio.middle_resi});
    }
    if (selected_data.property_ratio.large_senior_resi !== null) {
      current_row++;
      set_cell(current_row, start_col, t('大型シニアレジ'), selected_data, (d) => { return d.property_ratio.large_senior_resi});
    }
    if (selected_data.property_ratio.middle_senior_resi !== null) {
      current_row++;
      set_cell(current_row, start_col, t('中型シニアレジ'), selected_data, (d) => { return d.property_ratio.middle_senior_resi});
    }
    if (selected_data.property_ratio.urban_retail !== null) {
      current_row++;
      set_cell(current_row, start_col, t('都心型リテール'), selected_data, (d) => { return d.property_ratio.urban_retail});
    }
    if (selected_data.property_ratio.suburban_retail !== null) {
      current_row++;
      set_cell(current_row, start_col, t('郊外型リテール'), selected_data, (d) => { return d.property_ratio.suburban_retail});
    }
    if (selected_data.property_ratio.large_log !== null) {
      current_row++;
      set_cell(current_row, start_col, t('大型ロジ'), selected_data, (d) => { return d.property_ratio.large_log});
    }
    if (selected_data.property_ratio.middle_log !== null) {
      current_row++;
      set_cell(current_row, start_col, t('中型ロジ'), selected_data, (d) => { return d.property_ratio.middle_log});
    }
    if (selected_data.property_ratio.factory !== null) {
      current_row++;
      set_cell(current_row, start_col, t('工場・インフラ施設(物件タイプ)'), selected_data, (d) => { return d.property_ratio.factory});
    }
    if (selected_data.property_ratio.large_hotel !== null) {
      current_row++;
      set_cell(current_row, start_col, t('大型ホテル'), selected_data, (d) => { return d.property_ratio.large_hotel});
    }
    if (selected_data.property_ratio.middle_hotel !== null) {
      current_row++;
      set_cell(current_row, start_col, t('中型ホテル'), selected_data, (d) => { return d.property_ratio.middle_hotel});
    }
    if (selected_data.property_ratio.others_property !== null) {
      current_row++;
      set_cell(current_row, start_col, t('その他'), selected_data, (d) => { return d.property_ratio.others_property});
    }

    for (let i=(start_row + 1); i<(current_row + 1); i++) {
      // 罫線を引く。
      worksheet.getRow(i).getCell(start_col).border = BorderStyle;
      worksheet.getRow(i).getCell(start_col + 1).border = BorderStyle;
    }
  }

  static set_cell_expense_ratio = (worksheet: any, start_row: number, start_col: number, selected_data: InvestmentDataType, all_data: InvestmentDataType[], t: any) => {
    let current_row = start_row;

    worksheet.getCell(current_row, start_col).value = t('経費率');

    if (selected_data.exp.expense_ratio !== null) {
      current_row++;
      worksheet.getCell(current_row, start_col).value = t('経費率');
      worksheet.getCell(current_row, start_col + 1).value = selected_data.exp.expense_ratio;
      worksheet.getCell(current_row, start_col + 1).numFmt = '0.0%';
    }

    // REIT平均
    const expense_ratio_ave = InvestmentDataUtil.calc_average(all_data, (d) => { return d.exp.expense_ratio; });
    if (expense_ratio_ave !== null) {
      current_row++;
      worksheet.getCell(current_row, start_col).value = t('REIT平均');
      worksheet.getCell(current_row, start_col + 1).value = expense_ratio_ave;
      worksheet.getCell(current_row, start_col + 1).numFmt = '0.0%';
    }

    for (let i=(start_row + 1); i<(current_row + 1); i++) {
      // 罫線を引く。
      worksheet.getRow(i).getCell(start_col).border = BorderStyle;
      worksheet.getRow(i).getCell(start_col + 1).border = BorderStyle;
    }
  }

  static set_cell_expense_item = (worksheet: any, start_row: number, start_col: number, selected_data: InvestmentDataType, all_data: InvestmentDataType[], t: any) => {
    let current_row = start_row;

    worksheet.getCell(current_row, start_col).value = t('経費項目');

    const set_cell = (row: number, col: number, headword: string, selected_data: InvestmentDataType, callback: (d: InvestmentDataType) => number) => {
      const value = callback(selected_data);
      worksheet.getCell(row, col).value = headword;
      worksheet.getCell(row, col + 1).value = value;
      worksheet.getCell(row, col + 1).numFmt = '0.0%';
    };

    if (selected_data.exp.pm_bm !== null) {
      current_row++;
      set_cell(current_row, start_col, t('外注委託費'), selected_data, (e) => { return selected_data.exp.pm_bm; });
    }
    if (selected_data.exp.bm !== null) {
      current_row++;
      set_cell(current_row, start_col, t('建物管理委託'), selected_data, (e) => { return selected_data.exp.bm; });
    }
    if (selected_data.exp.pm !== null) {
      current_row++;
      set_cell(current_row, start_col, t('マネジメント報酬'), selected_data, (e) => { return selected_data.exp.pm; });
    }
    if (selected_data.exp.utility !== null) {
      current_row++;
      set_cell(current_row, start_col, t('水光熱費'), selected_data, (e) => { return selected_data.exp.utility; });
    }
    if (selected_data.exp.repair !== null) {
      current_row++;
      set_cell(current_row, start_col, t('修繕費'), selected_data, (e) => { return selected_data.exp.repair; });
    }
    if (selected_data.exp.tax !== null) {
      current_row++;
      set_cell(current_row, start_col, t('公租公課'), selected_data, (e) => { return selected_data.exp.tax; });
    }
    if (selected_data.exp.insurance !== null) {
      current_row++;
      set_cell(current_row, start_col, t('保険料'), selected_data, (e) => { return selected_data.exp.insurance; });
    }
    if (selected_data.exp.brokerage !== null) {
      current_row++;
      set_cell(current_row, start_col, t('仲介手数料'), selected_data, (e) => { return selected_data.exp.brokerage; });
    }
    if (selected_data.exp.trust !== null) {
      current_row++;
      set_cell(current_row, start_col, t('信託報酬'), selected_data, (e) => { return selected_data.exp.trust; });
    }
    if (selected_data.exp.others_exp !== null) {
      current_row++;
      set_cell(current_row, start_col, t('その他経費'), selected_data, (e) => { return selected_data.exp.others_exp; });
    }

    for (let i=(start_row + 1); i<(current_row + 1); i++) {
      // 罫線を引く。
      worksheet.getRow(i).getCell(start_col).border = BorderStyle;
      worksheet.getRow(i).getCell(start_col + 1).border = BorderStyle;
    }
  }

  static set_cell_management_company_and_related_party_expense = (worksheet: any, start_row: number, start_col: number, selected_data: InvestmentDataType, all_data: InvestmentDataType[], t: any) => {
    let current_row = start_row;

    // REIT平均
    let am_fee_reit_ave = InvestmentDataUtil.calc_average(all_data, (d) => { return d.fee.am_fee });
    let related_party_exp_ratio_reit_ave = InvestmentDataUtil.calc_average(all_data, (d) => { return d.fee.related_party_exp_ratio });
    let am_fee_pp_reit_ave = InvestmentDataUtil.calc_average(all_data, (d) => { return d.fee.am_fee_pp });

    const set_cell = (worksheet: any, row: number, col: number, headword: string, value1: number | null, value2: number | null, value3: number | null) => {
      worksheet.getCell(row, col).value = headword;
      if (value1 !== null) {
        worksheet.getCell(row, col + 1).value = value1;
        worksheet.getCell(row, col + 1).numFmt = '0.0%';  
      }
      if (value2 !== null) {
        worksheet.getCell(row, col + 2).value = value2;
        worksheet.getCell(row, col + 2).numFmt = '0.0%';  
      }
      if (value3 !== null) {
        worksheet.getCell(row, col + 3).value = value3;
        worksheet.getCell(row, col + 3).numFmt = '0.0%';  
      }
    };

    worksheet.getCell(current_row, start_col).value = t('運用会社及び関連会社経費');

    current_row++;
    worksheet.getCell(current_row, start_col + 1).value = t('運用報酬割合');
    worksheet.getCell(current_row, start_col + 2).value = t('関連会社費用割合');
    worksheet.getCell(current_row, start_col + 3).value = t('取得価格割合');

    current_row++;
    set_cell(worksheet, current_row, start_col, t('本投資法人'), selected_data.fee.am_fee, selected_data.fee.related_party_exp_ratio, selected_data.fee.am_fee_pp);
    
    current_row++;
    set_cell(worksheet, current_row, start_col, t('REIT平均'), am_fee_reit_ave, related_party_exp_ratio_reit_ave, am_fee_pp_reit_ave);

    // 罫線を引く。
    for (let i=(start_row + 1); i<(current_row + 1); i++) {
      for (let j=start_col; j<start_col+4; j++) {
        worksheet.getRow(i).getCell(j).border = BorderStyle;
      }
    }
  }

  static set_cell_sourcing = (worksheet: any, start_row: number, start_col: number, selected_data: InvestmentDataType, all_data: InvestmentDataType[], t: any) => {
    let current_row = start_row;

    // REIT平均
    let related_party_ave = InvestmentDataUtil.calc_average(all_data, (d) => { return d.sourcing.related_party });
    let non_related_party_ave = InvestmentDataUtil.calc_average(all_data, (d) => { return d.sourcing.non_related_party });
    
    const set_cell = (worksheet: any, row: number, col: number, headword: string, value1: number | null, value2: number | null) => {
      worksheet.getCell(row, col).value = headword;
      if (value1 !== null) {
        worksheet.getCell(row, col + 1).value = value1;
        worksheet.getCell(row, col + 1).numFmt = '0.0%';  
      }
      if (value2 !== null) {
        worksheet.getCell(row, col + 2).value = value2;
        worksheet.getCell(row, col + 2).numFmt = '0.0%';  
      }
    };

    worksheet.getCell(current_row, start_col).value = t('ソーシング');

    current_row++;
    worksheet.getCell(current_row, start_col + 1).value = t('関連会社');
    worksheet.getCell(current_row, start_col + 2).value = t('非関連会社');

    current_row++;
    set_cell(worksheet, current_row, start_col, t('本投資法人'), selected_data.sourcing.related_party, selected_data.sourcing.non_related_party);
    
    current_row++;
    set_cell(worksheet, current_row, start_col, t('REIT平均'), related_party_ave, non_related_party_ave);

    // 罫線を引く。
    for (let i=(start_row + 1); i<(current_row + 1); i++) {
      for (let j=start_col; j<start_col+3; j++) {
        worksheet.getRow(i).getCell(j).border = BorderStyle;
      }
    }
  }

  static set_cell_debt_ratio = (worksheet: any, start_row: number, start_col: number, selected_data: InvestmentDataType, all_data: InvestmentDataType[], t: any) => {
    let current_row = start_row;

    // REIT平均
    let long_term_debt_ave = InvestmentDataUtil.calc_average(all_data, (d) => { return d.debt.long_term_debt });
    let short_term_debt_ave = InvestmentDataUtil.calc_average(all_data, (d) => { return d.debt.short_term_debt });
    
    const set_cell = (worksheet: any, row: number, col: number, headword: string, value1: number | null, value2: number | null) => {
      worksheet.getCell(row, col).value = headword;
      if (value1 !== null) {
        worksheet.getCell(row, col + 1).value = value1;
        worksheet.getCell(row, col + 1).numFmt = '0.0%';  
      }
      if (value2 !== null) {
        worksheet.getCell(row, col + 2).value = value2;
        worksheet.getCell(row, col + 2).numFmt = '0.0%';  
      }
    };

    worksheet.getCell(current_row, start_col).value = t('負債比率');

    current_row++;
    worksheet.getCell(current_row, start_col + 1).value = t('長期借入');
    worksheet.getCell(current_row, start_col + 2).value = t('短期借入');

    current_row++;
    set_cell(worksheet, current_row, start_col, t('本投資法人'), selected_data.debt.long_term_debt, selected_data.debt.short_term_debt);
    
    current_row++;
    set_cell(worksheet, current_row, start_col, t('REIT平均'), long_term_debt_ave, short_term_debt_ave);

    // 罫線を引く。
    for (let i=(start_row + 1); i<(current_row + 1); i++) {
      for (let j=start_col; j<start_col+3; j++) {
        worksheet.getRow(i).getCell(j).border = BorderStyle;
      }
    }
  }

  static set_cell_investor_composition = (worksheet: any, start_row: number, start_col: number, selected_data: InvestmentDataType, all_data: InvestmentDataType[], t: any) => {
    let current_row = start_row;

    const set_cell = (worksheet: any, row: number, col: number, headword: string, value: number) => {
      worksheet.getCell(row, col).value = headword;
      worksheet.getCell(row, col + 1).value = value;
      worksheet.getCell(row, col + 1).numFmt = '0.0%';  
    };

    worksheet.getCell(current_row, start_col).value = t('投資主構成');

    if (selected_data.investor.financial_institution !== null) {
      current_row++;
      set_cell(worksheet, current_row, start_col, t('金融機関'), selected_data.investor.financial_institution);
    }
    if (selected_data.investor.foreign_corporation !== null) {
      current_row++;
      set_cell(worksheet, current_row, start_col, t('外国法人'), selected_data.investor.foreign_corporation);
    }
    if (selected_data.investor.individual !== null) {
      current_row++;
      set_cell(worksheet, current_row, start_col, t('個人'), selected_data.investor.individual);
    }
    if (selected_data.investor.others_investor !== null) {
      current_row++;
      set_cell(worksheet, current_row, start_col, t('その他'), selected_data.investor.others_investor);
    }

    // 罫線を引く。
    for (let i=(start_row + 1); i<(current_row + 1); i++) {
      for (let j=start_col; j<start_col+2; j++) {
        worksheet.getRow(i).getCell(j).border = BorderStyle;
      }
    }
  }
}

export default ToExcel