import { FC, useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';
import classNames from 'classnames';
import { AppContext, MyLanguage } from "../context/AppContext";
import { InvestmentDataType, InvestmentStockCode, InvestmentSectorType } from '../utils/InvestmentDataType';
import { HistInvestmentDataType } from '../utils/HistInvestmentDataType';
import InvestmentDataUtil from '../utils/InvestmentDataUtil';

import './StockChart.css';
import CommonUtil from '../utils/CommonUtil';

const StockChart: FC = () => {
  const ChartId = {
    StockPriceChange         : 'Stock Price Change',
    DividendYield            : 'Dividend Yield',
    MarketCapChange          : 'Market Cap Change',
    PurchasePriceChange      : 'Purchase Price Change',
    AppraisalValueChange     : 'Appraisal Value Change',
    AppraisalGainRatio       : 'Appraisal Gain Ratio',
    PropertyNum              : 'Property Num',
    BuildingAge              : 'Building Age',
    Occupancy                : 'Occupancy',
    NOI_Yield                : 'NOI Yield',
    Implied_CR               : 'Implied CR',
    NAV_Ratio                : 'NAV Ratio',
    FFO_Ratio                : 'FFO Ratio',
    PayoutRatio              : 'Payout Ratio',
    DSCR                     : 'DSCR',
    ROE                      : 'ROE',
    MarketCap                : 'Market Cap',
    LTV                      : 'LTV',
  };


  // チャートに表示する期の数
  const NumPeriodOnChart = 20;

  // チャートの縦サイズ
  const ChartHeight = 350;

  const GridInterval = 5;

  const FontSize = {
    Title: window.devicePixelRatio >= 1.5 ? '14px' : '16px',
    Data: window.devicePixelRatio >= 1.5 ? '10px' : '12px',
  };

  interface ChartColor {
    stock_code: number,
    color: string,
  };

  const [ t ] = useTranslation();
  const { myLang, checkedInvestmentData, histInvestmentData, histInvestmentDataOnlyLatest, investmentData } = useContext(AppContext);

  const [ chartId, setChartId ] = useState<string>(ChartId.StockPriceChange);

  const [ chartColors, setChartColors] = useState<ChartColor[]>([]);

  const [ scatterX_chartId, setScatterX_ChartId ] = useState<string>(ChartId.MarketCap);
  const [ scatterY_chartId, setScatterY_ChartId ] = useState<string>(ChartId.DividendYield);

  const yChangeRef = useRef<HTMLDivElement>(null);
  const [ scatterChartWidth, setScatterChartWidth ] = useState<number>(0);

  useEffect(() => {
    // 証券コードとチャートカラーを紐づける。
    const colors = [ '#4472C4', '#ED7D31', '#A5A5A5', '#FFC000', '#16a632' ];
    let colorArr: ChartColor[] = [];
    for (let d of checkedInvestmentData) {
      if (colorArr.length >= colors.length) break;

      colorArr.push({
        stock_code: d.stock_code,
        color: colors[colorArr.length]
      });
    }
    setChartColors(colorArr);
  }, []);  // []を指定することで、DOMマウント時に1回だけ実行される。


  useEffect(() => {
    calc_ScatterChartWidth();
  }, [yChangeRef.current]);


  useEffect(() => {
    const onResize = () => {
      calc_ScatterChartWidth();
    }
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);


  const calc_ScatterChartWidth = () => {
    // #StockChartに設定した左右のpadding
    let padding = 120;
    if (window.innerWidth < 1080) {
      padding = 10;
    }

    let chartWidth = window.innerWidth - padding * 2 - 20;
    if (yChangeRef.current !== null) {
      // Y軸の選択ボックスの幅を差し引く。
      chartWidth -= yChangeRef.current.clientWidth;
    }
  
    setScatterChartWidth(chartWidth);
  }

  const handleChangeChart = (e: any) => {
    setChartId(e.target.value);
  }

  const getClassName = (chart_id: string) => {
    if (chart_id === chartId) return 'checked';
    return ''
  }

  const get_hist_data = (stock_code: number) => {
    if (histInvestmentData.length === 0) return [];

    let hist_data = [];
    for (let d of histInvestmentData) {
      if (d.stock_code === stock_code) {
        hist_data.push(d);
      }
    }

    // 配列の後ろが新しい期のデータになるようにする。
    hist_data.sort((a, b) => {
      if (a.period > b.period) return 1;
      else if (a.period < b.period) return -1;
      else return 0;
    });
    return hist_data;
  }

  const get_investment_data = (stock_code: number) => {
    for (let d of investmentData) {
      if (d.stock_code === stock_code) {
        return d;
      }
    }
    return null;
  }


  const create_period_list = () => {
    let period_list: string[] = [];

    if (checkedInvestmentData.length === 0) return period_list;

    // 期が一番新しいヒストリカルデータを取得する。
    let latest_period: string = '';
    let latest_hist_data: HistInvestmentDataType[] = [];
    for (let checked_d of checkedInvestmentData) {
      const hist_data = get_hist_data(checked_d.stock_code);
      if (hist_data[hist_data.length - 1].period > latest_period) {
        latest_period = hist_data[hist_data.length - 1].period;
        latest_hist_data = hist_data;
      }
    }

    let year = Number(String(latest_period).substring(0, 4));
    let half = Number(String(latest_period).substring(4, 5));
    for (let i = 0; i < NumPeriodOnChart; i++) {
      period_list.push(`${year}${half}H`);
      if (half === 2) {
        half = 1;
      } else {
        year--;
        half = 2;
      }
    }
    period_list.sort();

    return period_list;
  }

  const get_data_by_chartId = (chartId: string, h: HistInvestmentDataType) => {
    switch (chartId) {
      case ChartId.StockPriceChange: {
        if (h.stock_price_change === null) return null;
        return h.stock_price_change * 100;
      }
      case ChartId.DividendYield: {
        if (h.dividend_yield === null) return null;
        return h.dividend_yield * 100;
      }
      case ChartId.MarketCapChange: {
        if (h.market_cap_change === null) return null;
        return h.market_cap_change * 100;
      }
      case ChartId.PurchasePriceChange: {
        if (h.purchase_price_change === null) return null;
        return h.purchase_price_change * 100;
      }
      case ChartId.AppraisalValueChange: {
        if (h.appraisal_value_change === null) return null;
        return h.appraisal_value_change * 100;
      }
      case ChartId.AppraisalGainRatio: {
        if (h.appraisal_gain_ratio === null) return null;
        return h.appraisal_gain_ratio * 100;
      }
      case ChartId.PropertyNum: {
        return h.property_num;
      }
      case ChartId.BuildingAge: {
        return h.building_age;
      }
      case ChartId.Occupancy: {
        if (h.occupancy === null) return null;
        return h.occupancy * 100;
      }
      case ChartId.NOI_Yield: {
        if (h.noi_yield === null) return null;
        return h.noi_yield * 100;
      }
      case ChartId.Implied_CR: {
        if (h.implied_cr === null) return null;
        return h.implied_cr * 100;
      }
      case ChartId.NAV_Ratio: {
        return h.nav_ratio;
      }
      case ChartId.FFO_Ratio: {
        return h.ffo_ratio;
      }
      case ChartId.PayoutRatio: {
        if (h.payout_ratio === null) return null;
        return h.payout_ratio * 100;
      }
      case ChartId.DSCR: {
        return h.icr_dscr;
      }
      case ChartId.ROE: {
        if (h.roe === null) return null;
        return h.roe * 100;
      }
      case ChartId.MarketCap: {
        if (h.market_cap === null) return null;
        let market_cap = h.market_cap;
        switch (myLang) {
          // 英語版では、billionで表現するため、10で割る。
          case MyLanguage.EN: market_cap /= 10; break;
        }
        return market_cap;    
      }
      case ChartId.LTV: {
        if (h.ltv === null) return null;
        return h.ltv * 100;
      }
    }

    return null;
  }

  const get_chart_data = (chartId: string, stock_code: number, period: string): number|null => {
    for (let h of histInvestmentData) {
      if (stock_code !== h.stock_code) continue;
      if (period !== h.period) continue;

      return get_data_by_chartId(chartId, h);
    }
    return null;
  }

  const get_chart_sub_data = (stock_code: number, period: string): number|null => {
    for (let h of histInvestmentData) {
      if (stock_code !== h.stock_code) continue;
      if (period !== h.period) continue;

      switch (chartId) {
        case ChartId.StockPriceChange: {
          return h.stock_price_E;   //20231105 Change
        }
        case ChartId.MarketCapChange: {
          if (h.market_cap === null) return null;
          let market_cap = h.market_cap;
          switch (myLang) {
            // 英語版では、billionで表現するため、10で割る。
            case MyLanguage.EN: market_cap /= 10; break;
          }
          return market_cap;
        }
        case ChartId.PurchasePriceChange: {
          if (h.purchase_price === null) return null;
          let purchase_price = h.purchase_price;
          switch (myLang) {
            // 英語版では、billionで表現するため、10で割る。
            case MyLanguage.EN: purchase_price /= 10; break;
          }
          return purchase_price;
        }
        case ChartId.AppraisalValueChange: {
          if (h.appraisal_value === null) return null;
          let appraisal_value = h.appraisal_value;
          switch (myLang) {
            // 英語版では、billionで表現するため、10で割る。
            case MyLanguage.EN: appraisal_value /= 10; break;
          }
          return appraisal_value;
        }
      }
    }
    return null;
  }

  const is_only_null_data = (data: any) => {
    for (let d of data) {
      if (d !== null) return false;
    }
    return true;
  }

  const get_xaxis_period = (period_list: string[]) => {
    return {
      categories: period_list,
      labels: {
        formatter: function(val: string) {
          if (val === null || val === undefined) return '';
          const year = Number(val.slice(0, 4));
          const semester = val.slice(4);
          if (semester === '2H') return '';
          return year;
        },
        style: {
          fontSize: FontSize.Data,
        }  
      }
    }
  }

  const get_title = (chartId: string) => {
    switch (chartId) {
      case ChartId.StockPriceChange: return t('株価');
      case ChartId.DividendYield: return t('分配金利回り');
      case ChartId.MarketCapChange: return t('時価総額');
      case ChartId.PurchasePriceChange: return t('取得価格');
      case ChartId.AppraisalValueChange: return t('鑑定評価額(投資)');
      case ChartId.AppraisalGainRatio: return t('含み益率');
      case ChartId.PropertyNum: return t('物件数');
      case ChartId.BuildingAge: return t('築年数(投資)');
      case ChartId.Occupancy: return t('稼働率');
      case ChartId.NOI_Yield: return t('NOI利回り');
      case ChartId.Implied_CR: return 'Implied CR';
      case ChartId.NAV_Ratio: return t('NAV倍率');
      case ChartId.FFO_Ratio: return t('FFO倍率');
      case ChartId.PayoutRatio: return t('配当性向');
      case ChartId.DSCR: return 'DSCR';
      case ChartId.ROE: return 'ROE';
      case ChartId.MarketCap: return t('時価総額');
      case ChartId.LTV: return 'LTV';
    }
    return '';
  }

  const create_chart_title = (chartId: string) => {
    return get_opt_title(get_title(chartId));
  }

  const get_opt_title = (text: string) => {
    return {
      text: text,
      align: 'center',
      style: {
        fontSize: FontSize.Title,
        fontWeight: 'normal'
      }
    }; 
  }

  const create_chart_opts = (period_list: string[], grid_min: number, grid_max: number, grid_interval: number, histSubData: (number|null)[][], line_colors: string[]) => {

    const options: any = {
      chart: {
        height: ChartHeight,
        type: 'line',
        toolbar: {
          show: false
        },
        animations: {
          enabled: false
        },
        zoom: {
          enabled: false
        }
      },
      colors: line_colors,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2.8,
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: [ 'transparent' ],
          opacity: 0.5
        },
      },
      markers: {
        size: 3.8,
        colors: [ '#fff' ],
        strokeColors: line_colors,
      },
      xaxis: get_xaxis_period(period_list),
      yaxis: {
        tickAmount: grid_interval,
        min: grid_min,
        max: grid_max,
        labels: {
          formatter: function(val: number, index: any) {
            if (val === null || val === undefined) return '';
            switch (chartId) {
              case ChartId.StockPriceChange:     return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';
              case ChartId.DividendYield:        return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';
              case ChartId.MarketCapChange:      return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';
              case ChartId.PurchasePriceChange:  return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';
              case ChartId.AppraisalValueChange: return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';
              case ChartId.AppraisalGainRatio:   return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';
              case ChartId.PropertyNum:          return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
              case ChartId.BuildingAge:          return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1});
              case ChartId.Occupancy:            return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + '%';
              case ChartId.NOI_Yield:            return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';
              case ChartId.Implied_CR:           return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';
              case ChartId.NAV_Ratio:            return val.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
              case ChartId.FFO_Ratio:            return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1});
              case ChartId.PayoutRatio:          return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + '%';
              case ChartId.DSCR:                  return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1});
              case ChartId.ROE:                  return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + '%';
            }
            return val;
          }
        }
      },
      tooltip: {
        style: {
          fontSize: FontSize.Data,
        },
        x: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            const period = period_list[val - 1];
            const year = period.slice(2, 4);
            const semester = period.slice(4);
            return `${semester}-${year}`;
          }
        },
        y: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            switch (chartId) {
              case ChartId.StockPriceChange: {
                const stock_price_change_text = CommonUtil.get_percent_text(val, 2) + '%';
                const stock_price_E = histSubData[opts.seriesIndex][opts.dataPointIndex];                                           //20231105 Change
                let stock_price_text = ''; 
                if (stock_price_E !== null) {                                                                                       //20231105 Change
                  stock_price_text = stock_price_E.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}); //20231105 Change
                  switch (myLang) {
                    case MyLanguage.JA: stock_price_text = stock_price_text + ' 円'; break;
                    case MyLanguage.EN: stock_price_text = '&yen; ' + stock_price_text; break
                  }  
                }
                if (stock_price_text) {
                  return `${stock_price_change_text} (${stock_price_text})`;
                } else {
                  return `${stock_price_change_text}`;
                }
              }
              case ChartId.DividendYield: {
                return CommonUtil.get_percent_text(val, 2)+ '%';
              }
              case ChartId.MarketCapChange: {
                const market_cap_change_text = CommonUtil.get_percent_text(val, 2) + '%';
                const market_cap = histSubData[opts.seriesIndex][opts.dataPointIndex];
                let market_cap_text = ''; 
                if (market_cap !== null) {
                  market_cap_text = market_cap.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
                  switch (myLang) {
                    case MyLanguage.JA: market_cap_text = market_cap_text + ' 億円'; break;
                    case MyLanguage.EN: market_cap_text = '&yen; ' + market_cap_text + 'bn'; break
                  }  
                }
                if (market_cap_text) {
                  return `${market_cap_change_text} (${market_cap_text})`;
                } else {
                  return `${market_cap_change_text}`;
                }
              }
              case ChartId.PurchasePriceChange: {
                const purchase_price_change_text = CommonUtil.get_percent_text(val, 2) + '%';
                const purchase_price = histSubData[opts.seriesIndex][opts.dataPointIndex];
                let purchase_price_text = ''; 
                if (purchase_price !== null) {
                  purchase_price_text = purchase_price.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
                  switch (myLang) {
                    case MyLanguage.JA: purchase_price_text = purchase_price_text + ' 億円'; break;
                    case MyLanguage.EN: purchase_price_text = '&yen; ' + purchase_price_text + 'bn'; break
                  }  
                }
                if (purchase_price_text) {
                  return `${purchase_price_change_text} (${purchase_price_text})`;
                } else {
                  return `${purchase_price_change_text}`;
                }
              }
              case ChartId.AppraisalValueChange: {
                const appraisal_value_change_text = CommonUtil.get_percent_text(val, 2) + '%';
                const appraisal_value = histSubData[opts.seriesIndex][opts.dataPointIndex];
                let appraisal_value_text = ''; 
                if (appraisal_value !== null) {
                  appraisal_value_text = appraisal_value.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
                  switch (myLang) {
                    case MyLanguage.JA: appraisal_value_text = appraisal_value_text + ' 億円'; break;
                    case MyLanguage.EN: appraisal_value_text = '&yen; ' + appraisal_value_text + 'bn'; break
                  }  
                }
                if (appraisal_value_text) {
                  return `${appraisal_value_change_text} (${appraisal_value_text})`;
                } else {
                  return `${appraisal_value_change_text}`;
                }
              }
              case ChartId.AppraisalGainRatio: {
                return CommonUtil.get_percent_text(val, 1) + '%';
              }
              case ChartId.PropertyNum: {
                return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
              }
              case ChartId.BuildingAge: {
                let text = val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1});
                switch (myLang) {
                  case MyLanguage.JA: return text + ' 年';
                  case MyLanguage.EN: return text + ' years';
                }
                return text;
              }
              case ChartId.Occupancy: {
                if (val === 100) {
                  // 100%の場合は小数点表記なし。
                  return CommonUtil.get_percent_text(val, 0) + '%';
                } else {
                  return CommonUtil.get_percent_text(val, 1) + '%';
                }
              }
              case ChartId.NOI_Yield: {
                return CommonUtil.get_percent_text(val, 2) + '%';
              }
              case ChartId.Implied_CR: {
                return CommonUtil.get_percent_text(val, 2) + '%';
              }
              case ChartId.NAV_Ratio: {
                return val.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
              }
              case ChartId.FFO_Ratio: {
                return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1});
              }
              case ChartId.PayoutRatio: {
                return CommonUtil.get_percent_text(val, 1) + '%';
              }
              case ChartId.DSCR: {
                return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1});
              }
              case ChartId.ROE: {
                return CommonUtil.get_percent_text(val, 1) + '%';
              }
            }
            return val;
          }
        }
      },
      legend: {
        showForSingleSeries: true,  // 1物件のみでも凡例表示する。
        onItemClick: {
          toggleDataSeries: true,  // true：クリックした物件の表示／非表示を切り替えられるようにする。
        },
      },
    };

    return options;
  }


  const get_color = (stock_code: number) => {
    for (let c of chartColors) {
      if (c.stock_code === stock_code) return c.color;
    }
    return '#cccccc';
  }


  const render_LineChart = () => {
    let period_list = create_period_list();

    let histData_by_stock_code: any = {};
    let histSubData: (number|null)[][] = [];  // 株価変動率など、吹き出しに金額も表示するもの向け。
    let all_histData: number[] = [];

    const series: any[] = [];
    
    const set_histData = (investmentData: InvestmentDataType[]) => {
      for (let d of investmentData) {
        histData_by_stock_code[d.stock_code] = [];
        histSubData.push([]);
        for (let period of period_list) {
          const chart_data = get_chart_data(chartId, d.stock_code, period);
          const chart_sub_data = get_chart_sub_data(d.stock_code, period);
  
          histData_by_stock_code[d.stock_code].push(chart_data);
          histSubData[histSubData.length - 1].push(chart_sub_data);
  
          if (chart_data !== null) {
            all_histData.push(chart_data);
          }
        }
      }
    }

    const set_series = (series: any[], investmentData: InvestmentDataType[]) => {
      for (let d of investmentData) {
        // nullデータのみの場合はチャート表示しない。
        if (is_only_null_data(histData_by_stock_code[d.stock_code])) continue;
  
        let stock_name = '';
        switch (myLang) {
          case MyLanguage.JA: stock_name = d.stock_name; break;
          case MyLanguage.EN: stock_name = d.stock_name_en; break;
        }  
  
        series.push({
          name : stock_name,
          data: histData_by_stock_code[d.stock_code]
        });
      }
    }

    const includes_office = (investmentData: InvestmentDataType[]) => {
      for (let d of investmentData) {
        if (d.sector === InvestmentSectorType.Office) {
          return true;
        }
      }
      return false;
    }
  
    const line_colors = [ '#4472C4', '#ED7D31', '#A5A5A5', '#FFC000', '#16a632' ].slice(0, checkedInvestmentData.length);

    set_histData(checkedInvestmentData);
    set_series(series, checkedInvestmentData);

    if (chartId === ChartId.StockPriceChange) {
      // 株価選択時は、東証REIT指数も追加
      const tse_reit_index_data = InvestmentDataUtil.create_blank_data(InvestmentStockCode.TSE_REIT_Index, '東証REIT指数', 'TSE REIT Index');
      if (tse_reit_index_data !== null) {
        set_histData([tse_reit_index_data]);
        set_series(series, [tse_reit_index_data]);
        line_colors.push('#EF454A');
      }

      if (includes_office(checkedInvestmentData)) {
        // 選択した銘柄にオフィスが含まれる場合は、東証REITオフィス指数も追加
        const tse_reit_office_index_data = InvestmentDataUtil.create_blank_data(InvestmentStockCode.TSE_REIT_Office_Index, '東証REITオフィス指数', 'TSE REIT Office Index');
        if (tse_reit_office_index_data !== null) {
          set_histData([tse_reit_office_index_data]);
          set_series(series, [tse_reit_office_index_data]);
          line_colors.push('#44546A');
        }
      }
    }

    let grid_min_max = { grid_max: 0, grid_min: 0 };
    grid_min_max.grid_max = Math.max(...all_histData);
    grid_min_max.grid_min = Math.min(...all_histData);

    const options = create_chart_opts(period_list, grid_min_max.grid_min, grid_min_max.grid_max, GridInterval, histSubData, line_colors);

    return (
      <div className="chart">
        <div className="title">{get_title(chartId)}</div>
        <Chart type='line' options={options} series={series} height={ChartHeight} />
      </div>
    );
  }

  /**
   * 分散チャートをレンダリングする。
   * @returns レンダリング内容
   */
  const render_scatterChart = () => {
    if (histInvestmentDataOnlyLatest.length === 0) return null;

    interface TmpSeries {
      stock_code: number,
      name: string,
      data: number[][]
    }
    let series: any[] = [];
    let tmp_series: TmpSeries[] = [];
    let colors: string[] = [];

    let all_xData = [];
    let all_yData = [];

    const period_list = create_period_list();
    const period = period_list[period_list.length - 1];

    for (let hist_d of histInvestmentDataOnlyLatest) {

      const d = get_investment_data(hist_d.stock_code);
      if (d === null) continue;

      const xData = get_data_by_chartId(scatterX_chartId, hist_d);
      const yData = get_data_by_chartId(scatterY_chartId, hist_d);
      if (xData === null || yData === null) continue;

      all_xData.push(xData);
      all_yData.push(yData);

      let stock_name = '';
      switch (myLang) {
        case MyLanguage.JA: stock_name = d.stock_name; break;
        case MyLanguage.EN: stock_name = d.stock_name_en; break;
      }
      tmp_series.push({
        stock_code: hist_d.stock_code,
        name: stock_name,
        data: [[xData, yData]],
      });
    }

    const grid_x_interval = 10;

    let grid_x_min = Math.min(...all_xData);
    let grid_x_max = Math.max(...all_xData);

    const grid_y_min = Math.min(...all_yData);
    const grid_y_max = Math.max(...all_yData);

    for (let s of tmp_series) {
      series.push({
        name: s.name,
        data: s.data,
      });
      
      colors.push(get_color(s.stock_code));
    }

    const options: any = {
      chart: {
        height: ChartHeight,
        type: 'scatter',
        zoom: {
          enabled: false,
          type: 'xy'
        },
        animations: {
          enabled: false
        },
        toolbar: { show: false },
      },
      tooltip: {
        x: { show: false },
      },
      fill: {
        opacity: 0.7,
      },
      colors: colors,
      stroke: { width: 2, curve: 'straight' },  // パフォーマンス向上のため。https://github.com/apexcharts/apexcharts.js/issues/214
      xaxis: {
        tickAmount: grid_x_interval,
        min: grid_x_min,
        max: grid_x_max,
        labels: {
          formatter: function(val: number) {
            if (val === null || val === undefined) return '';
            switch (scatterX_chartId) {
              case ChartId.MarketCap: {
                let text = val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
                switch (myLang) {
                  case MyLanguage.JA: return text + '億円';
                  // 「&yen;」が有効にならず、半角の￥マークもバックスラッシュで表示されてしまうので、全角で表示させる。
                  case MyLanguage.EN: return '￥' + text + 'bn';
                }
                return text;
              }
              case ChartId.AppraisalGainRatio: {
                return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + '%';
              }
              case ChartId.PropertyNum: {
                return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
              }
              case ChartId.BuildingAge: {
                return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
              }
              case ChartId.Occupancy: {
                return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + '%';
              }
              case ChartId.NAV_Ratio: {
                return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1});
              }
              case ChartId.FFO_Ratio: {
                return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1});
              }
              case ChartId.DSCR: {
                return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1});
              }
              case ChartId.LTV: {
                return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + '%';
              }
            }
            return val;
          }
        },
      },
      yaxis: {
        tickAmount: GridInterval,
        min: grid_y_min,
        max: grid_y_max,
        labels: {
          formatter: function(val: number) {
            return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';
          }
        }
      },
      legend: { show: false },
    };

    const render_select_yaxis = () => {
      const handleChangeYAxis = (e: any) => {
        setScatterY_ChartId(e.target.value);
      }
  
      return (
        <div className="yChange" ref={yChangeRef}>
          <select onChange={((e) => handleChangeYAxis(e))}>
            <option value={ChartId.DividendYield}>{get_title(ChartId.DividendYield)}</option>
            <option value={ChartId.NOI_Yield}>{get_title(ChartId.NOI_Yield)}</option>
            <option value={ChartId.Implied_CR}>{get_title(ChartId.Implied_CR)}</option>
            <option value={ChartId.ROE}>{get_title(ChartId.ROE)}</option>
          </select>
        </div>
      );
    } 

    const render_select_xaxis = () => {
      const handleChangeXAxis = (e: any) => {
        setScatterX_ChartId(e.target.value);
      }
  
      return (
        <div className="xChange">
          <select onChange={((e) => handleChangeXAxis(e))}>
            <option value={ChartId.MarketCap}>{get_title(ChartId.MarketCap)}</option>
            <option value={ChartId.AppraisalGainRatio}>{get_title(ChartId.AppraisalGainRatio)}</option>
            <option value={ChartId.PropertyNum}>{get_title(ChartId.PropertyNum)}</option>
            <option value={ChartId.BuildingAge}>{get_title(ChartId.BuildingAge)}</option>
            <option value={ChartId.Occupancy}>{get_title(ChartId.Occupancy)}</option>
            <option value={ChartId.NAV_Ratio}>{get_title(ChartId.NAV_Ratio)}</option>
            <option value={ChartId.FFO_Ratio}>{get_title(ChartId.FFO_Ratio)}</option>
            <option value={ChartId.DSCR}>{get_title(ChartId.DSCR)}</option>
            <option value={ChartId.LTV}>{get_title(ChartId.LTV)}</option>
          </select>
        </div>
      );
    }

    return (
      <div className="chart">
        <div className="title">{t('相関プロット')}</div>
        <div className='y_slider_and_scatter'>
          {render_select_yaxis()}
          <div className="scatter"><Chart type='scatter' options={options} series={series} height={ChartHeight} width={scatterChartWidth} /></div>
        </div>
        {render_select_xaxis()}
      </div>
    );
  }

  const render_label = (label: string, key: string) => {
    return (
      <>
        <label htmlFor={key} className={getClassName(key)}>{label}</label>
        <input type="radio" name="charts" value={key} id={key} checked={chartId === key} />
      </>
    );
  }

  return (
    <div id="StockChart">
      <div className="content">
        <div className="chart_tab" onChange={(e) => {handleChangeChart(e)}}>
          {render_label(t('株価'), ChartId.StockPriceChange)}
          {render_label(t('分配金利回り'), ChartId.DividendYield)}
          {render_label(t('時価総額'), ChartId.MarketCapChange)}
          {render_label(t('取得価格'), ChartId.PurchasePriceChange)}
          {render_label(t('鑑定評価額(投資)'), ChartId.AppraisalValueChange)}
          {render_label(t('含み益率'), ChartId.AppraisalGainRatio)}
          {render_label(t('物件数'), ChartId.PropertyNum)}
          {render_label(t('築年数(投資)'), ChartId.BuildingAge)}
          {render_label(t('稼働率'), ChartId.Occupancy)}
          {render_label(t('NOI利回り'), ChartId.NOI_Yield)}
          {render_label('Implied CR', ChartId.Implied_CR)}
          {render_label(t('NAV倍率'), ChartId.NAV_Ratio)}
          {render_label(t('FFO倍率'), ChartId.FFO_Ratio)}
          {render_label(t('配当性向'), ChartId.PayoutRatio)}
          {render_label('DSCR', ChartId.DSCR)}
          {render_label('ROE', ChartId.ROE)}
        </div>
        {render_LineChart()}
        {render_scatterChart()}
      </div>
    </div>
  );
}

export default StockChart;
