import { FC, useEffect, useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { AppContext, DisplayMode } from '../context/AppContext';
import { LoadScript } from "@react-google-maps/api";
import { useAuthContext } from '../context/AuthContext';
import AuthUtil from '../utils/AuthUtil';
import CommonUtil from '../utils/CommonUtil';
import Map from './Map';
import ValueMap from './ValueMap';

const WrappedMap: FC = () => { 

  const [ t ] = useTranslation();
  const { myLang, displayMode, memberType } = useContext(AppContext);
  // ログインユーザ
  const { currentUser } = useAuthContext();
  // 地図表示の制限
  const [ restricted, setRestricted ] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (currentUser === undefined) return;

    const ContextRoot = CommonUtil.get_context_root();

    fetch(`${ContextRoot}/jsons/restrict_map.json`, { method: 'GET' })
      .then(res => res.json())
      .then(data => {
        const allowed_member_type_level = data['allowed_member_type_level'];
        // 0レベルの場合は無条件で許可する。
        if (allowed_member_type_level === 0) {
          setRestricted(false);
          return;
        }
        // 0レベル以外はログインしているユーザの会員タイプで判定する。
        setRestricted(AuthUtil.restricted(memberType, allowed_member_type_level));
      });
  }, [currentUser, memberType]);

  if (restricted === undefined) return <div id="Map"></div>;
  if (restricted) return <div id="Map">{t(AuthUtil.RestrictedMessage)}</div>;

  const render_map = () => {
    switch (displayMode) {
      case DisplayMode.Map:
        return <Map />;
      case DisplayMode.ValueMap:
        return <ValueMap />;
    }
    return null;
  }

  return (
    <div id="Map">
      {/* 本番用 */}
      <LoadScript googleMapsApiKey="AIzaSyC2MiZSezgqSh-U8gBiXaiQfsKaNfCBWEk" language={myLang}>
      {/* ローカル用 */}
      {/* <LoadScript googleMapsApiKey="AIzaSyCAZmHXBZnvTCULE-E_a1M8R0bwzvBGmdM" language={myLang}> */}
        {render_map()}
      </LoadScript>
    </div>
  );
}

export default WrappedMap;
