class MapUtil {

  /**
   * 緯度をタイル座標に変換する。
   * @param lat 緯度
   * @param zoom ズームレベル
   * @returns 
   */
  static latToTile = (lat: number, zoom: number): number => {
    const latRad = lat * Math.PI / 180;
    const n = Math.pow(2, zoom);
    const yTile = (1.0 - (Math.log(Math.tan(latRad) + 1.0 / Math.cos(latRad)) / Math.PI)) / 2.0 * n;
    return Math.floor(yTile);
  }

  /**
   * 経度をタイル座標に変換する。
   * @param lon 経度
   * @param zoom ズームレベル
   * @returns 
   */
  static lonToTile = (lon: number, zoom: number): number => {
    const n = Math.pow(2, zoom);
    const xTile = (lon + 180.0) / 360.0 * n;
    return Math.floor(xTile);
  }

}

export default MapUtil
