import { FC, useContext, useEffect } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { AppContext, DisplayMode, LoginState, SearchType } from '../context/AppContext';
import Stock from "./Stock";
import StockData from "./StockData";
import StockChart from "./StockChart";
import Detail from "./Detail";
import ValueData from './ValueData';

import './Main.css';
import OfficialLandPricesDetail from "./OfficialLandPricesDetail";

const Main: FC = () => { 

  // 表示モード
  const { displayMode } = useContext(AppContext);
  // ログイン状態
  const { loginState } = useContext(AppContext);
  // 検索タイプ、検索条件
  const { setSearchType, setSearchCond } = useContext(AppContext);

  const { setSelectedId, setSelectedPropertyNo } = useContext(AppContext);


  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const mode = searchParams.get('mode');
    if (mode !== DisplayMode.Data) return;

    // DATAページ
    const id = searchParams.get('id');
    const property_no = searchParams.get('property_no')
    if (id !== null) {
      setSelectedId(Number(id));
    }
    if (property_no !== null) {
      setSelectedPropertyNo(Number(property_no));
    }
  }, []);

  useEffect(() => {
    const mode = searchParams.get('mode');
    if (mode !== DisplayMode.ValueData) return;

    // Value.mapのDATAページ
    const id = searchParams.get('id');
    if (id !== null) {
      setSelectedId(Number(id));
    }
  }, []);

  useEffect(() => {
    if (loginState !== LoginState.Not_LoggedIn) {
      // ExcelのURLでアクセスされた場合の処理
      const mode = searchParams.get('mode');
    
      if (mode === DisplayMode.Map) {
        let building_types = searchParams.getAll('building_type');
        if (building_types.length === 0) {
          // 指定なしの場合、オフィスのみを取得する。
          building_types.push('1');
        }
  
        setSearchType(SearchType.Panel);
        const cond = {
          'building_type1' : building_types.includes('1') ? '1' : false,
          'building_type2' : building_types.includes('2') ? '2' : false,
          'building_type3' : building_types.includes('3') ? '3' : false,
          'building_type4' : building_types.includes('4') ? '4' : false,
          'building_type5' : building_types.includes('5') ? '5' : false,
          'building_type6' : building_types.includes('6') ? '6' : false,
          'building_type99': building_types.includes('99') ? '99' : false,
        }
        setSearchCond(cond);
  
        const ids = searchParams.getAll('id');
        let id_params = '';
        for (let i=0; i<ids.length; i++) {
          const id = ids[i];
          if (i > 0) {
            id_params += '&'
          }
          id_params += `id=${id}`;
        }

        navigate(`./map?${id_params}`);
      }
    }
  }, [loginState]);

  useEffect(() => {
    if (loginState !== LoginState.Not_LoggedIn) {
      const mode = searchParams.get('mode');
    
      switch (mode) {
        case DisplayMode.List:
          navigate(`./list`);
          break;
        case DisplayMode.ValueMap:
          navigate(`./value.map/MAP`);
          break;
        case DisplayMode.ValueList:
          navigate(`./value.map/LIST`);
          break;
      }
    }
  }, [loginState]);

  const render_byDisplayMode = () => {
    switch (displayMode) {
      case DisplayMode.Stock:
        return <><Stock /></>
      case DisplayMode.StockData:
        return <><StockData /></>
      case DisplayMode.StockChart:
        return <><StockChart /></>
    }
    return null;
  }

  if (displayMode === DisplayMode.Data) {
    return <><Detail /></>;
  } else if (displayMode === DisplayMode.ValueData) {
    return <><ValueData /></>;
  } else if (displayMode === DisplayMode.OfficialLandPricesDetail) {
    return <><OfficialLandPricesDetail /></>;
  } else if (loginState !== LoginState.Not_LoggedIn) {
    return (
      <div id="Main" className={displayMode}>
        {render_byDisplayMode()}
      </div>
    );  
  } else {
    return null;
  }
}

export default Main;
