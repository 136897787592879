import { FC, useContext, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import classNames from 'classnames';
import Keyword from './search/Keyword';
import BuildingType from './search/BuildingType';
import CapRate from './search/CapRate';
import Addr from './search/Addr';
import TransactionPeriod from './search/TransactionPeriod';
import TransactionPrice from './search/TransactionPrice';
import InvestmentCorp from './search/InvestmentCorp';
import ArchitecturalArea from './search/ArchitecturalArea';
import ConstructionDate from './search/ConstructionDate';
import ManagedReit from './search/ManagedReit';
import SellOrBuy from './search/SellOrBuy';
import RelatedPartyTx from './search/RelatedPartyTx';
import { AppContext, SearchType } from '../context/AppContext';
import { useTranslation } from 'react-i18next';
import EstateDataUtil from '../utils/EstateDataUtil';
import { SubmitSearchButtonId } from '../utils/SearchPanelConst';
import AuthUtil from '../utils/AuthUtil';
import { MemberType } from '../utils/MemberType';

import './SearchPanel.css';
import { time } from 'console';

const SearchPanel: FC = () => {
  const [ t ] = useTranslation();
  const { setDialogMsg } = useContext(AppContext);
  const { setEstateData } = useContext(AppContext);
  const { setSearchType } = useContext(AppContext);
  const { setSearchCond } = useContext(AppContext);
  const { memberType, setIsOpenUserRegistrationDialog } = useContext(AppContext);

  const methods1 = useForm();
  const methods2 = useForm();

  const [ isOpenDetail, setIsOpenDetail ] = useState<boolean>(false);

  const [ isOpenSearchPanel, setIsOpenSearchPanel ] = useState<boolean>(false);

  const [ isShowPopup, setIsShowPopup ] = useState<boolean>(true);
  const [ popupClassname, setPopupClassname ] = useState<string>("");

  useEffect(() => {
    toggleVisibilityWithFade(4);
  }, []);

  const toggleVisibilityWithFade = (times: number) => {
    let count = 0;
    const duration = 1500;  // 1秒表示 + 0.5秒フェードアウト

    const fadeOutIn = () => {
      setPopupClassname('fadeout');
      setTimeout(() => {
        if (count < times) {
          setPopupClassname('fadein');
        }
      }, 500);  // 0.5秒後にフェードイン
    }

    const cycle = setInterval(() => {
      count++;
      fadeOutIn();
      if (count >= times) {
        clearInterval(cycle);
        setTimeout(() => { setIsShowPopup(false); }, 500);  // 最終的に非表示。
      }
    }, duration);
  }

  const onSubmit1 = (cond: any) => {
    if (AuthUtil.restricted(memberType, MemberType.C)) {
      setIsOpenUserRegistrationDialog(true);
      return;
    }

    if (cond['keyword'] === '') {
      // 空文字の場合、タイプやキャップレートなどの絞り込み条件で検索する。
      const submit_search_button = document.getElementById(SubmitSearchButtonId);
      if (!submit_search_button) return;
      submit_search_button.click();

    } else {
      setSearchType(SearchType.Keyword);
      EstateDataUtil.fetch_estate_data(cond, setEstateData, resultZero);  
    }
  }

  const onSubmit2 = (cond: any) => {
    // タイプに1つもチェックが入っていない場合は、空データをセット。(サーバにリクエストさせない)
    if (cond['building_type1'] === false &&
        cond['building_type2'] === false &&
        cond['building_type3'] === false &&
        cond['building_type4'] === false &&
        cond['building_type5'] === false &&
        cond['building_type99'] === false
    ) {
      setEstateData([]);
      resultZero();
      return;
    }

    // 検索条件を保存しておく。
    setSearchCond({...cond});

    setSearchType(SearchType.Panel);
  }

  /**
   * 検索でヒットしなかった場合に呼び出される。
   */
  const resultZero = () => {
    setDialogMsg(t('物件がありません。'));
  }

  const render_open_detail_button = () => {
    // type="button"を指定することによって、ボタンクリック時にonSubmitが動作しないようにする。
    if (isOpenDetail) {
      return <div className="open_detail_button_area"><button className="open_detail_button" onClick={handleOnClickOpenDetailButton} type="button"><span>{t('➖')}</span></button></div>
    } else {
      return <div className="open_detail_button_area"><button className="open_detail_button" onClick={handleOnClickOpenDetailButton} type="button"><span>{t('➕')}</span></button></div>
    }
  }

  const handleOnClickOpenDetailButton = () => {
    setIsOpenDetail(!isOpenDetail);
  }

  const render_search_panel = () => {
    let search_button_value = t('検索') || '';
    return (
      <div className="search-panel">
        <div className="form_wrapper">
          <FormProvider {...methods1}>
            <form name="serch_form1" onSubmit={methods1.handleSubmit(onSubmit1)}>
              <Keyword />
            </form>
          </FormProvider>

          <FormProvider {...methods2}>
            <form name="search_form2" onSubmit={methods2.handleSubmit(onSubmit2)}>
              <div className="search_button"><input type="submit" id={SubmitSearchButtonId} value={search_button_value}></input></div>
              <BuildingType />
              <CapRate />
              <Addr use_for="" />
              <TransactionPeriod />
              <TransactionPrice />
              <InvestmentCorp />
              <ArchitecturalArea />
              <ConstructionDate />
              {render_open_detail_button()}
              {isOpenDetail && <ManagedReit />}
              {isOpenDetail && <SellOrBuy />}
              {isOpenDetail && <RelatedPartyTx />}
            </form>
          </FormProvider >
        </div>
      </div>
    );
  }

  const render_open_close_button = () => {
    if (isOpenSearchPanel) {
      return <g><polygon points="419.916,71.821 348.084,0 92.084,256.005 348.084,512 419.916,440.178 235.742,256.005"></polygon></g>
    } else {
      return <g><polygon points="163.916,0 92.084,71.822 276.258,255.996 92.084,440.178 163.916,512 419.916,255.996"></polygon></g>;
    }
  }

  return (
    <div className={classNames("search-panel-area", isOpenSearchPanel ? "opened" : "closed")}>
      {render_search_panel()}
      <button className="open_close_panel" onClick={() => {setIsOpenSearchPanel(!isOpenSearchPanel)}}>
        <svg x="0px" y="0px" viewBox="0 0 512 512">{render_open_close_button()}</svg>
      </button>
      { isShowPopup && <div className={classNames("search-panel-balloon", popupClassname)}>{t('サイドパネル表示')}</div> }
    </div>
  );
}

export default SearchPanel;
