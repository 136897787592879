import React from 'react';
import { useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { FC, useContext, useState, useEffect } from 'react';
import classNames from 'classnames';

import { AppContext, DisplayMode, MaxLabel, MyLanguage } from '../context/AppContext';
import EstateDataUtil from '../utils/EstateDataUtil';
import { EstateDataName, EstateDataType } from '../utils/EstateDataType';
import { useTranslation } from 'react-i18next';
import AuthUtil from '../utils/AuthUtil';
import { MemberType } from '../utils/MemberType';
import CommonUtil from '../utils/CommonUtil';
import HistDataUtil from '../utils/HistDataUtil';

import './List.css';

const List: FC = () => {
  const ContextRoot = CommonUtil.get_context_root();

  const [ t ] = useTranslation();
  const navigate = useNavigate();
  const { myLang } = useContext(AppContext);
  const { estateData } = useContext(AppContext);
  const { appData } = useContext(AppContext);
  const { labeledEstateData, setLabeledEstateData } = useContext(AppContext);
  const { setDialogMsg, setIsOpenUserRegistrationDialog } = useContext(AppContext);
  const { memberType } = useContext(AppContext);

  // 表示件数
  const NumStep = 20;  // Moreボタン押下時に増やす件数
  const [ numDisplay, setNumDisplay ] = useState<number>(NumStep);

  // ソート対象のカラム名
  const [ orderBy, setOrderBy ] = useState<string>('');

  // 各カラムのソート方法(昇順／降順)
  const [ isOrderAsc_BuildingType, setIsOrderAsc_BuildingType ] = useState<boolean>(true);
  const [ isOrderAsc_CapRate, setIsOrderAsc_CapRate ] = useState<boolean>(true);
  const [ isOrderAsc_TransactionPrice, setIsOrderAsc_TransactionPrice ] = useState<boolean>(true);
  const [ isOrderAsc_UnitPrice, setIsOrderAsc_UnitPrice ] = useState<boolean>(true);
  const [ isOrderAsc_Transaction_YYYYMMDD, setIsOrderAsc_Transaction_YYYYMMDD ] = useState<boolean>(true);
  const [ isOrderAsc_ArchitecturalArea, setIsOrderAsc_ArchitecturalArea ] = useState<boolean>(true);
  const [ isOrderAsc_Construction_YYYYMMDD, setIsOrderAsc_Construction_YYYYMMDD ] = useState<boolean>(true);
  const [ isOrderAsc_NoiYield, setIsOrderAsc_NoiYield ] = useState<boolean>(true);
  const [ isOrderAsc_OccupancyRate, setIsOrderAsc_OccupancyRate ] = useState<boolean>(true);
  const [ isOrderAsc_Rent, setIsOrderAsc_Rent ] = useState<boolean>(true);
  const [ isOrderAsc_AppraisalCapRate, setIsOrderAsc_AppraisalCapRate ] = useState<boolean>(true);
  const [ isOrderAsc_AppraisalValue, setIsOrderAsc_AppraisalValue ] = useState<boolean>(true);

  const theadRef = useRef<HTMLTableSectionElement>(null);

  // ポップアップの表示スタイル
  interface PopupStyle {
    left: string;
    top: string;
    height: string;
  }
  const [ popupStyle, setPopupStyle ] = useState<PopupStyle>({ left: '0px', top: '0px', height: '0px' });
  // 取引単価のセルにマウスをあわせたデータ。
  const [ data_mouseEnter_on_unit_price, setData_mouseEnter_on_unit_price ] = useState<EstateDataType | null>(null);
  
  useEffect(() => {
    // 初期表示では取引年月の若い順にする。
    setIsOrderAsc_Transaction_YYYYMMDD(false);
    setOrderBy(EstateDataName.Transaction_YYYYMMDD);
  }, []);

  /**
   * 不動産情報をソートする。
   * 
   * @returns ソートした不動産情報
   */
  const sort_data = () => {
    if (!orderBy) return estateData;

    const my_compare = (a: EstateDataType, b: EstateDataType, key: keyof EstateDataType, isOrderAsc: boolean): number => {
      // null値の場合は後ろに並べる。
      if (!(key in a)) return 1;
      if (!(key in b)) return -1;
      if (a[key] === null) return 1;
      if (b[key] === null) return -1;

      if (isOrderAsc) {
        return Number(a[key]) - Number(b[key]);
      } else {
        return Number(b[key]) - Number(a[key]);
      }
    }

    const my_compare_building_type = (a: EstateDataType, b: EstateDataType, key: keyof EstateDataType, key2: keyof EstateDataType, isOrderAsc: boolean): number => {
      const diff = my_compare(a, b, key, isOrderAsc);

      if (diff !== 0) return diff;

      // タイプが同じ値の場合、タイプ2で比較する。
      if (!(key2 in a)) return 1;
      if (!(key2 in b)) return -1;
      if (a[key2] === null) return 1;
      if (b[key2] === null) return -1;

      if (isOrderAsc) {
        return Number(a[key2]) - Number(b[key2]);
      } else {
        return Number(b[key2]) - Number(a[key2]);
      }
    }

    let sortedEstateData = [...estateData];
    switch (orderBy) {
      case EstateDataName.BuildingType:
        sortedEstateData.sort((a, b) => my_compare_building_type(a, b, 'building_type', 'building_type2', isOrderAsc_BuildingType));
        break;
      case EstateDataName.CapRate:
        sortedEstateData.sort((a, b) => my_compare(a, b, 'cap_rate', isOrderAsc_CapRate));
        break;
      case EstateDataName.TransactionPrice:
        sortedEstateData.sort((a, b) => my_compare(a, b, 'transaction_price', isOrderAsc_TransactionPrice));
        break;
      case EstateDataName.UnitPrice:
        sortedEstateData.sort((a, b) => my_compare(a, b, 'unit_price', isOrderAsc_UnitPrice));
        break;  
      case EstateDataName.Transaction_YYYYMMDD:
        sortedEstateData.sort((a, b) => my_compare(a, b, 'transaction_yyyymmdd', isOrderAsc_Transaction_YYYYMMDD));
        break;
      case EstateDataName.ArchitecturalArea:
        sortedEstateData.sort((a, b) => my_compare(a, b, 'architectural_area', isOrderAsc_ArchitecturalArea));
        break;
      case EstateDataName.Construction_YYYYMMDD:
        sortedEstateData.sort((a, b) => my_compare(a, b, 'construction_yyyymmdd', isOrderAsc_Construction_YYYYMMDD));
        break;
      case EstateDataName.NOI_Yield:
        sortedEstateData.sort((a, b) => my_compare(a, b, 'noi_y', isOrderAsc_NoiYield));
        break;
      case EstateDataName.OccupancyRate:
        sortedEstateData.sort((a, b) => my_compare(a, b, 'occ', isOrderAsc_OccupancyRate));
        break;
      case EstateDataName.Rent:
        sortedEstateData.sort((a, b) => my_compare(a, b, 'rent', isOrderAsc_Rent));
        break;  
      case EstateDataName.AppraisalCapRate:
        sortedEstateData.sort((a, b) => my_compare(a, b, 'cr', isOrderAsc_AppraisalCapRate));
        break;
      case EstateDataName.AppraisalValue:
        sortedEstateData.sort((a, b) => my_compare(a, b, 'av', isOrderAsc_AppraisalValue));
        break;
    }
    return sortedEstateData;
  }

  /**
   * カラムのヘッダをクリックしたら、
   * ・そのカラムをソートの対象とする。
   * ・すでにソート対象の場合は、ソート方法を変更する。
   * 
   * @param estate_data_name ソート対象のデータ名
   */
  const handleClickHeader = (estate_data_name: string) => {
    if (AuthUtil.restricted(memberType, MemberType.C)) {
      setIsOpenUserRegistrationDialog(true);
      return;
    }

    // クリックしたカラムがすでにソート対象の場合は、ソート方法を変更する。
    switch (estate_data_name) {
      case EstateDataName.BuildingType:
        if (orderBy === estate_data_name) setIsOrderAsc_BuildingType(!isOrderAsc_BuildingType);
        break;
      case EstateDataName.CapRate:
        if (orderBy === estate_data_name) setIsOrderAsc_CapRate(!isOrderAsc_CapRate);
        break;
      case EstateDataName.TransactionPrice:
        if (orderBy === estate_data_name) setIsOrderAsc_TransactionPrice(!isOrderAsc_TransactionPrice);
        break;
      case EstateDataName.UnitPrice:
        if (orderBy === estate_data_name) setIsOrderAsc_UnitPrice(!isOrderAsc_UnitPrice);
        break;  
      case EstateDataName.Transaction_YYYYMMDD:
        if (orderBy === estate_data_name) setIsOrderAsc_Transaction_YYYYMMDD(!isOrderAsc_Transaction_YYYYMMDD);
        break;
      case EstateDataName.ArchitecturalArea:
        if (orderBy === estate_data_name) setIsOrderAsc_ArchitecturalArea(!isOrderAsc_ArchitecturalArea);
        break;
      case EstateDataName.Construction_YYYYMMDD:
        if (orderBy === estate_data_name) setIsOrderAsc_Construction_YYYYMMDD(!isOrderAsc_Construction_YYYYMMDD);
        break;
      case EstateDataName.NOI_Yield:
        if (orderBy === estate_data_name) setIsOrderAsc_NoiYield(!isOrderAsc_NoiYield);
        break;
      case EstateDataName.OccupancyRate:
        if (orderBy === estate_data_name) setIsOrderAsc_OccupancyRate(!isOrderAsc_OccupancyRate);
        break;
      case EstateDataName.Rent:
        if (orderBy === estate_data_name) setIsOrderAsc_Rent(!isOrderAsc_Rent);
        break;  
      case EstateDataName.AppraisalCapRate:
        if (orderBy === estate_data_name) setIsOrderAsc_AppraisalCapRate(!isOrderAsc_AppraisalCapRate);
        break;
      case EstateDataName.AppraisalValue:
        if (orderBy === estate_data_name) setIsOrderAsc_AppraisalValue(!isOrderAsc_AppraisalValue);
        break;
    }
    // ソート対象としてセットする。
    setOrderBy(estate_data_name);
  }

  const is_checked = (d: EstateDataType) : boolean => {
    for (let labeled_d of labeledEstateData) {
      if (labeled_d.id === d.id) return true;
    }
    return false;
  }

  const handleSourceButton = (source_file: string) => {
    if (AuthUtil.restricted(memberType, MemberType.C)) {
      setIsOpenUserRegistrationDialog(true);
      return;
    }

    // 別タブで「Source」のページを開く。
    const url = `./sources/${source_file}`;
    window.open(url, '_blank', 'noreferrer');
  }

  const render_data = () => {
    let rendering = [];
    let sortedEstateData = sort_data();
    sortedEstateData = sortedEstateData.filter(estateData => {
      // ラベル化した物件は重複回避のため削除。
      for (let labeled_d of labeledEstateData) {
        if (estateData.id === labeled_d.id) return false;
      }
      return true;
    });
    const mergedEstateData = [ ...labeledEstateData, ...sortedEstateData ];

    const checkBoxDisplay = CommonUtil.is_mobile() ? 'display_none' : '';

    let count = 0;
    for (let d of mergedEstateData) {
      // 表示する件数を制御する。
      count++;
      if (count > numDisplay) break;

      // ヒストリカルデータがあるか
      const has_hist_data = EstateDataUtil.has_hist_data(d);
      const has_not_hist_data_className = has_hist_data ? '' : 'has_not_hist_data';

      let row = [];

      // checkbox
      row.push(<td className={classNames('fixcell', 'checkbox', checkBoxDisplay)}><input type="checkbox" onChange={(e) => handleCheckbox(e, d)} checked={is_checked(d)} /></td>);
            
      // タイプ
      row.push(<td className={classNames('fixcell', 'building_type')}>{t(EstateDataUtil.convert_buildingType_name(d.building_type, d.building_type2))}</td>);

      // CR(NOI)
      if (!EstateDataUtil.convert_disclose_cap_rate(d.disclose_cap_rate)) {
        let className_not_disclosed = '';
        switch (myLang) {
          case MyLanguage.JA: className_not_disclosed='not_disclosed_ja'; break;
          case MyLanguage.EN: className_not_disclosed='not_disclosed_en'; break;
        }  
        row.push(<td className={classNames('fixcell', 'cap_rate', className_not_disclosed)}><b>{t('非開示')}</b></td>);
      }
      else if (d.cap_rate !== null) {
        row.push(<td className={classNames('right', 'fixcell', 'cap_rate')}><b>{EstateDataUtil.convert_capRate_percentage(d.cap_rate)}</b>%</td>);
      } else {
        row.push(<td className={classNames('fixcell', 'cap_rate')}>NA</td>);
      }

      // 物件名
      {
        const className = classNames('fixcell', 'property_name');
        switch (myLang) {
          case MyLanguage.JA: row.push(<td className={className}>{d.property_name}</td>); break;
          case MyLanguage.EN: row.push(<td className={className}>{d.property_name_en}</td>); break;
          default: row.push(<td className={className}></td>); break;
        }  
      }

      // 所在地
      switch (myLang) {
        case MyLanguage.JA: row.push(<td className={classNames('width_adjust')}>{d.addr1}{d.addr2}</td>); break;
        case MyLanguage.EN: row.push(<td className={classNames('width_adjust')}>{d.addr2_en},&nbsp;{d.addr1_en}</td>); break;
        default: row.push(<td></td>); break;
      }

      // 取引価格
      if (!EstateDataUtil.convert_disclose_tx_price(d.disclose_tx_price)) {
        row.push(<td className={classNames("right", 'width_adjust')}>{t('非開示')}</td>);
      }
      else if (d.transaction_price !== null) {
        switch (myLang) {
          case MyLanguage.JA: {
            row.push(<td className={classNames("right", 'width_adjust')}>{Number(d.transaction_price).toLocaleString()} 百万円</td>);
            break;
          }
          case MyLanguage.EN: {
            row.push(<td className={classNames("right", 'width_adjust')}>&yen; {Number(d.transaction_price).toLocaleString()}m</td>);
            break;
          }
        }
      } else {
        row.push(<td className="na">NA</td>);
      }

      // 取引単価
      if (d.unit_price !== null) {
        let text = '';
        const className = classNames('right', 'popup_parent');

        const onMouseEnterEvent = (e: any, d: EstateDataType) => {
          setPopupStyle(get_popup_style(e));
          setData_mouseEnter_on_unit_price(d);
        }
        const onMouseLeaveEvent = () => {
          setData_mouseEnter_on_unit_price(null);
        }

        const unit_price = d.unit_price / 1000;
        switch (myLang) {
          case MyLanguage.JA:
            text = unit_price.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + ' 千円/坪';  // カンマ区切り＆小数点以下0桁
            row.push(<td className={className}>{text}</td>);
            break;
          case MyLanguage.EN:
            text = (CommonUtil.tsubo_to_spft(unit_price)).toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + 'k/sq.ft';  // カンマ区切り＆小数点以下0桁
            row.push(<td className={className} onMouseEnter={(e) => onMouseEnterEvent(e, d)} onMouseLeave={() => onMouseLeaveEvent()}>&yen; {text}</td>);
            break;
          default:
            row.push(<td className={className}>{text}</td>);
            break;
        }
      } else {
        row.push(<td className="na">NA</td>);
      }
      
      // 取引時点
      if (d.transaction_yyyymmdd) {
        row.push(<td className={classNames('width_adjust')}>{EstateDataUtil.convert_YearMonth(d.transaction_yyyymmdd, myLang)}</td>);
      } else {
        row.push(<td className="na">NA</td>);
      }

      // 延床面積
      if (d.architectural_area !== null) {
        // カンマ区切りかつ小数点なしで揃える。
        row.push(<td className={classNames("right", 'width_adjust')}>{d.architectural_area.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}㎡</td>);
      } else {
        row.push(<td className="na">NA</td>);
      }

      // 竣工
      if (d.construction_yyyymmdd) {
        row.push(<td className={classNames('width_adjust')}>{EstateDataUtil.convert_YearMonth(d.construction_yyyymmdd, myLang)}</td>);
      } else {
        row.push(<td className="na">NA</td>);
      }

      // ヒストリカルデータがある場合は、ヒストリカルデータページを開くボタンを表示する。
      let data_buttons = [];
      if (has_hist_data) {
        if (!CommonUtil.is_mobile()) {
          const is_Nippon_Building = EstateDataUtil.is_Nippon_Building(d);
          const className = is_Nippon_Building ? 'nippon_building' : '';
          data_buttons.push(<button className={className} onClick={() => handleHistoricalDataButton(d.id, d.property_no, is_Nippon_Building)}>DATA</button>);
        }
      }
      // PDF
      let pdf_buttons = [];
      let source_files = '';
      switch (myLang) {
        case MyLanguage.JA:
          source_files = d.source_files;
          break;
        case MyLanguage.EN:
          source_files = d.source_files_en;
          break;
      }
      if (source_files) {
        const splits = source_files.split('|');
        for (let i=0; i<splits.length; i++) {
          const filename = `${splits[i]}.pdf`;
          let className = '';
          if (i > 0) {
            pdf_buttons.push(<br/>);
            className = 'top_margin';
          }
          pdf_buttons.push(<button className={className} onClick={() => handleSourceButton(filename)}>PDF</button>);
        }
      }
      row.push(
        <td className={classNames('fixcell', 'data_pdf_button')}>
          <div className={classNames("button_area", CommonUtil.is_mobile() ? "mobile" : "")}>
            <div>{data_buttons}</div>
            <div>{pdf_buttons}</div>
          </div>
        </td>
      );


      // 取得/売却
      if (d.sell_or_buy !== null) {
        row.push(<td className={classNames('width_adjust', 'center')}>{t(EstateDataUtil.convert_sellOrBuy(d.sell_or_buy))}</td>);
      } else {
        row.push(<td className={classNames('center')}>NA</td>);
      }

      // 運用投資法人
      {
        let corp = '';
        if (d.investment_corp_type !== null && d.investment_corp_code !== null) {
          corp = EstateDataUtil.get_investment_corp(appData['investment_corp'], d.investment_corp_type, d.investment_corp_code, myLang);
        }
        if (corp) {
          row.push(<td className={classNames("investment_corp", 'width_adjust')}>{corp}</td>);
        } else {
          row.push(<td className={classNames("investment_corp", 'width_adjust')}>NA</td>);
        }  
      }

      // 運用投資法人と更新日の間の隙間
      row.push(<td className="blank"></td>);

      // 更新日
      if (d.as_of_yyyymmdd) {
        row.push(<td className={classNames("update_date", 'width_adjust')}>{EstateDataUtil.convert_YearMonth(d.as_of_yyyymmdd, myLang)}</td>);
      } else {
        row.push(<td className={classNames("update_date", 'width_adjust')}></td>);
      }

      // NOI Yield
      if (d.noi_y !== null) {
        row.push(<td className={classNames("right", 'width_adjust')}>{EstateDataUtil.convert_noi_yield_percentage(d.noi_y)}%</td>);
      } else {
        row.push(<td className={classNames(has_not_hist_data_className, 'width_adjust', 'na')}>{has_hist_data && 'NA'}</td>);
      }
      
      // 稼働率
      if (d.occ !== null) {
        row.push(<td className={classNames("right", 'width_adjust')}>{EstateDataUtil.convert_occupancy_rate_percentage(d.occ)}%</td>);
      } else {
        row.push(<td className={classNames(has_not_hist_data_className, 'width_adjust', 'na')}>{has_hist_data && 'NA'}</td>);
      }

      // 賃料
      if (d.rent !== null) {
        // 小数点以下第一位を四捨五入して整数にする。
        const rent = Number(d.rent).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
        const className = classNames("right", 'width_adjust');
        switch (myLang) {
          case MyLanguage.JA:
            row.push(<td className={className}>{rent} 円/坪</td>);
            break;
          case MyLanguage.EN:
            row.push(<td className={className}>&yen; {rent}/tsubo</td>);
            break;
          default:
            row.push(<td className={className}></td>);
            break;
        }

      } else {
        row.push(<td className={classNames(has_not_hist_data_className, 'width_adjust', 'na')}>{has_hist_data && 'NA'}</td>);
      }

      // 鑑定CR（NOI）
      if (d.cr !== null) {
        row.push(<td className={classNames("right", "cr_noi", 'width_adjust')}>{EstateDataUtil.convert_appraisal_cap_rate_percentage(d.cr)}%</td>);
      } else {
        row.push(<td className={classNames(has_not_hist_data_className, 'cr_noi', 'width_adjust', 'na')}>{has_hist_data && 'NA'}</td>);
      }

      // 鑑定評価額
      if (d.av !== null) {
        switch (myLang) {
          case MyLanguage.JA: {
            row.push(<td className={classNames("right", 'width_adjust')}>{Number(d.av).toLocaleString()} 百万円</td>);
            break;
          }
          case MyLanguage.EN: {
            row.push(<td className={classNames("right", 'width_adjust')}>&yen; {Number(d.av).toLocaleString()}m</td>);
            break;
          }
        }
      } else {
        row.push(<td className={classNames(has_not_hist_data_className, 'width_adjust', 'na')}>{has_hist_data && 'NA'}</td>);
      } 

      // 1行分
      rendering.push(<tr>{row}</tr>);
    }

    return rendering;
  }

  const render_popup_on_unit_price = () => {
    if (data_mouseEnter_on_unit_price === null) return null;

    let rendering = [];

    let text = '';
    const unit_price = data_mouseEnter_on_unit_price.unit_price / 1000;
    switch (myLang) {
      case MyLanguage.EN:
        text = unit_price.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + 'k/tsubo';  // カンマ区切り＆小数点以下0桁
        rendering.push(<>&yen; {text}</>);
        break;
    }

    return (
      <div className="popup" style={popupStyle}>{rendering}</div>
    );
  }

  /**
   * ポップアップを表示するスタイルを取得する。
   * 
   * @param e マウスイベント
   * @returns ポップアップを表示するスタイル
   */
  const get_popup_style = (e: any): PopupStyle => {
    let style: PopupStyle = { left: '0px', top: '0px', height: '0px'};

    if (theadRef.current === null) return style;

    // 右側に表示。
    const leftPosition = e.target.offsetLeft + e.target.offsetWidth;
    // 同一縦位置に表示。
    const topPosition = e.target.offsetTop + theadRef.current.offsetHeight;

    style.left = `${leftPosition}px`;
    style.top = `${topPosition}px`;
    style.height = `${e.target.offsetHeight}px`;
    return style;
  }

  const render_more_button = () => {
    if (numDisplay >= estateData.length) return null;

    const handleClick = () => {
      setNumDisplay(numDisplay + NumStep);
    }

    return (<button className="more_button" onClick={() => handleClick()}>More...</button>);
  }
 
  /**
   * ソート可能なカラムのヘッダに指定するclassを取得する。
   * 
   * @param estate_data_name データ名
   * @returns カラムのヘッダに指定するclass
   */
  const get_classNameSortable = (estate_data_name: string) => {
    let className = classNames({
      'order_by': orderBy === estate_data_name,  // ソート対象のカラムの場合は、背景色を変える。
    }, 'sortable');
    return className;
  }

  const render_indicator = (estate_data_name: string, is_order_asc: boolean) => {
    if (orderBy !== estate_data_name) return render_indicator_neutral();
    if (orderBy === estate_data_name) {
      if (is_order_asc) return render_indicator_asc();
      else return render_indicator_dsc();
    }
    return null;
  }

  const render_indicator_neutral = () => {
    return (
      <svg className='indicator_neutral' width="10" height="12" viewBox="0 0 12 14" version="1.1" >
        <path fill-rule="evenodd" d="m 0,8.004969 6,6 6,-6 z" />
        <path fill-rule="evenodd" d="M 0.00993834,6.0650684 6.0099383,0.06506844 12.009938,6.0650684 Z" />
      </svg>
    );
  }

  const render_indicator_asc = () => {
    return (
      <svg className='indicator_asc' width="10" height="5" viewBox="0 0 12 6" version="1.1" >
        <path fill-rule="evenodd" d="M 0.00993834,6.0650684 6.0099383,0.06506844 12.009938,6.0650684 Z" />
      </svg>
    );
  }

  const render_indicator_dsc = () => {
    return (
      <svg className='indicator_dsc' width="10" height="5" viewBox="0 0 12 6" version="1.1" >
        <path fill-rule="evenodd" d="M 0.00993834,0.06506844 6.0099383,6.0650684 12.009938,0.06506844 Z" />
      </svg>
    );
  }

  /**
   * 吹き出しの文言を返却する。
   * @param balloon_text 吹き出しの文言(配列の1要素＝1行)
   * @param classname_align 吹き出しの位置を指定するクラス名
   * @returns 吹き出しの文言
   */
  const render_balloon = (balloon_text: string[], classname_align: string) => {
    if (balloon_text.length === 0) return null;

    let rendering = [];
    for (let i = 0; i < balloon_text.length; i++) {
      if (i !== 0) rendering.push(<br/>);
      rendering.push(balloon_text[i]);
    }
    
    return (
      <span className={classNames("balloon", classname_align)}>{rendering}</span>
    );
  }

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>, d: EstateDataType) => {
    if (e.target.checked) {
      // チェックを入れた場合
      let newLabeledEstateData = [...labeledEstateData];
      for (let labeled_d of newLabeledEstateData) {
        if (labeled_d.id === d.id) {
          // 同じ物件がラベル化されている場合は何もしない。
          return;
        }
      }
      if (newLabeledEstateData.length >= MaxLabel) {
        // ラベルの上限に達していたらダイアログで警告する。
        setDialogMsg(t('上限に達しました。最大X件まで追加できます。', { num: MaxLabel }));
        return;
      }
      newLabeledEstateData.push(d);
      setLabeledEstateData(newLabeledEstateData);
    }
    else {
      // チェックをはずした場合
      let newLabeledEstateData = labeledEstateData.filter(labeled_d => {
        // チェックをはずした物件だけ除外する。
        return labeled_d.id !== d.id;
      });
      setLabeledEstateData(newLabeledEstateData);
    }
  }

  const handleHistoricalDataButton = (id: number, property_no: number, is_Nippon_Building: boolean) => {
    if (!is_Nippon_Building && AuthUtil.restricted(memberType, MemberType.C)) {
      setIsOpenUserRegistrationDialog(true);
      return;
    }
    // 別タブで「DATA」のページを開く。
    let url = `${ContextRoot}/?mode=${DisplayMode.Data}&id=${id}&lang=${myLang}`;
    if (property_no !== null) {
      url += `&property_no=${property_no}`;
    }
    window.open(url, '_blank', 'noreferrer');
  }

  /**
   * CHARTボタン
   * @returns CHARTボタン
   */
  const render_chartButton = () => {
    const handleOnClick = () => {
      if (AuthUtil.restricted(memberType, MemberType.C)) {
        setIsOpenUserRegistrationDialog(true);
        return;
      }
      navigate(`${ContextRoot}/chart`);  
    }

    if (labeledEstateData.length === 0) return null;

    // ラベル化した取引に1件もヒストリカルデータがない場合はボタンを表示しない。
    let has_data = false;
    for (let d of labeledEstateData) {
      if (EstateDataUtil.has_hist_data(d)) {
        has_data = true;
        break;
      }
    }
    if (!has_data) return null;

    return <button onClick={() => handleOnClick()}>{t('CHART')}</button>;
  }

  const render_button_area = () => {
    const handleClickExcelOutput = async () => {
      if (AuthUtil.restricted(memberType, MemberType.C)) {
        setIsOpenUserRegistrationDialog(true);
        return;
      }
  
      HistDataUtil.to_excel(t, myLang, appData, labeledEstateData);
    }

    const render_excel_button = () => {
      if (labeledEstateData.length === 0) return null;
      if (CommonUtil.is_mobile()) return null;
      return <button onClick={handleClickExcelOutput}>{t('Excel出力')}</button>;
    }

    return (
      <div className="top_button_area">
        {render_excel_button()}
        {render_chartButton()}
      </div>
    )
  }

  const checkBoxDisplay = CommonUtil.is_mobile() ? 'display_none' : '';

  return (
    <div id="List">
      {render_button_area()}
      <div className="twrapper">
        <table>
          <thead ref={theadRef}>
            <tr>
              <th className={classNames('fixcell', 'checkbox', checkBoxDisplay)}></th>
              <th className={classNames(get_classNameSortable(EstateDataName.BuildingType), 'fixcell', 'building_type')} onClick={() => handleClickHeader(EstateDataName.BuildingType)}>
                <div>
                  {t('タイプ')}
                  {render_indicator(EstateDataName.BuildingType, isOrderAsc_BuildingType)}
                </div>
              </th>
              <th className={classNames(get_classNameSortable(EstateDataName.CapRate), 'fixcell', 'cap_rate', 'balloon_parent')} onClick={() => handleClickHeader(EstateDataName.CapRate)}>
                <div>
                {
                    myLang === MyLanguage.JA ? 
                    <><span>取引CR</span><span>（NOI）</span></> : 
                    <><span>{t('CR（NOI）')}</span></>
                }
                  {render_indicator(EstateDataName.CapRate, isOrderAsc_CapRate)}
                  {render_balloon([t('取得（売却）時鑑定NOI / 取引価格')], 'center')}
                </div>
              </th>
              <th className={classNames('fixcell', 'property_name')}>{t('物件名')}</th>
              <th className={classNames('width_adjust')}>{t('所在地')}</th>
              <th className={classNames(get_classNameSortable(EstateDataName.TransactionPrice), 'transaction_price', 'width_adjust')} onClick={() => handleClickHeader(EstateDataName.TransactionPrice)}>
                <div>
                  {
                    myLang === MyLanguage.JA ? 
                      <span>取引価格</span> : 
                      <><span>Transaction</span><span>Price</span></>
                  }
                  {render_indicator(EstateDataName.TransactionPrice, isOrderAsc_TransactionPrice)}
                </div>
              </th>
              <th className={classNames(get_classNameSortable(EstateDataName.UnitPrice), 'balloon_parent')} onClick={() => handleClickHeader(EstateDataName.UnitPrice)}>
                <div>
                  {t('取引単価')}
                  {render_indicator(EstateDataName.UnitPrice, isOrderAsc_UnitPrice)}
                  {render_balloon([t('取引価格 / 貸床面積（坪）')], 'right')}
                </div>
              </th>
              <th className={classNames(get_classNameSortable(EstateDataName.Transaction_YYYYMMDD), 'width_adjust')} onClick={() => handleClickHeader(EstateDataName.Transaction_YYYYMMDD)}>
                <div>
                  {t('取引年月')}
                  {render_indicator(EstateDataName.Transaction_YYYYMMDD, isOrderAsc_Transaction_YYYYMMDD)}
                </div>
              </th>
              <th className={classNames(get_classNameSortable(EstateDataName.ArchitecturalArea), 'width_adjust', 'balloon_parent')} onClick={() => handleClickHeader(EstateDataName.ArchitecturalArea)}>
                <div>
                  {t('延床面積')}
                  {render_indicator(EstateDataName.ArchitecturalArea, isOrderAsc_ArchitecturalArea)}
                  {render_balloon([t('取引対象面積')], 'right')}
                </div>
              </th>
              <th className={classNames(get_classNameSortable(EstateDataName.Construction_YYYYMMDD), 'width_adjust')} onClick={() => handleClickHeader(EstateDataName.Construction_YYYYMMDD)}>
                <div>
                  {t('竣工')}
                  {render_indicator(EstateDataName.Construction_YYYYMMDD, isOrderAsc_Construction_YYYYMMDD)}
                </div>
              </th>
              <th className={classNames('fixcell', 'data_pdf_button')}>{t('データ')}</th>
              <th className={classNames('width_adjust')}>{t('取得/売却')}</th>
              <th className={classNames("investment_corp", 'width_adjust')}>{t('投資法人')}</th>
              <th className={classNames("blank")}></th>
              <th className={classNames("update_date", 'width_adjust')}>{t('更新日(タイトル)')}</th>
              <th className={classNames(get_classNameSortable(EstateDataName.NOI_Yield), 'width_adjust', 'balloon_parent')} onClick={() => handleClickHeader(EstateDataName.NOI_Yield)}>
                <div>
                  {t('NOI Yield')}
                  {render_indicator(EstateDataName.NOI_Yield, isOrderAsc_NoiYield)}
                  {render_balloon([t('(半期実績NOI x 2) / 取引価格')], 'right')}
                </div>
              </th>
              <th className={classNames(get_classNameSortable(EstateDataName.OccupancyRate), 'width_adjust')} onClick={() => handleClickHeader(EstateDataName.OccupancyRate)}>
                <div>
                  {t('稼働率')}
                  {render_indicator(EstateDataName.OccupancyRate, isOrderAsc_OccupancyRate)}
                </div>
              </th>
              <th className={classNames(get_classNameSortable(EstateDataName.Rent), 'balloon_parent')} onClick={() => handleClickHeader(EstateDataName.Rent)}>
                <div>
                  {t('賃料')}
                  {render_indicator(EstateDataName.Rent, isOrderAsc_Rent)}
                  {render_balloon([t('賃料収入 / 稼働面積'), t('(＊テナント入退去時は数値のブレが生じる)')], 'right')}
                </div>
              </th>
              <th className={classNames(get_classNameSortable(EstateDataName.AppraisalCapRate),'width_adjust', 'balloon_parent') } onClick={() => handleClickHeader(EstateDataName.AppraisalCapRate)}>
                <div>
                  {
                    myLang === MyLanguage.JA ? 
                      <><span>鑑定CR</span><span>（NCF）</span></> : 
                      <><span>Appraisal CR</span><span>(NCF)</span></>
                  }
                  {render_indicator(EstateDataName.AppraisalCapRate, isOrderAsc_AppraisalCapRate)}
                  {render_balloon([t('NOI -（一時金運用益＋資本的支出）= NCF')], 'right')}
                </div>
              </th>
              <th className={classNames(get_classNameSortable(EstateDataName.AppraisalValue),'width_adjust')} onClick={() => handleClickHeader(EstateDataName.AppraisalValue)}>
                <div>
                  {React.createElement('span', { dangerouslySetInnerHTML: { __html: t('鑑定評価額')}})}
                  {render_indicator(EstateDataName.AppraisalValue, isOrderAsc_AppraisalValue)}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {render_data()}
          </tbody>
        </table>
        {render_popup_on_unit_price()}
        {render_more_button()}
      </div>
    </div>
  );
}

export default List;
