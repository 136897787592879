import './ContactPage.css';
import { FC, useContext, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Dialog from '../components/Dialog';
import LoginDialog from '../components/LoginDialog';
import Contact from '../components/Contact';
import { AppContext, DisplayMode } from '../context/AppContext';

const ContactPage: FC = () => {
  // ダイアログ
  const { dialogMsg, setDisplayMode } = useContext(AppContext);
  // ログインダイアログ
  const { isOpenLoginDialog } = useContext(AppContext);

  useEffect(() => {
    setDisplayMode(DisplayMode.Contact);
  }, []);

  return (
    <>
      <Header />
      <div id="ContactPage">
        <Contact />
      </div>
      <Footer />
      { dialogMsg && <Dialog /> }
      { isOpenLoginDialog && <LoginDialog /> }
    </>
  );

}

export default ContactPage