import './ListPage.css';
import { FC, useContext, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Dialog from '../components/Dialog';
import LoginDialog from '../components/LoginDialog';
import UserRegistrationDialog from '../components/UserRegistrationDialog';
import { AppContext, DisplayMode } from '../context/AppContext';
import SearchPanel from '../components/SearchPanel';
import List from '../components/List';

const ListPage: FC = () => {
  // ダイアログ
  const { dialogMsg, setDisplayMode } = useContext(AppContext);
  // ログインダイアログ
  const { isOpenLoginDialog } = useContext(AppContext);
  // ユーザー登録ダイアログ
  const { isOpenUserRegistrationDialog } = useContext(AppContext);

  useEffect(() => {
    setDisplayMode(DisplayMode.List);
  }, []);

  return (
    <>
      <Header />
      <div id="ListPage">
        <SearchPanel /><List />
      </div>
      <Footer />
      { dialogMsg && <Dialog /> }
      { isOpenLoginDialog && <LoginDialog /> }
      { isOpenUserRegistrationDialog && <UserRegistrationDialog /> }
    </>
  );

}

export default ListPage