import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext, LoginState } from '../context/AppContext';
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import './LoginDialog.css';

const LoginDialog: FC = () => {

  const [ t, i18n ] = useTranslation(); 
  const { setIsOpenLoginDialog } = useContext(AppContext);
  const { setLoginState } = useContext(AppContext);
  const [ emailAddress, setEmailAddress ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ errorMessage, setErrorMessage ] = useState('');

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailAddress(e.target.value);
  }

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  }

  const handleClickLogin = () => {
    setErrorMessage('');
    signInWithEmailAndPassword(auth, emailAddress, password)
      .then((userCredential) => {
        const user = userCredential.user;
        setIsOpenLoginDialog(false);
        setLoginState(LoginState.LoggedIn_With_Auth);
      })
      .catch((error) => {
        const errorCode = error.code;
        if (errorCode === 'auth/invalid-email' || errorCode === 'auth/wrong-password' || errorCode === 'auth/user-not-found') {
          setErrorMessage(t('メールアドレスまたはパスワードが違います'));
        } else {
          setErrorMessage(t('ログインに失敗しました'));
        }
      });
  }

  const handleClickCancel = () => {
    setIsOpenLoginDialog(false);
  }

  return (
    <div id="LoginDialog">
      <div className="content">
        <label>
          <div>ID (mail address)</div>
          <input className="input-node" name="emailOrPhone" type="email" inputMode="email" onChange={handleChangeEmail}></input>
        </label>
        <label>
          <div>PW</div>
          <input className="input-node with-suffix-button" name="password" type="password" inputMode="text" onChange={handleChangePassword}></input>
        </label>
        <div className="errorMessage">{errorMessage}</div>
        <div className="buttonArea">
          <button onClick={handleClickLogin}>Login</button>
          <button onClick={handleClickCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default LoginDialog;
