import React, { useContext, MouseEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SubmitSearchButtonId } from '../../utils/SearchPanelConst';
import { AppContext } from '../../context/AppContext';
import AuthUtil from '../../utils/AuthUtil';
import { MemberType } from '../../utils/MemberType';
import './ArchitecturalArea.css';

type Props = {

};

const ArchitecturalArea: React.VFC<Props> = () => {
  const [ t ] = useTranslation();
  const { register } = useFormContext();
  const { memberType, setIsOpenUserRegistrationDialog } = useContext(AppContext);

  const render_option = () => {
    const architectural_area_list = [ 1000, 3000, 5000, 10000, 30000, 50000, 100000 ];
    let rendering = [];
    for (let architectural_area of architectural_area_list) {
      rendering.push(<option value={architectural_area}>{architectural_area.toLocaleString()}m&sup2;</option>);
    }
    return rendering;
  }

  const handleChange = () => {
    // 延床面積変更時に再検索させる。
    const submit_search_button = document.getElementById(SubmitSearchButtonId);
    if (!submit_search_button) return;
    submit_search_button.click();
  }

  const handleMouseDown = (event: MouseEvent<HTMLSelectElement>): void => {
    if (AuthUtil.restricted(memberType, MemberType.C)) {
      setIsOpenUserRegistrationDialog(true);
      // optionの表示をキャンセルする。
      event.preventDefault();
      return;
    }
  }

  return (
    <div id="ArchitecturalArea">
      <div className="searchgroup">
        <span className="searchfieldlabel">{t('延床面積')}</span>
        <select onMouseDown={handleMouseDown} {...register('architectural_area_lower', { onChange: handleChange })}>
          <option value="0" selected disabled></option>
          <option value="0">{t('下限なし')}</option>
          {render_option()}
        </select> 
        <span>&nbsp;～&nbsp;</span>
        <select onMouseDown={handleMouseDown} {...register('architectural_area_upper', { onChange: handleChange })}>
          <option value="0" selected disabled></option>
          <option value="0">{t('上限なし')}</option>
          {render_option()}
        </select> 
      </div>
    </div>
  );
}

export default ArchitecturalArea;
