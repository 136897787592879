import { FC, useContext, useState, useEffect } from "react";
import { InfoWindow, Marker, MarkerClusterer } from "@react-google-maps/api";
import './MapContent.css';

import EstateDataUtil from '../../utils/EstateDataUtil';
import { EstateDataType, InvestmentCorpType } from '../../utils/EstateDataType';
import { t } from "i18next";
import { AppContext, MyLanguage, MaxLabel, DisplayMode } from '../../context/AppContext';
import classNames from 'classnames';
import { useAuthContext } from '../../context/AuthContext';
import AuthUtil from '../../utils/AuthUtil';
import { MemberType } from '../../utils/MemberType';
import CommonUtil from '../../utils/CommonUtil';
import MapMlit from "./MapMlit";
import HazardMapLayers from "./HazardMapLayers";

interface Props {
  estateData: EstateDataType[],
  mapRef: any,
  showOnlyLabeled: boolean,
  setShowOnlyLabeled: React.Dispatch<React.SetStateAction<boolean>>,
  popupEstates: EstateDataType[],
  setPopupEstates: React.Dispatch<React.SetStateAction<EstateDataType[]>>,
  setIsMapInteractive: React.Dispatch<React.SetStateAction<boolean>>,
  // 情報追加用のメニュー
  isLandPricesActive: boolean, 
  isZoningActive: boolean, 
  isFloodActive: boolean,
  isSedimentActive: boolean,
  isTsunamiActive: boolean,
  isStormSurgeActive: boolean,
};

const MapContent: FC<Props> = (props) => {
  const ContextRoot = CommonUtil.get_context_root();
  const { myLang } = useContext(AppContext);
  const { labeledEstateData, setLabeledEstateData } = useContext(AppContext);
  const { setDialogMsg, memberType, setIsOpenUserRegistrationDialog } = useContext(AppContext);

  // マウスオーバーした不動産
  const [ mouseOverEstate, setMouseOverEstate ] = useState<EstateDataType | null>(null);

  // InfoWindowのkeyに指定する値。
  //
  // 以下のような手順を行うと、3.で吹き出しが表示されない。
  // 1. 不動産マーカーをクリック
  // 2. 飲食店などのマーカーをクリック(GoogleMap側で用意されたマーカー)
  // 3. 1.と同じ不動産マーカーをクリック
  //
  // 1.の吹き出しは閉じられるが、InfoWindowの状態？がクリアされていないようで、
  // 3.の時にInfoWindowが表示されない。
  // これを防ぐため、マーカークリックのたびにkeyを変えて状態を変えるようにする。
  // 
  // [memo] GoogleMap側で用意されたマーカーをクリックしても反応しないようにしたので、以下をコメントアウトした。
  // const [ infoWindowKey, setInfoWindowKey ] = useState<number>(0);

  useEffect(() => {
    if (labeledEstateData.length === 0) {
      // すべてチェックをはずした場合、チェックを入れた不動産だけを表示する機能をOFFにする。
      props.setShowOnlyLabeled(false);
    }
  }, [labeledEstateData]);


  // クラスターアイコンへのパスを定義する (1.png, 2.png, ...)
  const clusterIcon_imagePath = `${ContextRoot}/images/markerclusterer/m`;
  const m2_size = 50;
  const m3_size = 100;
  const m4_size = 150;
  const m5_size = 200;
  const textColor_m2_m5 = 'transparent';
  // const textColor_m2_m5 = '#000000';

  const clusterStyles = [
    {
      url: `${clusterIcon_imagePath}1.png`,
      width: 30,
      height: 26,
      textSize: 14,
      anchorText: [0, -4],  // y, x
      anchorIcon: [26/2, 30/2],  // y, x
      textColor: '#ffffff',
    },
    {
      url: `${clusterIcon_imagePath}2.png`,
      width: m2_size,
      height: m2_size,
      textSize: 14,
      anchorText: [0, 0],
      anchorIcon: [m2_size/2, m2_size/2],
      textColor: textColor_m2_m5,
    },
    {
      url: `${clusterIcon_imagePath}3.png`,
      width: m3_size,
      height: m3_size,
      textSize: 14,
      anchorText: [0, 0],
      anchorIcon: [m3_size/2, m3_size/2],
      textColor: textColor_m2_m5,
    },
    {
      url: `${clusterIcon_imagePath}4.png`,
      width: m4_size,
      height: m4_size,
      textSize: 14,
      anchorText: [0, 0],
      anchorIcon: [m4_size/2, m4_size/2],
      textColor: textColor_m2_m5,
    },
    {
      url: `${clusterIcon_imagePath}5.png`,
      width: m5_size,
      height: m5_size,
      textSize: 14,
      anchorText: [0, 0],
      anchorIcon: [m5_size/2, m5_size/2],
      textColor: textColor_m2_m5,
    }
  ];

  const options = {
    gridSize: 80,
    styles: clusterStyles,
    maxZoom: 14,
  };

  /**
   * 情報ウィンドウ(吹き出し)の内容を取得する。
   * 
   * @param d 不動産情報
   * @returns 情報ウィンドウ(吹き出し)の内容
   */
  const render_infoWindowContent = (d: EstateDataType) => {
    let rendering_left = [];   // 吹き出しの左側
    let rendering_right = [];  // 吹き出しの右側

    // 物件名
    switch (myLang) {
      case MyLanguage.JA: d.property_name && rendering_left.push(<div className="property_name">{d.property_name}</div>); break;
      case MyLanguage.EN: d.property_name_en && rendering_left.push(<div className="property_name">{d.property_name_en}</div>); break;
    }
    // CR(NOI)
    if (!EstateDataUtil.convert_disclose_cap_rate(d.disclose_cap_rate)) {
      rendering_left.push(<div>{t('CR（NOI）')}：{t('非開示')}</div>);
    }
    else if (d.cap_rate !== null) {
      let rendering = [];
      rendering.push(<>{t('CR（NOI）')}：{EstateDataUtil.convert_capRate_percentage(d.cap_rate)}%</>);
      if (EstateDataUtil.convert_note_cap_rate(d.note_cap_rate)) {
        rendering.push(<span className="note">&nbsp;<span className="inner">{t('注')}</span><span className="balloon">{t('「取得後CFのNOI/取得価格」若しくは「直近CFのNOI/売却価格」')}</span></span>);
      }
      rendering_left.push(<div>{rendering}</div>);
    }
    // 取引価格
    if (!EstateDataUtil.convert_disclose_tx_price(d.disclose_tx_price)) {
      rendering_left.push(<div>{t('取引価格')}：{t('非開示')}</div>);
    }
    else if (d.transaction_price !== null) {
      let text = '';
      switch (myLang) {
        case MyLanguage.JA: {
          text = `${t('取引価格')}：${Number(d.transaction_price).toLocaleString()} 百万円`;
          break;
        }
        case MyLanguage.EN: {
          text = `${t('取引価格')}：&yen; ${Number(d.transaction_price).toLocaleString()}m`;
          break;
        }
      }
      rendering_left.push(<div dangerouslySetInnerHTML={{ __html : text }}></div>);
    }
    // 取引単価
    if (d.unit_price !== null) {
      let text = '';
      const unit_price = d.unit_price / 1000;
      switch (myLang) {
        case MyLanguage.JA:
          text = unit_price.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + ' 千円/坪';  // カンマ区切り＆小数点以下0桁
          rendering_left.push(<td>{t('取引単価')}：{text}</td>);
          break;
        case MyLanguage.EN:
          text = (CommonUtil.tsubo_to_spft(unit_price)).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + 'k/sq.ft';  // カンマ区切り＆小数点以下0桁
          rendering_left.push(<td>{t('取引単価')}：&yen; {text}</td>);
          break;
        default:
          rendering_left.push(<td>{t('取引単価')}：{text}</td>);
          break;
      }
    }
    // 取引時点
    if (d.transaction_yyyymmdd) {
      rendering_left.push(<div>{t('取引年月')}：{EstateDataUtil.convert_YearMonth(d.transaction_yyyymmdd, myLang)}</div>)
    }
    // タイプ
    if (d.building_type) {
      rendering_left.push(<div>{t('タイプ')}：{t(EstateDataUtil.convert_buildingType_name(d.building_type, d.building_type2))}</div>)
    }

    // フッター
    let footer = [];
    // 「DATA」ボタン
    if (EstateDataUtil.has_hist_data(d)) {
      if (!CommonUtil.is_mobile()) {
        const is_Nippon_Building = EstateDataUtil.is_Nippon_Building(d);
        const className = is_Nippon_Building ? 'nippon_building' : '';
        footer.push(<button className={classNames("footer_button", className)} onClick={() => handleHistoricalDataButton(d.id, d.property_no, is_Nippon_Building)}>DATA</button>);
      }
    }
    // 「Source」ボタン
    let source_files = '';
    switch (myLang) {
      case MyLanguage.JA:
        source_files = d.source_files;
        break;
      case MyLanguage.EN:
        source_files = d.source_files_en;
        break;
    }
    if (source_files) {
      const splits = source_files.split('|');
      for (let i=0; i<splits.length; i++) {
        const filename = `${splits[i]}.pdf`;
        footer.push(<button className="footer_button" onClick={() => handleSourceButton(filename)}>PDF</button>);
      }
    }

    // ×ボタン
    const render_close_button = () => {
      // 固定表示していない場合はボタンは表示しない。
      if (!props.popupEstates.some(item => {
        return item.id === d.id
      })) {
        return null;
      }

      return (
        <div className="close_button" onClick={() => {remove_from_infoWindows(d)}}>
          <svg x="0px" y="0px" viewBox="0 0 512 512" width="9" height="9" version="1.1">
            <g>
              <polygon points="512,52.535 459.467,0.002 256.002,203.462 52.538,0.002 0,52.535 203.47,256.005 0,459.465 52.533,511.998 256.002,308.527 459.467,511.998 512,459.475 308.536,256.005"></polygon>
            </g>
          </svg>
        </div>
      );
    }

    return (
      <div className="info_window">
        <div className="window_header">
          {render_close_button()}
        </div>
        <div className={classNames('content', { 'has_note_cap_rate': EstateDataUtil.convert_note_cap_rate(d.note_cap_rate) })}>
          <div className="left">{rendering_left}</div>
        </div>
        <footer>{footer}</footer>
      </div>
    );
  }

  const handleHistoricalDataButton = async (id: number, property_no: number, is_Nippon_Building: boolean) => {
    if (!is_Nippon_Building && AuthUtil.restricted(memberType, MemberType.C)) {
      setIsOpenUserRegistrationDialog(true);
      return;
    }
    // 別タブで「DATA」のページを開く。
    let url = `${ContextRoot}/?mode=${DisplayMode.Data}&id=${id}&lang=${myLang}`;
    if (property_no !== null) {
      url += `&property_no=${property_no}`;
    }
    window.open(url, '_blank', 'noreferrer');    
  }

  const handleSourceButton = (source_file: string) => {
    if (AuthUtil.restricted(memberType, MemberType.C)) {
      setIsOpenUserRegistrationDialog(true);
      return;
    }
    // 別タブで「Source」のページを開く。
    const url = `./sources/${source_file}`;
    window.open(url, '_blank', 'noreferrer');
  }

  const labeling = (d: EstateDataType) => {
    if (is_checked(d)) {
      // 同じ物件がラベル化されている場合は何もしない。
      return;
    }
    if (labeledEstateData.length >= MaxLabel) {
      // ラベルの上限に達していたらダイアログで警告する。
      setDialogMsg(t('上限に達しました。最大X件まで追加できます。', { num: MaxLabel }));
      return;
    }

    let newLabeledEstateData = [...labeledEstateData];
    newLabeledEstateData.push(d);
    setLabeledEstateData(newLabeledEstateData);

    if (!props.popupEstates.some(item => {
      return item.id === d.id
    })) {
      // すでに追加済みでなければ追加する。
      let newPopupEstate = [...props.popupEstates];
      newPopupEstate.push(d);
      props.setPopupEstates(newPopupEstate); 
    }
  }

  const is_checked = (d: EstateDataType) : boolean => {
    for (let labeled_d of labeledEstateData) {
      if (labeled_d.id === d.id) return true;
    }
    return false;
  }

  /**
   * 
   * @param clusterer クラスタリングマーカーを処理するレンダーのプロパティ
   * @param d 不動産情報
   * @returns マーカーのレンダリング内容
   */
  const render_marker = (clusterer: any, d: EstateDataType) => {
    const opts = get_markerOpts(d);
    return (
      <Marker
        key={d.id}  // keyを指定しないと、再検索した際にクラスタが再検索前の位置に置かれてしまう。
        clusterer={props.showOnlyLabeled ? undefined : clusterer}  // チェックを入れた不動産だけを表示する場合は、クラスタ化はしない。
        position={opts.position}
        icon={opts.icon}

        onClick={() => {
          // マウスオーバーで表示した吹き出しは閉じる。
          // [memo] マウスオーバー中、吹き出しが2重表示されるが、リレンダリングで重くなるのでコメントアウト。
          // setMouseOverEstate(null);
 
          if (is_checked(d)) {
            // ラベル化している場合は解除する。
            let newLabeledEstateData = labeledEstateData.filter(labeled_d => {
              return labeled_d.id !== d.id;
            });
            setLabeledEstateData(newLabeledEstateData);

            // 吹き出しを閉じる。
            remove_from_infoWindows(d);
            return;
          }

          // ラベリング
          labeling(d);
        }}

        onMouseOver={() => {
          if (!props.popupEstates.some(item => {
            return item.id === d.id
          })) {
            // 同じ吹き出しを多重に表示しないようにする。
            setMouseOverEstate(d);
          }
        }}

        onMouseOut={() => {
          setMouseOverEstate(null);
        }}
      >
      </Marker>
    );
  }

  /**
   * タイプに合わせたマーカーオプションを取得する。
   * 
   * @param d 不動産情報
   * @returns マーカーオプション
   */
  const get_markerOpts = (d: EstateDataType) => {
    const icon = get_marker_icon(d);
    
    let property_name = '';
    switch (myLang) {
      case MyLanguage.JA: property_name = d.property_name; break;
      case MyLanguage.EN: property_name = d.property_name_en; break;
    }

    return {
      position: { lat: Number(d.lat), lng: Number(d.lng) },
      title: property_name,
      icon: {
        url: `${ContextRoot}/images/marker/${icon}`,
        scaledSize: get_icon_size(d),
      },
    }
  }

  const get_marker_icon = (d: EstateDataType) => {
    for (let i=0; i<labeledEstateData.length; i++) {
      // チェックを入れた物件は数字アイコンにする。
      if (d.id === labeledEstateData[i].id) {
        return `${i+1}.png`;
      }
    }

    switch (d.building_type) {
      case 1:  return 'office.png';       // オフィス
      case 2:  return 'residential.png';  // レジデンシャル
      case 3:  return 'retail.png';       // リテール
      case 4:  return 'logistics.png';    // ロジスティクス
      case 5:  return 'hotel.png';        // ホテル
      case 99: return 'others.png';       // その他
      default: return 'others.png';       // 不正値(その他と同じにする)
    }
  }

  const get_icon_size = (d: EstateDataType) => {
    for (let i=0; i<labeledEstateData.length; i++) {
      // チェックを入れた物件は数字アイコンにする。
      if (d.id === labeledEstateData[i].id) {
        return new window.google.maps.Size(12, 12);
       // return new window.google.maps.Size(24, 24);
      }
    }

    return new window.google.maps.Size(10, 15);
  //  return new window.google.maps.Size(20, 30);
  }

  const remove_from_infoWindows = (d: EstateDataType) => {
    const newClickedEstate = props.popupEstates.filter(item => {
      return item.id !== d.id
    });
    props.setPopupEstates(newClickedEstate);
  }

  const render_infoWindows = () => {
    if (props.popupEstates.length === 0) return null;

    let rendering = [];
    for (let i=0; i<props.popupEstates.length; i++) {
      const d = props.popupEstates[i];
      rendering.push(
        <InfoWindow key={d.id}
          options={{
            pixelOffset: new window.google.maps.Size(0, -24),
            disableAutoPan: true,  // パンさせると吹き出しに合わせて地図が移動してマウスアウトしてしまうため抑止。
            zIndex: i,  // 後から追加したものを前面に表示する。
          }}
          position={
            {
              lat: Number(d.lat),
              lng: Number(d.lng)
            }
          }
          onCloseClick={() => {
            remove_from_infoWindows(d);
          }}
        >
          {render_infoWindowContent(d)}
        </InfoWindow>
      )
    }

    return rendering;
  }

  const render_infoWindow_temp = () => {
    if (!mouseOverEstate) return null;

    const infoWindowOptions = {
      pixelOffset: new window.google.maps.Size(0, -24),
      disableAutoPan: true,  // パンさせると吹き出しに合わせて地図が移動してマウスアウトしてしまうため抑止。
      zIndex: labeledEstateData.length + 1  // 最前面に表示する。
    }

    return (
      <InfoWindow key={mouseOverEstate.id}
        options={infoWindowOptions}
        position={
          {
            lat: Number(mouseOverEstate.lat),
            lng: Number(mouseOverEstate.lng)
          }
        }
        onCloseClick={() => {
          // 吹き出しを閉じたら、クリア
          setMouseOverEstate(null);
        }}
      >
        {render_infoWindowContent(mouseOverEstate)}
      </InfoWindow>
    );
  }

  /**
   * クラスターのスタイルをカスタマイズ
   * @param markers 
   * @param numStyles 
   * @returns 
   */
  const custom_calculator = (markers: any, numStyles: any) => {
    let index = 0;
    // クラスターに含まれるマーカーの数
    const count: number = markers.length;

    // 画面上に存在する物件数
    const num_data = calc_num_marker_in_screen();

    // クラスターのスタイルは最大5段階まで。
    // クラスターに含まれるマーカーの数と設定するスタイル(index)を以下のように定義する。
    // ------------------------------------------
    // 1段階：10件以下
    // 2段階：画面上の物件数の10%以下
    // 3段階：画面上の物件数の10%より多く20%以下
    // 4段階：画面上の物件数の20%より多く30%以下
    // 5段階：画面上の物件数の30%より多い
    // ------------------------------------------
    if (count <= num_data * 0.1) { index = 2; }
//    if (count > 0 && count <= 10) { index = 1; }
//    else if (count <= num_data * 0.1) { index = 2; }
    else if ((count <= num_data * 0.2) && (count > num_data * 0.1)) { index = 3; }
    else if ((count <= num_data * 0.3) && (count > num_data * 0.2)) { index = 4; }
    else if (count > num_data * 0.3) { index = 5; }

    index = Math.min(index, numStyles);
    return {
      text: count.toString(),
      // text: count + " ("+ index + ")",
      index: index,
      title: '',
    }
  }

  const calc_num_marker_in_screen = () => {
    let num_marker_in_screen = 0;
    const map_bounds = props.mapRef.current.getBounds();
    for (let d of props.estateData) {
      if (map_bounds.contains({ lat: Number(d.lat), lng: Number(d.lng) })) {
        num_marker_in_screen++;
      }
    }
    return num_marker_in_screen;
  }

  // 不動産情報が変わるたびにkeyを更新することで、再検索した際に地図上のマーカーなどを作り直させる。(速度向上のため)
  // idを文字列として全て連結したものをkeyとしている。
  // (今後データが増えた際に、もしkeyの長さが問題になる場合は、ハッシュ値にしても良いかもしれない)
  let key = '';
  for (let d of props.estateData) {
    key += d.id.toString();
  }

  return (
    <>
      <MarkerClusterer key={key} options={options} calculator={custom_calculator} onClusteringEnd={(markerClusterer) => {
        if (props.estateData.length === markerClusterer.markers.length) {
        }        
      }}
      >
        {(clusterer) => 
          props.estateData.map(
            (data: any) => render_marker(clusterer, data)
          ) 
        }
      </MarkerClusterer>
      {render_infoWindows()}
      {render_infoWindow_temp()}
      <MapMlit
        mapRef={props.mapRef}
        setIsMapInteractive={props.setIsMapInteractive}
        isLandPricesActive={props.isLandPricesActive}
        isZoningActive={props.isZoningActive}
      />
      <HazardMapLayers
        isFloodActive={props.isFloodActive}
        isSedimentActive={props.isSedimentActive}
        isTsunamiActive={props.isTsunamiActive}
        isStormSurgeActive={props.isStormSurgeActive}
      />
    </>
  );
}

export default MapContent;
