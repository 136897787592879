import './ValueData.css';
import { FC, useEffect, useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { AppContext, MyLanguage } from '../context/AppContext';
import ValueMapDataUtil from "../utils/ValueMapDataUtil";
import { ValueMapDataType } from "../utils/ValueMapDataType";
import EstateDataUtil from '../utils/EstateDataUtil';
import CommonUtil from '../utils/CommonUtil';

const ValueData: FC = () => { 

  const ContextRoot = CommonUtil.get_context_root();
  
  const [ t ] = useTranslation();
  const { myLang, selectedId } = useContext(AppContext);
  const [ valueMapDataForDetail, setValueMapForDetail ] = useState<ValueMapDataType[]>([]);

  const detail_first_tr_classname = 'first';
  const detail_last_tr_classname = 'last';
  const bold_classname = 'bold';

  useEffect(() => {
    const cond = {
      id: selectedId,
    }
    ValueMapDataUtil.fetch_value_map_data(cond, setValueMapForDetail, () => {});
  }, []);




  const render_left = () => {
    if (valueMapDataForDetail.length === 0) return null;
    const d = valueMapDataForDetail[0];

    let rows: any[] = [];
    render_basic(d, rows);
    render_blank_row(rows);
    render_kakaku(d, rows);

    return (
      <>
        <table className="left">
          {rows}
        </table>
      </>
    );
  }


  const render_basic = (d: ValueMapDataType, rows: any[]) => {
    const tr_className = 'basic';

    let tds = [];

    // 物件名
    {
      tds = [];
      tds.push(<td></td>);
      let property_name = '';
      switch (myLang) {
        case MyLanguage.JA: {
          if (d.property_name) property_name = d.property_name;
          break;
        }
        case MyLanguage.EN: {
          if (d.property_name_en) property_name = d.property_name_en;
          break;
        }
      }
      tds.push(<td className={bold_classname}>{property_name}</td>);
      rows.push(<tr>{tds}</tr>);  
    }

    // 物件の写真
    {
      tds = [];
      if (d.image_file) {
        const image_src = `${ContextRoot}/images/value_map/${d.image_file}.jpg`;
        tds.push(<td></td>)
        tds.push(<td><img src={image_src}></img></td>); 
        rows.push(<tr className="image_row">{tds}</tr>);  
      }  
    }

    // 住居表示
    {
      tds = [];
      tds.push(<td>{t('住居表示')}</td>);
      let rendering = [];
      switch (myLang) {
        case MyLanguage.JA: {
          rendering.push(<>{d.addr1}{d.addr2}</>);
          if (d.addr3) {
            rendering.push(<>{d.addr3}</>);
          }
          break;
        }
        case MyLanguage.EN: {
          if (d.addr3_en) {
            rendering.push(<>{d.addr3_en},&nbsp;</>)
          }
          rendering.push(<>{d.addr2_en},&nbsp;{d.addr1_en}</>);
          break;
        }
      }
      tds.push(<td className={classNames('width_adjust')}>{rendering}</td>);

      rows.push(<tr>{tds}</tr>);  
    }

    // 交通施設、距離
    {
      tds = [];
      tds.push(<td>{t('交通施設・距離')}</td>);
      let rendering = [];
      switch (myLang) {
        case MyLanguage.JA: {
          if (d.railway_line) rendering.push(<>{d.railway_line}&nbsp;</>);
          if (d.station) rendering.push(<>{d.station}&nbsp;</>);
          break;
        }
        case MyLanguage.EN: {
          if (d.railway_line_en) rendering.push(<>{d.railway_line_en}&nbsp;</>);
          if (d.station_en) rendering.push(<>{d.station_en}&nbsp;</>);
          break;
        }
      }
      if (d.distance !== null) {
        let distance = '';
        if (d.distance >= 1000) {
          // 小数点以下1桁
          distance = Number(d.distance / 1000).toFixed(1).toLocaleString() + 'km';
        } else {
          distance = String(d.distance) + 'm';
        }
        rendering.push(<>{distance}</>);
      }
      tds.push(<td className={classNames('width_adjust')}>{rendering}</td>);

      rows.push(<tr>{tds}</tr>);  
    }

    // 土地面積
    {
      tds = [];
      tds.push(<td>{t('土地面積（DATA）')}</td>);
      if (d.land_area === null) {
        tds.push(<td className={classNames('width_adjust')}>NA</td>)
      } else {
        const text = `${d.land_area.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} ㎡`;
        tds.push(<td className={classNames('width_adjust')}>{text}</td>);
      }
      rows.push(<tr>{tds}</tr>);  
    }

    // 延床面積 (登記簿)
    {
      tds = [];
      tds.push(<td>  {t('延床面積 (登記簿)')}  </td> );
      if (d.architectural_area === null) {
        tds.push(<td className={classNames('width_adjust')}>NA</td>)
      } else {
        const text = `${d.architectural_area.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} ㎡`;
        tds.push(<td className={classNames('width_adjust')}>{text}</td>);
      }
      rows.push(<tr>{tds}</tr>);  
    }
    
//    // 賃貸可能面積(想定)
//    {
//      tds = [];
//      tds.push(<td>{t('賃貸可能面積(想定)')}</td>);
//      if (d.rentable_area === null) {
//        tds.push(<td className={classNames('width_adjust')}>NA</td>)
//      } else {
//        const text = `${d.rentable_area.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} ㎡`;
//        tds.push(<td className={classNames('width_adjust')}>{text}</td>);
//      }
//      rows.push(<tr>{tds}</tr>);  
//    }

    // 竣工年月
    {
      tds = [];
      tds.push(<td>{t('竣工年月')}</td>);
      if (d.construction_yyyymmdd === null) {
        tds.push(<td className={classNames('width_adjust')}>NA</td>)
      } else {
        tds.push(<td className={classNames('width_adjust')}>{EstateDataUtil.convert_YearMonth(d.construction_yyyymmdd, myLang)}</td>);
      }
      rows.push(<tr>{tds}</tr>);  
    }
    
    // 構造・階層
    {
      tds = [];
      tds.push(<td>{t('構造・階層')}</td>);
      let rendering = [];
      switch (myLang) {
        case MyLanguage.JA: {
          if (d.structure) rendering.push(<>{d.structure}</>);
          if (d.floor) {
            if (rendering.length > 0) rendering.push(<>/</>);
            rendering.push(<>{d.floor}&nbsp;</>);
          }
          break;
        }
        case MyLanguage.EN: {
          if (d.structure_en) rendering.push(<>{d.structure_en}</>);
          if (d.floor_en) {
            if (rendering.length > 0) rendering.push(<>,&nbsp;</>);
            rendering.push(<>{d.floor_en}</>);
          }
          break;
        }
      }
      tds.push(<td className={classNames('width_adjust')}>{rendering}</td>);
      rows.push(<tr>{tds}</tr>);        
    }

    // 建ぺい率・容積率
    {
      tds = [];
      tds.push(<td>{t('建ぺい率・容積率')}</td>);
      let rendering = [];
      if (d.building_coverage_ratio === null) {
        rendering.push(<>NA</>);
      } else {
        rendering.push(<>{EstateDataUtil.convert_rate_percentage(d.building_coverage_ratio, 0)}%</>);
      }

      switch (myLang) {
        case MyLanguage.JA: rendering.push(<>・</>); break;
        case MyLanguage.EN: rendering.push(<>,&nbsp;</>); break;
      }

      if (d.far === null) {
        rendering.push(<>NA</>);
      } else {
        rendering.push(<>{EstateDataUtil.convert_rate_percentage(d.far, 0)}%</>);
      }

      tds.push(<td className={classNames('width_adjust')}>{rendering}</td>);
      rows.push(<tr>{tds}</tr>);        
    }

    // 用途区分
    {
      tds = [];
      tds.push(<td>{t('用途区分')}</td>);
      let rendering = [];
      switch (myLang) {
        case MyLanguage.JA: {
          if (d.zooning) {
            rendering.push(<>{d.zooning}</>);
          }
          if (d.fireproof) {
            if (rendering.length > 0) rendering.push(<>・</>);
            rendering.push(<>{d.fireproof}</>);
          }
          break;
        }
        case MyLanguage.EN: {
          if (d.zooning_en) {
            rendering.push(<>{d.zooning_en}</>);
          }
          if (d.fireproof_en) {
            if (rendering.length > 0) rendering.push(<>,&nbsp;</>);
            rendering.push(<>{d.fireproof_en}</>);
          }
          break;
        }
      }
      tds.push(<td className={classNames('width_adjust')}>{rendering}</td>);

      if(d.remarks === null) {
        rows.push(<tr className={classNames(tr_className, detail_last_tr_classname)}>{tds}</tr>);  
      } else {
      rows.push(<tr>{tds}</tr>);
    }    
    }

    // 特記
    if (d.remarks === null) {} else 
    {
      tds = [];
      tds.push(<td>{t('特記')}</td>);
      let rendering = [];
      switch (myLang) {
        case MyLanguage.JA: if (d.remarks) rendering.push(<>{d.remarks}</>); break;
        case MyLanguage.EN: if (d.remarks_en) rendering.push(<>{d.remarks_en}</>); break;
      }
      tds.push(<td className={classNames('width_adjust')}>{rendering}</td>);
      rows.push(<tr className={classNames(tr_className, detail_last_tr_classname)}>{tds}</tr>);  
    }
  }

  const render_kakaku = (d: ValueMapDataType, rows: any[]) => {
    const tr_className = 'kakaku';

    let tds = [];

    // 固定資産税路線価
    {
      tds = [];
      tds.push(<td>{t('固定資産税路線価（最新）')}</td>);
      if (d.rosenka === null) {
        tds.push(<td className={classNames('width_adjust')}>NA</td>)
      } else {
        let text_rosenka = '';
        switch (myLang) {
          case MyLanguage.JA: text_rosenka = `${d.rosenka.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} 円/㎡`;break;
          case MyLanguage.EN: text_rosenka = `¥${d.rosenka.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} /㎡`;break;
        }
        tds.push(<td className={classNames('width_adjust')}>{text_rosenka}</td>)
      }
      rows.push(<tr className={classNames(tr_className, detail_first_tr_classname)}>{tds}</tr>);  
    }

    // 地価公示等
    {
      tds = [];
      if(d.kouji_no === null) {
        tds.push(<td>{t('地価公示等')}</td>);
      } else {
        let text_kouji_no = '';
        switch (myLang) {
          case MyLanguage.JA: text_kouji_no = '地価公示等' + '（' + d.kouji_no + '）'; break;
          case MyLanguage.EN: text_kouji_no = 'Government value'; break;
        }
        tds.push(<td className={classNames('width_adjust')}>{text_kouji_no}</td>)
      }
      if (d.rosenka === null) {
        tds.push(<td className={classNames('width_adjust')}>NA</td>)
      } else {
        let text_rosenka = '';
        switch (myLang) {
          case MyLanguage.JA: text_rosenka = `${d.kouji.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} 円/㎡`;break;
          case MyLanguage.EN: text_rosenka = `¥${d.kouji.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} /㎡`;break;
        }
        tds.push(<td className={classNames('width_adjust')}>{text_rosenka}</td>)
      }
      rows.push(<tr>{tds}</tr>);  
    }

  }


  const render_detail = () => {
    if (valueMapDataForDetail.length === 0) return null;
    const d = valueMapDataForDetail[0];

    let rows: any[] = [];
    render_detail_1(d, rows);
    render_detail_2(d, rows);
    render_blank_row(rows);
    render_detail_3(d, rows);

    return (
      <>
        <table className="detail">
          {rows}
        </table>
      </>
    );
  }

  const render_blank_row = (rows: any[]) => {
    rows.push(<tr className='blank_row'><td colSpan={3}></td></tr>);
  }

  const render_detail_1 = (d: ValueMapDataType, rows: any[]) => {
    const tr_className = 'detail_1';

    let tds = [];

    // 評価額
    {
      tds = [];
      tds.push(<td>{t('評価額')}</td>);
      if (d.appraisal_value === null) {
        render_na(tds);
      } else {
        render_appraisal_value(d.appraisal_value, d.rentable_area, tds);
      }

      let comment_appraisal_value = '';
      switch (myLang) {
        case MyLanguage.JA: if (d.comment_appraisal_value) comment_appraisal_value = d.comment_appraisal_value; break;
        case MyLanguage.EN: if (d.comment_en_appraisal_value) comment_appraisal_value = d.comment_en_appraisal_value; break;
      }
      tds.push(<td>{comment_appraisal_value}</td>);

      rows.push(<tr className={classNames(tr_className, detail_first_tr_classname)}>{tds}</tr>);  
    }

    // 還元利回り	
    {
      tds = [];
      tds.push(<td>{t('還元利回り')}</td>);
      if (d.cap_rate === null) {
        render_na(tds);
      } else {
        tds.push(<td>{EstateDataUtil.convert_rate_percentage(d.cap_rate, 1)}%</td>);
      }

      let comment_cap_rate = '';
      switch (myLang) {
        case MyLanguage.JA: if (d.comment_cap_rate) comment_cap_rate = d.comment_cap_rate; break;
        case MyLanguage.EN: if (d.comment_en_cap_rate) comment_cap_rate = d.comment_en_cap_rate; break;
      }
      tds.push(<td>{comment_cap_rate}</td>);

      rows.push(<tr className={classNames(tr_className, detail_last_tr_classname)}>{tds}</tr>);  
    }
  }

  const render_detail_2 = (d: ValueMapDataType, rows: any[]) => {
    const tr_className = 'detail_2';

    let tds = [];

    rows.push(<tr className={classNames(tr_className, 'detail_2_title', 'blank_row')}><td colSpan={3}>{t('(年間想定収支)')}</td></tr>);

    // 賃料収入
    {
      tds = [];
      tds.push(<td>{t('賃料収入')}</td>);

      if (d.rent === null) {
        render_na(tds);
      } else {
        tds.push(<td>{d.rent.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>);
      }

      let comment_rent = '';
      switch (myLang) {
        case MyLanguage.JA: if (d.comment_rent) comment_rent = d.comment_rent; break;
        case MyLanguage.EN: if (d.comment_en_rent) comment_rent = d.comment_en_rent; break;
      }
      tds.push(<td>{comment_rent}</td>);

      rows.push(<tr className={classNames(tr_className, detail_first_tr_classname)}>{tds}</tr>);  
    }

    // 駐車場収入
    {
      if (d.parking_income === null || d.parking_income === 0 ) {
      } else {
        tds = [];
        tds.push(<td>{t('駐車場収入')}</td>);
        tds.push(<td>{d.parking_income.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>);
      

      let comment_parking_income = '';
      switch (myLang) {
        case MyLanguage.JA: if (d.comment_parking_income) comment_parking_income = d.comment_parking_income; break;
        case MyLanguage.EN: if (d.comment_en_parking_income) comment_parking_income = d.comment_en_parking_income; break;
      }
      tds.push(<td>{comment_parking_income}</td>);

      rows.push(<tr className={classNames(tr_className)}>{tds}</tr>);  
      }
    }

    // 礼金・権利金・更新料収入
    
    {
      if (d.key_money_right_fee_renewal_fee_income === 0 || d.key_money_right_fee_renewal_fee_income === null) {
      } else {
        tds = [];
        tds.push(<td>{t('礼金・権利金・更新料収入')}</td>);
        tds.push(<td>{d.key_money_right_fee_renewal_fee_income.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>);

      let comment_key_money_right_fee_renewal_fee_income = '';
      switch (myLang) {
        case MyLanguage.JA: if (d.comment_key_money_right_fee_renewal_fee_income) comment_key_money_right_fee_renewal_fee_income = d.comment_key_money_right_fee_renewal_fee_income; break;
        case MyLanguage.EN: if (d.comment_en_key_money_right_fee_renewal_fee_income) comment_key_money_right_fee_renewal_fee_income = d.comment_en_key_money_right_fee_renewal_fee_income; break;
      }
      tds.push(<td>{comment_key_money_right_fee_renewal_fee_income}</td>);

      rows.push(<tr className={classNames(tr_className)}>{tds}</tr>);  
    }
  }

    // 水道光熱費収入
    {
      tds = [];
      tds.push(<td>{t('水道光熱費収入')}</td>);

      if (d.utility_income === null) {
        render_na(tds);
      } else {
        tds.push(<td>{d.utility_income.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>);
      }

      let comment_utility_income = '';
      switch (myLang) {
        case MyLanguage.JA: if (d.comment_utility_income) comment_utility_income = d.comment_utility_income; break;
        case MyLanguage.EN: if (d.comment_en_utility_income) comment_utility_income = d.comment_en_utility_income; break;
      }
      tds.push(<td>{comment_utility_income}</td>);

      rows.push(<tr className={classNames(tr_className)}>{tds}</tr>);  
    }

    // その他収入
    {
      tds = [];
      tds.push(<td>{t('その他収入')}</td>);

      if (d.other_income === null) {
        render_na(tds);
      } else {
        tds.push(<td>{d.other_income.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>);
      }

      let comment_other_income = '';
      switch (myLang) {
        case MyLanguage.JA: if (d.comment_other_income) comment_other_income = d.comment_other_income; break;
        case MyLanguage.EN: if (d.comment_en_other_income) comment_other_income = d.comment_en_other_income; break;
      }
      tds.push(<td>{comment_other_income}</td>);

      rows.push(<tr className={classNames(tr_className)}>{tds}</tr>);  
    }

    // 潜在総収益
    {
      tds = [];
      tds.push(<td>{t('潜在総収益')}</td>);

      if (d.gross_potential_income === null) {
        render_na(tds);
      } else {
        tds.push(<td>{d.gross_potential_income.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>);
      }

      tds.push(<td className="gray_cell"></td>);

      rows.push(<tr className={classNames(tr_className)}>{tds}</tr>);  
    }

    // 空室損失等 ( - )
    {
      tds = [];
      tds.push(<td>{t('空室損失等 ( - )')}</td>);

      if (d.vacancy_loss_etc === null) {
        render_na(tds);
      } else {
        tds.push(<td>{d.vacancy_loss_etc.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>);
      }

      let comment_vacancy_loss_etc = '';
      switch (myLang) {
        case MyLanguage.JA: if (d.comment_vacancy_loss_etc) comment_vacancy_loss_etc = d.comment_vacancy_loss_etc; break;
        case MyLanguage.EN: if (d.comment_en_vacancy_loss_etc) comment_vacancy_loss_etc = d.comment_en_vacancy_loss_etc; break;
      }
      tds.push(<td>{comment_vacancy_loss_etc}</td>);

      rows.push(<tr className={classNames(tr_className)}>{tds}</tr>);  
    }

    // 運営収益
    {
      tds = [];
      tds.push(<td>{t('運営収益')}</td>);

      if (d.operating_income === null) {
        render_na(tds);
      } else {
        tds.push(<td>{d.operating_income.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>);
      }

      tds.push(<td className="gray_cell"></td>);

      rows.push(<tr className={classNames(tr_className, "bold")}>{tds}</tr>);  
    }

    // 維持管理費
    {
      tds = [];
      tds.push(<td>{t('維持管理費')}</td>);

      if (d.maintenance_fee === null) {
        render_na(tds);
      } else {
        tds.push(<td>{d.maintenance_fee.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>);
      }

      let comment_maintenance_fee = '';
      switch (myLang) {
        case MyLanguage.JA: if (d.comment_maintenance_fee) comment_maintenance_fee = d.comment_maintenance_fee; break;
        case MyLanguage.EN: if (d.comment_en_maintenance_fee) comment_maintenance_fee = d.comment_en_maintenance_fee; break;
      }
      tds.push(<td>{comment_maintenance_fee}</td>);

      rows.push(<tr className={classNames(tr_className)}>{tds}</tr>);  
    }

    // 水道光熱費
    {
      tds = [];
      tds.push(<td>{t('水道光熱費')}</td>);

      if (d.utility_fee === null) {
        render_na(tds);
      } else {
        tds.push(<td>{d.utility_fee.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>);
      }

      let comment_utility_fee = '';
      switch (myLang) {
        case MyLanguage.JA: if (d.comment_utility_fee) comment_utility_fee = d.comment_utility_fee; break;
        case MyLanguage.EN: if (d.comment_en_utility_fee) comment_utility_fee = d.comment_en_utility_fee; break;
      }
      tds.push(<td>{comment_utility_fee}</td>);

      rows.push(<tr className={classNames(tr_className)}>{tds}</tr>);  
    }

    // 修繕費
    {
      tds = [];
      tds.push(<td>{t('修繕費')}</td>);

      if (d.repair_fee === null) {
        render_na(tds);
      } else {
        tds.push(<td>{d.repair_fee.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>);
      }

      let comment_repair_fee = '';
      switch (myLang) {
        case MyLanguage.JA: if (d.comment_repair_fee) comment_repair_fee = d.comment_repair_fee; break;
        case MyLanguage.EN: if (d.comment_en_repair_fee) comment_repair_fee = d.comment_en_repair_fee; break;
      }
      tds.push(<td>{comment_repair_fee}</td>);

      rows.push(<tr className={classNames(tr_className)}>{tds}</tr>);  
    }

    // PMフィー	
    {
      tds = [];
      tds.push(<td>{t('PMフィー')}</td>);

      if (d.pm_fee === null) {
        render_na(tds);
      } else {
        tds.push(<td>{d.pm_fee.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>);
      }

      let comment_pm_fee = '';
      switch (myLang) {
        case MyLanguage.JA: if (d.comment_pm_fee) comment_pm_fee = d.comment_pm_fee; break;
        case MyLanguage.EN: if (d.comment_en_pm_fee) comment_pm_fee = d.comment_en_pm_fee; break;
      }
      tds.push(<td>{comment_pm_fee}</td>);

      rows.push(<tr className={classNames(tr_className)}>{tds}</tr>);  
    }

    // テナント募集費用
    {
      tds = [];
      tds.push(<td>{t('テナント募集費用')}</td>);

      if (d.tenant_recruitment_fee === null) {
        render_na(tds);
      } else {
        tds.push(<td>{d.tenant_recruitment_fee.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>);
      }

      let comment_tenant_recruitment_fee = '';
      switch (myLang) {
        case MyLanguage.JA: if (d.comment_tenant_recruitment_fee) comment_tenant_recruitment_fee = d.comment_tenant_recruitment_fee; break;
        case MyLanguage.EN: if (d.comment_en_tenant_recruitment_fee) comment_tenant_recruitment_fee = d.comment_en_tenant_recruitment_fee; break;
      }
      tds.push(<td>{comment_tenant_recruitment_fee}</td>);

      rows.push(<tr className={classNames(tr_className)}>{tds}</tr>);  
    }

    // 公租公課（土地）
    {
      tds = [];
      tds.push(<td>{t('公租公課（土地）')}</td>);

      if (d.land_tax === null) {
        render_na(tds);
      } else {
        tds.push(<td>{d.land_tax.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>);
      }

      let comment_land_tax = '';
      switch (myLang) {
        case MyLanguage.JA: if (d.comment_land_tax) comment_land_tax = d.comment_land_tax; break;
        case MyLanguage.EN: if (d.comment_en_land_tax) comment_land_tax = d.comment_en_land_tax; break;
      }
      tds.push(<td>{comment_land_tax}</td>);

      rows.push(<tr className={classNames(tr_className)}>{tds}</tr>);  
    }

    // 公租公課（建物）
    {
      tds = [];
      tds.push(<td>{t('公租公課（建物）')}</td>);

      if (d.building_tax === null) {
        render_na(tds);
      } else {
        tds.push(<td>{d.building_tax.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>);
      }

      let comment_building_tax = '';
      switch (myLang) {
        case MyLanguage.JA: if (d.comment_building_tax) comment_building_tax = d.comment_building_tax; break;
        case MyLanguage.EN: if (d.comment_en_building_tax) comment_building_tax = d.comment_en_building_tax; break;
      }
      tds.push(<td>{comment_building_tax}</td>);

      rows.push(<tr className={classNames(tr_className)}>{tds}</tr>);  
    }

    // 公租公課（償却資産）
    {
      tds = [];
      tds.push(<td>{t('公租公課（償却資産）')}</td>);

      if (d.depreciable_tax === null) {
        render_na(tds);
      } else {
        tds.push(<td>{d.depreciable_tax.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>);
      }

      let comment_depreciable_tax = '';
      switch (myLang) {
        case MyLanguage.JA: if (d.comment_depreciable_tax) comment_depreciable_tax = d.comment_depreciable_tax; break;
        case MyLanguage.EN: if (d.comment_en_depreciable_tax) comment_depreciable_tax = d.comment_en_depreciable_tax; break;
      }
      tds.push(<td>{comment_depreciable_tax}</td>);

      rows.push(<tr className={classNames(tr_className)}>{tds}</tr>);  
    }

    // 損害保険料
    {
      tds = [];
      tds.push(<td>{t('損害保険料')}</td>);

      if (d.insurance === null) {
        render_na(tds);
      } else {
        tds.push(<td>{d.insurance.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>);
      }

      let comment_insurance = '';
      switch (myLang) {
        case MyLanguage.JA: if (d.comment_insurance) comment_insurance = d.comment_insurance; break;
        case MyLanguage.EN: if (d.comment_en_insurance) comment_insurance = d.comment_en_insurance; break;
      }
      tds.push(<td>{comment_insurance}</td>);

      rows.push(<tr className={classNames(tr_className)}>{tds}</tr>);  
    }

    // その他費用
    {
      tds = [];
      tds.push(<td>{t('その他費用')}</td>);

      if (d.other_fee === null) {
        render_na(tds);
      } else {
        tds.push(<td>{d.other_fee.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>);
      }

      let comment_other_fee = '';
      switch (myLang) {
        case MyLanguage.JA: if (d.comment_other_fee) comment_other_fee = d.comment_other_fee; break;
        case MyLanguage.EN: if (d.comment_en_other_fee) comment_other_fee = d.comment_en_other_fee; break;
      }
      tds.push(<td>{comment_other_fee}</td>);

      rows.push(<tr className={classNames(tr_className)}>{tds}</tr>);  
    }

    // 運営費用
    {
      tds = [];
      tds.push(<td>{t('運営費用')}</td>);

      if (d.operating_fee === null) {
        render_na(tds);
      } else {
        tds.push(<td>{d.operating_fee.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>);
      }

      let comment_operating_fee = '';
      switch (myLang) {
        case MyLanguage.JA: if (d.comment_operating_fee) comment_operating_fee = d.comment_operating_fee; break;
        case MyLanguage.EN: if (d.comment_en_operating_fee) comment_operating_fee = d.comment_en_operating_fee; break;
      }
      tds.push(<td>{comment_operating_fee}</td>);

      rows.push(<tr className={classNames(tr_className, "bold")}>{tds}</tr>);  
    }

    // 運営純収益 ( NOI )
    {
      tds = [];
      tds.push(<td>{t('運営純収益 ( NOI )')}</td>);

      if (d.noi === null) {
        render_na(tds);
      } else {
        tds.push(<td>{d.noi.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>);
      }

      let comment_noi = '';
      switch (myLang) {
        case MyLanguage.JA: if (d.comment_noi) comment_noi = d.comment_noi; break;
        case MyLanguage.EN: if (d.comment_en_noi) comment_noi = d.comment_en_noi; break;
      }
      tds.push(<td>{comment_noi}</td>);

      rows.push(<tr className={classNames(tr_className, "bold")}>{tds}</tr>);  
    }
    
    // 一時金の運用益 (+)
    {
      tds = [];
      tds.push(<td>{t('一時金の運用益 (+)')}</td>);

      if (d.lump_sum_investment_income === null) {
        render_na(tds);
      } else {
        tds.push(<td>{d.lump_sum_investment_income.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>);
      }

      let comment_lump_sum_investment_income = '';
      switch (myLang) {
        case MyLanguage.JA: if (d.comment_lump_sum_investment_income) comment_lump_sum_investment_income = d.comment_lump_sum_investment_income; break;
        case MyLanguage.EN: if (d.comment_en_lump_sum_investment_income) comment_lump_sum_investment_income = d.comment_en_lump_sum_investment_income; break;
      }
      tds.push(<td>{comment_lump_sum_investment_income}</td>);

      rows.push(<tr className={classNames(tr_className)}>{tds}</tr>);  
    }

    // 資本的支出 ( - )
    {
      tds = [];
      tds.push(<td>{t('資本的支出 ( - )')}</td>);

      if (d.capital_exp === null) {
        render_na(tds);
      } else {
        tds.push(<td>{d.capital_exp.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>);
      }

      let comment_capital_exp = '';
      switch (myLang) {
        case MyLanguage.JA: if (d.comment_capital_exp) comment_capital_exp = d.comment_capital_exp; break;
        case MyLanguage.EN: if (d.comment_en_capital_exp) comment_capital_exp = d.comment_en_capital_exp; break;
      }
      tds.push(<td>{comment_capital_exp}</td>);

      rows.push(<tr className={classNames(tr_className)}>{tds}</tr>);  
    }

    // 純収益 ( NCF )
    {
      tds = [];
      tds.push(<td>{t('純収益 ( NCF )')}</td>);

      if (d.ncf === null) {
        render_na(tds);
      } else {
        tds.push(<td>{d.ncf.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</td>);
      }

      tds.push(<td className="gray_cell"></td>);

      rows.push(<tr className={classNames(tr_className, detail_last_tr_classname, "bold")}>{tds}</tr>);  
    }
  }

  const render_detail_3 = (d: ValueMapDataType, rows: any[]) => {
    const tr_className = 'detail_3';

    let tds = [];

    // 土地価格
    {
      tds = [];
      tds.push(<td>{t('土地価格（更地）')}</td>);

      let divs = [];
      if (d.land_value === null) {
        divs.push(<div className={classNames('na')}>NA</div>);
      } else {
        let text = '';
        switch (myLang) {
          case MyLanguage.JA: {
            text = `${d.land_value.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} 円`;
            divs.push(<div>{text}</div>);
            break;
          }
          case MyLanguage.EN: {
            text = `${d.land_value.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}`;
            divs.push(<div>&yen; {text}</div>);
            break;
          }
        }
      }
      if (d.building_value !== null) {
        let text = '';
        switch (myLang) {
          case MyLanguage.JA: {
            text = `${d.building_value.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} 円/㎡`;
            divs.push(<div className="normal">( {text} )</div>);
            break;
          }
          case MyLanguage.EN: {
            text = `${d.building_value.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}/㎡`;
            divs.push(<div className="normal">( &yen; {text} )</div>);
            break;
          }
        }
      }
      tds.push(<td>{divs}</td>);

      let comment_land_value = '';
      switch (myLang) {
        case MyLanguage.JA: if (d.comment_land_value) comment_land_value = d.comment_land_value; break;
        case MyLanguage.EN: if (d.comment_en_land_value) comment_land_value = d.comment_en_land_value; break;
      }
      tds.push(<td>{comment_land_value}</td>);

      rows.push(<tr className={classNames(tr_className, detail_first_tr_classname, 'land_value')}>{tds}</tr>);  
    }
  }

  const render_na = (row: any[]) => {
    row.push(<td className={classNames('na')}>NA</td>);
  }

  const render_appraisal_value = (appraisal_value: number, rentable_area: number, row: any[]) => {
    const className = classNames('width_adjust');
    let rendering = [];
    let text = '';
    switch (myLang) {
      case MyLanguage.JA: {
        text = `${appraisal_value.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} 円`;
        rendering.push(<div>{text}</div>);
        break;
      }
      case MyLanguage.EN: {
        text = `${appraisal_value.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}`;
        rendering.push(<div>&yen; {text}</div>);
        break;
      }
    }

    if (rentable_area !== null && rentable_area > 0) {
      const unit_price = appraisal_value / rentable_area;
    /*  const [_, digitStr] = unit_price.toExponential().split('e')
      const unit_price_keta = Number(digitStr)+1 ; */
      switch (myLang) {
        case MyLanguage.JA: {
          text = `${unit_price.toLocaleString(undefined, {minimumSignificantDigits: 3, maximumSignificantDigits: 3})} 円/㎡`;
          rendering.push(<div className="normal">( {text} )</div>);
          break;
        }
        case MyLanguage.EN: {
          text = `${unit_price.toLocaleString(undefined, {minimumSignificantDigits: 3, maximumSignificantDigits: 3})}/㎡`;
          rendering.push(<div className="normal">( &yen; {text} )</div>);
          break;
        }
      }  
    }

    row.push(<td className={className}>{rendering}</td>);
  }

  const render_evaluation_date = () => {
    if (valueMapDataForDetail.length === 0) return null;

    const d = valueMapDataForDetail[0];
    const evaluation_date = CommonUtil.convert_YearMonthDay(d.updated_at, myLang);

    return <div className={classNames("evaluation_date")}>{`${t('評価日：')}${evaluation_date}`}</div>;
  }

  return (
    <div id="ValueData">
      {render_evaluation_date()}
      <div className="row">
        <div className="column">
          {render_left()}
        </div>
        <div className="column">
          {render_detail()}
        </div>
      </div>
    </div>
  );
}

export default ValueData;
