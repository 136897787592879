import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext, MyLanguage } from '../context/AppContext';

import './Footer.css';
import CommonUtil from '../utils/CommonUtil';

const Footer: FC = () => {
  const [ t, i18n ] = useTranslation(); 
  const { myLang } = useContext(AppContext);
  const ContextRoot = CommonUtil.get_context_root();

  return (
    <footer id="Footer">
      <div className='disclaimer'>{t('＊当サイトは投資勧誘を目的とせず、情報の内容についても保証するものではない。')}</div>
      {
        !CommonUtil.is_mobile() &&
        <div>
          <button onClick={() => CommonUtil.open_TermOfUse(myLang)}>{t('利用規約')}</button>
          <button onClick={() => CommonUtil.open_PrivacyPolicy(myLang)}>{t('プライバシーポリシー')}</button>
          <button onClick={() => CommonUtil.open_Company(myLang)}>{t('運営会社')}</button>
          {/* <button onClick={() => CommonUtil.open_Contact(myLang)}>{<img className="mail-icon" src={`${ContextRoot}/images/icon/mail.png`}></img>}</button> */}
        </div>
      }
    </footer>
  );
}

export default Footer