import { FC, useContext } from 'react';
import { AppContext } from '../context/AppContext';
import './Dialog.css';

const Dialog: FC = () => {
  const { dialogMsg, setDialogMsg } = useContext(AppContext);

  const handleClick = () => {
    setDialogMsg('');
  }

  return (
    <div id="Dialog">
      <div className="content">
        <div>{dialogMsg}</div>
        <button onClick={handleClick}>Close</button>
      </div>
    </div>
  );
}

export default Dialog;
