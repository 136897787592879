import './App.css';
import './firebase';
import i18n from 'i18next';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { initReactI18next, useTranslation } from 'react-i18next';
import jaJson from './locales/ja.json';
import enJson from './locales/en.json';
import { AppContext, MyLanguage, DisplayMode, LoginState } from './context/AppContext';
import { AuthProvider } from './context/AuthContext';
import Home from './pages/Home';
import MapPage from './pages/MapPage';
import ListPage from './pages/ListPage';
import ChartPage from './pages/ChartPage';
import StockCalendarPage from './pages/StockCalendarPage';
import ValueMapPage from './pages/ValueMapPage';
import ValueListPage from './pages/ValueListPage';
import UsReitPage from './pages/UsReitPage';
import ContactPage from './pages/ContactPage';
import { EstateDataType } from './utils/EstateDataType';
import EstateDataUtil from './utils/EstateDataUtil';
import InvestmentDataUtil from './utils/InvestmentDataUtil';
import { InvestmentDataType } from './utils/InvestmentDataType';
import { HistInvestmentDataType } from './utils/HistInvestmentDataType';
import { InvestmentCalendarType } from './utils/InvestmentCalendarType';
import HistInvestmentDataUtil from './utils/HistInvestmentDataUtil';
import CommonUtil from './utils/CommonUtil';
import InvestmentCalendarUtil from './utils/InvestmentCalendarUtil';
import ValueMapDataUtil from './utils/ValueMapDataUtil';
import { ValueMapDataType } from './utils/ValueMapDataType';
import GuidePage from './pages/GuidePage';
import { MemberType } from './utils/MemberType';

i18n.use(initReactI18next).init({
  resources: {
    ja: { translation: jaJson },
    en: { translation: enJson },
  },
  lng: 'ja',
  fallbackLng: 'ja',
  interpolation: { escapeValue: false },
});

function App() {
  const ContextRoot = CommonUtil.get_context_root();

  const [ t ] = useTranslation();
  // 言語
  const [ myLang, setMyLang ] = useState(MyLanguage.JA);
  // ダイアログ
  const [ dialogMsg, setDialogMsg ] = useState('');
  // ログインダイアログ
  const [ isOpenLoginDialog, setIsOpenLoginDialog ] = useState(false);
  // ユーザー登録ダイアログ
  const [ isOpenUserRegistrationDialog, setIsOpenUserRegistrationDialog ] = useState(false);
  // 表示モード
  const [ displayMode, setDisplayMode ] = useState(DisplayMode.Stock);
  // 検索タイプ
  const [ searchType, setSearchType ] = useState<string>('');
  // 検索条件(デフォルトは全て取得する)
  const [ searchCond, setSearchCond ] = useState<any>({ 
    building_type1: '1',
    building_type2: '2',
    building_type3: '3',
    building_type4: '4',
    building_type5: '5',
    building_type99: '99'
  });
  // 不動産情報
  const [ estateData, setEstateData ] = useState<EstateDataType[]>([]);
  // ラベル付けした不動産情報
  const [ labeledEstateData, setLabeledEstateData ] = useState<EstateDataType[]>([]);
  // アプリで使用するデータ
  const [ appData, setAppData ] = useState<any>({});
  // ログイン状態
  const [ loginState, setLoginState ] = useState<string>(LoginState.LoggedIn_Without_Auth);
  // 会員タイプ
  const [ memberType, setMemberType ] = useState<number>(MemberType.A);

  // 選択した不動産のID(不動産情報のDATAボタンで開くページ向け)
  const [ selectedId, setSelectedId ] = useState<number | null>(null);
  // 選択した物件No(不動産情報のDATAボタンで開くページ向け)
  const [ selectedPropertyNo, setSelectedPropertyNo ] = useState<number | null>(null);
  // チェックを入れた不動産にフォーカスするのが一回目か否か
  const [ isFirstFocusLabeled, setIsFirstFocusLabeled ] = useState(true);

  // 投資情報
  const [ investmentData, setInvestmentData ] = useState<InvestmentDataType[]>([]);
  // チェックを入れた投資情報
  const [ checkedInvestmentData, setCheckedInvestmentData ] = useState<InvestmentDataType[]>([]);
  // 投資情報(ヒストリカルデータ)
  const [ histInvestmentData, setHistInvestmentData] = useState<HistInvestmentDataType[]>([]);
  // 投資情報(ヒストリカルデータ。各銘柄の最新データのみ)
  const [ histInvestmentDataOnlyLatest, setHistInvestmentDataOnlyLatest ] = useState<HistInvestmentDataType[]>([]);
  // 選択している銘柄の証券コード
  const [ selectedStockCode, setSelectedStockCode ] = useState<number | null>(null);
  // 投資情報(カレンダー)
  const [ investmentCalendar, setInvestmentCalendar ] = useState<InvestmentCalendarType[]>([]);
  // 不動産評価情報
  const [ valueMapData, setValueMapData ] = useState<ValueMapDataType[]>([]);
  const [ labeledValueMapData, setLabeledValueMapData ] = useState<ValueMapDataType[]>([]);
  // 検索タイプ(不動産評価情報)
  const [ searchTypeForValue, setSearchTypeForValue ] = useState<string>('');
  // 検索条件(デフォルトは全て取得する)
  const [ searchCondForValue, setSearchCondForValue ] = useState<any>({ 
    building_type1: '1',
    building_type2: '2',
    building_type3: '3',
    building_type4: '4',
    building_type5: '5',
    building_type99: '99'
  });
  // Value.map初回表示時に表示するダイアログで「確認」を押下したか。
  const [ confirmedValueMap, setConfirmedValueMap ] = useState<boolean>(false);

  useEffect(() => {
    fetch(`${ContextRoot}/jsons/investment_corp.json`, { method: 'GET' })
    .then(res => res.json())
    .then(data => {
      appData['investment_corp'] = data;
      setAppData(appData);
    })
  }, []);

  useEffect(() => {
    // 検索条件が変わったら呼び出され、不動産情報を再取得する。
    EstateDataUtil.fetch_estate_data(searchCond, setEstateData, () => {
      setDialogMsg(t('物件がありません。'));
    });
  }, [searchCond]);

  useEffect(() => {
    let cond = {};
    InvestmentDataUtil.fetch_investment_data(cond, setInvestmentData, () => {
      setDialogMsg(t('投資情報の取得に失敗しました。'));
    });
  }, []);

  useEffect(() => {
    let cond = {};
    HistInvestmentDataUtil.fetch_hist_data(cond, setHistInvestmentData, () => {
      setDialogMsg(t('投資情報の取得に失敗しました。'));
    });

    // ヒストリカルデータの最新データのみ。
    HistInvestmentDataUtil.fetch_hist_data(cond, setHistInvestmentDataOnlyLatest, () => {
      setDialogMsg(t('投資情報の取得に失敗しました。'));
    }, true);
  }, []);

  useEffect(() => {
    let cond = {};
    InvestmentCalendarUtil.fetch_investment_calendar(cond, setInvestmentCalendar, () => {
      setDialogMsg(t('投資情報の取得に失敗しました。'));
    });
  }, []);

  useEffect(() => {
    let cond = {};
    ValueMapDataUtil.fetch_value_map_data(cond, setValueMapData, () => {
      setDialogMsg(t('物件がありません。'));
    });
  }, []);

  useEffect(() => {
    // 検索条件が変わったら呼び出され、不動産情報を再取得する。
    ValueMapDataUtil.fetch_value_map_data(searchCondForValue, setValueMapData, () => {
      setDialogMsg(t('物件がありません。'));
    });
  }, [searchCondForValue]);


  useEffect(() => {
    // URLパラメータ
    const url = new URL(window.location.href)
    const params = url.searchParams;
    const mode = params.get('mode');
    const lang = params.get('lang');
  
    if (mode !== null) {
      // Mapへの遷移はMain.tsxで行う。(navigate()はRouter下でないと呼び出せないため)
      setDisplayMode(mode);

      if (mode === DisplayMode.ValueData) {
        // Value.mapのDATA画面表示では、認証無しでのログインにしておく。
        setLoginState(LoginState.LoggedIn_Without_Auth);
      }
    }
    if (lang !== null) {
      setMyLang(lang);
      i18n.changeLanguage(lang);
    }
  }, []);

  const value = {
    myLang, setMyLang,
    loginState, setLoginState,
    dialogMsg, setDialogMsg,
    isOpenLoginDialog, setIsOpenLoginDialog,
    isOpenUserRegistrationDialog, setIsOpenUserRegistrationDialog,
    memberType, setMemberType,
    displayMode, setDisplayMode,
    searchCond, setSearchCond,
    searchType, setSearchType,
    estateData, setEstateData,
    labeledEstateData, setLabeledEstateData,
    appData, setAppData,
    investmentData, setInvestmentData,
    checkedInvestmentData, setCheckedInvestmentData,
    histInvestmentData, setHistInvestmentData,
    histInvestmentDataOnlyLatest, setHistInvestmentDataOnlyLatest,
    selectedStockCode, setSelectedStockCode,
    selectedId, setSelectedId,
    selectedPropertyNo, setSelectedPropertyNo,
    isFirstFocusLabeled, setIsFirstFocusLabeled,
    investmentCalendar, setInvestmentCalendar,
    valueMapData, setValueMapData,
    labeledValueMapData, setLabeledValueMapData,
    searchTypeForValue, setSearchTypeForValue,
    searchCondForValue, setSearchCondForValue,
    confirmedValueMap, setConfirmedValueMap,
  }

  return (
    <div className="App">
      <AppContext.Provider value={value}>
        <AuthProvider>
          <Router>
            <Routes>
              <Route path={ContextRoot} element={<Home />}></Route>
              <Route path={`${ContextRoot}/calendar`} element={<StockCalendarPage />}></Route>
              <Route path={`${ContextRoot}/map`} element={<MapPage />}></Route>
              <Route path={`${ContextRoot}/list`} element={<ListPage />}></Route>
              <Route path={`${ContextRoot}/chart`} element={<ChartPage />}></Route>
              <Route path={`${ContextRoot}/value.map/MAP`} element={<ValueMapPage />}></Route>
              <Route path={`${ContextRoot}/value.map/LIST`} element={<ValueListPage />}></Route>
              <Route path={`${ContextRoot}/us-reit`} element={<UsReitPage />}></Route>
              <Route path={`${ContextRoot}/guide`} element={<GuidePage />}></Route>
              <Route path={`${ContextRoot}/contact`} element={<ContactPage/>}></Route>
            </Routes>
          </Router>
        </AuthProvider>
      </AppContext.Provider>
    </div>
  );
}

export default App;
