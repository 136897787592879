import React from 'react';
import { FC, useContext, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import classNames from 'classnames';
import Chart from 'react-apexcharts';
import './Stock.css';

import { InvestmentDataName, InvestmentDataType, InvestmentSectorType } from '../utils/InvestmentDataType';
import InvestmentDataUtil from '../utils/InvestmentDataUtil';
import { BuildingTypeType, InvestmentCorpType } from '../utils/EstateDataType';
import { useTranslation } from 'react-i18next';
import { AppContext, DisplayMode, MyLanguage, MaxStockChecked } from '../context/AppContext';
import CommonUtil from '../utils/CommonUtil';
import ToExcel from '../utils/ToExcel';
import { ManagedByReitType } from '../utils/EstateDataType';
import AuthUtil from '../utils/AuthUtil';
import { MemberType } from '../utils/MemberType';

const Stock: FC = () => {
  const ContextRoot = CommonUtil.get_context_root();

  const [ t ] = useTranslation();
  const { myLang } = useContext(AppContext);
  const { investmentData } = useContext(AppContext);
  const { checkedInvestmentData, setCheckedInvestmentData } = useContext(AppContext);
  const { setDisplayMode, setSelectedStockCode } = useContext(AppContext);
  const { setDialogMsg, setIsOpenUserRegistrationDialog } = useContext(AppContext);
  const { setSearchCond } = useContext(AppContext);
  const { memberType } = useContext(AppContext);


  // ソート対象のカラム名
  const [ orderBy, setOrderBy ] = useState<string>('');

  // 各カラムのソート方法(昇順／降順)
  const [ sectorPriorityIndex, setSectorPriorityIndex ] = useState<number>(0);
  const [ isOrderAsc_StockPrice, setIsOrderAsc_StockPrice ] = useState<boolean>(true);
  const [ isOrderAsc_StockPriceChange, setIsOrderAsc_StockPriceChange ] = useState<boolean>(true);
  const [ isOrderAsc_DividendYield, setIsOrderAsc_DividendYield ] = useState<boolean>(true);
  const [ isOrderAsc_LatestForecastDividend, setIsOrderAsc_LatestForecastDividend ] = useState<boolean>(true);
  const [ isOrderAsc_MarketCap, setIsOrderAsc_MarketCap ] = useState<boolean>(true);
  const [ isOrderAsc_MarketCapShare, setIsOrderAsc_MarketCapShare ] = useState<boolean>(true);
  const [ isOrderAsc_PurchasePriceM, setIsOrderAsc_PurchasePriceM ] = useState<boolean>(true);                //20231105 Change
  const [ isOrderAsc_PurchasePriceShare, setIsOrderAsc_PurchasePriceShare ] = useState<boolean>(true);
  const [ isOrderAsc_AppraisalValueM, setIsOrderAsc_AppraisalValueM ] = useState<boolean>(true);              //20231105 Change
  const [ isOrderAsc_AppraisalGainRatioM, setIsOrderAsc_AppraisalGainRatioM ] = useState<boolean>(true);      //20231105 Change
  const [ isOrderAsc_PropertyNumM, setIsOrderAsc_PropertyNumM ] = useState<boolean>(true);
  const [ isOrderAsc_BuildingAgeM, setIsOrderAsc_BuildingAgeM ] = useState<boolean>(true);                    //20231105 Change
  const [ isOrderAsc_OccupancyM, setIsOrderAsc_OccupancyM ] = useState<boolean>(true);                        //20231105 Change
  const [ isOrderAsc_NoiYield, setIsOrderAsc_NoiYield ] = useState<boolean>(true);
  const [ isOrderAsc_ImpliedCr, setIsOrderAsc_ImpliedCr ] = useState<boolean>(true);
  const [ isOrderAsc_NavPerUnit, setIsOrderAsc_NavPerUnit ] = useState<boolean>(true);
  const [ isOrderAsc_NavRatio, setIsOrderAsc_NavRatio ] = useState<boolean>(true);
  const [ isOrderAsc_FfoPerUnit, setIsOrderAsc_FfoPerUnit ] = useState<boolean>(true);
  const [ isOrderAsc_FfoRatio, setIsOrderAsc_FfoRatio ] = useState<boolean>(true);
  const [ isOrderAsc_PayoutRatio, setIsOrderAsc_PayoutRatio ] = useState<boolean>(true);
  const [ isOrderAsc_IcrDscr, setIsOrderAsc_IcrDscr ] = useState<boolean>(true);
  const [ isOrderAsc_Ltv, setIsOrderAsc_Ltv ] = useState<boolean>(true);
  const [ isOrderAsc_Roe, setIsOrderAsc_Roe ] = useState<boolean>(true);
  const [ isOrderAsc_IssuedInvestment, setIsOrderAsc_IssuedInvestment ] = useState<boolean>(true);
  const [ isOrderAsc_Rating, setIsOrderAsc_Rating ] = useState<boolean>(true);
  const [ isOrderAsc_ListingDate, setIsOrderAsc_ListingDate ] = useState<boolean>(true);
  const [ isOrderAsc_StockCode, setIsOrderAsc_StockCode ] = useState<boolean>(true);

  const SectorTypeValues = Object.values(InvestmentSectorType).filter((v): v is InvestmentSectorType => typeof v === 'number') as InvestmentSectorType[];

  const twrapperRef = useRef<HTMLDivElement>(null);
  const theadRef = useRef<HTMLTableSectionElement>(null);

  // 投資エリアの円グラフアイコンにマウスをあわせた投資データ。
  const [ data_mouseEnterInvestmentArea, setData_mouseEnterInvestmentArea ] = useState<InvestmentDataType | null>(null);
  // 物件タイプの円グラフアイコンにマウスをあわせた投資データ。
  const [ data_mouseEnterPropertyType, setData_mouseEnterPropertyType ] = useState<InvestmentDataType | null>(null);
  // 円グラフの表示位置
  interface PieChartPosition {
    left: string;
    top: string;
  }
  const [ popupPieChartPosition, setPopupPieChartPosition ] = useState<PieChartPosition>({ left: '0px', top: '0px' });

  // 円グラフの吹き出しのサイズ
  const ChartWidth = 400;
  const ChartHeight = 280;
  
  
  /**
   * 円グラフを表示する位置を取得する。
   * 
   * @param e マウスイベント
   * @returns 円グラフを表示する位置
   */
  const get_popup_pie_chart_position = (e: any): PieChartPosition => {
    let pos: PieChartPosition = { left: '0px', top: '0px'};

    if (twrapperRef.current === null) return pos;
    if (theadRef.current === null) return pos;

    let leftPosition = 0;
    let topPosition = 0;

    if ((e.target.parentElement.offsetLeft - twrapperRef.current.scrollLeft) > (window.innerWidth / 2)) {
      // 円グラフがアイコンの左に表示して画面に収まる場合は左に表示する。
      leftPosition = e.target.parentElement.offsetLeft - ChartWidth + (e.target.parentElement.offsetWidth / 2);
    } else {
      // 円グラフがアイコンの右に表示して画面に収まる場合は右に表示する。
      leftPosition = e.target.parentElement.offsetLeft + (e.target.parentElement.offsetWidth / 2);
    }

    if ((e.target.parentElement.offsetTop + theadRef.current.offsetHeight - twrapperRef.current.scrollTop) > (window.innerHeight / 2)) {
      // 円グラフがアイコンの上に表示して画面に収まる場合は上に表示する。
      topPosition = e.target.parentElement.offsetTop + theadRef.current.offsetHeight - ChartHeight + e.target.parentElement.offsetHeight;
    } else {
      // 円グラフがアイコンの下に表示して画面に収まる場合は下に表示する。
      topPosition = e.target.parentElement.offsetTop + e.target.parentElement.offsetHeight + theadRef.current.offsetHeight;
    }

    pos.left = `${leftPosition}px`;
    pos.top = `${topPosition}px`;
    return pos;
  }

  /**
   * 投資情報をソートする。
   * 
   * @returns ソートした投資情報
   */
  const sort_data = () => {
    if (!orderBy) return investmentData;

    const my_compare = (a: InvestmentDataType, b: InvestmentDataType, key: keyof InvestmentDataType, isOrderAsc: boolean): number => {
      // null値の場合は後ろに並べる。
      if (!(key in a)) return 1;
      if (!(key in b)) return -1;
      if (a[key] === null) return 1;
      if (b[key] === null) return -1;

      if (isOrderAsc) {
        return Number(a[key]) - Number(b[key]);
      } else {
        return Number(b[key]) - Number(a[key]);
      }
    }

    const my_comapre_sector = (a: InvestmentDataType, b: InvestmentDataType, key: keyof InvestmentDataType): number => {
      // 優先して表示するセクターは前に並べる。
      if (Number(a[key]) >= SectorTypeValues[sectorPriorityIndex]){
        if(Number(b[key]) >= SectorTypeValues[sectorPriorityIndex]){
          return Number(a[key]) - Number(b[key]);
        } else{
          return -1;
        }
      }else{
        if(Number(b[key]) >= SectorTypeValues[sectorPriorityIndex]){
          return 1;
        } else{
          return Number(a[key]) - Number(b[key]);
        }
      }
    }
    /* const my_comapre_sector = (a: InvestmentDataType, b: InvestmentDataType, key: keyof InvestmentDataType): number => {
      // 優先して表示するセクターは前に並べる。
      if (Number(a[key]) === SectorTypeValues[sectorPriorityIndex]) return -1;
      if (Number(b[key]) === SectorTypeValues[sectorPriorityIndex]) return 1;

      return Number(a[key]) - Number(b[key]);
    }*/

    let sortedInvestmentData = [...investmentData];
    switch (orderBy) {
      case InvestmentDataName.Sector:
        sortedInvestmentData.sort((a, b) => my_comapre_sector(a, b, 'sector'));
        break;
      case InvestmentDataName.StockPrice:
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'stock_price', isOrderAsc_StockPrice));
        break;
      case InvestmentDataName.StockPriceChange:
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'stock_price_change', isOrderAsc_StockPriceChange));
        break;
      case InvestmentDataName.DividendYield:
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'dividend_yield', isOrderAsc_DividendYield));
        break;
      case InvestmentDataName.LatestForecastDividend:
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'latest_forecast_dividend', isOrderAsc_LatestForecastDividend));
        break;
      case InvestmentDataName.MarketCap:
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'market_cap', isOrderAsc_MarketCap));
        break;
      case InvestmentDataName.MarketCapShare:
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'market_cap_share', isOrderAsc_MarketCapShare));
        break;
      case InvestmentDataName.PurchasePriceM:      //20231105 Change
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'purchase_price_M', isOrderAsc_PurchasePriceM));  //20231105 Change
        break;
      case InvestmentDataName.PurchasePriceShare:
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'purchase_price_share', isOrderAsc_PurchasePriceShare));
        break;
      case InvestmentDataName.AppraisalValueM:     //20231105 Change
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'appraisal_value_M', isOrderAsc_AppraisalValueM));  //20231105 Change
        break;
      case InvestmentDataName.AppraisalGainRatioM: //20231105 Change
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'appraisal_gain_ratio', isOrderAsc_AppraisalGainRatioM)); //20231105 Change
        break;
      case InvestmentDataName.PropertyNumM:
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'property_num_M', isOrderAsc_PropertyNumM));
        break;
      case InvestmentDataName.BuildingAgeM: //20231105 Change
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'building_age_M', isOrderAsc_BuildingAgeM));
        break;
      case InvestmentDataName.OccupancyM: //20231105 Change
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'occupancy_M', isOrderAsc_OccupancyM)); //20231105 Change
        break;
      case InvestmentDataName.NoiYield:
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'noi_yield', isOrderAsc_NoiYield));
        break;
      case InvestmentDataName.ImpliedCr:
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'implied_cr', isOrderAsc_ImpliedCr));
        break;
      case InvestmentDataName.NavPerUnit:
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'nav_per_unit', isOrderAsc_NavPerUnit));
        break;
      case InvestmentDataName.NavRatio:
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'nav_ratio', isOrderAsc_NavRatio));
        break;
      case InvestmentDataName.FfoPerUnit:
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'ffo_per_unit', isOrderAsc_FfoPerUnit));
        break;
      case InvestmentDataName.FfoRatio:
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'ffo_ratio', isOrderAsc_FfoRatio));
        break;
      case InvestmentDataName.PayoutRatio:
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'payout_ratio', isOrderAsc_PayoutRatio));
        break;
      case InvestmentDataName.IcrDscr:
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'icr_dscr', isOrderAsc_IcrDscr));
        break;
      case InvestmentDataName.Ltv:
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'ltv', isOrderAsc_Ltv));
        break;
      case InvestmentDataName.Roe:
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'roe', isOrderAsc_Roe));
        break;
      case InvestmentDataName.IssuedInvestment:
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'issued_investment', isOrderAsc_IssuedInvestment));
        break;
      case InvestmentDataName.Rating:
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'rating', isOrderAsc_Rating));
        break;
      case InvestmentDataName.ListingDate:
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'listing_date', isOrderAsc_ListingDate));
        break;
      case InvestmentDataName.StockCode:
        sortedInvestmentData.sort((a, b) => my_compare(a, b, 'stock_code', isOrderAsc_StockCode));
        break;
    }
    return sortedInvestmentData;
  }

  /**
   * カラムのヘッダをクリックしたら、
   * ・そのカラムをソートの対象とする。
   * ・すでにソート対象の場合は、ソート方法を変更する。
   * 
   * @param investment_data_name ソート対象のデータ名
   */
  const handleClickHeader = (investment_data_name: string) => {
    // クリックしたカラムがすでにソート対象の場合は、ソート方法を変更する。
    switch (investment_data_name) {
      case InvestmentDataName.Sector:
        if (orderBy === investment_data_name) {
          if (sectorPriorityIndex === (SectorTypeValues.length - 1)) {
            setSectorPriorityIndex(0);
          } else {
            setSectorPriorityIndex(sectorPriorityIndex + 1);
          }
        }
        break;
      case InvestmentDataName.StockPrice:
        if (orderBy === investment_data_name) setIsOrderAsc_StockPrice(!isOrderAsc_StockPrice);
        break;
      case InvestmentDataName.StockPriceChange:
        if (orderBy === investment_data_name) setIsOrderAsc_StockPriceChange(!isOrderAsc_StockPriceChange);
        break;
      case InvestmentDataName.DividendYield:
        if (orderBy === investment_data_name) setIsOrderAsc_DividendYield(!isOrderAsc_DividendYield);
        break;
      case InvestmentDataName.LatestForecastDividend:
        if (orderBy === investment_data_name) setIsOrderAsc_LatestForecastDividend(!isOrderAsc_LatestForecastDividend);
        break;
      case InvestmentDataName.MarketCap:
        if (orderBy === investment_data_name) setIsOrderAsc_MarketCap(!isOrderAsc_MarketCap);
        break;
      case InvestmentDataName.MarketCapShare:
        if (orderBy === investment_data_name) setIsOrderAsc_MarketCapShare(!isOrderAsc_MarketCapShare);
        break;
      case InvestmentDataName.PurchasePriceM:        //20231105 Change
        if (orderBy === investment_data_name) setIsOrderAsc_PurchasePriceM(!isOrderAsc_PurchasePriceM);             //20231105 Change
        break;
      case InvestmentDataName.PurchasePriceShare:
        if (orderBy === investment_data_name) setIsOrderAsc_PurchasePriceShare(!isOrderAsc_PurchasePriceShare);
        break;
      case InvestmentDataName.AppraisalValueM:        //20231105 Change
        if (orderBy === investment_data_name) setIsOrderAsc_AppraisalValueM(!isOrderAsc_AppraisalValueM);           //20231105 Change
        break;
      case InvestmentDataName.AppraisalGainRatioM:    //20231105 Change
        if (orderBy === investment_data_name) setIsOrderAsc_AppraisalGainRatioM(!isOrderAsc_AppraisalGainRatioM);   //20231105 Change
        break;
      case InvestmentDataName.PropertyNumM:
        if (orderBy === investment_data_name) setIsOrderAsc_PropertyNumM(!isOrderAsc_PropertyNumM);
        break;
      case InvestmentDataName.BuildingAgeM:           //20231105 Change
        if (orderBy === investment_data_name) setIsOrderAsc_BuildingAgeM(!isOrderAsc_BuildingAgeM);                 //20231105 Change
        break;
      case InvestmentDataName.OccupancyM:           //20231105 Change
        if (orderBy === investment_data_name) setIsOrderAsc_OccupancyM(!isOrderAsc_OccupancyM);           //20231105 Change
        break;
      case InvestmentDataName.NoiYield:
        if (orderBy === investment_data_name) setIsOrderAsc_NoiYield(!isOrderAsc_NoiYield);
        break;
      case InvestmentDataName.ImpliedCr:
        if (orderBy === investment_data_name) setIsOrderAsc_ImpliedCr(!isOrderAsc_ImpliedCr);
        break;
      case InvestmentDataName.NavPerUnit:
        if (orderBy === investment_data_name) setIsOrderAsc_NavPerUnit(!isOrderAsc_NavPerUnit);
        break;
      case InvestmentDataName.NavRatio:
        if (orderBy === investment_data_name) setIsOrderAsc_NavRatio(!isOrderAsc_NavRatio);
        break;
      case InvestmentDataName.FfoPerUnit:
        if (orderBy === investment_data_name) setIsOrderAsc_FfoPerUnit(!isOrderAsc_FfoPerUnit);
        break;
      case InvestmentDataName.FfoRatio:
        if (orderBy === investment_data_name) setIsOrderAsc_FfoRatio(!isOrderAsc_FfoRatio);
        break;
      case InvestmentDataName.PayoutRatio:
        if (orderBy === investment_data_name) setIsOrderAsc_PayoutRatio(!isOrderAsc_PayoutRatio);
        break;
      case InvestmentDataName.IcrDscr:
        if (orderBy === investment_data_name) setIsOrderAsc_IcrDscr(!isOrderAsc_IcrDscr);
        break;
      case InvestmentDataName.Ltv:
        if (orderBy === investment_data_name) setIsOrderAsc_Ltv(!isOrderAsc_Ltv);
        break;
      case InvestmentDataName.Roe:
        if (orderBy === investment_data_name) setIsOrderAsc_Roe(!isOrderAsc_Roe);
        break;
      case InvestmentDataName.IssuedInvestment:
        if (orderBy === investment_data_name) setIsOrderAsc_IssuedInvestment(!isOrderAsc_IssuedInvestment);
        break;
      case InvestmentDataName.Rating:
        if (orderBy === investment_data_name) setIsOrderAsc_Rating(!isOrderAsc_Rating);
        break;
      case InvestmentDataName.ListingDate:
        if (orderBy === investment_data_name) setIsOrderAsc_ListingDate(!isOrderAsc_ListingDate);
        break;
      case InvestmentDataName.StockCode:
        if (orderBy === investment_data_name) setIsOrderAsc_StockCode(!isOrderAsc_StockCode);
        break;
    }
    // ソート対象としてセットする。
    setOrderBy(investment_data_name);
  }

  const is_checked = (d: InvestmentDataType) : boolean => {
    for (let checked_d of checkedInvestmentData) {
      if (checked_d.stock_code === d.stock_code) return true;
    }
    return false;
  }

  const render_na = (row: any[]) => {
    row.push(<td className={classNames('width_adjust', 'na')}>NA</td>);
  }

  /**
   * レートに符号を付与し、色を付ける。
   * @param ratio レート
   * @param decimal_place 小数点以下何桁まで表示するか。
   * @param row レンダリング配列
   */
  const render_ratio_with_sign_and_color = (ratio: number, decimal_place: number, row: any[]) => {
    // レートがマイナスの場合、赤文字にする。
    const className_color = ratio < 0 ? 'minus' : '';
    // レートがプラスの場合、プラス符号を付与する。
    const sign_text = ratio > 0 ? '+' : '';

    const ratio_text = InvestmentDataUtil.convert_ratio_percentage(ratio, decimal_place);
    row.push(<td className={classNames("right", 'width_adjust', className_color)}>{sign_text}{ratio_text}%</td>);
  }

  const render_billion_data = (price: number, row: any[]) => {
    const className = classNames('width_adjust', 'right');
    let text = '';
    switch (myLang) {
      case MyLanguage.JA: {
        text = `${price.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} 億円`;
        row.push(<td className={className}>{text}</td>);
        break;
      }
      case MyLanguage.EN: {
        // billion表記なので、10で割る。
        text = `${(price / 10).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}b`;
        row.push(<td className={className}>&yen; {text}</td>);
        break;
      }
      default: {
        row.push(<td className={className}>{text}</td>);    
        break;
      }
    }
  }

  const render_thead = () => {
    const HasSecondHeadingClassName = 'has_second_heading';

    const classNameNoWrap = 'nowrap';
    const classNameWrap = 'wrap';

    const checkBoxDisplay = CommonUtil.is_mobile() ? 'display_none' : '';

    return (
      <>
        <tr>
          {/* チェックボックス */}
          <th className={classNames('fixcell', 'checkbox', checkBoxDisplay)}></th>
          {/* セクター */}
          <th className={classNames(get_classNameSortable(InvestmentDataName.Sector), 'fixcell', 'sector')} onClick={() => handleClickHeader(InvestmentDataName.Sector)}>
            <div>
              <span>{t('セクター')}</span>
              {render_indicator_sector(InvestmentDataName.Sector)}
              {render_balloon(get_balloon_text(InvestmentDataName.Sector), 'left', classNameNoWrap)}
            </div>
          </th>
          {/* 銘柄名 */}
          <th className={classNames('fixcell', 'stock_name')}>{t('銘柄名')}</th>
          {/* 株価 */}
          <th  className={classNames(get_classNameSortable(InvestmentDataName.StockPrice), 'fixcell', 'stock_price')} onClick={() => handleClickHeader(InvestmentDataName.StockPrice)}>
            <div>
              <span>{t('株価')}</span>
              {render_indicator(InvestmentDataName.StockPrice, isOrderAsc_StockPrice)}
            </div>
          </th>
          {/* 前日比 */}
          <th  className={classNames(get_classNameSortable(InvestmentDataName.StockPriceChange), 'fixcell', 'stock_price_change')} onClick={() => handleClickHeader(InvestmentDataName.StockPriceChange)}>
            <div>
              <span>{t('前日比')}</span>
              {render_indicator(InvestmentDataName.StockPriceChange, isOrderAsc_StockPriceChange)}
            </div>
          </th>
          {/* 分配金利回り */}
          <th className={classNames(get_classNameSortable(InvestmentDataName.DividendYield), 'fixcell')} onClick={() => handleClickHeader(InvestmentDataName.DividendYield)}>
            <div>
              <span>{t('分配金')}</span>
              <span>{t('利回り')}</span>
              {render_indicator(InvestmentDataName.DividendYield, isOrderAsc_DividendYield)}
              {render_balloon(get_balloon_text(InvestmentDataName.DividendYield), 'center', classNameNoWrap)}
            </div>
          </th>
          {/* 予想分配金 */}
          <th className={classNames(get_classNameSortable(InvestmentDataName.LatestForecastDividend), 'fixcell')} onClick={() => handleClickHeader(InvestmentDataName.LatestForecastDividend)}>
            <div>
              { 
                myLang === MyLanguage.JA ? 
                  <span>予想分配金</span> : 
                  <><span>Dividend</span><span>Estimates</span></>
              }
              {render_indicator(InvestmentDataName.LatestForecastDividend, isOrderAsc_LatestForecastDividend)}
            </div>
          </th>
          {/* 時価総額 */}
          <th className={classNames(get_classNameSortable(InvestmentDataName.MarketCap), 'fixcell')} onClick={() => handleClickHeader(InvestmentDataName.MarketCap)}>
            <div>
              <span>{t('時価総額')}</span>
              {render_indicator(InvestmentDataName.MarketCap, isOrderAsc_MarketCap)}
              {render_balloon(get_balloon_text(InvestmentDataName.MarketCap), 'center', classNameNoWrap)}
            </div>
          </th>
          {/* 取得価格 */}  {/* 20231105 Change */}
          <th className={classNames(get_classNameSortable(InvestmentDataName.PurchasePriceM), 'fixcell', 'purchase_price_M')} onClick={() => handleClickHeader(InvestmentDataName.PurchasePriceM)}>   
            <div>
              {
                myLang === MyLanguage.JA ? 
                  <span>取得価格</span> : 
                  <><span>Purchase</span><span>Price</span></>
              }
              {render_indicator(InvestmentDataName.PurchasePriceM, isOrderAsc_PurchasePriceM)}
            </div>
          </th>
          {/* 鑑定評価額 */}   {/* 20231105 Change */}
          <th className={classNames(get_classNameSortable(InvestmentDataName.AppraisalValueM), 'fixcell')} onClick={() => handleClickHeader(InvestmentDataName.AppraisalValueM)}>
            <div>
              <span>{t('鑑定評価額')}</span>
              {render_indicator(InvestmentDataName.AppraisalValueM, isOrderAsc_AppraisalValueM)}
              {/* {render_balloon(get_balloon_text(InvestmentDataName.AppraisalValueM), 'center', classNameNoWrap)} */}  {/* 20240210 Change */}
            </div>
          </th>
          {/* 鑑定評価額(含み益) */}  {/* 20231105 Change */}
          <th className={classNames(get_classNameSortable(InvestmentDataName.AppraisalGainRatioM), 'fixcell', HasSecondHeadingClassName)} onClick={() => handleClickHeader(InvestmentDataName.AppraisalGainRatioM)}>
            <div>
              {render_indicator(InvestmentDataName.AppraisalGainRatioM, isOrderAsc_AppraisalGainRatioM)}
              {
                myLang === MyLanguage.JA ? 
                  <span>含み益</span> : 
                  <span>Gain</span>
              }
              {render_balloon(get_balloon_text(InvestmentDataName.AppraisalGainRatioM), 'center', classNameNoWrap)}
            </div>
          </th>
          {/* 物件数 */}
          <th className={classNames(get_classNameSortable(InvestmentDataName.PropertyNumM), 'fixcell')} onClick={() => handleClickHeader(InvestmentDataName.PropertyNumM)}>
            <div>
              {
                myLang === MyLanguage.JA ? 
                  <span>物件数</span> : 
                  <><span>Property</span><span>Num</span></>
              }
              {render_indicator(InvestmentDataName.PropertyNumM, isOrderAsc_PropertyNumM)}
            </div>
          </th>
          {/* 築年数 */}  {/* 20231105 Change */}
          <th className={classNames(get_classNameSortable(InvestmentDataName.BuildingAgeM), 'fixcell')} onClick={() => handleClickHeader(InvestmentDataName.BuildingAgeM)}>
            <div>
              {
                myLang === MyLanguage.JA ? 
                  <span>築年数</span> : 
                  <><span>Building</span><span>Age (Ave)</span></>
              }
              {render_indicator(InvestmentDataName.BuildingAgeM, isOrderAsc_BuildingAgeM)}
              {render_balloon(get_balloon_text(InvestmentDataName.BuildingAgeM), 'center', classNameNoWrap)}
            </div>
          </th>
          {/* 稼働率 */}  {/* 20231105 Change */}
          <th className={classNames(get_classNameSortable(InvestmentDataName.OccupancyM), 'fixcell')} onClick={() => handleClickHeader(InvestmentDataName.OccupancyM)}>
            <div>
              <span>{t('稼働率')}</span>
              {render_indicator(InvestmentDataName.OccupancyM, isOrderAsc_OccupancyM)}
              {render_balloon(get_balloon_text(InvestmentDataName.OccupancyM), 'center', classNameNoWrap)}
            </div>
          </th>
          {/* 決算データ */}
          <th className={classNames('fixcell', 'data_ir')}>{t('決算データ')}</th>
          {/* 投資エリア */}
          <th className={classNames('fixcell')}>
            <div><span>{t('投資')}</span><span>{t('エリア')}</span></div>
            {render_balloon(get_balloon_text(InvestmentDataName.InvestmentArea), 'center', classNameNoWrap)}
          </th>
          {/* 物件タイプ */}
          <th className={classNames('fixcell')}>
            <div><span>{t('物件')}</span><span>{t('タイプ')}</span></div>
            {render_balloon(get_balloon_text(InvestmentDataName.PropertyType), 'center', classNameNoWrap)}
          </th>
          {/* NOI利回り */}
          <th className={classNames(get_classNameSortable(InvestmentDataName.NoiYield), 'fixcell')} onClick={() => handleClickHeader(InvestmentDataName.NoiYield)}>
            <div>
              <span>{t('NOI')}</span><span>{t('利回り')}</span>
              {render_indicator(InvestmentDataName.NoiYield, isOrderAsc_NoiYield)}
              {render_balloon(get_balloon_text(InvestmentDataName.NoiYield), 'center', classNameNoWrap)}
            </div>
          </th>
          {/* Implied CR */}
          <th className={classNames(get_classNameSortable(InvestmentDataName.ImpliedCr), 'fixcell')} onClick={() => handleClickHeader(InvestmentDataName.ImpliedCr)}>
            <div>
              <span>Implied</span><span>CR</span>
              {render_indicator(InvestmentDataName.ImpliedCr, isOrderAsc_ImpliedCr)}
              {render_balloon(get_balloon_text(InvestmentDataName.ImpliedCr), 'center', classNameWrap)}
            </div>
          </th>
          {/* NAV/口数(NAV倍率) */}
          <th className={classNames(get_classNameSortable(InvestmentDataName.NavRatio), 'fixcell')} onClick={() => handleClickHeader(InvestmentDataName.NavRatio)}>
            <div>
              {
                myLang === MyLanguage.JA ?
              <span>NAV倍率</span>:
              <><span>NAV</span><span>Ratio</span></>
              }
              {render_indicator(InvestmentDataName.NavRatio, isOrderAsc_NavRatio)}
              {render_balloon(get_balloon_text(InvestmentDataName.NavRatio), 'center', classNameNoWrap)}
            </div>
          </th>
          {/* FFO/口数(FFO倍率) */}
          <th className={classNames(get_classNameSortable(InvestmentDataName.FfoRatio), 'fixcell')} onClick={() => handleClickHeader(InvestmentDataName.FfoRatio)}>
            <div>
              {
                myLang === MyLanguage.JA ?
              <span>FFO倍率</span>:
              <><span>FFO</span><span>Ratio</span></>
              }
              {render_indicator(InvestmentDataName.FfoRatio, isOrderAsc_FfoRatio)}
              {render_balloon(get_balloon_text(InvestmentDataName.FfoRatio), 'center', classNameNoWrap)}
            </div>
          </th>
          {/* 配当性向 */}
          <th className={classNames(get_classNameSortable(InvestmentDataName.PayoutRatio), 'fixcell')} onClick={() => handleClickHeader(InvestmentDataName.PayoutRatio)}>
            <div>
              {
                myLang === MyLanguage.JA ? 
                  <span>配当性向</span> : 
                  <><span>Payout</span><span>Ratio</span></>
              }
              {render_indicator(InvestmentDataName.PayoutRatio, isOrderAsc_PayoutRatio)}
              {render_balloon(get_balloon_text(InvestmentDataName.PayoutRatio), 'center', classNameNoWrap)}
            </div>
          </th>
          {/* DSCR */}
          <th className={classNames(get_classNameSortable(InvestmentDataName.IcrDscr), 'fixcell')} onClick={() => handleClickHeader(InvestmentDataName.IcrDscr)}>
            <div>
              <span>DSCR</span>
              {render_indicator(InvestmentDataName.IcrDscr, isOrderAsc_IcrDscr)}
              {render_balloon(get_balloon_text(InvestmentDataName.IcrDscr), 'center', classNameWrap)}
            </div>
          </th>
          {/* LTV */}
          <th className={classNames(get_classNameSortable(InvestmentDataName.Ltv), 'fixcell')} onClick={() => handleClickHeader(InvestmentDataName.Ltv)}>
            <div>
              <span>{t('LTV')}</span>
              {render_indicator(InvestmentDataName.Ltv, isOrderAsc_Ltv)}
              {render_balloon(get_balloon_text(InvestmentDataName.Ltv), 'center', classNameNoWrap)}
            </div>
          </th>
          {/* ROE */}
          <th className={classNames(get_classNameSortable(InvestmentDataName.Roe), 'fixcell')} onClick={() => handleClickHeader(InvestmentDataName.Roe)}>
            <div>
              <span>{t('ROE')}</span>
              {render_indicator(InvestmentDataName.Roe, isOrderAsc_Roe)}
              {render_balloon(get_balloon_text(InvestmentDataName.Roe), 'center', classNameNoWrap)}
            </div>
          </th>
          {/* 格付 */}
          <th className={classNames(get_classNameSortable(InvestmentDataName.Rating), 'fixcell', 'rating')} onClick={() => handleClickHeader(InvestmentDataName.Rating)}>
            <div>
              <span>{t('格付')}</span>
              {render_indicator(InvestmentDataName.Rating, isOrderAsc_Rating)}
              {render_balloon(get_balloon_text(InvestmentDataName.Rating), 'center', classNameNoWrap)}
            </div>
          </th>
          {/* ポートフォリオ */}
          <th className={classNames('fixcell')}>{t('ポートフォリオ')}</th>
          {/* 決算期 */}
          <th className={classNames('fixcell')}>{t('決算期')}</th>
        </tr>
      </>
    )
  }

  const render_tbody = () => {
    let rendering = [];
    let sortedInvestmentData = sort_data();
    sortedInvestmentData = sortedInvestmentData.filter(investmentData => {
      // チェックした銘柄は重複回避のため削除。
      for (let checked_d of checkedInvestmentData) {
        if (investmentData.stock_code === checked_d.stock_code) return false;
      }
      return true;
    });
    const mergedInvestmentData = [ ...checkedInvestmentData, ...sortedInvestmentData ];

    const checkBoxDisplay = CommonUtil.is_mobile() ? 'display_none' : '';

    for (let d of mergedInvestmentData) {
      let row = [];

      // checkbox
      row.push(<td className={classNames('fixcell', 'checkbox', checkBoxDisplay)}><input type="checkbox" onChange={(e) => handleCheckbox(e, d)} checked={is_checked(d)} /></td>);
            
      // セクター
      row.push(<td className={classNames('fixcell', 'sector')}>{t(InvestmentDataUtil.convert_sector_name(d.sector))}</td>);

      // 銘柄名
      {
        let stock_name = '';
        switch (myLang) {
          case MyLanguage.JA: stock_name = d.stock_name; break;
          case MyLanguage.EN: stock_name = d.stock_name_en; break;
        }

        const className = classNames('fixcell', 'stock_name');        //20230015 Change
        if (d.hp_url === null) {
          row.push(<td className={className}>{stock_name}</td>);
        } else {
          switch (myLang) {
            case MyLanguage.JA:
              row.push(<td className={className}><a href={d.hp_url} target="_blank" rel="noreferrer">{stock_name}</a></td>);
              break;
            case MyLanguage.EN:
              row.push(<td className={className}><a href={d.hp_en_url} target="_blank" rel="noreferrer">{stock_name}</a></td>);
              break;
          }
        }
      }

      // 株価
      {
        if (d.stock_price === null) {
          render_na(row);
        } else {
          const className = classNames('right', 'stock_price');
          let rendering_stock_price = <></>;
          let text = '';
          switch (myLang) {
            case MyLanguage.JA:
              text = d.stock_price.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + ' 円';  // カンマ区切り＆小数点以下0桁
              rendering_stock_price = <>{text}</>
              break;
            case MyLanguage.EN:
              text = d.stock_price.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});  // カンマ区切り＆小数点以下0桁
              rendering_stock_price = <>&yen;{text}</>
              break;
          }

          if (d.trading_view_url) {
            row.push(<td className={className}><a href={d.trading_view_url} target="_blank" rel="noreferrer">{rendering_stock_price}</a></td>);
          } else {
            row.push(<td className={className}>{rendering_stock_price}</td>);
          }
        }
      }

      // 前日比
      {
        if (d.stock_price_change === null) {
          render_na(row);
        } else {
          render_ratio_with_sign_and_color(d.stock_price_change, 2, row);
        }
      }

      // 分配金利回り
      {
        if (d.dividend_yield === null) {
          render_na(row);
        } else {
          row.push(<td className={classNames("right", 'width_adjust')}>{InvestmentDataUtil.convert_ratio_percentage(d.dividend_yield, 2)}%</td>);
        }
      }

      // 予想分配金
      {
        if (d.latest_forecast_dividend === null) {
          render_na(row);
        } else {
          const className = classNames('width_adjust', 'right');
          let text = d.latest_forecast_dividend.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
          switch (myLang) {
            case MyLanguage.JA: {
              row.push(<td className={className}>{text} 円</td>);
              break;
            }
            case MyLanguage.EN: {
              row.push(<td className={className}>&yen; {text}</td>);
              break;
            }
            default: {
              row.push(<td className={className}>{text}</td>);    
              break;
            }
          }
        }
      }

      // 時価総額
      {
        if (d.market_cap === null) {
          render_na(row);
        } else {
          render_billion_data(d.market_cap, row);
        }
      }

      // 取得価格  20231105 Change 
      {
        if (d.purchase_price_M === null) {
          render_na(row);
        } else {
          render_billion_data(d.purchase_price_M, row);
        }
      }

      // 鑑定評価額  20231105 Change
      {
        if (d.appraisal_value_M === null) {
          render_na(row);
        } else {
          render_billion_data(d.appraisal_value_M, row);
        }
      }

      // 鑑定評価額(含み益)  20231105 Change
      {
        if (d.appraisal_gain_ratio_M === null) {
          render_na(row);
        } else {
          render_ratio_with_sign_and_color(d.appraisal_gain_ratio_M, 1, row);
        }
      }

      // 物件数
//      {
//        if (d.property_num_M === null) {
//          render_na(row);
//        } else {
//          let text = d.property_num_M.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
//          switch (myLang) {
//            case MyLanguage.JA:
//              text += ' 物件';
//              break;
//          }
//          row.push(<td className={classNames('width_adjust', 'center')}>{text}</td>);
//        }
//      }
      {
        if (d.property_num_M === null) {
          render_na(row);
        } else {
          let text = d.property_num_M.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
          switch (myLang) {
            case MyLanguage.JA:
              text += ' 物件';
              break;
          }
          row.push(<td className={classNames('width_adjust', 'center')}>{text}</td>);
        }
      }

      // 築年数  20231105 Change
      {
        if (d.building_age_M === null) {
          render_na(row);
        } else {
          const className = classNames('width_adjust', 'right');
          let text = d.building_age_M.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1});
          switch (myLang) {
            case MyLanguage.JA: {
              row.push(<td className={className}>{text} 年</td>);
              break;
            }
            case MyLanguage.EN: {
              row.push(<td className={className}>{text} years</td>);
              break;
            }
            default: {
              row.push(<td className={className}>{text}</td>);    
              break;
            }
          }
        }
      }

      // 稼働率  20231105 Change
      {
        if (d.occupancy_M === null) {
          render_na(row);
        } else {
          if (d.occupancy_M === 1) {
            switch (myLang) {
              case MyLanguage.JA: {
                row.push(<td className={classNames("right", 'width_adjust')}>{InvestmentDataUtil.convert_ratio_percentage(d.occupancy_M, 0)}%</td>);
                break;
              }
              case MyLanguage.EN: {
                 row.push(<td className={classNames("center", 'width_adjust')}>{InvestmentDataUtil.convert_ratio_percentage(d.occupancy_M, 0)}%</td>);
                break;
              }
              default: {
                row.push(<td className={classNames("right", 'width_adjust')}>{InvestmentDataUtil.convert_ratio_percentage(d.occupancy_M, 0)}%</td>);
                break;
              }
            } 
          } else {
              switch (myLang) {
                case MyLanguage.JA: {
                  row.push(<td className={classNames("right", 'width_adjust')}>{InvestmentDataUtil.convert_ratio_percentage(d.occupancy_M, 1)}%</td>);
                  break;
                }
                case MyLanguage.EN: {
                   row.push(<td className={classNames("center", 'width_adjust')}>{InvestmentDataUtil.convert_ratio_percentage(d.occupancy_M, 1)}%</td>);
                  break;
                }
                default: {
                  row.push(<td className={classNames("right", 'width_adjust')}>{InvestmentDataUtil.convert_ratio_percentage(d.occupancy_M, 1)}%</td>);
                  break;
                }
              }
            }
          }
        }
      
      
      // 決算データ 20231105 Change
      {
        let rendering_ir = <></>;
        switch (myLang) {
          case MyLanguage.JA: 
            if (d.ir_url) {
              rendering_ir = <button onClick={() => window.open(d.ir_url, '_blank', 'noreferrer')}>IR</button>;
            }
            break;
          case MyLanguage.EN:
            if (d.ir_en_url) {
              rendering_ir = <button onClick={() => window.open(d.ir_en_url, '_blank', 'noreferrer')}>IR</button>;
            }
            break;
        }        

        const handleOnClickData = (d: InvestmentDataType, is_Nippon_Building: boolean) => {
          if (!is_Nippon_Building && AuthUtil.restricted(memberType, MemberType.C)) {
            setIsOpenUserRegistrationDialog(true);
            return;
          }
      
          setDisplayMode(DisplayMode.StockData);
          setSelectedStockCode(Number(d.stock_code));
        };

        const is_Nippon_Building = InvestmentDataUtil.is_Nippon_Building(d);
        const className = is_Nippon_Building ? 'nippon_building' : '';

        row.push(
          <td className={classNames('width_adjust', 'data_ir')}>
            <div className={classNames("button_area", CommonUtil.is_mobile() ? "mobile" : "")}>
              { !CommonUtil.is_mobile() && <button className={className} onClick={() => handleOnClickData(d, is_Nippon_Building)}>DATA</button> }
              {rendering_ir}
            </div>
          </td>
        );
      }

      // 投資エリア
      {
        const onMouseEnterEvent = (e: any, d: InvestmentDataType) => {
          setPopupPieChartPosition(get_popup_pie_chart_position(e));
          setData_mouseEnterInvestmentArea(d);
        }
        const onMouseLeaveEvent = () => {
          setData_mouseEnterInvestmentArea(null);
        }
        row.push(<td className={classNames('width_adjust', 'pie')}><img src={`${ContextRoot}/images/icon/pie.png`} onMouseEnter={(e) => onMouseEnterEvent(e, d)} onMouseLeave={() => onMouseLeaveEvent()}></img></td>);
      }

      // 物件タイプ
      {
        const onMouseEnterEvent = (e: any, d: InvestmentDataType) => {
          setPopupPieChartPosition(get_popup_pie_chart_position(e));
          setData_mouseEnterPropertyType(d);
        }
        const onMouseLeaveEvent = () => {
          setData_mouseEnterPropertyType(null);
        }
        row.push(<td className={classNames('width_adjust', 'pie')}><img src={`${ContextRoot}/images/icon/pie.png`} onMouseEnter={(e) => onMouseEnterEvent(e, d)} onMouseLeave={() => onMouseLeaveEvent()}></img></td>);
      }

      // NOI利回り
      {
        if (d.noi_yield === null) {
          render_na(row);
        } else {
          row.push(<td className={classNames("right", 'width_adjust')}>{InvestmentDataUtil.convert_ratio_percentage(d.noi_yield, 2)}%</td>);
        }
      }

      // Implied CR
      {
        if (d.implied_cr === null) {
          render_na(row);
        } else {
          row.push(<td className={classNames("right", 'width_adjust')}>{InvestmentDataUtil.convert_ratio_percentage(d.implied_cr, 2)}%</td>);
        }
      }

      // NAV/口数(NAV倍率)
      {
        if (d.nav_ratio === null) {
          render_na(row);
        } else {
          let text = d.nav_ratio.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
          switch (myLang) {
            case MyLanguage.JA:
              text += ' 倍';
              break;
          }
          row.push(<td className={classNames("center", 'width_adjust')}>{text}</td>);
        }
      }

      // FFO/口数(FFO倍率)
      {
        if (d.ffo_ratio === null) {
          render_na(row);
        } else {
          let text = d.ffo_ratio.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1});
          switch (myLang) {
            case MyLanguage.JA:
              text += ' 倍';
              break;
          }
          row.push(<td className={classNames("center", 'width_adjust')}>{text}</td>);
        }
      }

      // 配当性向
      {
        if (d.payout_ratio === null) {
          render_na(row);
        } else {
          row.push(<td className={classNames("center", 'width_adjust')}>{InvestmentDataUtil.convert_ratio_percentage(d.payout_ratio, 0)}%</td>);
        }
      }

      // DSCR
      {
        if (d.icr_dscr === null) {
          render_na(row);
        } else {
          let text = d.icr_dscr.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1});
          row.push(<td className={classNames('width_adjust', 'center')}>{text}</td>);
        }
      }

      // LTV
      {
        if (d.ltv === null) {
          render_na(row);
        } else {
          row.push(<td className={classNames("right", 'width_adjust')}>{InvestmentDataUtil.convert_ratio_percentage(d.ltv, 1)}%</td>);
        }
      }

      // ROE
      {
        if (d.roe === null) {
          render_na(row);
        } else {
          row.push(<td className={classNames("right", 'width_adjust')}>{InvestmentDataUtil.convert_ratio_percentage(d.roe, 1)}%</td>);
        }
      }

      // 格付
      {
        row.push(<td className={classNames('width_adjust', 'center')}>{InvestmentDataUtil.convert_rating(d.rating)}</td>);
      }

      // ポートフォリオ
      {
        const onClickHandler = () => {
          // 指定した銘柄のみを表示させる。
          setSearchCond({
            investment_corp: `${InvestmentCorpType.InvestmentCorp}_${d.stock_code}`,
            managed_reit_type: ManagedByReitType.Managed,  // REIT保有
            building_type1: BuildingTypeType.Office.toString(),
            building_type2: BuildingTypeType.Residential.toString(),
            building_type3: BuildingTypeType.Retail.toString(),
            building_type4: BuildingTypeType.Logistics.toString(),
            building_type5: BuildingTypeType.Hotel.toString(),
            building_type99: BuildingTypeType.Others.toString(),
          });
        }
        row.push(
          <td className={classNames('width_adjust', 'portfolio')}>
            <div className="button_area">
              <Link to={'./map'}><button onClick={onClickHandler}>MAP</button></Link>
              <Link to={'./list'}><button onClick={onClickHandler}>LIST</button></Link>
            </div>
          </td>
        );
      }

      // 決算期
      {
        row.push(<td className={classNames('width_adjust', 'right')}>{InvestmentDataUtil.convert_fiscal_period(d.fiscal_period, myLang)}</td>);
      }

      // 1行分
      rendering.push(<tr>{row}</tr>);
    }

    return rendering;
  }
 
  /**
   * ソート可能なカラムのヘッダに指定するclassを取得する。
   * 
   * @param estate_data_name データ名
   * @returns カラムのヘッダに指定するclass
   */
  const get_classNameSortable = (estate_data_name: string) => {
    let className = classNames({
      'order_by': orderBy === estate_data_name,  // ソート対象のカラムの場合は、背景色を変える。
    }, 'sortable');
    return className;
  }

  const render_indicator = (estate_data_name: string, is_order_asc: boolean) => {
    if (orderBy !== estate_data_name) return render_indicator_neutral();
    if (orderBy === estate_data_name) {
      if (is_order_asc) return render_indicator_asc();
      else return render_indicator_dsc();
    }
    return null;
  }

  const render_indicator_sector = (estate_data_name: string) => {
    if (orderBy !== estate_data_name) return render_indicator_neutral();
    else return render_indicator_orderBy_sector();
  }

  const render_indicator_neutral = () => {
    return (
      <svg className='indicator_neutral' width="10" height="12" viewBox="0 0 12 14" version="1.1" >
        <path fill-rule="evenodd" d="m 0,8.004969 6,6 6,-6 z" />
        <path fill-rule="evenodd" d="M 0.00993834,6.0650684 6.0099383,0.06506844 12.009938,6.0650684 Z" />
      </svg>
    );
  }

  const render_indicator_asc = () => {
    return (
      <svg className='indicator_asc' width="10" height="5" viewBox="0 0 12 6" version="1.1" >
        <path fill-rule="evenodd" d="M 0.00993834,6.0650684 6.0099383,0.06506844 12.009938,6.0650684 Z" />
      </svg>
    );
  }

  const render_indicator_dsc = () => {
    return (
      <svg className='indicator_dsc' width="10" height="5" viewBox="0 0 12 6" version="1.1" >
        <path fill-rule="evenodd" d="M 0.00993834,0.06506844 6.0099383,6.0650684 12.009938,0.06506844 Z" />
      </svg>
    );
  }

  const render_indicator_orderBy_sector = () => {
    return (
      <svg className='indicator_orderBy_sector' width="10" height="12" viewBox="0 0 12 14" version="1.1" >
        <path fill-rule="evenodd" d="m 0,8.004969 6,6 6,-6 z" />
        <path fill-rule="evenodd" d="M 0.00993834,6.0650684 6.0099383,0.06506844 12.009938,6.0650684 Z" />
      </svg>
    );
  }

  /**
   * 吹き出しの文言を返却する。
   * @param balloon_text 吹き出しの文言(配列の1要素＝1行)
   * @param classname_align 吹き出しの位置を指定するクラス名
   * @returns 吹き出しの文言
   */
  const render_balloon = (balloon_text: string[], classname_align: string, classname_wrap: string) => {
    if (balloon_text.length === 0) return null;

    let rendering = [];
    for (let i = 0; i < balloon_text.length; i++) {
      if (i !== 0) rendering.push(<br/>);
      rendering.push(balloon_text[i]);
    }
    
    return (
      <span className={classNames("balloon", classname_align, classname_wrap)}>{rendering}</span>
    );
  }

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>, d: InvestmentDataType) => {
    if (e.target.checked) {
      // チェックを入れた場合
      let newCheckedInvestmentData = [...checkedInvestmentData];
      for (let checked_d of newCheckedInvestmentData) {
        if (checked_d.stock_code === d.stock_code) {
          // 同じ投資情報がチェックされている場合は何もしない。
          return;
        }
      }
      if (newCheckedInvestmentData.length >= MaxStockChecked) {
        // チェックの上限に達していたらダイアログで警告する。
        setDialogMsg(t('上限に達しました。最大X社まで追加できます。', { num: MaxStockChecked }));
        return;
      }
      newCheckedInvestmentData.push(d);
      setCheckedInvestmentData(newCheckedInvestmentData);
    }
    else {
      // チェックをはずした場合
      let newCheckedInvestmentData = checkedInvestmentData.filter(checked_d => {
        // チェックをはずした投資情報だけ除外する。
        return checked_d.stock_code !== d.stock_code;
      });
      setCheckedInvestmentData(newCheckedInvestmentData);
    }
  }

  const get_balloon_text = (dataName: string) => {
    let text: string[] = [];

    switch (dataName) {
      case InvestmentDataName.Sector: {
        text.push(t('tooltip.Sector.1'));
        break;
      }
      case InvestmentDataName.DividendYield: {
        text.push(t('tooltip.DividendYield.1'));
        text.push(t('tooltip.DividendYield.2'));
        break;
      }
      case InvestmentDataName.MarketCap: {
        text.push(t('tooltip.MarketCap.1'));
        text.push(t('tooltip.MarketCap.2'));
        break;
      }
      case InvestmentDataName.BuildingAgeM: {      //20231105 Chenge
        text.push(t('tooltip.BuildingAgeM.1'));    //20231105 Chenge
        break;
      }
      case InvestmentDataName.AppraisalValueM: {      //20231105 Chenge
        text.push(t('tooltip.AppraisalValueM.1'));    //20231105 Chenge
        break;
      }
//      case InvestmentDataName.Occupancy: {
//        text.push(t('tooltip.Occupancy.1'));
//        break;
//      }
//      case InvestmentDataName.AppraisalValue: {
//        text.push(t('tooltip.AppraisalValue.1'));
//        break;
//      }
      case InvestmentDataName.AppraisalGainRatioM: {        //20231105 Chenge
        text.push(t('tooltip.AppraisalGainRatioM.1'));      //20231105 Chenge
        text.push(t('tooltip.AppraisalGainRatioM.2'));      //20231105 Chenge
        break;
      }
      case InvestmentDataName.InvestmentArea: {
        text.push(t('tooltip.InvestmentArea.1'));
        text.push(t('tooltip.InvestmentArea.2'));
        text.push(t('tooltip.InvestmentArea.3'));
        text.push(t('tooltip.InvestmentArea.4'));
        break;
      }
      case InvestmentDataName.PropertyType: {
        text.push(t('tooltip.PropertyType.1'));
        text.push(t('tooltip.PropertyType.2'));
        text.push(t('tooltip.PropertyType.3'));
        text.push(t('tooltip.PropertyType.4'));
        text.push(t('tooltip.PropertyType.5'));
        text.push(t('tooltip.PropertyType.6'));
        text.push(t('tooltip.PropertyType.7'));
        text.push(t('tooltip.PropertyType.8'));
        break;
      }
      case InvestmentDataName.NoiYield: {
        text.push(t('tooltip.NoiYield.1'));
        text.push(t('tooltip.NoiYield.2'));
        text.push(t('tooltip.NoiYield.3'));
        text.push(t('tooltip.NoiYield.4'));
        break;
      }
      case InvestmentDataName.ImpliedCr: {
        text.push(t('tooltip.ImpliedCr.1'));
        text.push(t('tooltip.ImpliedCr.2'));
        text.push(t('tooltip.ImpliedCr.3'));
        break;
      }
      case InvestmentDataName.NavRatio: {
        text.push(t('tooltip.NavRatio.1'));
        text.push(t('tooltip.NavRatio.2'));
        text.push(t('tooltip.NavRatio.3'));
        break;
      }
      case InvestmentDataName.FfoRatio: {
        text.push(t('tooltip.FfoRatio.1'));
        text.push(t('tooltip.FfoRatio.2'));
        text.push(t('tooltip.FfoRatio.3'));
        break;
      }
      case InvestmentDataName.PayoutRatio: {
        text.push(t('tooltip.PayoutRatio.1'));
        text.push(t('tooltip.PayoutRatio.2'));
        text.push(t('tooltip.PayoutRatio.3'));
        break;
      }
      case InvestmentDataName.IcrDscr: {
        text.push(t('tooltip.IcrDscr.1'));
        text.push(t('tooltip.IcrDscr.2'));
        text.push(t('tooltip.IcrDscr.3'));
        break;
      }
      case InvestmentDataName.Ltv: {
        text.push(t('tooltip.Ltv.1'));
        text.push(t('tooltip.Ltv.2'));
        text.push(t('tooltip.Ltv.3'));
        break;
      }
      case InvestmentDataName.Roe: {
        text.push(t('tooltip.Roe.1'));
        text.push(t('tooltip.Roe.2'));
        text.push(t('tooltip.Roe.3'));
        break;
      }
      case InvestmentDataName.Rating:
        text.push(t('tooltip.Rating.1'));
        break;
    }
    return text;
  }

  /**
   * 投資エリアの円グラフを描画する。
   * 
   * @returns 投資エリアの円グラフ
   */
  const render_pie_chart_investment_area = () => {
    if (data_mouseEnterInvestmentArea === null) return null;

    let series: number[] = [];
    let colors: string[] = [];
    let dataLabelFontColors: string[] = [];
    let labels: string[] = [];

    const d = data_mouseEnterInvestmentArea;
    if (d.tokyo_5_main_ward !== null) {
      series.push(d.tokyo_5_main_ward * 100);
      colors.push('#222B35');
      dataLabelFontColors.push('#FFFFFF');
      labels.push(t('都心5区'));
    }
    if (d.tokyo_23_ward !== null) {
      series.push(d.tokyo_23_ward * 100);
      colors.push('#333F4F');
      dataLabelFontColors.push('#FFFFFF');
      labels.push(t('東京23区'));
    }
    if (d.tokyo_area !== null) {
      series.push(d.tokyo_area * 100);
      colors.push('#8497B0');
      dataLabelFontColors.push('#FFFFFF');
      labels.push(t('東京圏'));
    }
    if (d.osaka_area !== null) {
      series.push(d.osaka_area * 100);
      colors.push('#ACB9CA');
      dataLabelFontColors.push('#000000');
      labels.push(t('大阪圏'));
    }
    if (d.nagoya_area !== null) {
      series.push(d.nagoya_area * 100);
      colors.push('#D6DCE4');
      dataLabelFontColors.push('#000000');
      labels.push(t('名古屋圏'));
    }
    if (d.sapporo_city !== null) {
      series.push(d.sapporo_city * 100);
      colors.push('#D9E1F2');
      dataLabelFontColors.push('#000000');
      labels.push(t('札幌市'));
    }
    if (d.fukuoka_city !== null) {
      series.push(d.fukuoka_city * 100);
      colors.push('#DDEBF7');
      dataLabelFontColors.push('#000000');
      labels.push(t('福岡市'));
    }
    if (d.others_area !== null) {
      series.push(d.others_area * 100);
      colors.push('#EDEDED');
      dataLabelFontColors.push('#000000');
      labels.push(t('その他'));
    }
    let stock_name = '';
    switch (myLang) {
      case MyLanguage.JA: stock_name = d.stock_name; break;
      case MyLanguage.EN: stock_name = d.stock_name_en; break;
    }
    const options = get_pie_chart_options(stock_name ,labels, colors, dataLabelFontColors);
    return (
      <div className="pie_chart" style={popupPieChartPosition}><Chart type="donut" options={options} series={series} width={ChartWidth} height={ChartHeight}/></div>
    );
  }

  /**
   * 物件タイプの円グラフを描画する。
   * 
   * @returns 物件タイプの円グラフ
   */
  const render_pie_chart_property_type = () => {
    if (data_mouseEnterPropertyType === null) return null;

    let series: number[] = [];
    let colors: string[] = [];
    const dataLabelFontColors: string[] = ['#000000'];
    let labels: string[] = [];

    const d = data_mouseEnterPropertyType;
    if (d.property_ratio.super_high_office !== null) {
      series.push(d.property_ratio.super_high_office * 100);
      colors.push('#ED7D31');
      labels.push(t('超高層オフィス'));
    }
    if (d.property_ratio.high_office !== null) {
      series.push(d.property_ratio.high_office * 100);
      colors.push('#F4B084');
      labels.push(t('高層オフィス'));
    }
    if (d.property_ratio.middle_office !== null) {
      series.push(d.property_ratio.middle_office * 100);
      colors.push('#F8CBAD');
      labels.push(t('中低層オフィス'));
    }
    if (d.property_ratio.super_high_resi !== null) {
      series.push(d.property_ratio.super_high_resi * 100);
      colors.push('#FFD966');
      labels.push(t('超高層レジ'));
    }
    if (d.property_ratio.high_resi !== null) {
      series.push(d.property_ratio.high_resi * 100);
      colors.push('#FFE699');
      labels.push(t('高層レジ'));
    }
    if (d.property_ratio.middle_resi !== null) {
      series.push(d.property_ratio.middle_resi * 100);
      colors.push('#FFF2CC');
      labels.push(t('中低層レジ'));
    }
    if (d.property_ratio.large_senior_resi !== null) {
      series.push(d.property_ratio.large_senior_resi * 100);
      colors.push('#FFFFAF');
      labels.push(t('大型シニアレジ'));
    }
    if (d.property_ratio.middle_senior_resi !== null) {
      series.push(d.property_ratio.middle_senior_resi * 100);
      colors.push('#FFFFA9');
      labels.push(t('中型シニアレジ'));
    }
    if (d.property_ratio.urban_retail !== null) {
      series.push(d.property_ratio.urban_retail * 100);
      colors.push('#FFFF00');
      labels.push(t('都心型リテール'));
    }
    if (d.property_ratio.suburban_retail !== null) {
      series.push(d.property_ratio.suburban_retail * 100);
      colors.push('#FFFF66');
      labels.push(t('郊外型リテール'));
    }
    if (d.property_ratio.large_log !== null) {
      series.push(d.property_ratio.large_log * 100);
      colors.push('#A9D08E');
      labels.push(t('大型ロジ'));
    }
    if (d.property_ratio.middle_log !== null) {
      series.push(d.property_ratio.middle_log * 100);
      colors.push('#C6E0B4');
      labels.push(t('中型ロジ'));
    }
    if (d.property_ratio.factory !== null) {
      series.push(d.property_ratio.factory * 100);
      colors.push('#E2EFDA');
      labels.push(t('工場・インフラ施設(物件タイプ)'));
    }
    if (d.property_ratio.large_hotel !== null) {
      series.push(d.property_ratio.large_hotel * 100);
      colors.push('#BDD7EE');
      labels.push(t('大型ホテル'));
    }
    if (d.property_ratio.middle_hotel !== null) {
      series.push(d.property_ratio.middle_hotel * 100);
      colors.push('#DDEBF7');
      labels.push(t('中型ホテル'));
    }
    if (d.property_ratio.others_property !== null) {
      series.push(d.property_ratio.others_property * 100);
      colors.push('#E7E6E6');
      labels.push(t('その他'));
    }
    let stock_name = '';
    switch (myLang) {
      case MyLanguage.JA: stock_name = d.stock_name; break;
      case MyLanguage.EN: stock_name = d.stock_name_en; break;
    }
    const options = get_pie_chart_options(stock_name ,labels, colors, dataLabelFontColors);
    return (
      <div className="pie_chart" style={popupPieChartPosition}><Chart type="donut" options={options} series={series} width={ChartWidth} height={ChartHeight}/></div>
    );
  }

  const get_pie_chart_options = (title: string,labels: string[], colors: string[], dataLabelFontColors: string[]): any => {
    return {
      title: get_opt_title(title, 0),
      labels: labels,
      colors: colors,
      plotOptions: {
        pie: {
          donut: {
            size: '50%',  // 円グラフの穴の大きさ
          },
        },
      },
      dataLabels: {
        style: {
          colors: dataLabelFontColors,
        },
        dropShadow: {
          enabled: false
        },
        formatter: function (val: number) {
          if (val === null || val === undefined) return '';
          // 5%以下は表示しない。
          if (val <= 5) return '';
          return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';
        },
      },
      tooltip: {
        fillSeriesColor: false,
        theme: 'light',
        y: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';
          }
        }
      },
      legend: {
        position: 'bottom',
      },  
    }
  };

  const get_opt_title = (text: string, offsetY: number) => {
    return {
      text: text,
      align: 'left',
      offsetY: offsetY,
      style: {
        fontSize: FontSize.Data,  //20240203 変更
        fontWeight: 'bold'  //20240203 変更
      }
    }; 
  }

  const FontSize = {
    Title: window.devicePixelRatio >= 1.5 ? '12px' : '16px',
    Data: window.devicePixelRatio >= 1.5 ? '10px' : '12px',
    ExpRatio: window.devicePixelRatio >= 1.5 ? '14px' : '18px',
  };


  const render_chart_button = () => {
    if (checkedInvestmentData.length === 0) return null;

    const onClickHandler = () => {
      if (AuthUtil.restricted(memberType, MemberType.C)) {
        setIsOpenUserRegistrationDialog(true);
        return;
      }
      setDisplayMode(DisplayMode.StockChart);
    }

    return <button onClick={() => { onClickHandler() } }>CHART</button>;
  }


  const handleClickExcelOutput = async () => {
    if (AuthUtil.restricted(memberType, MemberType.C)) {
      setIsOpenUserRegistrationDialog(true);
      return;
    }
    await ToExcel.out_investmentData(t, myLang, investmentData, get_stock_price_update_date());
  }

  const get_stock_price_update_date = () => {
    if (investmentData.length === 0) return '';

    const d = investmentData[0];
    if (d.stock_price_update_date === null || d.stock_price_update_date === 0) {
      return '';
    }

    const year = String(d.stock_price_update_date).substring(0, 4);
    // 月は、1文字目が0の場合は省略するため、数値に変換する。
    const month = Number(String(d.stock_price_update_date).substring(4, 6));
    const day = Number(String(d.stock_price_update_date).substring(6, 8));

    let text = '';
    // 月は、1文字目が0の場合は省略するため、数値に変換する。
    switch (myLang) {
      case MyLanguage.JA:
        text = `${year}/${Number(month)}/${Number(day)} 更新`;
        break;
      case MyLanguage.EN:
        text = `${CommonUtil.convert_month_3letters(Number(month)-1)}. ${Number(day)}, ${year} Updated`;
        break;
    }
    return text;
  }

  const render_stock_price_update_date = () => {
    const text = get_stock_price_update_date();
    return <span className={classNames("update_date", CommonUtil.is_mobile() ? "mobile" : "")}>{text}</span>;
  }

  const render_excel_button = () => {
    if (CommonUtil.is_mobile()) {
      return null;
    } else {
      return <button onClick={handleClickExcelOutput}>{t('Excel出力')}</button>;
    }
  }

  return (
    <div id="Stock">
      <div className={classNames("top_button_area", CommonUtil.is_mobile() ? "mobile" : "")}>
        {render_excel_button()}
        {render_chart_button()}
        {render_stock_price_update_date()}
      </div>

      <div className="twrapper" ref={twrapperRef}>
        <table>
          <thead ref={theadRef}>{render_thead()}</thead>
          <tbody>{render_tbody()}</tbody>
        </table>
        {render_pie_chart_investment_area()}
        {render_pie_chart_property_type()}
      </div>
    </div>
  );
}

export default Stock;
