import { ValueMapDataType } from './ValueMapDataType';
import CommonUtil from '../utils/CommonUtil';

class ValueMapDataUtil {

  static fetch_value_map_data = (cond: any, callback: (valueData: ValueMapDataType[]) => void, resultZero: () => void) => {
    const ContextRoot = CommonUtil.get_context_root();

    const formData = new FormData();
    for (let key in cond) {
      formData.set(key, cond[key]);
    }
    fetch(`${ContextRoot}/value_map.php`, {
      method: 'POST',
      body: formData,
    }).then((res) => {
      if (!res.ok) {
        // エラー対応
      }
      return res.json();
    }).then((data) => {
      data = ValueMapDataUtil.conv_data(data);
      data = ValueMapDataUtil.set_appraisal_value_unit_price(data);
      data = ValueMapDataUtil.set_land_value_unit_price(data);
      callback(data);
      if (data.length === 0) {
        resultZero();
      }
    })
    .catch((reason) => {
      // エラー対応
      console.error(reason);
    });
  }

  /**
   * サーバから取得した不動産評価データを適切なデータ型に変換する。(主に文字列→数値)
   * 
   * @param data 不動産評価データ
   * @returns 適切なデータ型に変換した不動産評価データ
   */
  static conv_data = (data: any) => {
    const conv_number = (d: any, prop_name: string) => {
      if (d[prop_name] !== null) {
        d[prop_name] = Number(d[prop_name]);
      }
    }

    for (let d of data) {
      conv_number(d, 'id');
      conv_number(d, 'lat');
      conv_number(d, 'lng');
      conv_number(d, 'allowed_member_type_level');
      conv_number(d, 'building_type');
      conv_number(d, 'distance');
      conv_number(d, 'land_area');
      conv_number(d, 'architectural_area');
      conv_number(d, 'rentable_area');
      conv_number(d, 'construction_yyyymmdd');
      conv_number(d, 'building_coverage_ratio');
      conv_number(d, 'far');
      conv_number(d, 'land_far');          //20231104 Add
      conv_number(d, 'rosenka');          //20240218 Add
    //  conv_number(d, 'kouji_no');          //20240218 Add
      conv_number(d, 'kouji');          //20240218 Add
      conv_number(d, 'assumed_rent');
      conv_number(d, 'appraisal_value');
      conv_number(d, 'cap_rate');
      conv_number(d, 'rent');
      conv_number(d, 'parking_income');
      conv_number(d, 'key_money_right_fee_renewal_fee_income');
      conv_number(d, 'utility_income');
      conv_number(d, 'other_income');
      conv_number(d, 'gross_potential_income');
      conv_number(d, 'vacancy_loss_etc');
      conv_number(d, 'operating_income');
      conv_number(d, 'maintenance_fee');
      conv_number(d, 'utility_fee');
      conv_number(d, 'repair_fee');
      conv_number(d, 'pm_fee');
      conv_number(d, 'tenant_recruitment_fee');
      conv_number(d, 'land_tax');
      conv_number(d, 'building_tax');
      conv_number(d, 'depreciable_tax');
      conv_number(d, 'insurance');
      conv_number(d, 'other_fee');
      conv_number(d, 'operating_fee');
      conv_number(d, 'noi');
      conv_number(d, 'lump_sum_investment_income');
      conv_number(d, 'capital_exp');
      conv_number(d, 'ncf');
      conv_number(d, 'land_value');
      conv_number(d, 'building_value');
    }      
    return data;
  }

  /**
   * 評価額(単価)を計算して設定する。
   * 
   * @param data 不動産評価データ
   * @returns 評価額(単価)を設定した不動産評価データ
   */
  static set_appraisal_value_unit_price = (data: any) => {
    for (let d of data) {
      d['appraisal_value_unit_price'] = null;

      if (d['appraisal_value'] === null) continue;
      if (d['rentable_area'] === null) continue;

      const appraisal_value = Number(d['appraisal_value']);
      const rentable_area = Number(d['rentable_area']);
      if (rentable_area == 0) continue;

      // 評価額(単価) = 評価額 / 賃貸可能面積(想定)
      const appraisal_value_unit_price = appraisal_value / rentable_area;
      d['appraisal_value_unit_price'] = appraisal_value_unit_price;
    }
    return data;
  }

  /**
   * 土地価格(単価)を計算して設定する。
   * 
   * @param data 不動産評価データ
   * @returns 土地価格(単価)を設定した不動産評価データ
   */
  static set_land_value_unit_price = (data: any) => {
    for (let d of data) {
      d['land_value_unit_price'] = null;

      if (d['land_value'] === null) continue;
      if (d['land_area'] === null) continue;

      const land_value = Number(d['land_value']);
      const land_area = Number(d['land_area']);
      if (land_area == 0) continue;

      // 土地価格(単価) = 土地価格 / 土地面積
      const land_value_unit_price = land_value / land_area;
      d['land_value_unit_price'] = land_value_unit_price;
    }
    return data;
  }
}

export default ValueMapDataUtil