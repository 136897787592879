
class ChartUtil {

  /**
   * 目盛りの最小値と最大値を取得する。
   * 
   * @param min 最小値
   * @param max 最大値
   * @param n_div 分割数
   * @returns { number, number } 目盛りの最小値と最大値
   * 
   * // 参考(https://ameblo.jp/hiromi-0505/entry-12580621059.html)
   */
  static get_grid_min_max = (min: number, max: number, n_div: number) => {
    const yoyu = 0.01;
    if (min === max) {
      // 最小値と最大値が同じ場合は、少し幅を持たせる。
      min = min * 0.9;
      max = max * 1.1;
    }
    const scale: number = ChartUtil.get_scale_num(min, max, n_div)

    let grid_min = scale * Math.trunc(min / scale - yoyu);
    if (grid_min === -0) grid_min = 0;  // -0は0にする。

    const grid_max = scale * Math.trunc(max / scale + 1 + yoyu);

    return {
      grid_min,
      grid_max
    };
  }

  /**
   * 目盛りのスケールを取得する。
   * 
   * @param min 最小値
   * @param max 最大値
   * @param n_div 分割数
   * @returns number 目盛りのスケール
   */
  static get_scale_num = (min: number, max: number, n_div: number): number => {
    const kisu_arr = [ 2, 5, 10, 20, 50 ];

    let res = max - min;
    const keta = 10 ** Math.trunc(Math.log10(max - min));
    res = res / keta;

    for (let i=0; i<kisu_arr.length-1; i++) {
      if (Math.trunc(res/kisu_arr[i]) < 1) {
        res = kisu_arr[i+1] * keta / n_div;
        break;
      }
    }
    return res;
  }

  /**
   * 指定した最小値から最大値までの範囲が、指定した比率になる新しい最大値を算出する。
   * 
   * @param min 最小値
   * @param max 最大値
   * @param ratio 比率
   * @returns 指定した最小値から最大値までの範囲が、指定した比率になる新しい最大値
   */
  static expand_max = (min: number, max: number, ratio: number): number => {
    const range = max - min;
    const new_max = max + (range * (1 - ratio) / ratio);
    return new_max;
  }

  /**
   * 指定した最小値から最大値までの範囲が、指定した比率になる新しい最小値を算出する。
   * @param min 最小値
   * @param max 最大値
   * @param ratio 比率
   * @returns 指定した最小値から最大値までの範囲が、指定した比率になる新しい最小値
   */
  static expand_min = (min: number, max: number, ratio: number): number => {
    const range = max - min;
    const new_min = min - (range * (1 - ratio) / ratio);
    return new_min;
  }

  /**
   * 小数を、指定した桁に丸め、パーセント値にして返す。
   * @param val 少数
   * @param digits 丸める小数点の後に現れる桁の数
   * @returns パーセント値
   */
  static to_percentage = (val: number, digits: number): number => {
    // 値によっては、「35.699999999999996」のような誤差が出るため、丸めた値を返すようにする。
    return Number((val * 100).toFixed(digits));
  }
}

export default ChartUtil