import { HistAreaDataType } from './HistAreaDataType';
import CommonUtil from './CommonUtil';

class HistAreaDataUtil {

  static fetch_hist_area_data = (cond: any, callback: (histAreaData: HistAreaDataType[]) => void, resultZero: () => void) => {
    const ContextRoot = CommonUtil.get_context_root();
    const formData = new FormData();
    for (let key in cond) {
      formData.set(key, cond[key]);
    }
    fetch(`${ContextRoot}/hist_area.php`, {
      method: 'POST',
      body: formData,
    }).then((res) => {
      if (!res.ok) {
        // エラー対応
      }
      return res.json();
    }).then((data) => {
      data = HistAreaDataUtil.conv_data(data);
      callback(data);
      if (data.length === 0) {
        resultZero();
      }
    })
    .catch((reason) => {
      // エラー対応
      console.error(reason);
    });
  }

  /**
   * サーバから取得したヒエリアのストリカルデータを適切なデータ型に変換する。(主に文字列→数値)
   * 
   * @param data エリアのヒストリカルデータ
   * @returns 適切なデータ型に変換したエリアのヒストリカルデータ
   */
  static conv_data = (data: any) => {
    const conv_number = (d: any, prop_name: string) => {
      if (d[prop_name] !== null) {
        d[prop_name] = Number(d[prop_name]);
      }
    }

    for (let d of data) {
      conv_number(d, 'area_no');
      conv_number(d, 'as_of_yyyymmdd');
      conv_number(d, 'rent');
    }
    return data;
  }
}

export default HistAreaDataUtil