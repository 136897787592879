import { FC } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import StockCalendar from '../components/StockCalendar';

const StockCalendarPage: FC = () => {
  return (
    <>
      <Header />
      <StockCalendar />
      <Footer />
    </>
  );

}

export default StockCalendarPage