import { ExpDataType } from './ExpDataType';
import CommonUtil from './CommonUtil';

class ExpDataUtil {

  static fetch_exp_data = (cond: any, callback: (expData: ExpDataType[]) => void, resultZero: () => void) => {
    const ContextRoot = CommonUtil.get_context_root();
    const formData = new FormData();
    for (let key in cond) {
      formData.set(key, cond[key]);
    }
    fetch(`${ContextRoot}/exp.php`, {
      method: 'POST',
      body: formData,
    }).then((res) => {
      if (!res.ok) {
        // エラー対応
      }
      return res.json();
    }).then((data) => {
      data = ExpDataUtil.conv_data(data);
      callback(data);
      if (data.length === 0) {
        resultZero();
      }
    })
    .catch((reason) => {
      // エラー対応
      console.error(reason);
    });
  }

  /**
   * サーバから取得した費用率データを適切なデータ型に変換する。(主に文字列→数値)
   * 
   * @param data 費用率データ
   * @returns 適切なデータ型に変換した費用率データ
   */
  static conv_data = (data: any) => {
    const conv_number = (d: any, prop_name: string) => {
      if (d[prop_name] !== null) {
        d[prop_name] = Number(d[prop_name]);
      }
    }

    for (let d of data) {
      conv_number(d, 'property_no');
      conv_number(d, 'exp_ratio');
      conv_number(d, 'bm');
      conv_number(d, 'pm');
      conv_number(d, 'bm_pm');
      conv_number(d, 'utility');
      conv_number(d, 'repair');
      conv_number(d, 'tax');
      conv_number(d, 'insurance');
      conv_number(d, 'leased_land');
      conv_number(d, 'maintenance');
      conv_number(d, 'leasing_fee');
      conv_number(d, 'trust_fee');
      conv_number(d, 'restore');
      conv_number(d, 'management_union_fee');
      conv_number(d, 'others');      
    }
    return data;
  }
}

export default ExpDataUtil