import React from "react";
import TileLayer from "./TileLayer";

interface Props {
  isFloodActive: boolean,
  isSedimentActive: boolean,
  isTsunamiActive: boolean,
  isStormSurgeActive: boolean,  
}

const HazardMapLayers: React.FC<Props> = (props) => {

  const rendering = [];
  const opacity = 0.8;

  // 洪水浸水想定地域
  if (props.isFloodActive) {
    rendering.push(<TileLayer url="https://disaportaldata.gsi.go.jp/raster/01_flood_l2_shinsuishin_data/{z}/{x}/{y}.png" opacity={opacity} />);
  }

  // 土砂災害警戒区域
  if (props.isSedimentActive) {
    // 土石流
    rendering.push(<TileLayer url="https://disaportaldata.gsi.go.jp/raster/05_dosekiryukeikaikuiki/{z}/{x}/{y}.png" opacity={opacity} />);
    // 急傾斜地の崩壊
    rendering.push(<TileLayer url="https://disaportaldata.gsi.go.jp/raster/05_kyukeishakeikaikuiki/{z}/{x}/{y}.png" opacity={opacity} />);
    // 地すべり
    rendering.push(<TileLayer url="https://disaportaldata.gsi.go.jp/raster/05_jisuberikeikaikuiki/{z}/{x}/{y}.png" opacity={opacity} />);
  }

  // 津波浸水想定区域
  if (props.isTsunamiActive) {
    rendering.push(<TileLayer url="https://disaportaldata.gsi.go.jp/raster/04_tsunami_newlegend_data/{z}/{x}/{y}.png" opacity={opacity} />);
  }

  // 高潮浸水想定区域
  if (props.isStormSurgeActive) {
    rendering.push(<TileLayer url="https://disaportaldata.gsi.go.jp/raster/03_hightide_l2_shinsuishin_data/{z}/{x}/{y}.png" opacity={opacity} />);
  }

  return <>{rendering}</>;
};

export default HazardMapLayers;
