import React from 'react';
import { useContext, MouseEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SellOrBuyType } from '../../utils/EstateDataType';
import { AppContext } from '../../context/AppContext';
import { SubmitSearchButtonId } from '../../utils/SearchPanelConst';
import AuthUtil from '../../utils/AuthUtil';
import { MemberType } from '../../utils/MemberType';
import './SellOrBuy.css';

type Props = {

};

const SellOrBuy: React.VFC<Props> = () => {
  const [ t ] = useTranslation();
  const { register } = useFormContext();
  const { myLang, memberType, setIsOpenUserRegistrationDialog } = useContext(AppContext);

  const handleChange = () => {
    // 変更時に再検索させる。
    const submit_search_button = document.getElementById(SubmitSearchButtonId);
    if (!submit_search_button) return;
    submit_search_button.click();
  }

  const handleMouseDown = (event: MouseEvent<HTMLSelectElement>): void => {
    if (AuthUtil.restricted(memberType, MemberType.C)) {
      setIsOpenUserRegistrationDialog(true);
      // optionの表示をキャンセルする。
      event.preventDefault();
      return;
    }
  }

  return (
    <div id="SellOrBuy">
      <div className="searchgroup">
        <span className="searchfieldlabel">{t('取得/売却')}</span>
        <select id="SellOrBuyType" className={myLang} onMouseDown={handleMouseDown} {...register('sell_or_buy', { onChange: handleChange })}>
          <option value="0" selected disabled></option>
          <option value="0">{t('指定なし')}</option>
          <option value={SellOrBuyType.Sell}>{t('売却')}</option>
          <option value={SellOrBuyType.Buy}>{t('取得')}</option>
        </select> 
      </div>
    </div>
  );
}

export default SellOrBuy;
