import './Header.css';
import { FC, useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { AppContext, DisplayMode, MyLanguage } from '../context/AppContext';
import HamburgerMenu from './HamburgerMenu';
import CommonUtil from '../utils/CommonUtil';

const Header: FC = () => {
  const ContextRoot = CommonUtil.get_context_root();
  const [ t, i18n ] = useTranslation(); 
  const { displayMode, setDisplayMode } = useContext(AppContext);
  const { myLang, setMyLang } = useContext(AppContext);
  const { setSearchCond } = useContext(AppContext);

  useEffect(() => {
    i18n.changeLanguage(myLang);
    // タイトル変更
    document.title = 'REIT.map | プロの不動産情報を個人に';
  }, [myLang, i18n]);  // [myLang, i18n]に変更があった場合に、useEffectが動作し、言語切替を行う。
  
  const handleChangeLanguage = () => {
    switch (myLang) {
      case MyLanguage.JA:
        setMyLang(MyLanguage.EN);
        break;
      case MyLanguage.EN:
        setMyLang(MyLanguage.JA);
        break;
    }
  }

  const handleClickTitle = () => {
    window.open('https://fairvalue.works/valuemap/', '_blank', 'noreferrer'); //20231211 無効化
  }

  const render_left = () => {
    return render_select_display_mode();
  }

  const render_select_display_mode = () => {
    const reset_searchCond = (newDisplayMode: string) => {
      if (displayMode === newDisplayMode) return;
      setSearchCond({
        building_type1: '1',
        building_type2: '2',
        building_type3: '3',
        building_type4: '4',
        building_type5: '5',
        building_type99: '99'
      });
    };

    return (
      <>
        <HamburgerMenu /><button className="title" onClick={handleClickTitle}>REIT<span className="dot">.</span>map</button>

        <img className="menu-icon" src={`${ContextRoot}/images/icon/corporate.png`}></img>
        <div className="dropdown">
          <button className="dropdown-button">{t('コーポレート')}</button>
          <div className="dropdown-content">
            <Link to={ContextRoot} onClick={() => { setDisplayMode(DisplayMode.Stock) }}>{t('MAIN')}</Link>
            <Link to={`${ContextRoot}/calendar`} onClick={() => {}}>{t('CALENDAR')}</Link>
          </div>
        </div>

        <img className="menu-icon" src={`${ContextRoot}/images/icon/property.png`}></img>
        <div className="dropdown">
          <button className="dropdown-button">{t('プロパティ')}</button>
          <div className="dropdown-content">
            <Link to={`${ContextRoot}/map`} onClick={() => { reset_searchCond(DisplayMode.Map); setDisplayMode(DisplayMode.Map) }}>{t('MAP')}</Link>
            <Link to={`${ContextRoot}/list`} onClick={() => { reset_searchCond(DisplayMode.List); setDisplayMode(DisplayMode.List) }}>{t('LIST')}</Link>
          </div>
        </div>

        {
          !CommonUtil.is_mobile() &&
          <>
            <img className={classNames("menu-icon", "value")} src={`${ContextRoot}/images/icon/value.png`}></img>
            <div className="dropdown">
              <button className="dropdown-button">{t('バリュー')}</button>
              <div className="dropdown-content">
                <Link to={`${ContextRoot}/value.map/MAP`} onClick={() => { setDisplayMode(DisplayMode.ValueMap) }}>{t('MAP')}</Link>
                <Link to={`${ContextRoot}/value.map/LIST`} onClick={() => { setDisplayMode(DisplayMode.ValueList) }}>{t('LIST')}</Link>
              </div>
            </div>
          </>
        }
      </>
    );
  }

  const render_right = () => {
    if (CommonUtil.is_mobile()) return null;

    return (
      <div className="right">

{/*
        <div className="dropdown">
          <button className="dropdown-button"><img className={classNames("menu-icon", "mail-icon")} src={`${ContextRoot}/images/icon/mail.png`}></img></button>
          <div className="dropdown-content">
                <Link to={`${ContextRoot}/contact`} onClick={() => {  setDisplayMode(DisplayMode.Contact) }}>{t('MAIL')}</Link>
          </div>
        </div>
    */}
        {/* <Link to={`${ContextRoot}/contact`} onClick={() => { setDisplayMode(DisplayMode.Contact) }}><button><img className={classNames("mail-icon")} src={`${ContextRoot}/images/icon/mail.png`}></img></button></Link> */}
        <Link to={`${ContextRoot}/contact`} onClick={() => { setDisplayMode(DisplayMode.Contact) }}><button>{t('お問合せ')}</button></Link>
        <button className="language" onClick={handleChangeLanguage}>{CommonUtil.get_language_value(myLang)}</button>
      </div>
    );
  }
//修正前のメールボタン
//  <Link to={`${ContextRoot}/contact`} onClick={() => { setDisplayMode(DisplayMode.Contact) }}><button><img className={classNames("mail-icon")} src={`${ContextRoot}/images/icon/mail.png`}></img></button></Link>

  return (
    <header id="Header">
      {render_left()}
      {render_right()}
    </header>
  );
}

export default Header;
