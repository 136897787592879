import { FC, useContext, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Dialog from '../components/Dialog';
import LoginDialog from '../components/LoginDialog';
import { AppContext, DisplayMode } from '../context/AppContext';
import HistChart from '../components/HistChart';

import './ChartPage.css';

const ChartPage: FC = () => {
  // ダイアログ
  const { dialogMsg, setDisplayMode } = useContext(AppContext);
  // ログインダイアログ
  const { isOpenLoginDialog } = useContext(AppContext);

  useEffect(() => {
    setDisplayMode(DisplayMode.Chart);
  }, []);

  return (
    <>
      <Header />
      <div id="ChartPage">
        <HistChart />
      </div>
      <Footer />
      { dialogMsg && <Dialog /> }
      { isOpenLoginDialog && <LoginDialog /> }
    </>
  );

}

export default ChartPage