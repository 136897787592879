import './DialogValueMap.css';
import { FC, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { AppContext, MyLanguage } from '../context/AppContext';
import CommonUtil from '../utils/CommonUtil';

const DialogValueMap: FC = () => {
  const [ t ] = useTranslation();
  const { myLang, confirmedValueMap, setConfirmedValueMap } = useContext(AppContext);

  const ContextRoot = CommonUtil.get_context_root();
  const navigate = useNavigate();

  const handleClickLeave = () => {
    // MAIN画面に遷移させる。
    navigate(`${ContextRoot}/`);  
  }
  const handleClickConfirmed = () => {
   setConfirmedValueMap(true); //無効化切り替え箇所20231112無効化
  }

  const render_message_ja = () => {
    return (
      <p>
本ページは、日々の不動産価格をマップ上に表示したものです。(国際評価基準に準拠)<br/>
これまでの全てのREIT取引（意図的なデータ修正無し）に基づいた、中立的な市場価格を開示しております。<br/>
<br/>
【前提条件】<br/>
・完全所有権（第三者間取引）<br/>
・還元利回りは日々のインプライドCRにより変動し、個別収支項目は毎期のREIT運用データにより変動。<br/>
　（特許：特願2024-116248「インプライドCRによる不動産時価査定システム」）<br/>
・サイト運営会社が依頼を受けて評価した物件は除く。<br/>
<br/>
さらなるテクノロジー（AI,ICT等）の進展により不動産評価額及び固定資産税額はより正確に査定され、不動産市場の透明性が高められることにより、公正な市場が形成されると考えます。<br/>
      </p>
    )
  }

  const render_message_en = () => {
    return (
      <p>
The “Appraisal Value" page shows daily real estate prices on a map, (Real estate valuation in accordance with international standards)<br/>
 In the real estate market, which is considered opaque, this site discloses real estate appraisal values as neutral (not giving either side), aiming to eliminate the "asymmetry of information".<br/>
The following assumptions are made in this appraisal value assessment. <br/>
・Fee simple<br/>
・The cap rate fluctuates according to the daily Implied CR, and the individual income/expense items fluctuate according to the REIT property management data each period.<br/>
・Excluding properties evaluated at the request of the company (Fairvalue Labo).<br/>
<br/>
We believe that the further development of technology (AI, etc.) will enable more accurate assessments of real estate market values and tax base values, and increase the transparency of the real estate market, thereby creating a fair market. <br/>
      </p>
    )
  }

  const render_message = () => {
    switch (myLang) {
      case MyLanguage.JA:
        return render_message_ja();
      case MyLanguage.EN:
        return render_message_en();
    }
    return null;
  }

  if (confirmedValueMap) {
    return null;
  } else {
    return (
      <div id="DialogValueMap">
        <div className="content">
          {render_message()}
          <div className="button_area">
            <button onClick={handleClickLeave}>{t('退出')}</button>
            <button onClick={handleClickConfirmed}>{t('確認')}</button>
          </div>
        </div>
      </div>
    );  
  }
}

export default DialogValueMap;
