export const InvestmentDataName = {
  Sector: 'sector',
  StockPrice: 'stock_price',
  StockPriceChange: 'stock_price_change',
  DividendYield: 'dividend_yield',
  LatestForecastDividend: 'latest_forecast_dividend',
  MarketCap: 'market_cap',
  MarketCapShare: 'market_cap_share',
  PurchasePriceM: 'purchase_price_M',//20231105 Add
  PurchasePrice: 'purchase_price',
  PurchasePriceShare: 'purchase_price_share',
  AppraisalValueM: 'appraisal_value_M',//20231105 Add
  AppraisalValue: 'appraisal_value',
  AppraisalGainRatioM: 'appraisal_gain_ratio_M',//20231105 Add
  AppraisalGainRatio: 'appraisal_gain_ratio',
  PropertyNumM: 'property_num_M',//20231229 Add
  PropertyNum: 'property_num',
  BuildingAgeM: 'building_age_M',//20231105 Add
  BuildingAge: 'building_age',
  OccupancyM: 'occupancy_M',//20231105 Add
  Occupancy: 'occupancy',
  InvestmentArea: 'investment_area',
  PropertyType: 'property_type',
  NoiYield: 'noi_yield',
  ImpliedCr: 'implied_cr',
  NavPerUnit: 'nav_per_unit',
  NavRatio: 'nav_ratio',
  FfoPerUnit: 'ffo_per_unit',
  FfoRatio: 'ffo_ratio',
  PayoutRatio: 'payout_ratio',
  IcrDscr: 'icr_dscr',
  Ltv: 'ltv',
  Roe: 'roe',
  IssuedInvestment: 'issued_investment',
  Rating: 'rating',
  ListingDate: 'listing_date',
  StockCode: 'stock_code',
}

export enum InvestmentStockCode {
  TSE_REIT_Index        = 10000,
  TSE_REIT_Office_Index = 10001,
}

export enum InvestmentSectorType {
  Office      = 1,
  Residential = 2,
  Retail      = 3,
  Logistics   = 4,
  Industrial  = 5,
  Hotel       = 6,
  Healthcare  = 7,
  Complex     = 8,
}

export interface PropertyRatioType {
  super_high_office: number,
  high_office: number,
  middle_office: number,
  super_high_resi: number,
  high_resi: number,
  middle_resi: number,
  large_senior_resi: number,
  middle_senior_resi: number,
  urban_retail: number,
  suburban_retail: number,
  large_log: number,
  middle_log: number,
  factory: number,
  large_hotel: number,
  middle_hotel: number,
  others_property: number,  
}

export interface ExpType {
  expense_ratio: number,
  pm_bm: number,
  bm: number,
  pm: number,
  utility: number,
  repair: number,
  tax: number,
  insurance: number,
  brokerage: number,
  trust: number,
  others_exp: number,
}

export interface DebtType {
  long_term_debt: number,
  short_term_debt: number,
}

export interface FeeType {
  am_fee: number,
  am_fee_pp: number,
  related_party_exp_ratio: number,
}

export interface SourcingType {
  related_party: number,
  non_related_party: number,
}

export interface InvestorType {
  financial_institution: number,
  foreign_corporation: number,
  individual: number,
  others_investor: number,
}

export interface InvestmentDataType {
  stock_name: string,
  stock_name_en: string,
  stock_price: number,
  stock_price_previous_day: number,
  stock_price_change: number,
  investment_company_name: string,
  investment_company_name_en: string,
  sector: InvestmentSectorType,
  asset_management_company_name: string,
  asset_management_company_name_en: string,
  sponsor: string,
  sponsor_en: string,
  latest_forecast_dividend: number,
  rating: number,
  fiscal_period: string,
  listing_date: number,
  stock_code: number,
  data_update_date: number,
  stock_price_update_date: number,
  next_settlement_date: number,
  hp_url: string,
  ir_url: string,
  hp_en_url: string,              //20231105 Add
  ir_en_url: string,              //20231105 Add
  trading_view_url: string,
  issued_investment: number,
  noi: number,
  interest_bearing_debt: number,
  cash_and_deposits: number,
  deposits_and_guarantees: number,
  net_assets: number,
  balance_sheet_amount: number,
  current_net_profit: number,
  real_estate_sales_profit_or_loss: number,
  depreciation_expense: number,
  purchase_price_M: number,       //20231105 Add
  appraisal_value_M: number,      //20231105 Add
  appraisal_gain_ratio_M: number, //20231105 Add
  building_age_M: number,         //20231105 Add
  occupancy_M: number,            //20231105 Add
  property_num_M: number,         //20231229 Add
  // 以下はinvestment_histから取得するデータ
  purchase_price: number,
  book_value: number,
  appraisal_value: number,
  appraisal_gain_ratio: number,
  property_num: number,
  building_age: number,
  dividend: number,
  dividend_yield: number,
  occupancy: number,
  noi_yield: number,
  payout_ratio: number,
  roe: number,
  ltv: number,
  icr_dscr: number,
  // 以下は計算して求めるデータ
  market_cap: number,
  purchase_price_share: number,
  market_cap_share: number,
  implied_cr: number,
  nav_per_unit: number,
  ffo_per_unit: number,
  nav_ratio: number,
  ffo_ratio: number,
  // 以下はinvestment_areaから取得するデータ
  tokyo_5_main_ward: number,
  tokyo_23_ward: number,
  tokyo_area: number,
  osaka_area: number,
  nagoya_area: number,
  sapporo_city: number,
  fukuoka_city: number,
  others_area: number,
  // 以下はinvestment_property_ratioから取得するデータ
  property_ratio: PropertyRatioType,
  // 以下はinvestment_expから取得するデータ
  exp: ExpType,
  // 以下はinvestment_debtから取得するデータ
  debt: DebtType,
  // 以下はinvestment_feeから取得するデータ
  fee: FeeType,
  // 以下はinvestment_sourcingから取得するデータ
  sourcing: SourcingType,
  // 以下はinvestment_investorから取得するデータ
  investor: InvestorType,
}
