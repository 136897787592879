import { HistInvestmentDataType } from './HistInvestmentDataType';
import { InvestmentDataType } from './InvestmentDataType';
import CommonUtil from './CommonUtil';

class HistInvestmentDataUtil {

  static fetch_hist_data = (cond: any, callback: (histData: HistInvestmentDataType[]) => void, resultZero: () => void, only_latest: boolean = false) => {
    const ContextRoot = CommonUtil.get_context_root();
    const formData = new FormData();
    for (let key in cond) {
      formData.set(key, cond[key]);
    }

    const php_filename = only_latest ? `${ContextRoot}/hist_investment.php?only_latest=true` : `${ContextRoot}/hist_investment.php`;
    fetch(php_filename, {
      method: 'POST',
      body: formData,
    }).then((res) => {
      if (!res.ok) {
        // エラー対応
      }
      return res.json();
    }).then((data) => {
      data = HistInvestmentDataUtil.conv_data(data);
      data = HistInvestmentDataUtil.set_appraisal_gain(data);
      callback(data);
      if (data.length === 0) {
        resultZero();
      }
    })
    .catch((reason) => {
      // エラー対応
      console.error(reason);
    });
  }

  /**
   * サーバから取得したヒストリカルデータを適切なデータ型に変換する。(主に文字列→数値)
   * 
   * @param data ヒストリカルデータ
   * @returns 適切なデータ型に変換したヒストリカルデータ
   */
  static conv_data = (data: any) => {
    const conv_number = (d: any, prop_name: string) => {
      if (d[prop_name] !== null) {
        d[prop_name] = Number(d[prop_name]);
      }
    }

    for (let d of data) {
      conv_number(d, 'stock_code');
      conv_number(d, 'purchase_price');
      conv_number(d, 'book_value');
      conv_number(d, 'appraisal_value');
      conv_number(d, 'appraisal_gain_ratio');
      conv_number(d, 'property_num');
      conv_number(d, 'building_age');
      conv_number(d, 'stock_price');
      conv_number(d, 'stock_price_E');      //20231105 Change
      conv_number(d, 'market_cap');
      conv_number(d, 'dividend');
      conv_number(d, 'dividend_yield');
      conv_number(d, 'occupancy');
      conv_number(d, 'noi_yield');
      conv_number(d, 'implied_cr');
      conv_number(d, 'nav_ratio');
      conv_number(d, 'ffo_ratio');
      conv_number(d, 'payout_ratio');
      conv_number(d, 'roe');
      conv_number(d, 'ltv');
      conv_number(d, 'icr_dscr');
      conv_number(d, 'stock_price_change');
      conv_number(d, 'market_cap_change');
      conv_number(d, 'purchase_price_change');
      conv_number(d, 'appraisal_value_change');
    }
    return data;
  }

  /**
   * 含み益を計算して設定する。
   * 
   * @param data ヒストリカルデータ
   * @returns 含み益を設定したヒストリカルデータ
   */
  static set_appraisal_gain = (data: any) => {
    for (let d of data) {
      d['appraisal_gain'] = null;

      if (d['appraisal_value'] === null) continue;
      if (d['book_value'] === null) continue;

      const appraisal_value = Number(d['appraisal_value']);
      const book_value = Number(d['book_value']);

      // 含み益 = 鑑定評価額 - 簿価
      const appraisal_gain = appraisal_value - book_value;
      d['appraisal_gain'] = appraisal_gain;
    }
    return data;
  }

  /**
   * ヒストリカルデータの期の平均値を算出する。
   * 
   * @param histInvestmentData ヒストリカルデータ
   * @param period 期
   * @param callback 呼び出し元でどのヒストリカルデータの平均値を取得するか指定する。
   * @returns 平均値
   */
  static calc_average = (histInvestmentData: HistInvestmentDataType[], period: string, callback: (hist_d: HistInvestmentDataType) => number | null) => {
    let sum_data = 0;
    let valid_data_count = 0;
    for (let hist_d of histInvestmentData) {
      if (period !== hist_d.period) continue;
      const value = callback(hist_d);
      if (value === null) continue;
      sum_data += value;
      valid_data_count++;
    }
    if (valid_data_count === 0) return null;
    return sum_data / valid_data_count;
  }

  static calc_average_by_sector = (investmentData: InvestmentDataType[], histInvestmentData: HistInvestmentDataType[], period: string, selected_sector: number, callback: (hist_d: HistInvestmentDataType) => number | null) => {
    let sum_data = 0;
    let valid_data_count = 0;
    for (let hist_d of histInvestmentData) {
      if (period !== hist_d.period) continue;
      const value = callback(hist_d);
      if (value === null) continue;

      // 同一セクターのみを集計する。
      const sector = HistInvestmentDataUtil.get_sector(hist_d.stock_code, investmentData);
      if (selected_sector !== sector) continue;

      sum_data += value;
      valid_data_count++;
    }
    if (valid_data_count === 0) return null;
    return sum_data / valid_data_count;
  }

  static get_sector = (stock_code: number | null, investmentData: InvestmentDataType[]) => {
    for (let d of investmentData) {
      if (d.stock_code === stock_code) {
        return d.sector;
      }
    }
    return null;
  }

}

export default HistInvestmentDataUtil