import React from 'react';
import { useState, useContext, MouseEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AppContext, MyLanguage } from '../../context/AppContext';
import { InvestmentCorpType } from '../../utils/EstateDataType';
import { SubmitSearchButtonId } from '../../utils/SearchPanelConst';
import AuthUtil from '../../utils/AuthUtil';
import { MemberType } from '../../utils/MemberType';
import './InvestmentCorp.css';

const InvestmentCorp: React.VFC = () => {
  const [ t ] = useTranslation();
  const { myLang } = useContext(AppContext);
  const { register } = useFormContext();
  const { appData, searchCond, memberType, setIsOpenUserRegistrationDialog } = useContext(AppContext);

  const [ changed, setChanged ] = useState<boolean>(false);

  const render_option = () => {
    if (!('investment_corp' in appData)) return;
    if (Object.keys(appData['investment_corp']).length === 0) return;

    let searchCond_corp_type = '';
    let searchCond_corp_code = '';
    if ('investment_corp' in searchCond) {
      const splits = searchCond['investment_corp'].split('_');
      if (splits.length > 0) {
        searchCond_corp_type = splits[0];
        searchCond_corp_code = splits[1];
      }
    }

    let rendering = [];
    for (let investmentCorp of appData['investment_corp'][InvestmentCorpType.InvestmentCorp]) {
      let name = '';
      switch (myLang) {
        case MyLanguage.JA: name = investmentCorp['name']; break;
        case MyLanguage.EN: name = investmentCorp['name_en']; break;
      }
      // 運用法人の場合は、<タイプ>_<コード>をvalueとする。(DB問い合わせ時に分割して検索する)
      const value = `${InvestmentCorpType.InvestmentCorp}_${investmentCorp['code']}`;
      // デフォルトの選択判定
      const selected = searchCond_corp_type === InvestmentCorpType.InvestmentCorp && searchCond_corp_code === investmentCorp['code'];
      rendering.push(<option value={value} selected={selected}>{name}</option>);
    }

    rendering.push(<option value={InvestmentCorpType.Other}>{t('その他（旧投資法人）')}</option>);

    return rendering;
  }

  const handleChange = () => {
    // 投資法人変更時に再検索させる。
    const submit_search_button = document.getElementById(SubmitSearchButtonId);
    if (!submit_search_button) return;
    submit_search_button.click();

    setChanged(true);
  }

  const handleMouseDown = (event: MouseEvent<HTMLSelectElement>): void => {
    if (AuthUtil.restricted(memberType, MemberType.C)) {
      setIsOpenUserRegistrationDialog(true);
      // optionの表示をキャンセルする。
      event.preventDefault();
      return;
    }
  }

  const is_selected_default = () => {
    if (changed) return false;
    return true;
  }

  return (
    <div id="InvestmentCorp">
      <div className="searchgroup">
        <span className="searchfieldlabel">{t('投資法人')}</span>
        <select id="InvestmentCorpCode" onMouseDown={handleMouseDown} {...register('investment_corp', { onChange: handleChange })}>
          <option value="0" selected={is_selected_default()} disabled></option>
          <option value="0">{t('指定なし')}</option>
          {render_option()}
        </select> 
      </div>
    </div>
  );
}

export default InvestmentCorp;
