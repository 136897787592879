import { MyLanguage } from '../context/AppContext';
import { EstateDataType } from './EstateDataType';
import { InvestmentCorpType } from './EstateDataType';
import CommonUtil from './CommonUtil';

class EstateDataUtil {

  static fetch_estate_data = (cond: any, callback: (estateData: EstateDataType[]) => void, resultZero: () => void) => {
    const ContextRoot = CommonUtil.get_context_root();
    const formData = new FormData();
    for (let key in cond) {
      if (key === 'investment_corp') {
        // 投資法人の指定
        const splits = cond[key].split('_');
        formData.set('investment_corp_type', splits[0]);
        if (splits.length > 1) {
          // 運用法人指定の場合はコードでの絞り込みをする。
          formData.set('investment_corp_code', splits[1]);
        }
      } else {
        formData.set(key, cond[key]);
      }
    }
    fetch(`${ContextRoot}/index.php`, {
      method: 'POST',
      body: formData,
    }).then((res) => {
      if (!res.ok) {
        // エラー対応
      }
      return res.json();
    }).then((data) => {
      data = EstateDataUtil.conv_data(data);
      callback(data);
      if (data.length === 0) {
        resultZero();
      }
    })
    .catch((reason) => {
      // エラー対応
      console.error(reason);
    });
  }

  /**
   * サーバから取得した不動産情報を適切なデータ型に変換する。(主に文字列→数値)
   * 
   * @param data 不動産情報
   * @returns 適切なデータ型に変換した不動産情報
   */
  static conv_data = (data: any) => {
    const conv_number = (d: any, prop_name: string) => {
      if (d[prop_name] !== null) {
        d[prop_name] = Number(d[prop_name]);
      }
    }

    const calc_unit_price = (d: any) => {
      if (d.rentable_area === null || d.rentable_area === 0) return null;
      if (d.transaction_price === null || d.transaction_price === 0) return null;
      
      const tubo = d.rentable_area * 0.3025;
      const tx_unit_price = d.transaction_price * 1000000 / tubo;  // 単価が100万円のため円単価に戻して計算する。
      return tx_unit_price;
    }
    
    for (let d of data) {
      conv_number(d, 'id');
      conv_number(d, 'lat');
      conv_number(d, 'lng');
      conv_number(d, 'building_type');
      conv_number(d, '_ownership');
      conv_number(d, 'cap_rate');
      conv_number(d, 'disclose_cap_rate');
      conv_number(d, 'note_cap_rate');
      conv_number(d, 'transaction_yyyymmdd');
      conv_number(d, 'transaction_price');
      conv_number(d, 'disclose_tx_price');
      conv_number(d, 'construction_yyyymmdd');
      conv_number(d, 'managed_reit_type');
      conv_number(d, 'sell_or_buy');
      conv_number(d, 'related_party_tx_type');
      conv_number(d, 'land_area');
      conv_number(d, 'total_units');
      conv_number(d, 'architectural_area');
      conv_number(d, 'rentable_area');
      conv_number(d, 'as_of_yyyymmdd');
      conv_number(d, 'noi_yield');
      conv_number(d, 'occupancy_rate');
      conv_number(d, 'appraisal_cap_rate');
      conv_number(d, 'appraisal_value');
      conv_number(d, 'investment_corp_type')
      conv_number(d, 'investment_corp_code');
      conv_number(d, 'area_no');
      conv_number(d, 'property_no');
      conv_number(d, 'la');
      conv_number(d, 'building_type2');
      conv_number(d, 'distance');

      d.unit_price = calc_unit_price(d);
    }
    return data;
  }

  
  /**
   * タイプ(数字)からタイプ(名称)に変換する。
   * (呼び出し元で多言語対応する)
   * 
   * @param building_type タイプ(数字)
   * @param building_type2 タイプ2(数字)
   * @returns タイプ(名称)
   */
   static convert_buildingType_name = (building_type: number, building_type2: number) => {
    switch (Number(building_type)) {
      case 1:
        return 'オフィス';
      case 2:
        return 'レジデンシャル';
      case 3:
        return 'リテール';
      case 4:
        return 'ロジスティクス';
      case 5:
        return 'ホテル';
      case 99:
        switch (building_type2) {
          case 1:
            return 'シニアレジデンシャル';
          case 2:
            return '工場・インフラ施設';
          case 99:
            return 'その他';
        }
        return 'その他';
      default:
        // 不正値(その他と同じとする)
        return 'その他';
    }
  }

  /**
   * 権利(数字)から権利(名称)に変換する。
   * (呼び出し元で多言語対応する)
   * 
   * @param _ownership 権利(数字)
   * @returns 権利(名称)
   */
  static convert_ownership = (_ownership: number) => {
    switch (_ownership) {
      case 1:
        return '完全所有権';
      case 2:
        return '（不）完全所有権';
      default:
        // 不正値
        return 'その他';
    }
  }

  /**
   * %に変換し、小数点以下2桁までにする。
   * 
   * @param cap_rate キャップレート
   * @returns 変換後のキャップレート
   */
  static convert_capRate_percentage = (cap_rate: number) => {
    return EstateDataUtil.convert_rate_percentage(cap_rate, 2);
  }

  /**
   * YYYYMMDD形式を、「YYYY年M月」に変換する(多言語対応あり)。
   * @param yyyymmdd 年月(YYYYMM形式)
   * @param lang 言語
   * @returns 年月(「YYYY年M月」)
   */
  static convert_YearMonth = (yyyymmdd: number, lang: string) => {
    const year = String(yyyymmdd).substring(0, 4);
    // 月は、1文字目が0の場合は省略するため、数値に変換する。
    const month = Number(String(yyyymmdd).substring(4, 6));

    // // 月は、1文字目が0の場合は省略するため、数値に変換する。
    // return `${year}年${Number(month)}月`;
    switch (lang) {
      case MyLanguage.JA:
        return `${year}年${Number(month)}月`;
      case MyLanguage.EN:
        // 「May. 2020」
        return `${EstateDataUtil.convert_month_3letters(month-1)} ${year}`;
    }
    return '';
  }

  /**
   * 売却or取得(数字)から売却or取得(名称)に変換する。
   * (呼び出し元で多言語対応する)
   * 
   * @param sell_or_buy 売却or取得(数字)
   * @returns 売却or取得(名称)
   */
   static convert_sellOrBuy = (sell_or_buy: number) => {
    switch (sell_or_buy) {
      case 1:
        return '売却';
      case 2:
        return '取得';
      case 3:
        return '一部売却';
      default:
        // 不正値
        return '';
    }
  }

  /**
   * コードに対応した投資法人の名称を取得する。
   * 
   * @param json_data 投資法人情報
   * @param investment_corp_type タイプ
   * @param investment_corp_code コード
   * @returns 投資法人の名称
   */
  static get_investment_corp = (json_data: any, investment_corp_type: number, investment_corp_code: number, lang: string): string => {
    if (!json_data) return '';
    if (Object.keys(json_data).length === 0) return '';

    for (let investment_corp of json_data[investment_corp_type]) {
      if (Number(investment_corp.code) === investment_corp_code) {
        switch (lang) {
          case MyLanguage.JA:
            return investment_corp.name;
          case MyLanguage.EN:
            return investment_corp.name_en;
        }
      }
    }
    return '';
  }

  /**
   * YYYYMMDD形式を、「YYYY/M/D」に変換する(多言語対応あり)。
   * @param yyyymmdd 年月(YYYYMM形式)
   * @param lang 言語
   * @returns 年月日(「YYYY/M/D」)
   */
   static convert_YearMonthDay = (yyyymmdd: number, lang: string) => {
    const year = String(yyyymmdd).substring(0, 4);
    // 月と日は、1文字目が0の場合は省略するため、数値に変換する。
    const month = Number(String(yyyymmdd).substring(4, 6));
    const day = Number(String(yyyymmdd).substring(6, 8));

    switch (lang) {
      case MyLanguage.JA:
        return `${year}/${month}/${day}`;
      case MyLanguage.EN:
        // 米国式で返却する。「May 1, 2020」
        return `${EstateDataUtil.convert_month_3letters(month-1)} ${day}, ${year}`;
    }
    return '';
  }

  /**
   * %に変換し、小数点以下2桁までにする。
   * 
   * @param noi_yield NOI Yield
   * @returns 変換後のNOI Yield
   */
  static convert_noi_yield_percentage = (noi_yield: number): string => {
    return EstateDataUtil.convert_rate_percentage(noi_yield, 2);
  }
  
  /**
   * %に変換し、小数点以下1桁までにする。
   * 
   * @param occupancy_rate 稼働率
   * @returns 変換後の稼働率
   */
  static convert_occupancy_rate_percentage = (occupancy_rate: number): string => {
    if (occupancy_rate == 1) {
      // 100%の場合は小数点表記なし。
      return EstateDataUtil.convert_rate_percentage(occupancy_rate, 0);
    } else {
      return EstateDataUtil.convert_rate_percentage(occupancy_rate, 1);
    }
  }

  /**
   * %に変換し、小数点以下1桁までにする。
   * 
   * @param appraisal_cap_rate  鑑定キャップレート
   * @returns 変換後の鑑定キャップレート
   */
  static convert_appraisal_cap_rate_percentage = (appraisal_cap_rate: number): string => {
    return EstateDataUtil.convert_rate_percentage(appraisal_cap_rate, 1);
  }

  /**
   * 3文字表記の月に変換する。
   * 
   * @param month 月(0～11までの数字)
   * @returns 3文字表記の月
   */
  static convert_month_3letters = (month: number) => {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    return months[month];
  }

  /**
   * レートを%に変換し、小数点以下x桁までにする。
   * 
   * @param rate レート
   * @param decimal_place 小数点以下何桁まで表示するか。
   * @returns 変換後のレート
   */
   static convert_rate_percentage = (rate: number, decimal_place: number): string => {
    return (100 * rate).toFixed(decimal_place).toLocaleString();
  }

  /**
   * キャップレートの開示／非開示を、boolean型にして返す。
   * 
   * @param disclose_cap_rate キャップレートの開示／非開示(number)
   * @returns キャップレートの開示／非開示(boolean)
   */
  static convert_disclose_cap_rate = (disclose_cap_rate: number): boolean => {
    if (disclose_cap_rate === 0) return false;
    return true;
  }

  /**
   * キャップレートの注意事項の有無を、boolean型にして返す。
   * 
   * @param note_cap_rate キャップレートの注意事項の有無(number)
   * @returns キャップレートの注意事項の有無(boolean)
   */
  static convert_note_cap_rate = (note_cap_rate: number): boolean => {
    if (note_cap_rate === 0) return false;
    return true;
  }

  /**
   * 取引価格の開示／非開示を、boolean型にして返す。
   * 
   * @param disclose_tx_price 取引価格の開示／非開示(number)
   * @returns 取引価格の開示／非開示(boolean)
   */
  static convert_disclose_tx_price = (disclose_tx_price: number): boolean => {
    if (disclose_tx_price === 0) return false;
    return true;
  }

  /**
   * ヒストリカルデータがあるか否か。
   * 
   * @param d 物件情報
   * @returns ヒストリカルデータがあるか否か
   */
  static has_hist_data = (d: EstateDataType): boolean => {
    return d.as_of_yyyymmdd !== null || d.noi_y !== null || d.occ !== null || d.rent !== null || d.cr !== null || d.av !== null;
  }

  /**
   * 総戸数を持つ物件タイプか否か
   * 
   * @param d 物件情報
   * @returns 総戸数を持つ物件タイプか否か
   */
  static has_total_units = (d: EstateDataType): boolean => {
    if (d.property_no === null) return false;
    // 物件Noの1桁目を取得する。
    const property_type = d.property_no.toString().slice(0, 1);
    if (property_type === '2' || property_type === '5') {
      // レジデンシャルまたはホテル物件の場合。
      return true;
    } else {
      return false;
    }
  }

  /**
   * 日本ビルファンドの物件か否か
   * 
   * @param d 物件情報
   * @returns 日本ビルファンドの物件か否か
   */
  static is_Nippon_Building = (d: EstateDataType): boolean => {
    if (d.investment_corp_type !== Number(InvestmentCorpType.InvestmentCorp)) return false;
    if (d.investment_corp_code !== 8951) return false;
    return true;
  }
}

export default EstateDataUtil