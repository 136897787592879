import React, { useContext, MouseEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SubmitSearchButtonId } from '../../utils/SearchPanelConst';
import { AppContext } from '../../context/AppContext';
import AuthUtil from '../../utils/AuthUtil';
import { MemberType } from '../../utils/MemberType';
import './ConstructionDate.css';

type Props = {

};

const ConstructionDate: React.VFC<Props> = () => {
  const [ t ] = useTranslation();
  const { register } = useFormContext();
  const { memberType, setIsOpenUserRegistrationDialog } = useContext(AppContext);

  /**
   * 竣工のオプションを生成する。
   * 
   * @returns オプション要素
   */
  const render_option = () => {
    let rendering = [];

    // 現在(端末側の時間)から直近3年間は1年刻みとする。(直近3年に2020年が含まれる場合は、2020年まで1年刻み)
    const now = new Date();
    let year = now.getFullYear();
    for (let i = year; i > year-3 && i >= 2020; i--) {
      rendering.push(<option value={i}>{i}{t('年')}</option>);
    }
    
    // 直近3年以前は、2005までは5年刻みとする。
    year = year - (3 - 1);
    if (year < 2020) {
      year = 2005;
    } else {
      let amari = year % 5;
      if (amari > 0) year = year - amari;  // 5年刻みになるように調整する。
      else year -= 5;
    }
    for (let i = year; i >= 2005; i-=5) {
      rendering.push(<option value={i}>{i}{t('年')}</option>);
    }

    // 2000年～1990年まで。
    rendering.push(<option value="2000">2000{t('年')}</option>);
    rendering.push(<option value="1990">1990{t('年')}</option>);

    return rendering;
  }

  const handleChange = () => {
    // 竣工変更時に再検索させる。
    const submit_search_button = document.getElementById(SubmitSearchButtonId);
    if (!submit_search_button) return;
    submit_search_button.click();
  }

  const handleMouseDown = (event: MouseEvent<HTMLSelectElement>): void => {
    if (AuthUtil.restricted(memberType, MemberType.C)) {
      setIsOpenUserRegistrationDialog(true);
      // optionの表示をキャンセルする。
      event.preventDefault();
      return;
    }
  }

  return (
    <div id="TransactionPeriod">
      <div className="searchgroup">
        <span className="searchfieldlabel">{t('竣工')}</span>
        <select onMouseDown={handleMouseDown} {...register('construction_date_lower', { onChange: handleChange })}>
          <option value="0" selected disabled></option>
          <option value="0">{t('下限なし')}</option>
          {render_option()}
        </select>
        <span>&nbsp;～&nbsp;</span>
        <select onMouseDown={handleMouseDown} {...register('construction_date_upper', { onChange: handleChange })}>
          <option value="0" selected disabled></option>
          <option value="0">{t('上限なし')}</option>
          {render_option()}
        </select>
      </div>
    </div>
  );
}

export default ConstructionDate;
