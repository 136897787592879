import { HistDataType } from './HistDataType';
import CommonUtil from './CommonUtil';
import { EstateDataType } from './EstateDataType';
import { ChartType, ChartSeriesType } from '../utils/ToExcelType';
import { MyLanguage } from '../context/AppContext';
import ToExcel from './ToExcel';

class HistDataUtil {

  static fetch_hist_data = (cond: any, callback: (histData: HistDataType[]) => void, resultZero: () => void) => {
    const ContextRoot = CommonUtil.get_context_root();
    const formData = new FormData();
    for (let key in cond) {
      formData.set(key, cond[key]);
    }
    fetch(`${ContextRoot}/hist.php`, {
      method: 'POST',
      body: formData,
    }).then((res) => {
      if (!res.ok) {
        // エラー対応
      }
      return res.json();
    }).then((data) => {
      data = HistDataUtil.conv_data(data);
      callback(data);
      if (data.length === 0) {
        resultZero();
      }
    })
    .catch((reason) => {
      // エラー対応
      console.error(reason);
    });
  }

  /**
   * サーバから取得したヒストリカルデータを適切なデータ型に変換する。(主に文字列→数値)
   * 
   * @param data ヒストリカルデータ
   * @returns 適切なデータ型に変換したヒストリカルデータ
   */
  static conv_data = (data: any) => {
    const conv_number = (d: any, prop_name: string) => {
      if (d[prop_name] !== null) {
        d[prop_name] = Number(d[prop_name]);
      }
    }

    for (let d of data) {
      conv_number(d, 'property_no');
      conv_number(d, 'as_of_yyyymmdd');
      conv_number(d, 'noi_y');
      conv_number(d, 'occ');
      conv_number(d, 'rent');
      conv_number(d, 'cr');
      conv_number(d, 'av');
      conv_number(d, 'adr');
      conv_number(d, 'rev_par');
    }
    return data;
  }


  /**
   * Excelを出力する
   * @param t 
   * @param myLang 
   * @param appData 
   * @param labeledEstateData 
   */
  static to_excel = (t: any, myLang: string, appData: any, labeledEstateData: EstateDataType[]) => {
    const ChartId = {
      NOI_Y : 'noi_y',
      OCC   : 'occ',
      RENT  : 'rent',
      CR    : 'cr'
    };

    const create_date_list = (_histData: HistDataType[]) => {
      if (_histData.length === 0) return [];
  
      // 最新の日時を取得する。
      let latest_yyyymmdd: number = 0;
      for (let h of _histData) {
        latest_yyyymmdd = Math.max(latest_yyyymmdd, h.as_of_yyyymmdd);
      }
      const latest_year = Number(String(latest_yyyymmdd).substring(0, 4));
      const latest_month = Number(String(latest_yyyymmdd).substring(4, 6)) - 1;
  
      // 半年ごとで5年分
      let date_list: number[] = [];
      for (let i=0; i<10; i++) {
        let date = new Date(latest_year, latest_month, 1);
        date.setMonth(date.getMonth() - (6 * i));
  
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, '0');  // 0埋め
        let day = '01';
        date_list.push(Number(`${year}${month}${day}`));
      }
  
      // 日付を昇順にソートする。
      date_list.sort((a, b) => {
        if (a > b) return 1;
        else if (a < b) return -1;
        else return 0;
      })
  
      return date_list;
    }

    const get_chart_data = (_histData: HistDataType[], _chartId: string, property_no: number, date: number, conv_percent=true): number|null => {
      for (let h of _histData) {
        if (property_no !== h.property_no) continue;
        if (date !== h.as_of_yyyymmdd) continue;
  
        switch (_chartId) {
          case ChartId.NOI_Y: {
            if (h.noi_y === null || h.noi_y === undefined) return null;
            if (conv_percent) {
              return 100 * h.noi_y;  // %値に変換する。
            } else {
              return h.noi_y;
            }
          }
          case ChartId.OCC: {
            if (h.occ === null || h.occ === undefined) return null;
            if (conv_percent) {
              return 100 * h.occ;  // %値に変換する。
            } else {
              return h.occ;
            }
          } 
          case ChartId.RENT: {
            return h.rent;
          }
          case ChartId.CR: {
            if (h.cr === null || h.cr === undefined) return null;
            if (conv_percent) {
              return 100 * h.cr;   // %値に変換する。
            } else {
              return h.cr;
            }
          }
        }
      }
      return null;
    }

    const is_only_null_data = (data: any) => {
      for (let d of data) {
        if (d !== null) return false;
      }
      return true;
    }

    const get_chart_title = (_chartId: string) => {
      switch (_chartId) {
        case ChartId.NOI_Y: return 'NOI Yield';
        case ChartId.OCC:   return t('稼働率');
        case ChartId.RENT:  return t('賃料（円/坪）');
        case ChartId.CR:    return t('鑑定CR（NCF）');
      }
      return '';
    }

    /**
     * Excelに出力するためのチャートデータを取得する。
     * @param _histData
     * @param _chartId 
     * @param date_list 
     * @returns 
     */
    const get_chart_forExcel = (_histData: HistDataType[], _chartId: string, date_list: number[]): ChartType => {
      let histData_byId: any = {}
      
      for (let d of labeledEstateData) {
        histData_byId[d.id] = [];
        for (let date of date_list) {
          const chart_data = get_chart_data(_histData, _chartId, d.property_no, date, false);
          histData_byId[d.id].push(chart_data);
        }
      }

      const series: ChartSeriesType[] = [];
      for (let d of labeledEstateData) {
        // nullデータのみの場合は除外。
        if (is_only_null_data(histData_byId[d.id])) continue;

        let property_name = '';
        switch (myLang) {
          case MyLanguage.JA: property_name = d.property_name; break;
          case MyLanguage.EN: property_name = d.property_name_en; break;
        }  

        series.push({
          name : property_name,
          data: histData_byId[d.id]
        });
      }

      let numFmt = '';
      switch (_chartId) {
        case ChartId.NOI_Y:
          numFmt = '0.00%'; break;
        case ChartId.OCC:
          numFmt = '0.0%'; break;
        case ChartId.RENT:
          numFmt = '#,##0'; break;
        case ChartId.CR:
          numFmt = '0.0%'; break;
      }

      const chart: ChartType = {
        title: get_chart_title(_chartId),
        date_list,
        series,
        numFmt,
      } 

      return chart;
    }
    
    const ContextRoot = CommonUtil.get_context_root();
    let property_no_arr = '';
    for (let d of labeledEstateData) {
      if (property_no_arr === '') {
        property_no_arr = String(d.property_no);
      } else {
        property_no_arr = property_no_arr + ',' + String(d.property_no);
      }
    }

    const callback = (_histData: HistDataType[]) => {
      // ヒストリカルデータを取得したら呼び出され、そのタイミングでExcel出力を行う。
      let date_list = create_date_list(_histData);
      let charts: ChartType[] = [];
      charts.push(get_chart_forExcel(_histData, ChartId.NOI_Y, date_list));
      charts.push(get_chart_forExcel(_histData, ChartId.OCC, date_list));
      charts.push(get_chart_forExcel(_histData, ChartId.RENT, date_list));
      charts.push(get_chart_forExcel(_histData, ChartId.CR, date_list));
      ToExcel.out_charts(t, myLang, appData, labeledEstateData, charts, ContextRoot);  
    }

    HistDataUtil.fetch_hist_data({ property_no: property_no_arr }, callback, () => {});
  }
}

export default HistDataUtil