import { MyLanguage } from '../context/AppContext';
import { InvestmentCalendarType } from './InvestmentCalendarType';
import CommonUtil from './CommonUtil';

class InvestmentCalendarUtil {

  static fetch_investment_calendar = (cond: any, callback: (InvestmentCalendar: InvestmentCalendarType[]) => void, resultZero: () => void) => {
    const ContextRoot = CommonUtil.get_context_root();
    const formData = new FormData();
    for (let key in cond) {
      formData.set(key, cond[key]);
    }
    fetch(`${ContextRoot}/investment_calendar.php`, {
      method: 'POST',
      body: formData,
    }).then((res) => {
      if (!res.ok) {
        // エラー対応
      }
      return res.json();
    }).then((data) => {
      data = InvestmentCalendarUtil.conv_data(data);
      callback(data);
      if (data.length === 0) {
        resultZero();
      }
    })
    .catch((reason) => {
      // エラー対応
      console.error(reason);
    });
  }

  /**
   * サーバから取得したデータを適切なデータ型に変換する。(主に文字列→数値)
   * 
   * @param data データ
   * @returns 適切なデータ型に変換したデータ
   */
  static conv_data = (data: any) => {
    const conv_number = (d: any, prop_name: string) => {
      if (d[prop_name] !== null) {
        d[prop_name] = Number(d[prop_name]);
      }
    }

    for (let d of data) {
      conv_number(d, 'date');
    }
    return data;
  }
}

export default InvestmentCalendarUtil