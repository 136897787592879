import './ValueMapContent.css';
import { FC, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { InfoWindow, Marker, MarkerClusterer } from "@react-google-maps/api";
import EstateDataUtil from '../../utils/EstateDataUtil';
import { ValueMapDataType } from '../../utils/ValueMapDataType';
import { t } from "i18next";
import { AppContext, MyLanguage, MaxLabel, DisplayMode, InquirySubject_Id } from '../../context/AppContext';
import classNames from 'classnames';
import { useAuthContext } from '../../context/AuthContext';
import AuthUtil from '../../utils/AuthUtil';
import { MemberType } from '../../utils/MemberType';
import CommonUtil from '../../utils/CommonUtil';
import MapMlit from './MapMlit';
import HazardMapLayers from './HazardMapLayers';


interface Props {
  valueMapData: ValueMapDataType[],
  mapRef: any,
  showOnlyLabeled: boolean,
  setShowOnlyLabeled: React.Dispatch<React.SetStateAction<boolean>>,
  popupEstates: ValueMapDataType[],
  setPopupEstates: React.Dispatch<React.SetStateAction<ValueMapDataType[]>>,
  setIsMapInteractive: React.Dispatch<React.SetStateAction<boolean>>,
  // 情報追加用のメニュー
  isLandPricesActive: boolean, 
  isZoningActive: boolean, 
  isFloodActive: boolean,
  isSedimentActive: boolean,
  isTsunamiActive: boolean,
  isStormSurgeActive: boolean,
};

const ValueMapContent: FC<Props> = (props) => {
  const ContextRoot = CommonUtil.get_context_root();
  const { myLang } = useContext(AppContext);
  const { labeledValueMapData, setLabeledValueMapData } = useContext(AppContext);
  const { setDialogMsg, memberType, setIsOpenUserRegistrationDialog } = useContext(AppContext);

  // マウスオーバーした不動産
  const [ mouseOverEstate, setMouseOverEstate ] = useState<ValueMapDataType | null>(null);

  const navigate = useNavigate();

  // InfoWindowのkeyに指定する値。
  //
  // 以下のような手順を行うと、3.で吹き出しが表示されない。
  // 1. 不動産マーカーをクリック
  // 2. 飲食店などのマーカーをクリック(GoogleMap側で用意されたマーカー)
  // 3. 1.と同じ不動産マーカーをクリック
  //
  // 1.の吹き出しは閉じられるが、InfoWindowの状態？がクリアされていないようで、
  // 3.の時にInfoWindowが表示されない。
  // これを防ぐため、マーカークリックのたびにkeyを変えて状態を変えるようにする。
  // 
  // [memo] GoogleMap側で用意されたマーカーをクリックしても反応しないようにしたので、以下をコメントアウトした。
  // const [ infoWindowKey, setInfoWindowKey ] = useState<number>(0);


  useEffect(() => {
    if (labeledValueMapData.length === 0) {
      // すべてチェックをはずした場合、チェックを入れた不動産だけを表示する機能をOFFにする。
      props.setShowOnlyLabeled(false);
    }
  }, [labeledValueMapData]);


  // クラスターアイコンへのパスを定義する (1.png, 2.png, ...)
  const clusterIcon_imagePath = `${ContextRoot}/images/markerclusterer/m`;
  const m2_size = 50;
  const m3_size = 100;
  const m4_size = 150;
  const m5_size = 200;
  const textColor_m2_m5 = 'transparent';
  // const textColor_m2_m5 = '#000000';

  const clusterStyles = [
    {
      url: `${clusterIcon_imagePath}1.png`,
      width: 30,
      height: 26,
      textSize: 14,
      anchorText: [0, -4],  // y, x
      anchorIcon: [26/2, 30/2],  // y, x
      textColor: '#ffffff',
    },
    {
      url: `${clusterIcon_imagePath}2.png`,
      width: m2_size,
      height: m2_size,
      textSize: 14,
      anchorText: [0, 0],
      anchorIcon: [m2_size/2, m2_size/2],
      textColor: textColor_m2_m5,
    },
    {
      url: `${clusterIcon_imagePath}3.png`,
      width: m3_size,
      height: m3_size,
      textSize: 14,
      anchorText: [0, 0],
      anchorIcon: [m3_size/2, m3_size/2],
      textColor: textColor_m2_m5,
    },
    {
      url: `${clusterIcon_imagePath}4.png`,
      width: m4_size,
      height: m4_size,
      textSize: 14,
      anchorText: [0, 0],
      anchorIcon: [m4_size/2, m4_size/2],
      textColor: textColor_m2_m5,
    },
    {
      url: `${clusterIcon_imagePath}5.png`,
      width: m5_size,
      height: m5_size,
      textSize: 14,
      anchorText: [0, 0],
      anchorIcon: [m5_size/2, m5_size/2],
      textColor: textColor_m2_m5,
    }
  ];

  const options = {
    gridSize: 80,
    styles: clusterStyles,
    maxZoom: 14,
  };

  /**
   * 情報ウィンドウ(吹き出し)の内容を取得する。
   * 
   * @param d 不動産情報
   * @returns 情報ウィンドウ(吹き出し)の内容
   */
  const render_infoWindowContent = (d: ValueMapDataType) => {

    const render_property_name = () => {
      // 物件名
      switch (myLang) {
        case MyLanguage.JA:
          if (d.property_name)  {
            return <div className="property_name">{d.property_name}</div>
          }
          break;
        case MyLanguage.EN:
          if (d.property_name_en) {
            return <div className="property_name">{d.property_name_en}</div>;
          }
          break;
      }
      return null;
    }

    const render_data_left = () => {
      let rendering = [];
      // 評価額
      if (d.appraisal_value !== null) {
        rendering.push(<div>{t('評価額')}</div>);
      }
      // 還元利回り
      if (d.cap_rate !== null) {
        rendering.push(<div>{t('還元利回り')}</div>);
      }
      // 土地単価
      if (d.land_value_unit_price !== null) {
        rendering.push(<div>{t('土地単価')}</div>);
      }
      return <div className={classNames("data_content")}>{rendering}</div>;
    }

    const render_data_center = () => {
      const get_sep = () => {
        switch (myLang) {
          case MyLanguage.JA: return <div>：</div>;
          case MyLanguage.EN: return <div>&nbsp;:&nbsp;</div>;
        }
      }

      let rendering = [];
      // 評価額
      if (d.appraisal_value !== null) {
        rendering.push(get_sep());
      }
      // 還元利回り
      if (d.cap_rate !== null) {
        rendering.push(get_sep());
      }
      // 土地単価
      if (d.land_value_unit_price !== null) {
        rendering.push(get_sep());
      }

      return <div className={classNames("data_content")}>{rendering}</div>;
    }

    const render_data_right = () => {
      if (AuthUtil.restricted(memberType, d.allowed_member_type_level)) {
        const handleClick = () => {
          let id_params = '';
          switch (memberType) {
            case MemberType.A:
              id_params = `id=${InquirySubject_Id.FreeUserRegistration}`;
              break;
            case MemberType.B:
              id_params = `id=${InquirySubject_Id.PaidUserRegistration}`;
              break
          }
          navigate(`${ContextRoot}/contact?${id_params}`);
        }
    
        const className = classNames("data_content", "data_content_restricted");
        switch (memberType) {
          case MemberType.A:
            return <div className={className} onClick={() => handleClick()}>{t('value_map.free_user_registration.1')}<br/>{t('value_map.free_user_registration.2')}<br/>{t('value_map.free_user_registration.3')}</div>;
          case MemberType.B:
            return <div className={className} onClick={() => handleClick()}>{t('value_map.paid_user_registration.1')}<br/>{t('value_map.paid_user_registration.2')}</div>;
          default:
            return null;
        }      
      }    

      let rendering = [];
      // 評価額
      if (d.appraisal_value !== null) {
        let text = '';
        const appraisal_value = d.appraisal_value / 100000000;
        switch (myLang) {
          case MyLanguage.JA: {
            if (appraisal_value >= 100) {
              text = `${appraisal_value.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} 億円`;
            } else {
              text = `${appraisal_value.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1})} 億円`;
            }
            rendering.push(<div>{text}</div>);
            break;
          }
          case MyLanguage.EN: {
            // billion表記なので、10で割る。
            if (appraisal_value >= 100) {
              text = `${(appraisal_value / 10).toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1})}bn`;
            } else {
              text = `${(appraisal_value / 10).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}bn`;
            }
            rendering.push(<div>&yen; {text}</div>);
            break;
          }
        }
      }
      // 還元利回り
      if (d.cap_rate !== null) {
        rendering.push(<div>{EstateDataUtil.convert_rate_percentage(d.cap_rate, 1)}%</div>);
      }
    
      // 土地単価
      if (d.land_value_unit_price !== null) {
        let text = '';
        switch (myLang) {
          case MyLanguage.JA: {
            text = `${(d.land_value_unit_price / 1000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} 千円/㎡`;
            rendering.push(<div>{text}</div>);
            break;
          }
          case MyLanguage.EN: {
            text = `${(d.land_value_unit_price / 1000).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}k/㎡`;
            rendering.push(<div>&yen; {text}</div>);
            break;
          }
        }
      }
      return <div className={classNames("data_content")}>{rendering}</div>;
    }

    // フッター
    let footer = [];
    // 「DATA」ボタン
    {
      const handleOnClickData = (d: ValueMapDataType) => {
        if (AuthUtil.restricted(memberType, d.allowed_member_type_level)) {
          setIsOpenUserRegistrationDialog(true);
          return;
        }    
        // 別タブで「DATA」のページを開く。
        const url = `${ContextRoot}/?mode=${DisplayMode.ValueData}&id=${d.id}&lang=${myLang}`;
        window.open(url, '_blank', 'noreferrer');
      };
      if (!CommonUtil.is_mobile()) {
        footer.push(<button className="footer_button" onClick={() => handleOnClickData(d)}>DATA</button>);
      }
    }

    // ×ボタン
    const render_close_button = () => {
      // 固定表示していない場合はボタンは表示しない。
      if (!props.popupEstates.some(item => {
        return item.id === d.id
      })) {
        return null;
      }

      return (
        <div className="close_button" onClick={() => {remove_from_infoWindows(d)}}>
          <svg x="0px" y="0px" viewBox="0 0 512 512" width="9" height="9" version="1.1">
            <g>
              <polygon points="512,52.535 459.467,0.002 256.002,203.462 52.538,0.002 0,52.535 203.47,256.005 0,459.465 52.533,511.998 256.002,308.527 459.467,511.998 512,459.475 308.536,256.005"></polygon>
            </g>
          </svg>
        </div>
      );
    }

    return (
      <div className="info_window">
        <div className="window_header">
          {render_close_button()}
        </div>
        <div className={classNames('content')}>
          {render_property_name()}
          <div className="data">
            {render_data_left()}
            {render_data_center()}
            {render_data_right()}
          </div>
        </div>
        <footer>{footer}</footer>
      </div>
    );
  }

  const labeling = (d: ValueMapDataType) => {
    if (is_checked(d)) {
      // 同じ物件がラベル化されている場合は何もしない。
      return;
    }
    if (labeledValueMapData.length >= MaxLabel) {
      // ラベルの上限に達していたらダイアログで警告する。
      setDialogMsg(t('上限に達しました。最大X件まで追加できます。', { num: MaxLabel }));
      return;
    }

    let newLabeledEstateData = [...labeledValueMapData];
    newLabeledEstateData.push(d);
    setLabeledValueMapData(newLabeledEstateData);

    if (!props.popupEstates.some(item => {
      return item.id === d.id
    })) {
      // すでに追加済みでなければ追加する。
      let newPopupEstate = [...props.popupEstates];
      newPopupEstate.push(d);
      props.setPopupEstates(newPopupEstate); 
    }
  }

  const is_checked = (d: ValueMapDataType) : boolean => {
    for (let labeled_d of labeledValueMapData) {
      if (labeled_d.id === d.id) return true;
    }
    return false;
  }

  /**
   * 
   * @param clusterer クラスタリングマーカーを処理するレンダーのプロパティ
   * @param d 不動産情報
   * @returns マーカーのレンダリング内容
   */
  const render_marker = (clusterer: any, d: ValueMapDataType) => {
    const opts = get_markerOpts(d);
    return (
      <Marker
        key={d.id}  // keyを指定しないと、再検索した際にクラスタが再検索前の位置に置かれてしまう。
        clusterer={props.showOnlyLabeled ? undefined : clusterer}  // チェックを入れた不動産だけを表示する場合は、クラスタ化はしない。
        position={opts.position}
        icon={opts.icon}

        onClick={() => {
          // マウスオーバーで表示した吹き出しは閉じる。
          // [memo] マウスオーバー中、吹き出しが2重表示されるが、リレンダリングで重くなるのでコメントアウト。
          // setMouseOverEstate(null);
 
          if (is_checked(d)) {
            // ラベル化している場合は解除する。
            let newLabeledEstateData = labeledValueMapData.filter(labeled_d => {
              return labeled_d.id !== d.id;
            });
            setLabeledValueMapData(newLabeledEstateData);

            // 吹き出しを閉じる。
            remove_from_infoWindows(d);
            return;
          }

          // ラベリング
          labeling(d);
        }}

        onMouseOver={() => {
          if (!props.popupEstates.some(item => {
            return item.id === d.id
          })) {
            // 同じ吹き出しを多重に表示しないようにする。
            setMouseOverEstate(d);
          }
        }}

        onMouseOut={() => {
          setMouseOverEstate(null);
        }}
      >
      </Marker>
    );
  }

  /**
   * タイプに合わせたマーカーオプションを取得する。
   * 
   * @param d 不動産情報
   * @returns マーカーオプション
   */
  const get_markerOpts = (d: ValueMapDataType) => {
    const icon = get_marker_icon(d);
    
    let property_name = '';
    switch (myLang) {
      case MyLanguage.JA: property_name = d.property_name; break;
      case MyLanguage.EN: property_name = d.property_name_en; break;
    }

    return {
      position: { lat: Number(d.lat), lng: Number(d.lng) },
      title: property_name,
      icon: {
        url: `${ContextRoot}/images/marker/${icon}`,
        scaledSize: get_icon_size(d),
      },
    }
  }

  const get_marker_icon = (d: ValueMapDataType) => {
    for (let i=0; i<labeledValueMapData.length; i++) {
      // チェックを入れた物件は数字アイコンにする。
      if (d.id === labeledValueMapData[i].id) {
        return `${i+1}.png`;
      }
    }

    switch (d.building_type) {
      case 1:  return 'v-office.png';       // オフィス
      case 2:  return 'v-residential.png';  // レジデンシャル
      case 3:  return 'v-retail.png';       // リテール
      case 4:  return 'v-logistics.png';    // ロジスティクス
      case 5:  return 'v-hotel.png';        // ホテル
      case 99: return 'v-others.png';       // その他
      default: return 'v-others.png';       // 不正値(その他と同じにする)
    }
  }

  const get_icon_size = (d: ValueMapDataType) => {
    for (let i=0; i<labeledValueMapData.length; i++) {
      // チェックを入れた物件は数字アイコンにする。
      if (d.id === labeledValueMapData[i].id) {
        return new window.google.maps.Size(12, 12);   //20240810変更
        //return new window.google.maps.Size(17, 17);   //20240128変更→20240203再変更
        //return new window.google.maps.Size(24, 24);   //20240128変更
      }
    }
    return new window.google.maps.Size(12, 12);  //20240810変更
    //return new window.google.maps.Size(17, 17);   //20240128変更→20240203再変更
    //return new window.google.maps.Size(24, 24);   //20240128変更
  }

  const remove_from_infoWindows = (d: ValueMapDataType) => {
    const newClickedEstate = props.popupEstates.filter(item => {
      return item.id !== d.id
    });
    props.setPopupEstates(newClickedEstate);
  }

  const render_infoWindows = () => {
    if (props.popupEstates.length === 0) return null;

    let rendering = [];
    for (let i=0; i<props.popupEstates.length; i++) {
      const d = props.popupEstates[i];
      rendering.push(
        <InfoWindow key={d.id}
          options={{
            pixelOffset: new window.google.maps.Size(0, -24),
            disableAutoPan: true,  // パンさせると吹き出しに合わせて地図が移動してマウスアウトしてしまうため抑止。
            zIndex: i,  // 後から追加したものを前面に表示する。
          }}
          position={
            {
              lat: Number(d.lat),
              lng: Number(d.lng)
            }
          }
          onCloseClick={() => {
            remove_from_infoWindows(d);
          }}
        >
          {render_infoWindowContent(d)}
        </InfoWindow>
      )
    }

    return rendering;
  }

  const render_infoWindow_temp = () => {
    if (!mouseOverEstate) return null;

    const infoWindowOptions = {
      pixelOffset: new window.google.maps.Size(0, -24),
      disableAutoPan: true,  // パンさせると吹き出しに合わせて地図が移動してマウスアウトしてしまうため抑止。
      zIndex: labeledValueMapData.length + 1  // 最前面に表示する。
    }

    return (
      <InfoWindow key={mouseOverEstate.id}
        options={infoWindowOptions}
        position={
          {
            lat: Number(mouseOverEstate.lat),
            lng: Number(mouseOverEstate.lng)
          }
        }
        onCloseClick={() => {
          // 吹き出しを閉じたら、クリア
          setMouseOverEstate(null);
        }}
      >
        {render_infoWindowContent(mouseOverEstate)}
      </InfoWindow>
    );
  }

  /**
   * クラスターのスタイルをカスタマイズ
   * @param markers 
   * @param numStyles 
   * @returns 
   */
  const custom_calculator = (markers: any, numStyles: any) => {
    let index = 0;
    // クラスターに含まれるマーカーの数
    const count: number = markers.length;

    // 画面上に存在する物件数
    const num_data = calc_num_marker_in_screen();

    // クラスターのスタイルは最大5段階まで。
    // クラスターに含まれるマーカーの数と設定するスタイル(index)を以下のように定義する。
    // ------------------------------------------
    // 1段階：10件以下
    // 2段階：画面上の物件数の10%以下
    // 3段階：画面上の物件数の10%より多く20%以下
    // 4段階：画面上の物件数の20%より多く30%以下
    // 5段階：画面上の物件数の30%より多い
    // ------------------------------------------
    if (count <= num_data * 0.1) { index = 2; }
//    if (count > 0 && count <= 10) { index = 1; }
//    else if (count <= num_data * 0.1) { index = 2; }
    else if ((count <= num_data * 0.2) && (count > num_data * 0.1)) { index = 3; }
    else if ((count <= num_data * 0.3) && (count > num_data * 0.2)) { index = 4; }
    else if (count > num_data * 0.3) { index = 5; }

    index = Math.min(index, numStyles);
    return {
      text: count.toString(),
      // text: count + " ("+ index + ")",
      index: index,
      title: '',
    }
  }

  const calc_num_marker_in_screen = () => {
    let num_marker_in_screen = 0;
    const map_bounds = props.mapRef.current.getBounds();
    for (let d of props.valueMapData) {
      if (map_bounds.contains({ lat: Number(d.lat), lng: Number(d.lng) })) {
        num_marker_in_screen++;
      }
    }
    return num_marker_in_screen;
  }

  // 不動産情報が変わるたびにkeyを更新することで、再検索した際に地図上のマーカーなどを作り直させる。(速度向上のため)
  // idを文字列として全て連結したものをkeyとしている。
  // (今後データが増えた際に、もしkeyの長さが問題になる場合は、ハッシュ値にしても良いかもしれない)
  let key = '';
  for (let d of props.valueMapData) {
    key += d.id.toString();
  }

  return (
    <>
      <MarkerClusterer key={key} options={options} calculator={custom_calculator} onClusteringEnd={(markerClusterer) => {
        if (props.valueMapData.length === markerClusterer.markers.length) {
        }        
      }}
      >
        {(clusterer) => 
          props.valueMapData.map(
            (data: any) => render_marker(clusterer, data)
          ) 
        }
      </MarkerClusterer>
      {render_infoWindows()}
      {render_infoWindow_temp()}
      <MapMlit
        mapRef={props.mapRef}
        setIsMapInteractive={props.setIsMapInteractive}
        isLandPricesActive={props.isLandPricesActive}
        isZoningActive={props.isZoningActive}
      />
      <HazardMapLayers
        isFloodActive={props.isFloodActive}
        isSedimentActive={props.isSedimentActive}
        isTsunamiActive={props.isTsunamiActive}
        isStormSurgeActive={props.isStormSurgeActive}
      />
    </>
  );
}

export default ValueMapContent;
