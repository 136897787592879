import { FC, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import * as holiday_jp from '@holiday-jp/holiday_jp';
import { AppContext, MyLanguage } from '../context/AppContext';
import CommonUtil from '../utils/CommonUtil';

import './StockCalendar.css';

const StockCalendar: FC = () => {

  const today = new Date();
  const [ t ] = useTranslation();
  const { myLang } = useContext(AppContext);
  const [ currentDate, setCurrentDate ] = useState<Date>(today);
  const { investmentCalendar } = useContext(AppContext);

  useEffect(() => {
  }, []);
  
  const is_same_date = (date1: Date, date2: Date) => {
    if (date1.getFullYear() === date2.getFullYear() && 
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()) {
      return true;
    } else {
      return false;
    }
  }

  const get_currentDate = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();

    switch (myLang) {
      case MyLanguage.JA:
        return `${year}年${month + 1}月`;
      case MyLanguage.EN:
        return `${CommonUtil.convert_month_3letters(month)} ${year}`;
    }
    return '';
  }

  const get_month = (month: number) => {
    switch (myLang) {
      case MyLanguage.JA:
        return `${month + 1}月`;
      case MyLanguage.EN:
        return `${CommonUtil.convert_month_3letters(month)}`;
    }
    return '';
  }

  const convert_Date = (date: number) => {
    const year = Number(String(date).substring(0, 4));
    // 月は、1文字目が0の場合は省略するため、数値に変換する。
    const month = Number(String(date).substring(4, 6));
    const day = Number(String(date).substring(6, 8));

    return new Date(year, month - 1, day);
  }

  const get_prevMonth = () => {
    const prevMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
    return get_month(prevMonth.getMonth());
  }

  const get_nextMonth = () => {
    const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
    return get_month(nextMonth.getMonth());
  }


  const render_prev_icon = () => {
    return (
      <svg x="0px" y="0px" viewBox="0 0 512 512">
        <g><polygon points="419.916,71.821 348.084,0 92.084,256.005 348.084,512 419.916,440.178 235.742,256.005"></polygon></g>
      </svg>
    );
  }

  const render_next_icon = () => {
    return (
      <svg x="0px" y="0px" viewBox="0 0 512 512">
        <g><polygon points="163.916,0 92.084,71.822 276.258,255.996 92.084,440.178 163.916,512 419.916,255.996"></polygon></g>
      </svg>
    );
  }

  const render_prev_month = () => {
    if (investmentCalendar.length === 0) return null;

    let hiddenClassName = '';
    const first_event = investmentCalendar[0];
    const first_event_date = convert_Date(first_event.date);

    const prevMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
    if (first_event_date > prevMonth) {
      // 最初のイベント日が前月以前に無ければ、非表示。
      hiddenClassName = 'hidden';
    }

    const change_month = () => {
      const new_month = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1);
      setCurrentDate(new_month);
    }

    return (
      <div className={classNames("prev_month", hiddenClassName)} onClick={() => {change_month()}}><span>{get_prevMonth()}</span><span>{render_prev_icon()}</span></div>
    )
  }

  const render_next_month = () => {
    if (investmentCalendar.length === 0) return null;

    let hiddenClassName = '';
    const last_event = investmentCalendar[investmentCalendar.length - 1];
    const last_event_date = convert_Date(last_event.date);

    const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
    if (last_event_date < nextMonth) {
      // 最後のイベント日が次月以降に無ければ、非表示。
      hiddenClassName = 'hidden';
    }

    const change_month = () => {
      const new_month = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1);
      setCurrentDate(new_month);
    }

    return (
      <div className={classNames("next_month", hiddenClassName)} onClick={() => {change_month()}}><span>{render_next_icon()}</span><span>{get_nextMonth()}</span></div>
    )
  }

  const get_San_Sat_Weekday_Holiday_className = (date: Date, holidays: holiday_jp.Holiday<Date>[]) => {

    if (date.getDay() === 0) {
      // 日曜日
      return 'Sun';
    }

    for (let holiday of holidays) {
      if (is_same_date(date, holiday.date)) {
        // 祝日
        return 'holiday';
      }
    }

    if (date.getDay() === 6) {
      // 土曜日
      return 'Sat';
    }
    // 平日
    return 'weekday';
  }

  const get_today_className = (date: Date) => {
    if (is_same_date(date, today)) {
      return 'today';
    }
    return '';
  }

  const render_events = (date: Date) => {
    let rendering = [];
    for (let d of investmentCalendar) {
      const event_date = convert_Date(d.date);
      if (is_same_date(date, event_date)) {
        let event = '';
        let url = '';
        switch (myLang) {
          case MyLanguage.JA: {
            event = d.event;
            url = d.url;
            break;
          }
          case MyLanguage.EN: {
            event = d.event_en;
            url = d.url_en;
            break;
          }
        }

        if (url) {
          rendering.push(<span><a href={url} target="_blank" rel="noreferrer">{event}</a></span>);
        } else {
          rendering.push(<span>{event}</span>);
        }
      }
    }
    return rendering;
  }

  const render_days = () => {
    // まず表示月の最初の日を求める。
    const first_date_to_display = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    // 次にその週の日曜日に相当する日を求める。
    first_date_to_display.setDate(first_date_to_display.getDate() - first_date_to_display.getDay());

    // 表示月の最後の日を求める。
    const last_date_of_currentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    // その週の土曜日に相当する日を求める。
    const last_date_to_display = last_date_of_currentMonth;
    last_date_to_display.setDate(last_date_to_display.getDate() + (6 - last_date_to_display.getDay()));

    // 祝日を取得
    const holidays = holiday_jp.between(first_date_to_display, last_date_to_display);

    let rendering = [];
    const date = first_date_to_display;
    while (date <= last_date_to_display) {
      let week = [];
      for (let day = 0; day < 7; day++) {
        const San_Sat_Weekday_className = get_San_Sat_Weekday_Holiday_className(date, holidays);
        const today_className = get_today_className(date);
        week.push(
          <td className={classNames(San_Sat_Weekday_className, today_className)}>
            <div>
              <span className="date">{date.getDate()}</span>
              {render_events(date)}
            </div>
          </td>
        );
        date.setDate(date.getDate() + 1);
      }
      rendering.push(<tr>{week}</tr>);
    }
    return rendering;
  }

  return (
    <div id="StockCalendar">
      <div className="month_controller">
        {render_prev_month()}
        <span>{get_currentDate()}</span>
        {render_next_month()}
      </div>
      <div className="calendar">
        <table>
          <thead>
            <tr>
              <th className="Sun">{t('日')}</th>
              <th className="weekday">{t('月')}</th>
              <th className="weekday">{t('火')}</th>
              <th className="weekday">{t('水')}</th>
              <th className="weekday">{t('木')}</th>
              <th className="weekday">{t('金')}</th>
              <th className="Sat">{t('土')}</th>
            </tr>
          </thead>
          <tbody>
            {render_days()}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default StockCalendar;