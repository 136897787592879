import { MyLanguage } from '../context/AppContext';

class CommonUtil {
  /**
   * コンテキストルートを取得する。
   * 
   * @returns コンテキストルート
   */
  static get_context_root = () => {
    return '/jreit';
  }

  /**
   * YYYYMMDD形式を、「YYYY年M月」に変換する(多言語対応あり)。
   * @param yyyymmdd 年月(YYYYMM形式)
   * @param lang 言語
   * @returns 年月(「YYYY年M月」)
   */
  static convert_YearMonth = (yyyymmdd: number | null, lang: string) => {
    if (yyyymmdd === null) {
      return '';
    }

    const year = String(yyyymmdd).substring(0, 4);
    // 月は、1文字目が0の場合は省略するため、数値に変換する。
    const month = Number(String(yyyymmdd).substring(4, 6));

    // // 月は、1文字目が0の場合は省略するため、数値に変換する。
    // return `${year}年${Number(month)}月`;
    switch (lang) {
      case MyLanguage.JA:
        return `${year}年${Number(month)}月`;
      case MyLanguage.EN:
        // 「May. 2020」
        return `${CommonUtil.convert_month_3letters(month-1)} ${year}`;
    }
    return '';
  }

  /**
   * YYYYMMDD形式を、「YYYY年M月D日」に変換する(多言語対応あり)。
   * @param yyyymmdd 年月(YYYYMMDD形式)
   * @param lang 言語
   * @returns 年月日(「YYYY/M/D」)
   */
  static convert_YearMonthDay_with_slash = (yyyymmdd: number | null, lang: string) => {
    if (yyyymmdd === null) {
      return '';
    }

    const year = String(yyyymmdd).substring(0, 4);
    // 月は、1文字目が0の場合は省略するため、数値に変換する。
    const month = Number(String(yyyymmdd).substring(4, 6));
    const day = Number(String(yyyymmdd).substring(6, 8));

    // // 月は、1文字目が0の場合は省略するため、数値に変換する。
    // return `${year}年${Number(month)}月`;
    switch (lang) {
      case MyLanguage.JA:
        return `${year}/${Number(month)}/${Number(day)}`;
      case MyLanguage.EN:
        // 「Month/Day/Year」
        return `${Number(month)}/${Number(day)}/${year}`;
    }
    return '';
  }

  /**
   * YYYYMMDD形式を、「YYYY年M月D日」に変換する(多言語対応あり)。
   * @param timestamp タイムスタンプ
   * @param lang 言語
   * @returns 年月日(「YYYY年M月D日」)
   */
  static convert_YearMonthDay = (timestamp: string | null, lang: string) => {
    if (timestamp === null) {
      return '';
    }

    const date = new Date(timestamp);
    console.log(date);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    switch (lang) {
      case MyLanguage.JA:
        return `${year}年${month}月${day}日`;
      case MyLanguage.EN:
        // 「月/日/年」
        return `${month}/${day}/${year}`;
    }
    return '';
  }

  /**
   * 3文字表記の月に変換する。
   * 
   * @param month 月(0～11までの数字)
   * @returns 3文字表記の月
   */
  static convert_month_3letters = (month: number) => {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    return months[month];
  }

  static get_percent_text = (val: number, digits: number) => {
    if (val == 0) {
      return '0';
    }
    return val.toLocaleString(undefined, {minimumFractionDigits: digits, maximumFractionDigits: digits});
  }

  static tsubo_to_spft = (tsubo: number) => {
    return tsubo / 35.58;
  }

  static is_mobile = () => {
    if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
      return true;
    } else {
      return false;
    }
  }

  static open_Contact = (myLang: string) => {
    let url = '';
    switch (myLang) {
      case MyLanguage.JA:
        url = 'https://fairvalue.works/contact/';
        break;
      case MyLanguage.EN:
        url = 'https://fairvalue.works/en/contact/';
        break;
    }
    window.open(url, '_blank', 'noreferrer');
  }

  static open_TermOfUse = (myLang: string) => {
    let url = '';
    switch (myLang) {
      case MyLanguage.JA:
        url = 'https://fairvalue.works/terms-of-use/';
        break;
      case MyLanguage.EN:
        url = 'https://fairvalue.works/en/terms-of-use/';
        break;
    }
    window.open(url, '_blank', 'noreferrer');
  }

  static open_PrivacyPolicy = (myLang: string) => {
    let url = '';
    switch (myLang) {
      case MyLanguage.JA:
        url = 'https://fairvalue.works/privacy/';
        break;
      case MyLanguage.EN:
        url = 'https://fairvalue.works/en/privacy/';
        break;
    }
    window.open(url, '_blank', 'noreferrer');
  }

  static open_Company = (myLang: string) => {
    let url = '';
    switch (myLang) {
      case MyLanguage.JA:
        url = 'https://fairvalue.works/';
        break;
      case MyLanguage.EN:
        url = 'https://fairvalue.works/en/';
        break;
    }
    window.open(url, '_blank', 'noreferrer');
  }

  static get_language_value = (myLang: string) => {
    if (myLang === MyLanguage.JA) return 'ENG';
    if (myLang === MyLanguage.EN) return 'JP';
    return '';
  }

}

export default CommonUtil
