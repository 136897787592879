import './GuidePage.css';
import { FC, useContext, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Dialog from '../components/Dialog';
import LoginDialog from '../components/LoginDialog';
import Guide from "../components/Guide";
import { AppContext, DisplayMode } from '../context/AppContext';

const GuidePage: FC = () => {
  const { dialogMsg, setDisplayMode, isOpenLoginDialog } = useContext(AppContext);
  
  useEffect(() => {
    setDisplayMode(DisplayMode.Guide);
  }, []);

  return (
    <>
      <Header />
      <div id="GuidePage">
        <Guide />
      </div>
      <Footer />
      { dialogMsg && <Dialog /> }
      { isOpenLoginDialog && <LoginDialog /> }
    </>
  );
}

export default GuidePage