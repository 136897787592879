class AuthUtil {
  static RestrictedMessage = '権限無しのメッセージ';

  static restricted = (memberType: number, allowedMemberTypeLevel: number) => {
    // return false;
    if (memberType >= allowedMemberTypeLevel) {
      return false;
    }
    return true;
  }
}

export default AuthUtil