import { FC, useContext, useEffect, useState , useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext, MyLanguage, SearchType } from '../context/AppContext';
import EstateDataUtil from '../utils/EstateDataUtil';
import classNames from 'classnames';
import HistDataUtil from '../utils/HistDataUtil';
import AreaDataUtil from '../utils/AreaDataUtil';
import Chart from 'react-apexcharts';
import HistAreaDataUtil from '../utils/HistAreaDataUtil';
import ChartUtil from '../utils/ChartUtil';
import ToExcel from '../utils/ToExcel';
import { useAuthContext } from '../context/AuthContext';
import AuthUtil from '../utils/AuthUtil';
import { MemberType } from '../utils/MemberType';
import ExpDataUtil from '../utils/ExpDataUtil';
import { EstateDataType } from '../utils/EstateDataType';
import { HistDataType } from '../utils/HistDataType';
import { ExpDataType } from '../utils/ExpDataType';
import { HistAreaDataType } from '../utils/HistAreaDataType';
import { AreaDataType } from '../utils/AreaDataType';
import CommonUtil from '../utils/CommonUtil';
import { ChartNoiyOccType, ChartAdrRevParType, ChartRentType, ChartAvCrType, ChartExpRatioType, ChartExpDetailType } from '../utils/ToExcelType';

import './Detail.css';

const Detail: FC = () => {
  const ContextRoot = CommonUtil.get_context_root();

  const [ t, i18n ] = useTranslation();
  const { myLang, setMyLang, memberType, setIsOpenUserRegistrationDialog } = useContext(AppContext);
  const [ estateDataForDetail, setEstateDataForDetail ] = useState<EstateDataType[]>([]);
  const [ histData, setHistData ] = useState<HistDataType[]>([]);
  const [ expData, setExpData ] = useState<ExpDataType[]>([]);
  const [ histAreaData, setHistAreaData ] = useState<HistAreaDataType[]>([]);
  const [ areaData, setAreaData ] = useState<AreaDataType[]>([]);
  const { selectedId } = useContext(AppContext);
  const { selectedPropertyNo } = useContext(AppContext);
  const { setSearchType } = useContext(AppContext);
  const { appData } = useContext(AppContext);
  const { currentUser } = useAuthContext();
  const [ restricted, setRestricted ] = useState<boolean | undefined>(undefined);


  // Y軸の最大文字数
  const [ maxNumOfCharaYAxis, setMaxNumOfCharAxis ] = useState<number>(4);

  const useDelay = (msec: number) => {
    const [waiting, setWaiting] = useState(true);
    useEffect(()=>{
      setTimeout(()=> setWaiting(false), msec);
    }, []);
    return waiting
  }
  // レンダリングを遅延させる。
  // # 物件名や住所などの表の幅を自動調整することにより、その下の経費率や内訳のグラフの幅が伸びてしまう模様。
  // # これを回避するため、表の表示よりもレンダリングを遅らせて対応する。
  const render_waiting = useDelay(1000);
  
  const ChartId = {
    NOI_Y : 'noi_y',
    OCC   : 'occ',
    RENT  : 'rent',
    CR    : 'cr',
    AV    : 'av',
    ADR   : 'adr',
    REV_PAR : 'rev_par',
  };

  interface ChartSize {
    width: number,
    height: number,
  }

  // チャートの縦サイズ
  const ChartHeight = 345;

  // チャートのタイトルのフォントサイズ
  const ChartTitleFontSize = '16px';
  // チャートのサブタイトルのフォントサイズ
  const ChartSubTitleFontSize = '11px';

  const GridInterval = 5;

  const StrokeWidth = 2.8;
  const MarkerSize = 4.8;

  const MinWidth = 50;

  useEffect(() => {
    if (currentUser === undefined) return;

    setRestricted(AuthUtil.restricted(memberType, MemberType.C));
  }, [currentUser, memberType]);

  useEffect(() => {
    // document.title = 'reit-map';
    const cond = {
      id: selectedId,
    }
    setSearchType(SearchType.Id);
    EstateDataUtil.fetch_estate_data(cond, setEstateDataForDetail, () => {});

    if (selectedPropertyNo !== null) {
      HistDataUtil.fetch_hist_data({property_no: selectedPropertyNo}, setHistData, () => {});
      ExpDataUtil.fetch_exp_data({property_no: selectedPropertyNo}, setExpData, () => {});
    }

  }, []);

  useEffect(() => {
    // 不動産情報が取得できたら、エリアNoに対応するエリア名を取得する。
    if (estateDataForDetail.length === 0) return;
    const d = estateDataForDetail[0];
    if (d.area_no) {
      const cond = {
        area_no: d.area_no
      }
      AreaDataUtil.fetch_area_data(cond, setAreaData, () => {});
      HistAreaDataUtil.fetch_hist_area_data(cond, setHistAreaData, () => {});
    }
  }, [estateDataForDetail]);

  useEffect(() => {
    if (histData.length === 0) return;

    let date_list = create_date_list();

    let y_data = [];
    for (let date of date_list) {
      const rent = get_chart_hist_data(ChartId.RENT, date);
      const av = get_chart_hist_data(ChartId.AV, date);
      if (rent !== null) y_data.push(rent);
      if (av !== null) y_data.push(av);
    }

    if (y_data.length === 0) return;

    const max_data = Math.max(...y_data);
    const max_data_str = max_data.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}); 
    setMaxNumOfCharAxis(max_data_str.length);

  }, [histData]);


  const render_detail = () => {
    if (estateDataForDetail.length === 0) return null;
    const d = estateDataForDetail[0];

    let rows = [];
    let tds = [];

    // 物件名
    tds = [];
    tds.push(<td></td>);
    switch (myLang) {
      case MyLanguage.JA: {
        if (!d.property_name) tds.push(<td></td>);
        else tds.push(<td>{d.property_name}</td>);
        break;
      }
      case MyLanguage.EN: {
        if (!d.property_name_en) tds.push(<td></td>);
        else tds.push(<td>{d.property_name_en}</td>);
        break;
      }
    }
    rows.push(<tr>{tds}</tr>);

    // 物件の写真
    tds = [];
    {
      if (d.images_files) {
        const image_src = `${ContextRoot}/images/properties/${d.images_files}.jpg`;
        tds.push(<td></td>)
        tds.push(<td><img src={image_src}></img></td>); 
        rows.push(<tr className="image_row">{tds}</tr>);  
      }
    }

    // タイプ
    tds = [];
    tds.push(<td>{t('タイプ')}</td>);
    if (!d.building_type) tds.push(<td>NA</td>);
    else tds.push(<td>{t(EstateDataUtil.convert_buildingType_name(d.building_type, d.building_type2))}</td>);
    rows.push(<tr>{tds}</tr>);
    
    // 所在地
    tds = [];
    tds.push(<td>{t('所在地')}</td>);
    switch (myLang) {
      case MyLanguage.JA: {
        if (d.addr1 || d.addr2 || d.addr3) {
          tds.push(<td>{d.addr1}{d.addr2}{d.addr3}</td>);
        } else {
          tds.push(<td>NA</td>);
        }
        break;
      }
      case MyLanguage.EN: {
        if (d.addr1_en || d.addr2_en || d.addr3_en) {
          let addr = [];
          if (d.addr3_en) addr.push(<>{d.addr3_en},&nbsp;</>);
          if (d.addr2_en) addr.push(<>{d.addr2_en},&nbsp;</>);
          if (d.addr1_en) addr.push(<>{d.addr1_en}</>);
          tds.push(<td>{addr}</td>);
        } else {
          tds.push(<td>NA</td>);
        }
        break;
      }
    }
    rows.push(<tr>{tds}</tr>);

    // 最寄り駅
    tds = [];
    tds.push(<td>{t('最寄り駅')}</td>);
    let station = 'NA';
    let distance = '';
    switch (myLang) {
      case MyLanguage.JA: {
        if (d.station) {
          station = d.station;
        }
        break;
      }
      case MyLanguage.EN: {
        if (d.station_en) {
          station = d.station_en;
        }
        break;
      }
    }
    if (d.distance !== null) {
      if (d.distance >= 1000) {
        // 小数点以下1桁
        distance = Number(d.distance / 1000).toFixed(1).toLocaleString() + 'km';
      } else {
        distance = String(d.distance) + 'm';
      }
    }
    tds.push(<td>{station}&emsp;{distance}</td>);
    rows.push(<tr>{tds}</tr>);

    // 取引価格
    tds = [];
    tds.push(<td>{t('取引価格(Hist)')}</td>);
    if (!EstateDataUtil.convert_disclose_tx_price(d.disclose_tx_price)) {
      tds.push(<td>{t('非開示')}</td>);
    } else if (d.transaction_price !== null) {
      let text = '';
      switch (myLang) {
        case MyLanguage.JA: {
          text = `${Number(d.transaction_price).toLocaleString()} 百万円`;
          tds.push(<td>{text}</td>);
          break;
        }
        case MyLanguage.EN: {
          text = `${Number(d.transaction_price).toLocaleString()}m`;
          tds.push(<td>&yen; {text}</td>);
          break;
        }
        default: {
          tds.push(<td>{text}</td>);    
          break;
        }
      }
    } else {
      tds.push(<td>NA</td>);
    }
    rows.push(<tr>{tds}</tr>);
    
    // CR(NOI)
    tds = [];
    tds.push(<td>{t('キャップレート（NOI）')}</td>);
    if (!EstateDataUtil.convert_disclose_cap_rate(d.disclose_cap_rate)) {
      tds.push(<td>{t('非開示')}</td>);
    }
    else if (d.cap_rate !== null) {
      tds.push(<td>{EstateDataUtil.convert_capRate_percentage(d.cap_rate)}%</td>);
    } else {
      tds.push(<td>NA</td>);
    }
    rows.push(<tr>{tds}</tr>);

    // 取引年月
    tds = [];
    tds.push(<td>{t('取引年月')}</td>);
    if (d.transaction_yyyymmdd) {
      tds.push(<td>{EstateDataUtil.convert_YearMonth(d.transaction_yyyymmdd, myLang)}</td>);
    } else {
      tds.push(<td>NA</td>);
    }
    rows.push(<tr>{tds}</tr>);

    // 土地面積
    tds = [];
    tds.push(<td>{t('土地面積')}</td>);
    if (d.land_area !== null) {
      tds.push(<td>{d.land_area.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} ㎡</td>);
    } else {
      tds.push(<td>NA</td>);
    }
    rows.push(<tr>{tds}</tr>);

    // 階数
    tds = [];
    tds.push(<td>{t('階数')}</td>);
    switch (myLang) {
      case MyLanguage.JA: {
        if (!d.num_of_floors) tds.push(<td>NA</td>);
        else tds.push(<td>{d.num_of_floors}</td>);
        break;
      }
      case MyLanguage.EN: {
        if (!d.num_of_floors_en) tds.push(<td>NA</td>);
        else tds.push(<td>{d.num_of_floors_en}</td>);
        break;
      }
    }
    rows.push(<tr>{tds}</tr>);

    // 総戸数
    if (EstateDataUtil.has_total_units(d)) {
      tds = [];
      tds.push(<td>{t('総戸数')}</td>);
      if (d.total_units === null) tds.push(<td>NA</td>);
      else tds.push(<td>{d.total_units}</td>);
      rows.push(<tr>{tds}</tr>);  
    }
    
    // 建物全体面積
    tds = [];
    tds.push(<td>{t('建物全体面積')}</td>);
    if (d.la === null) tds.push(<td>NA</td>);
    else tds.push(<td>{d.la.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} ㎡</td>);
    rows.push(<tr>{tds}</tr>);

    // 延床面積（GFL）
    tds = [];
    tds.push(<td>{t('延床面積（GFL）')}</td>);
    if (d.architectural_area === null) tds.push(<td>NA</td>);
    else tds.push(<td>{d.architectural_area.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} ㎡</td>);
    rows.push(<tr>{tds}</tr>);

  // 賃貸可能面積（NLA） 20240227 無効化
    tds = [];
    tds.push(<td>{t('賃貸可能面積（NLA）')}</td>);
    if (d.rentable_area === null) tds.push(<td>NA</td>);
    else tds.push(<td>{d.rentable_area.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})} ㎡</td>);
    rows.push(<tr>{tds}</tr>);

    // 取引単価（取引価格/NLA）
    tds = [];
    tds.push(<td>{t('取引単価（取引価格/NLA）')}</td>);
    if (d.unit_price === null) tds.push(<td>NA</td>);
    else {
      let text = '';
      switch (myLang) {
        case MyLanguage.JA:
          text = d.unit_price.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + ' 円/坪';  // カンマ区切り＆小数点以下0桁
          tds.push(<td>{text}</td>);
          break;
        case MyLanguage.EN:
          text = (CommonUtil.tsubo_to_spft(d.unit_price)).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + '/sq.ft';  // カンマ区切り＆小数点以下0桁
          tds.push(<td>&yen; {text}</td>);
          break;
        default:
          tds.push(<td>{text}</td>);
          break;
      }
    }
    rows.push(<tr>{tds}</tr>);

    // 竣工
    tds = [];
    tds.push(<td>{t('竣工')}</td>);
    if (!d.construction_yyyymmdd) tds.push(<td>NA</td>);
    else tds.push(<td>{EstateDataUtil.convert_YearMonth(d.construction_yyyymmdd, myLang)}</td>);
    rows.push(<tr>{tds}</tr>);

    // 投資法人
    tds = [];
    tds.push(<td>{t('投資法人')}</td>);
    let corp = 'NA';
    if (d.investment_corp_type !== null && d.investment_corp_code !== null) {
      corp = EstateDataUtil.get_investment_corp(appData['investment_corp'], d.investment_corp_type, d.investment_corp_code, myLang);
    }
    tds.push(<td>{corp}</td>);
    rows.push(<tr>{tds}</tr>);

    // 権利
    tds = [];
    tds.push(<td>{t('権利')}</td>);
    if (d._ownership === null) tds.push(<td>NA</td>);
    else tds.push(<td>{t(EstateDataUtil.convert_ownership(d._ownership))}</td>);
    rows.push(<tr>{tds}</tr>);

    return (
      <>
        <table>
          {rows}
        </table>
      </>
    );
  }

  /**
   * NOI利回り＆稼働率のチャート
   * @returns 
   */
  const render_Chart_NOIY_Occ = () => {
    let date_list = create_date_list();

    let histData_NOIY = [];
    let histData_Occ = [];
    let all_histData_NOIY: number[] = [];
    let all_histData_Occ: number[] = [];

    for (let date of date_list) {
      const noiy = get_chart_hist_data(ChartId.NOI_Y, date);
      const occ = get_chart_hist_data(ChartId.OCC, date);

      histData_NOIY.push(noiy);
      histData_Occ.push(occ);

      if (noiy !== null) all_histData_NOIY.push(noiy);
      if (occ !== null) all_histData_Occ.push(occ);
    }

    const series = [
      {
        name: 'NOI Yield',
        type: 'line',
        data: histData_NOIY
      },
      {
        name: t('稼働率'),
        type: 'area',
        data: histData_Occ
      },
    ];

    const options = create_NOIY_Occ_chart_opts(date_list, all_histData_NOIY, all_histData_Occ);

    const chart_size = get_right_chart_size();
    return (<div id="Chart_NOIY_Occ" className={classNames("Chart", "Chart_NOIY_Occ")}><Chart type='line' options={options} series={series} height={chart_size.height} width={chart_size.width} /></div>);
  }

  /**
   * ADR & RevParのチャート
   */
  const render_Chart_Adr_RevPar = () => {
    let date_list = create_date_list_24Month();

    let histData_Adr = [];
    let histData_RevPar = [];
    let histData_dummy = [];
    let all_histData: number[] = [];         // nullを除くデータ(Y軸の最小値最大値の計算用)
    let all_histData_Adr: number[] = [];     // nullを除くデータ
    let all_histData_RevPar: number[] = [];  // nullを除くデータ

    for (let date of date_list) {
      const adr = get_chart_hist_data(ChartId.ADR, date);
      const rev_par = get_chart_hist_data(ChartId.REV_PAR, date);

      histData_Adr.push(adr);
      histData_RevPar.push(rev_par);
      histData_dummy.push(null);

      if (adr !== null) {
        all_histData_Adr.push(adr);
        all_histData.push(adr);
      }
      if (rev_par !== null) {
        all_histData_RevPar.push(rev_par);
        all_histData.push(rev_par);
      }
    }

    if (all_histData_Adr.length === 0 && all_histData_RevPar.length === 0) {
      // データがない場合はチャートを表示しない。
      return null;
    }

    let series = [
      {
        name: 'ADR',
        type: 'line',
        data: histData_Adr
      },
      {
        name: 'RevPar',
        type: 'line',
        data: histData_RevPar
      },
    ];

    // x軸を「鑑定評価額 ＆ 鑑定CR（NCF）」のチャートと合わせるため、ダミーの棒グラフを用意する。
    series.push({
      name: '',
      type: 'column',
      data: histData_dummy
    });

    const grid_min_max = ChartUtil.get_grid_min_max(Math.min(...all_histData), Math.max(...all_histData), GridInterval);

    const options = create_Adr_RevPar_chart_opts(date_list, grid_min_max.grid_min, grid_min_max.grid_max, GridInterval, all_histData_Adr, all_histData_RevPar);
    const chart_size = get_right_chart_size();
    return (
      <div id="Chart_Adr_RevPar" className='Chart'>
        <Chart type='line' options={options} series={series} height={chart_size.height} width={chart_size.width} />
      </div>
    );
  }

  /**
   * 賃料のチャート
   * @returns 
   */
  const render_Chart_Rent = () => {
    if (estateDataForDetail.length === 0) return;
    const d = estateDataForDetail[0];

    let date_list = create_date_list();

    let histData_Rent = [];
    let histData_Rent_Area = [];
    let histData_dummy = [];
    let all_histData: number[] = [];
    let all_histData_Rent: number[] = [];
    let only_null_data = true;

    for (let date of date_list) {
      const rent = get_chart_hist_data(ChartId.RENT, date);
      const rent_area = get_chart_hist_area_data(ChartId.RENT, date);

      histData_Rent.push(rent);
      histData_Rent_Area.push(rent_area);
      histData_dummy.push(null);

      if (rent !== null) {
        all_histData.push(rent);
        all_histData_Rent.push(rent);
      }
      if (rent_area !== null) all_histData.push(rent_area);

      if (rent !== null) only_null_data = false;
    }

    // 賃料のデータがなければチャートを表示しない。
    if (only_null_data) return null;

    let property_name = '';
    switch (myLang) {
      case MyLanguage.JA: property_name = d.property_name; break;
      case MyLanguage.EN: property_name = d.property_name_en; break;
    }
    let series = [
      {
        name: property_name,
        type: 'line',
        data: histData_Rent
      }
    ];

    // エリアデータがある場合、チャートに追加。
    let has_area_data = false;
    let not_only_null_area_data = false;
    for (let a of histData_Rent_Area) {
      // 全てnullデータかどうかをチェック。
      if (a !== null) {
        not_only_null_area_data = true;
        break;
      }
    }
    if (not_only_null_area_data && histData_Rent_Area.length > 0 && areaData.length > 0) {
      has_area_data  = true;
      let area_name = '';
      switch (myLang) {
        case MyLanguage.JA: area_name = areaData[0].area_name + '（平均）'; break;
        case MyLanguage.EN: area_name = areaData[0].area_name_en + ' (Av)'; break;
      }
      series.push({
        name: area_name,
        type: 'line',
        data: histData_Rent_Area
      });
    }

    // x軸を「鑑定評価額 ＆ 鑑定CR（NCF）」のチャートと合わせるため、ダミーの棒グラフを用意する。
    series.push({
      name: '',
      type: 'column',
      data: histData_dummy
    });

    const grid_min_max = ChartUtil.get_grid_min_max(Math.min(...all_histData), Math.max(...all_histData), GridInterval);

    const options = create_Rent_chart_opts(date_list, grid_min_max.grid_min, grid_min_max.grid_max, GridInterval, only_null_data, has_area_data);
    const chart_size = get_right_chart_size();
    return (
      <div id="Chart_Rent" className='Chart'>
        <div className="title">
          <span className='balloon_parent'>
            {t('賃料（円/坪）')}
            {render_balloon([t('賃料収入 / 稼働面積'), t('(＊テナント入退去時は数値のブレが生じる)')], 'center')}
          </span>
        </div>
        <Chart type='line' options={options} series={series} height={chart_size.height} width={chart_size.width} />
      </div>
    );
  }


  const render_Chart_ExpRatio = () => {
    if (expData.length === 0) return null;

    const d = expData[0];
    if (d.exp_ratio === null) return null;

    // 半円のため、2倍する。
    const series = [d.exp_ratio * 100 * 2];
    const options: any = {
      chart: {
        type: 'radialBar',
        offsetY: -10,
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#efefef",
            strokeWidth: '100%',
            margin: 5, // margin is in pixels
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -15,
              fontSize: '24px',
              fontWeight: 'bold',
              formatter: function (val: number) {
                if (val === null || val === undefined) return '';
                // 表示は元の数字にする。
                return (val / 2).toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';  // カンマ区切り＆小数点以下1桁
              }
            },
          }
        }
      },
      grid: {
        padding: {
          top: -10
        }
      },
      fill: {
        colors: [ '#2F75B5' ],
        type: 'solid',
      },
      stroke: {
        lineCap: 'round'
      },
    };

    return (
      <div id="Chart_ExpRatio" className={classNames('Chart', 'Chart_ExpRatio')}>
        <div className="title">
          <span className='balloon_parent'>
            {t('経費率')}
            {render_balloon([t('（＊最大直近５年平均）')], 'center')}
          </span>
        </div>
        <Chart type="radialBar" options={options} series={series} />
      </div>
    );
  }


  const render_Chart_ExpDetail = () => {
    if (expData.length === 0) return null;

    const d = expData[0];
    
    let data = [];
    let categories = [];
    let colors = [];

    const digits = 2;
    if (d.bm !== null) {
      data.push(ChartUtil.to_percentage(d.bm, digits));
      categories.push('BM');
      colors.push('#5B9BD5');
    }
    if (d.pm !== null) {
      data.push(ChartUtil.to_percentage(d.pm, digits));
      categories.push('PM');
      colors.push('#4472C4');
    }
    if (d.bm_pm !== null) {
      data.push(ChartUtil.to_percentage(d.bm_pm, digits));
      categories.push('BM & PM');
      colors.push('#44546A');
    }
    if (d.utility !== null) {
      data.push(ChartUtil.to_percentage(d.utility, digits));
      categories.push(t('水光熱費'));
      colors.push('#ED7D31');
    }
    if (d.repair !== null) {
      data.push(ChartUtil.to_percentage(d.repair, digits));
      categories.push(t('修繕費'));
      colors.push('#A5A5A5');
    }
    if (d.tax !== null) {
      data.push(ChartUtil.to_percentage(d.tax, digits));
      categories.push(t('公租公課'));
      colors.push('#70AD47');
    }
    if (d.insurance !== null) {
      data.push(ChartUtil.to_percentage(d.insurance, digits));
      categories.push(t('保険料'));
      colors.push('#F8CBAD');
    }
    if (d.leased_land !== null) {
      data.push(ChartUtil.to_percentage(d.leased_land, digits));
      categories.push(t('賃借料'));
      colors.push('#ACB9CA');
    }
    if (d.maintenance !== null) {
      data.push(ChartUtil.to_percentage(d.maintenance, digits));
      categories.push(t('定期保守'));
      colors.push('#9BC2E6');
    }
    if (d.leasing_fee !== null) {
      data.push(ChartUtil.to_percentage(d.leasing_fee, digits));
      categories.push(t('仲介手数料、宣伝広告'));
      colors.push('#A9D08E');
    }
    if (d.trust_fee !== null) {
      data.push(ChartUtil.to_percentage(d.trust_fee, digits));
      categories.push(t('信託報酬'));
      colors.push('#FFE699');
    }
    if (d.restore !== null) {
      data.push(ChartUtil.to_percentage(d.restore, digits));
      categories.push(t('原状回復'));
      colors.push('#AEAAAA');
    }
    if (d.management_union_fee !== null) {
      data.push(ChartUtil.to_percentage(d.management_union_fee, digits));
      categories.push(t('管理組合費'));
      colors.push('#A9D08E');
    }
    if (d.others !== null) {
      data.push(ChartUtil.to_percentage(d.others, digits));
      categories.push(t('その他'));
      colors.push('#FFC000');
    }

    if (data.length === 0) return null;

    let series = [{ data: data }];

    // データラベルがバーの外側に表示できるように、バーの先端とチャート右端とに余裕を設ける。
    const grid_max = Math.trunc((Math.max(...data) + 20) / 10) * 10;
    // x軸が10間隔になるようにする。
    const tickAmount = grid_max / 10;

    const options: any = {
      chart: get_opt_chart('bar', undefined),
      plotOptions: {
        bar: {
          barHeight: '70%',
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'top'
          },
        }
      },
      colors: colors,
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        offsetX: 20,
        style: {
          // fontSize: '14px',
          // fontFamily: 'Helvetica, Arial, sans-serif',
          // fontWeight: 'bold',
          colors: [ '#000']
        },
        formatter: function (val: number, opt: any) {
          return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';  // カンマ区切り＆小数点以下1桁
        }
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories: categories,
        min: 0,
        max: grid_max,
        tickAmount: tickAmount,
        labels: {
          formatter: function(val: number, index: any) {
            if (val === null || val === undefined) return '';
            if (val > 100) return '';
            return val;
          }
        }
      },
      grid: {
        xaxis: { lines: { show: true } },   
        yaxis: { lines: { show: false } },
        padding: { right: 30 }
      },
      legend: { show: false },
      tooltip: {
        y: {
          title: {
            formatter: function (val: any, opts: any) {
              return '';
            }
          },
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';  // カンマ区切り＆小数点以下1桁
          }
        },
      }
    }
    return (
      <div id="Chart_ExpDetail" className={classNames('Chart', 'Chart_ExpDetail')}><Chart type="bar" options={options} series={series} /></div>
    );
  }


  const render_Chart_AV_CR = () => {
    let date_list = create_date_list();

    let histData_AV = [];
    let histData_CR = [];
    let all_histData_AV: number[] = [];
    let all_histData_CR: number[] = [];

    for (let date of date_list) {
      const av = get_chart_hist_data(ChartId.AV, date);
      const cr = get_chart_hist_data(ChartId.CR, date);

      histData_AV.push(av);
      histData_CR.push(cr);

      if (av !== null) all_histData_AV.push(av);
      if (cr !== null) all_histData_CR.push(cr);
    }

    const series = [
      {
        name: t('鑑定評価額'),
        type: 'column',
        data: histData_AV
      },
      {
        name: t('鑑定CR（NCF）'),
        type: 'line',
        data: histData_CR
      }
    ];

    const options = create_AV_CR_chart_opts(date_list, all_histData_AV, all_histData_CR);
    const chart_size = get_right_chart_size();

    return (<div id="Chart_AV_CR" className="Chart"><Chart type='line' options={options} series={series} height={chart_size.height} width={chart_size.width} /></div>);
  }

  const generateDayWiseTimeSeries = (baseval:any, count:any, yrange:any) => {
    var i = 0;
    var series = [];
    while (i < count) {
      var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
  
      series.push([baseval, y]);
      baseval += 86400000;
      i++;
    }
    return series;
  }

  const create_date_list = () => {
    if (histData.length === 0) return [];

    // 最新の日時を取得する。
    let latest_yyyymmdd: number = 0;
    for (let h of histData) {
      latest_yyyymmdd = Math.max(latest_yyyymmdd, h.as_of_yyyymmdd);
    }
    const latest_year = Number(String(latest_yyyymmdd).substring(0, 4));
    const latest_month = Number(String(latest_yyyymmdd).substring(4, 6)) - 1;

    // 半年ごとで5年分
    let date_list: number[] = [];
    for (let i=0; i<10; i++) {
      let date = new Date(latest_year, latest_month, 1);
      date.setMonth(date.getMonth() - (6 * i));

      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, '0');  // 0埋め
      let day = '01';
      date_list.push(Number(`${year}${month}${day}`));
    }

    // 日付を昇順にソートする。
    date_list.sort((a, b) => {
      if (a > b) return 1;
      else if (a < b) return -1;
      else return 0;
    })

    return date_list;
  }

  const create_date_list_24Month = () => {
    if (histData.length === 0) return [];

    // 最新の日時を取得する。
    let latest_yyyymmdd: number = 0;
    for (let h of histData) {
      latest_yyyymmdd = Math.max(latest_yyyymmdd, h.as_of_yyyymmdd);
    }
    const latest_year = Number(String(latest_yyyymmdd).substring(0, 4));
    const latest_month = Number(String(latest_yyyymmdd).substring(4, 6)) - 1;

    // 24か月分
    let date_list: number[] = [];
    for (let i=0; i<24; i++) {
      let date = new Date(latest_year, latest_month, 1);
      date.setMonth(date.getMonth() - i);

      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, '0');  // 0埋め
      let day = '01';
      date_list.push(Number(`${year}${month}${day}`));
    }

    // 日付を昇順にソートする。
    date_list.sort((a, b) => {
      if (a > b) return 1;
      else if (a < b) return -1;
      else return 0;
    })

    return date_list;
  }

  const get_chart_hist_data = (chartId: string, date: number, conv_percent=true): number|null => {
    for (let h of histData) {
      if (date !== h.as_of_yyyymmdd) continue;

      switch (chartId) {
        case ChartId.NOI_Y: {
          if (h.noi_y === null || h.noi_y === undefined) return null;
          if (conv_percent) {
            return 100 * h.noi_y;  // %値に変換する。
          } else {
            return h.noi_y;
          }
        }
        case ChartId.OCC: {
          if (h.occ === null || h.occ === undefined) return null;
          if (conv_percent) {
            return 100 * h.occ;  // %値に変換する。
          } else {
            return h.occ;
          }
        }
        case ChartId.RENT: {
          return h.rent;
        }
        case ChartId.CR: {
          if (h.cr === null || h.cr === undefined) return null;
          if (conv_percent) {
            return 100 * h.cr;   // %値に変換する。
          } else {
            return h.cr;
          }
        }
        case ChartId.AV: {
          return h.av;
        }
        case ChartId.ADR: {
          return h.adr;
        }
        case ChartId.REV_PAR: {
          return h.rev_par;
        }
      }
    }
    return null;
  }

  const get_chart_hist_area_data = (chartId: string, date: number): number|null => {
    for (let h of histAreaData) {
      if (date !== h.as_of_yyyymmdd) continue;

      switch (chartId) {
        case ChartId.RENT:  return h.rent;
      }
    }
    return null;
  }

  const create_chart_categories = (date_list: number[]): string[] => {
    let categories = [];
    for (let date of date_list) {
      const year = Number(String(date).substring(2, 4));
      const month = Number(String(date).substring(4, 6)) - 1;
  
      const month_3letters = EstateDataUtil.convert_month_3letters(month);
      
      categories.push(`${month_3letters}-${year}`);
    }
    return categories;
  }

  const create_NOIY_Occ_chart_opts = (date_list: number[], all_histData_NOIY: number[], all_histData_Occ: number[]) => {
    let categories = create_chart_categories(date_list);

    const line_colors = [ '#225C90', '#AFCEEA' ];
    const marker_colors = [ '#FFF', 'transparent' ];
    const marker_strokeColors = [ '#225C90', 'transparent' ];

    const min_noiy = Math.min(...all_histData_NOIY);
    let max_noiy = Math.max(...all_histData_NOIY);
    if (min_noiy === max_noiy) max_noiy = max_noiy * 1.1;  // 最小値と最大値が同じ場合、最大値を1.1倍する。(同じだと折れ線が表示されないため)

    // NOI Yieldの折れ線が稼働率の縦棒の上にくるようにY軸目盛の最小値／最大値を調整。
    const grid_min_noiy = ChartUtil.expand_min(min_noiy, max_noiy, 0.2);
    const grid_max_noiy = ChartUtil.expand_max(min_noiy, max_noiy, 0.7);

    let enabledOnSeries = [];
    if (all_histData_NOIY.length > 0) enabledOnSeries.push(0);
    if (all_histData_Occ.length > 0) enabledOnSeries.push(1);

    const options: any = {
      chart: get_opt_chart('line', ChartHeight),
      colors: line_colors,
      dataLabels: 
        get_opt_dataLabels(
          [0],  // NOI Yieldのみデータラベル表示
          function(val: number, opts: any) {
            if (val === null || val === undefined) return '';
            return val.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%';  // カンマ区切り＆小数点以下2桁
          }
        )
      , 
      fill: {
        opacity: 0.8,
        type: 'solid'
      },
      stroke: {
        width: [StrokeWidth, 0],
        curve: 'straight'
      },
      title: get_opt_title(t('NOI Yield ＆ 稼働率'), 0),
      grid: get_opt_grid(),
      markers: {
        size: [ MarkerSize, 0 ],
        colors: marker_colors,
        strokeColors: marker_strokeColors,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: [
        {
          tickAmount: 8,
          min: grid_min_noiy,
          max: grid_max_noiy,
          opposite: true,
          labels: {
            show: false,
            formatter: function(val: number, index: any) {
              if (val === null || val === undefined) return '';
              return val.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%';  // カンマ区切り＆小数点以下0桁
            }
          }
        },
        {
          tickAmount: 8,
          min: 0,
          max: 160,
          opposite: false,
          labels: {
            minWidth: MinWidth,
            formatter: function(val: number, index: any) {
              let ret = '';
              if (val === null || val === undefined) {
                ret = '';
              }
              else if (val > 100) {
                ret = '';  // 100%を超える部分は何も表示しない。
              }
              else {
                ret = val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + '%';  // カンマ区切り＆小数点以下0桁
              }
              // Y軸の桁をそろえる。
              return ret.padStart(maxNumOfCharaYAxis, ' ');
            }
          }
        },
      ],
      tooltip: {
        enabledOnSeries: enabledOnSeries,
        y: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            if (opts.seriesIndex === 1) {
              // 稼働率
              if (val === 100) {
                // 100%の場合は小数点表記なし。
                return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + '%';  // カンマ区切り＆小数点以下0桁
              } else {
                return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';  // カンマ区切り＆小数点以下1桁
              }  
            }
            else {
              // NOI Yield
              return val.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%';  // カンマ区切り＆小数点以下2桁
            }
          }
        }
      },
      legend: { show: false }
    };

    return options;
  }

  const create_Adr_RevPar_chart_opts = (date_list: number[], grid_min: number, grid_max: number, grid_interval: number, all_histData_Adr: number[], all_histData_RevPar: number[]) => {
    let categories = create_chart_categories(date_list);

    const line_colors = [ '#225C90', '#173d60' ];
    const marker_colors = [ '#fff', '#fff' ];
    const marker_strokeColors = [ '#225C90', '#173d60' ];

    let enabledOnSeries = [];
    if (all_histData_Adr.length > 0) enabledOnSeries.push(0);
    if (all_histData_RevPar.length > 0) enabledOnSeries.push(1);
    
    const options: any = {
      chart: get_opt_chart('line', ChartHeight),
      colors: line_colors,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [StrokeWidth, StrokeWidth],
      },
      title: get_opt_title('ADR & RevPAR', 0),
      grid: get_opt_grid(),
      markers: {
        size: MarkerSize,
        colors: marker_colors,
        strokeColors: marker_strokeColors,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        tickAmount: grid_interval,
        min: grid_min,
        max: grid_max,
        labels: {
          minWidth: MinWidth,
          formatter: function(val: number, index: any) {
            if (val === null || val === undefined) return '';
            return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});  // カンマ区切り＆小数点以下0桁
          }
        }
      },
      tooltip: {
        enabledOnSeries: enabledOnSeries,
        y: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';

            let text = '';
            switch (myLang) {
              case MyLanguage.JA:
                text = val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + ' 円';  // カンマ区切り＆小数点以下0桁
                break;
              case MyLanguage.EN:
                text = '&yen; ' + val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});  // カンマ区切り＆小数点以下0桁
                break;
            }
            return text
          }
        }
      },
      legend: { show: false }
    };

    return options;
  }

  const create_Rent_chart_opts = (date_list: number[], grid_min: number, grid_max: number, grid_interval: number, only_null_data: boolean, has_area_data: boolean) => {
    let categories = create_chart_categories(date_list);

    const line_colors = [ '#225C90', '#A5A5A5' ];
    const marker_colors = [ '#fff', '#fff' ];
    const marker_strokeColors = [ '#225C90', '#A5A5A5' ];

    let enabledOnSeries = [];
    if (!only_null_data) enabledOnSeries.push(0);  // 物件の賃料
    if (has_area_data) enabledOnSeries.push(1);  // エリア平均
    
    let grid = get_opt_grid();
    grid.padding = { bottom : 40 };  // ApexChartのtitleを使わない場合、手動で下部に余白を居れる必要がある。

    const options: any = {
      chart: get_opt_chart('line', ChartHeight),
      colors: line_colors,
      // dataLabels: {
      //   enabled: false,
      // },
      dataLabels: 
        get_opt_dataLabels(
          [0],  // 当該物件のみデータラベル表示
          function(val: number, opts: any) {
            if (val === null || val === undefined) return '';
            return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});  // カンマ区切り＆小数点以下0桁
          }
        )
      , 
      stroke: {
        width: [StrokeWidth, StrokeWidth],
      },
      grid: grid,
      markers: {
        size: MarkerSize,
        colors: marker_colors,
        strokeColors: marker_strokeColors,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        tickAmount: grid_interval,
        min: grid_min,
        max: grid_max,
        labels: {
          minWidth: MinWidth,
          formatter: function(val: number, index: any) {
            if (val === null || val === undefined) return '';
            return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});  // カンマ区切り＆小数点以下0桁
          }
        }
      },
      tooltip: {
        enabledOnSeries: enabledOnSeries,
        y: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';

            let text = '';
            switch (myLang) {
              case MyLanguage.JA:
                text = val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + ' 円/坪';  // カンマ区切り＆小数点以下0桁
                break;
              case MyLanguage.EN:
                text = '&yen; ' + val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + '/tsubo';  // カンマ区切り＆小数点以下0桁
                break;
            }
            return text
          }
        }
      },
      legend: { show: false }
    };

    return options;
  }

  const create_AV_CR_chart_opts = (date_list: number[], all_histData_AV: number[], all_histData_CR: number[]) => {
    let categories = create_chart_categories(date_list);

    const line_colors = [ '#5B9BD5', '#225C90' ];
    const marker_colors = [ 'transparent', '#FFF' ];
    const marker_strokeColors = [ 'transparent', '#225C90' ];

    let min_av = Math.min(...all_histData_AV);
    let max_av = Math.max(...all_histData_AV);
    min_av = max_av / 1.75;  // 最大値の50%くらいを下限とする。(きっちい50%にすると最小値が0になってしまうことがあり、調整している)
    max_av = ChartUtil.expand_max(min_av, max_av, 0.6);
    const grid_min_max_av = ChartUtil.get_grid_min_max(min_av, max_av, GridInterval);

    let min_cr = Math.min(...all_histData_CR);
    let max_cr = Math.max(...all_histData_CR);
    if (min_cr === max_cr) max_cr = max_cr * 1.1;  // 最小値と最大値が同じ場合、最大値を1.1倍する。(同じだと折れ線が表示されないため)

    // 鑑定CRの折れ線が稼働率の縦棒の上にくるようにY軸目盛の最小値／最大値を調整。
    const gird_min_cr = ChartUtil.expand_min(min_cr, max_cr, 0.1);
    const grid_max_cr = ChartUtil.expand_max(min_cr, max_cr, 0.7);

    const options: any = {
      chart: get_opt_chart('line', ChartHeight),
      colors: line_colors,
      plotOptions: {
        bar: {
          columnWidth: '60%',
        }
      },
      // dataLabels: {
      //   enabled: false,
      // },
      dataLabels: 
        get_opt_dataLabels(
          [1],  // 鑑定CR（NCF）のみデータラベル表示
          function(val: number, opts: any) {
            if (val === null || val === undefined) return '';
            return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';  // カンマ区切り＆小数点以下1桁
          }
        )
      , 
      fill: {
        opacity: 0.8,
        type: 'solid'
      },
      stroke: {
        width: [0, StrokeWidth],
        curve: 'straight'
      },
      title: get_opt_title(t('鑑定評価額 ＆ 鑑定CR（NCF）'), 0),
      grid: get_opt_grid(),
      markers: {
        size: [0, MarkerSize],
        colors: marker_colors,
        strokeColors: marker_strokeColors,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: [
        {
          tickAmount: GridInterval,
          min: grid_min_max_av.grid_min,
          max: grid_min_max_av.grid_max,
          opposite: false,
          labels: {
            minWidth: MinWidth,
            formatter: function(val: number, index: any) {
              if (val === null || val === undefined) return '';
              return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});  // カンマ区切り＆小数点以下0桁
            }
          }
        },
        {
          tickAmount: GridInterval,
          min: gird_min_cr,
          max: grid_max_cr,
          opposite: true,
          labels: {
            show: false,
          }
        }
      ],
      tooltip: {
        y: {
          formatter: function(val: number, opts: any) {
            if (val === null || val === undefined) return 'No data';
            if (opts.seriesIndex === 0) {
              // 鑑定評価額
              switch (myLang) {
                case MyLanguage.JA: {
                  return val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + ' 百万円';  // カンマ区切り＆小数点以下0桁
                }
                case MyLanguage.EN: {
                  return '&yen; ' + val.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) + 'm';  // カンマ区切り＆小数点以下0桁
                }
              }
              return '';
            }
            else {
              // 鑑定CR（NCF）
              return val.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) + '%';  // カンマ区切り＆小数点以下1桁
            }
          }
        }
      },
      legend: { show: false }
    };

    return options;
  }

  const get_opt_chart = (type: string, height: number|undefined) => {
    return {
      height: height,
      type: type,
      toolbar: {
        show: false
      },
      animations: {
        enabled: false
      },
      zoom: {
        enabled: false
      }
    };
  }

  const get_opt_grid = () => {
    return {
      borderColor: '#e7e7e7',
      row: {
        colors: [ 'transparent' ],
        opacity: 0.5
      },
      padding: {
        bottom: 0
      }
    };
  }

  const get_opt_title = (text: string, offsetY: number) => {
    return {
      text: text,
      align: 'center',
      offsetY: offsetY,
      style: {
        fontSize: ChartTitleFontSize,
        fontWeight: 'normal'
      }
    }; 
  }

  const get_opt_subtitle = (text: string, offsetX: number, offsetY: number) => {
    return {
      text: text,
      align: 'right',
      offsetX: offsetX,
      offsetY: offsetY,
      style: {
        fontSize: ChartSubTitleFontSize,
        fontWeight: 'normal'
      }
    }; 
  }

  const get_opt_dataLabels = (enabledOnSeries: number[], formatter: any) => {
    return { 
      enabled: true,
      enabledOnSeries: enabledOnSeries,
      textAnchor: 'middle',
      offsetY: -10,
      style: {
        fontSize: '12px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'normal',
        colors: ['#000', '#000']
      },
      background: {
        enabled: false,
        foreColor: '#000',
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: '#fff',
      },
      formatter: formatter
    };
  }

  const get_right_chart_size = (): ChartSize => {
    // clientWidth * 0.X%は左側(物件データ)のサイズ(Detail.cssの「#Detail .column:first-child」参照)
    // 40pxはpaddingサイズ
    const left_width = 0.36;
    let width = document.documentElement.clientWidth - (document.documentElement.clientWidth * left_width) - (40 * 2);  
    const height = width / 2.125;
    // 横：縦＝1.7：0.8
    const chart_size: ChartSize = { width, height };
    return chart_size;
  }

  const render_SourceButton = (d: EstateDataType) => {
    let source_files = '';
    switch (myLang) {
      case MyLanguage.JA:
        source_files = d.source_files;
        break;
      case MyLanguage.EN:
        source_files = d.source_files_en;
        break;
    }
    if (!source_files) return null;

    let rendering = [];
    const splits = source_files.split('|');
    for (let i=0; i<splits.length; i++) {
      const filename = `${splits[i]}.pdf`;
      rendering.push(<button className="" onClick={() => handleSourceButton(filename)}>PDF</button>);
    }
    return rendering;
  }

  const handleSourceButton = (source_file: string) => {
    if (AuthUtil.restricted(memberType, MemberType.C)) {
      setIsOpenUserRegistrationDialog(true);
      return;
    }

    // 別タブで「Source」のページを開く。
    const url = `./sources/${source_file}`;
    window.open(url, '_blank', 'noreferrer');
  }

  const handleClickExcelOutput = async () => {
    const ContextRoot = CommonUtil.get_context_root();
    const date_list = create_date_list();

    // --------------------------------------
    // 「NOI Yield & 稼働率」
    // --------------------------------------
    let histData_NOIY: (number | null)[] = [];
    let histData_Occ: (number | null)[] = [];
    for (let date of date_list) {
      const noiy = get_chart_hist_data(ChartId.NOI_Y, date, false);
      const occ = get_chart_hist_data(ChartId.OCC, date, false);
      histData_NOIY.push(noiy);
      histData_Occ.push(occ);
    }

    const chart_noiy_occ: ChartNoiyOccType = {
      has_chart_image: document.getElementById('Chart_NOIY_Occ') !== null,
      date_list,
      histData_NOIY,
      histData_Occ
    }

    // --------------------------------------
    // 「ADR & RevPAR」
    // --------------------------------------
    const date_list_24Month = create_date_list_24Month();
    let histData_Adr = [];
    let histData_RevPar = [];
    for (let date of date_list_24Month) {
      const adr = get_chart_hist_data(ChartId.ADR, date);
      const rev_par = get_chart_hist_data(ChartId.REV_PAR, date);
      histData_Adr.push(adr);
      histData_RevPar.push(rev_par);
    }

    const chart_adr_revPar: ChartAdrRevParType = {
      has_chart_image: document.getElementById('Chart_Adr_RevPar') !== null,
      date_list: date_list_24Month,
      histData_Adr,
      histData_RevPar
    }

    // --------------------------------------
    // 「賃料（円/坪）」
    // --------------------------------------
    let histData_Rent = [];
    let histData_Rent_Area = [];

    for (let date of date_list) {
      const rent = get_chart_hist_data(ChartId.RENT, date);
      const rent_area = get_chart_hist_area_data(ChartId.RENT, date);
      histData_Rent.push(rent);
      histData_Rent_Area.push(rent_area);
    }

    let area_name = '';
    switch (myLang) {
      case MyLanguage.JA: area_name = areaData[0].area_name ? areaData[0].area_name + '（平均）' : ''; break;
      case MyLanguage.EN: area_name = areaData[0].area_name_en ? areaData[0].area_name_en + ' (Av)' : ''; break;
    }

    const chart_rent: ChartRentType = {
      has_chart_image: document.getElementById('Chart_Rent') !== null,
      date_list,
      histData_Rent,
      histData_Rent_Area,
      area_name,
    }
    
    // --------------------------------------
    // 「鑑定評価額 ＆ 鑑定CR（NCF）」
    // --------------------------------------
    let histData_AV = [];
    let histData_CR = [];

    for (let date of date_list) {
      const av = get_chart_hist_data(ChartId.AV, date);
      const cr = get_chart_hist_data(ChartId.CR, date, false);

      histData_AV.push(av);
      histData_CR.push(cr);
    }

    const chart_av_cr: ChartAvCrType = {
      has_chart_image: document.getElementById('Chart_AV_CR') !== null,
      date_list,
      histData_AV,
      histData_CR,
    }

    // --------------------------------------
    // 「経費率」
    // --------------------------------------
    const chart_expRatio: ChartExpRatioType = {
      has_chart_image: document.getElementById('Chart_ExpRatio') !== null,
      exp_ratio: expData[0].exp_ratio,
    }

    // --------------------------------------
    // 「費用内訳」
    // --------------------------------------
    const chart_expDetail: ChartExpDetailType = {
      has_chart_image: document.getElementById('Chart_ExpDetail') !== null,
      bm: expData[0].bm,
      pm: expData[0].pm, 
      bm_pm: expData[0].bm_pm,
      utility: expData[0].utility,
      repair: expData[0].repair,
      tax: expData[0].tax,
      insurance: expData[0].insurance,
      leased_land: expData[0].leased_land,
      maintenance: expData[0].maintenance,
      leasing_fee: expData[0].leasing_fee,
      trust_fee: expData[0].trust_fee,
      restore: expData[0].restore,
      management_union_fee: expData[0].management_union_fee,
      others: expData[0].others,    
    }

    await ToExcel.out_hist(t, myLang, appData, estateDataForDetail, chart_noiy_occ, chart_adr_revPar, chart_rent, chart_av_cr, chart_expRatio, chart_expDetail, ContextRoot);
  }

  /**
   * 吹き出しの文言を返却する。
   * @param balloon_text 吹き出しの文言(配列の1要素＝1行)
   * @param classname_align 吹き出しの位置を指定するクラス名
   * @returns 吹き出しの文言
   */
  const render_balloon = (balloon_text: string[], classname_align: string) => {
    if (balloon_text.length === 0) return null;

    let rendering = [];
    for (let i = 0; i < balloon_text.length; i++) {
      if (i !== 0) rendering.push(<br/>);
      rendering.push(balloon_text[i]);
    }
    
    return (
      <span className={classNames("balloon", classname_align)}>{rendering}</span>
    );
  }

  const render_excel_button = () => {
    if (CommonUtil.is_mobile()) {
      return null;
    } else {
      return <button className="" onClick={() => handleClickExcelOutput()}>{t('Excel出力')}</button>
    }
  }

  if (estateDataForDetail.length === 0) return null;
  const d = estateDataForDetail[0];

  // 日本ビルファンドの物件以外は、
  if (!EstateDataUtil.is_Nippon_Building(d)) {
    // 会員タイプが確定するまでは何も表示しない。
    if (restricted === undefined) return null;
    // 許可されていない会員タイプであれば、許可なしメッセージを表示する。
    if (restricted) return <div id="Detail">{t(AuthUtil.RestrictedMessage)}</div>
  }

  return (
    <div id="Detail">
      <div className="button_area">
        {render_SourceButton(d)}
        {render_excel_button()}
      </div>
      <div className="row">
        <div className="column">
          {render_detail()}
          {!render_waiting && render_Chart_ExpRatio()}
          {!render_waiting && render_Chart_ExpDetail()}
        </div>
        <div className="column">
          {render_Chart_NOIY_Occ()}
          {render_Chart_Adr_RevPar()}
          {render_Chart_Rent()}
          {render_Chart_AV_CR()}
        </div>
      </div>
    </div>
  );



}

export default Detail;
