import { FC, useContext, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Main from '../components/Main';
import Dialog from '../components/Dialog';
import LoginDialog from '../components/LoginDialog';
import UserRegistrationDialog from '../components/UserRegistrationDialog';
import { AppContext, DisplayMode, LoginState } from '../context/AppContext';

const Home: FC = () => {
  // ダイアログ
  const { dialogMsg } = useContext(AppContext);
  // ログインダイアログ
  const { isOpenLoginDialog } = useContext(AppContext);
  // ユーザー登録ダイアログ
  const { isOpenUserRegistrationDialog } = useContext(AppContext);
  // 表示モード
  const { displayMode, setDisplayMode } = useContext(AppContext);

  const { loginState } = useContext(AppContext);

  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // URLパラメータから状態を復元する。(ブラウザの戻るに対応)
    let mode = searchParams.get('mode');
    if (mode === null) {
      mode = DisplayMode.Stock;
    }
    setDisplayMode(mode);
    
  }, [location]);

  const render_header = () => {
    if (displayMode === DisplayMode.OfficialLandPricesDetail) return null;
    return <Header />;
  }

  const render_footer = () => {
    if (loginState === LoginState.Not_LoggedIn) return null;
    if (displayMode === DisplayMode.OfficialLandPricesDetail) return null;
    return <Footer />;
  }

  return (
    <>
      {render_header()}
      <Main />
      {render_footer()}
      { dialogMsg && <Dialog /> }
      { isOpenLoginDialog && <LoginDialog /> }
      { isOpenUserRegistrationDialog && <UserRegistrationDialog /> }
    </>
  );

}

export default Home