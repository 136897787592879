import { MyLanguage } from '../context/AppContext';
import { InvestmentDataType, InvestmentSectorType } from './InvestmentDataType';
import CommonUtil from '../utils/CommonUtil';

class InvestmentDataUtil {

  static fetch_investment_data = (cond: any, callback: (investmentData: InvestmentDataType[]) => void, resultZero: () => void) => {
    const ContextRoot = CommonUtil.get_context_root();
    const formData = new FormData();
    for (let key in cond) {
      formData.set(key, cond[key]);
    }
    fetch(`${ContextRoot}/investment.php`, {
      method: 'POST',
      body: formData,
    }).then((res) => {
      if (!res.ok) {
        // エラー対応
      }
      return res.json();
    }).then((data) => {
      data = InvestmentDataUtil.conv_data(data);
      data = InvestmentDataUtil.set_stock_price_change(data);
      data = InvestmentDataUtil.set_market_cap(data);
      data = InvestmentDataUtil.set_share(data);
      data = InvestmentDataUtil.set_implied_cr(data);
      data = InvestmentDataUtil.set_nav(data);
      data = InvestmentDataUtil.set_ffo(data);
      callback(data);
      if (data.length === 0) {
        resultZero();
      }
    })
    .catch((reason) => {
      // エラー対応
      console.error(reason);
    });
  }

  /**
   * サーバから取得した投資情報を適切なデータ型に変換する。(主に文字列→数値)
   * 
   * @param data 投資情報
   * @returns 適切なデータ型に変換した投資情報
   */
  static conv_data = (data: any) => {
    const conv_number = (d: any, prop_name: string) => {
      if (d[prop_name] !== null) {
        d[prop_name] = Number(d[prop_name]);
      }
    }

    const conv_number_nest = (d: any, parent_prop_name: string, prop_name: string) => {
      if (d[parent_prop_name][prop_name] !== null) {
        d[parent_prop_name][prop_name] = Number(d[parent_prop_name][prop_name]);
      }
    }

    for (let d of data) {
      conv_number(d, 'stock_price');
      conv_number(d, 'stock_price_previous_day');
      conv_number(d, 'sector');
      conv_number(d, 'dividend_yield');
      conv_number(d, 'latest_forecast_dividend');
      conv_number(d, 'rating');
      conv_number(d, 'listing_date');
      conv_number(d, 'stock_code');
      conv_number(d, 'data_update_date');
      conv_number(d, 'stock_price_update_date');
      conv_number(d, 'next_settlement_date');
      conv_number(d, 'issued_investment');
      conv_number(d, 'noi');
      conv_number(d, 'interest_bearing_debt');
      conv_number(d, 'cash_and_deposits');
      conv_number(d, 'deposits_and_guarantees');
      conv_number(d, 'net_assets');
      conv_number(d, 'balance_sheet_amount');
      conv_number(d, 'current_net_profit');
      conv_number(d, 'real_estate_sales_profit_or_loss');
      conv_number(d, 'depreciation_expense');
      conv_number(d, 'purchase_price_M');       //20231105 Add
      conv_number(d, 'appraisal_value_M');      //20231105 Add
      conv_number(d, 'appraisal_gain_ratio_M'); //20231105 Add
      conv_number(d, 'building_age_M');         //20231105 Add
      conv_number(d, 'occupancy_M');            //20231105 Add
      conv_number(d, 'property_num_M');         //20231229 Add

      conv_number(d, 'purchase_price');
      conv_number(d, 'book_value');
      conv_number(d, 'appraisal_value');
      conv_number(d, 'appraisal_gain_ratio');
      conv_number(d, 'property_num');
      conv_number(d, 'building_age');
      conv_number(d, 'market_cap');
      conv_number(d, 'dividend');
      conv_number(d, 'occupancy');
      conv_number(d, 'noi_yield');
      conv_number(d, 'payout_ratio');
      conv_number(d, 'roe');
      conv_number(d, 'ltv');
      conv_number(d, 'icr_dscr');

      conv_number(d, 'tokyo_5_main_ward');
      conv_number(d, 'tokyo_23_ward');
      conv_number(d, 'tokyo_area');
      conv_number(d, 'osaka_area');
      conv_number(d, 'nagoya_area');
      conv_number(d, 'sapporo_city');
      conv_number(d, 'fukuoka_city');
      conv_number(d, 'others_area');

      conv_number_nest(d, 'property_ratio', 'super_high_office');
      conv_number_nest(d, 'property_ratio', 'high_office');
      conv_number_nest(d, 'property_ratio', 'middle_office');
      conv_number_nest(d, 'property_ratio', 'super_high_resi');
      conv_number_nest(d, 'property_ratio', 'high_resi');
      conv_number_nest(d, 'property_ratio', 'middle_resi');
      conv_number_nest(d, 'property_ratio', 'large_senior_resi');
      conv_number_nest(d, 'property_ratio', 'middle_senior_resi');
      conv_number_nest(d, 'property_ratio', 'urban_retail');
      conv_number_nest(d, 'property_ratio', 'suburban_retail');
      conv_number_nest(d, 'property_ratio', 'large_log');
      conv_number_nest(d, 'property_ratio', 'middle_log');
      conv_number_nest(d, 'property_ratio', 'factory');
      conv_number_nest(d, 'property_ratio', 'large_hotel');
      conv_number_nest(d, 'property_ratio', 'middle_hotel');
      conv_number_nest(d, 'property_ratio', 'others_property');

      conv_number_nest(d, 'exp', 'expense_ratio');
      conv_number_nest(d, 'exp', 'pm_bm');
      conv_number_nest(d, 'exp', 'bm');
      conv_number_nest(d, 'exp', 'pm');
      conv_number_nest(d, 'exp', 'utility');
      conv_number_nest(d, 'exp', 'repair');
      conv_number_nest(d, 'exp', 'tax');
      conv_number_nest(d, 'exp', 'insurance');
      conv_number_nest(d, 'exp', 'brokerage');
      conv_number_nest(d, 'exp', 'trust');
      conv_number_nest(d, 'exp', 'others_exp');

      conv_number_nest(d, 'debt', 'long_term_debt');
      conv_number_nest(d, 'debt', 'short_term_debt');

      conv_number_nest(d, 'fee', 'am_fee');
      conv_number_nest(d, 'fee', 'am_fee_pp');
      conv_number_nest(d, 'fee', 'related_party_exp_ratio');

      conv_number_nest(d, 'sourcing', 'related_party');
      conv_number_nest(d, 'sourcing', 'non_related_party');

//      conv_number_nest(d, 'investor', 'financial_institution_ave');  //20231105test
//      conv_number_nest(d, 'investor', 'foreign_corporation_ave');    //20231105test
//      conv_number_nest(d, 'investor', 'individual_ave');             //20231105test
//      conv_number_nest(d, 'investor', 'others_investor_ave');        //20231105test


      conv_number_nest(d, 'investor', 'financial_institution');
      conv_number_nest(d, 'investor', 'foreign_corporation');
      conv_number_nest(d, 'investor', 'individual');
      conv_number_nest(d, 'investor', 'others_investor');
    }
    return data;
  }

  /**
   * 株価の前日比を計算して設定する。
   * 
   * @param data 投資情報
   * @returns 株価の前日比を設定した投資情報
   */
  static set_stock_price_change = (data: any) => {
    // 前日比÷前日終値×100
    for (let d of data) {
      if (d['stock_price'] !== null && d['stock_price_previous_day'] !== null) {
        const change = (Number(d['stock_price']) - Number(d['stock_price_previous_day'])) / Number(d['stock_price_previous_day']);
        d['stock_price_change'] = change;
      } else {
        d['stock_price_change'] = null;
      }
    }
    return data;
  }

  /**
   * 時価総額を計算して設定する。
   * 
   * @param data 投資情報
   * @returns 時価総額を設定した投資情報
   */
  static set_market_cap = (data: any) => {
    for (let d of data) {
      if (d['stock_price'] !== null && d['issued_investment'] !== null) {
        const market_cap = Number(d['stock_price']) * Number(d['issued_investment']);
        d['market_cap'] = market_cap / 100_000_000;
      } else {
        d['market_cap'] = null;
      }
    }
    return data;
  }

  /**
   * シェアを計算して設定する。
   * 
   * @param data 投資情報
   * @returns シェアを設定した投資情報
   */
  static set_share = (data: any) => {
    // 全銘柄の時価総額の合計
    let sum_market_cap = 0;
    // 全銘柄の取得価格の合計
    let sum_purchase_price = 0;

    for (let d of data) {
      if (d['market_cap'] !== null) {
        sum_market_cap += Number(d['market_cap']);
      }
      if (d['purchase_price_M'] !== null) {
        sum_purchase_price += Number(d['purchase_price_M']);
      }
    }

    for (let d of data) {
      if (d['market_cap'] !== null) {
        d['market_cap_share'] = Number(d['market_cap']) / sum_market_cap;
      } else {
        d['market_cap_share'] = null;
      }

      if (d['purchase_price_M'] !== null) {
        d['purchase_price_share'] = Number(d['purchase_price_M']) / sum_purchase_price;
      } else {
        d['purchase_price_share'] = null;
      }
    }
    return data;
  }

  static set_implied_cr = (data: any) => {
    for (let d of data) {
      d['implied_cr'] = null;

      if (d['stock_price'] === null) continue;
      if (d['noi'] === null) continue;
      if (d['issued_investment'] === null) continue;
      if (d['interest_bearing_debt'] === null) continue;
      if (d['cash_and_deposits'] === null) continue;
      if (d['deposits_and_guarantees'] === null) continue;

      const stock_price = Number(d['stock_price']);
      const noi = Number(d['noi']);
      const issued_investment = Number(d['issued_investment']);
      const interest_bearing_debt = Number(d['interest_bearing_debt']);
      const cash_and_deposits = Number(d['cash_and_deposits']);
      const deposits_and_guarantees = Number(d['deposits_and_guarantees']);

      // Implied CR = (NOI * 2) / (株価 * 発行済投資口数 / 100_000_000 + 有利子負債 - 現預金 + 預り敷金・保証金)
      const bunbo = stock_price * issued_investment / 100_000_000 + interest_bearing_debt - cash_and_deposits + deposits_and_guarantees;
      if (bunbo === 0) continue;  // 0割り算回避
      const implied_cr = (noi * 2) / bunbo;
      d['implied_cr'] = implied_cr;
    }
    return data;
  }

  /**
   * NAV/口数およびNAV倍率を計算して設定する。
   * 
   * @param data 投資情報
   * @returns NAV/口数およびNAV倍率を設定した投資情報
   */
  static set_nav = (data: any) => {
    for (let d of data) {
      d['nav_per_unit'] = null;
      d['nav_ratio'] = null;

      if (d['net_assets'] === null) continue;
      if (d['appraisal_value_M'] === null) continue;            //20231105 Change
//      if (d['book_value'] === null) continue;
      if (d['balance_sheet_amount'] === null) continue;            //20231230 Change
      if (d['issued_investment'] === null) continue;

      const net_assets = Number(d['net_assets']);
      const appraisal_value = Number(d['appraisal_value_M']);            //20231105 Change
//      const book_value = Number(d['book_value']);
      const balance_sheet_amount = Number(d['balance_sheet_amount']);            //20231230 Change
      const issued_investment = Number(d['issued_investment']);

      // 0割り算回避
      if (issued_investment === 0) continue;

      // NAV/口数 = (純資産 + (鑑定評価額 - 帳簿価額)) * 100000000 / 発行済投資口数
//      const nav_per_unit = (net_assets + (appraisal_value - book_value)) * 100_000_000 / issued_investment;
      const nav_per_unit = (net_assets + (appraisal_value - balance_sheet_amount)) * 100_000_000 / issued_investment;            //20231230 Change
      d['nav_per_unit'] = nav_per_unit;

      // NAV倍率 = 株価 / NAV/口数
      if (d['stock_price'] === null) continue;
      if (nav_per_unit === 0) continue;
      d['nav_ratio'] = Number(d['stock_price']) / nav_per_unit;
    }
    return data;
  }

  /**
   * FFO/口数およびFFO倍率を計算して設定する。
   * 
   * @param data 投資情報
   * @returns FFO/口数およびFFO倍率を設定した投資情報
   */
  static set_ffo = (data: any) => {
    for (let d of data) {
      d['ffo_per_unit'] = null;
      d['ffo_ratio'] = null;

      if (d['current_net_profit'] === null) continue;
      if (d['real_estate_sales_profit_or_loss'] === null) continue;
      if (d['depreciation_expense'] === null) continue;
      if (d['issued_investment'] === null) continue;

      const current_net_profit = Number(d['current_net_profit']);
      const real_estate_sales_profit_or_loss = Number(d['real_estate_sales_profit_or_loss']);
      const depreciation_expense = Number(d['depreciation_expense']);
      const issued_investment = Number(d['issued_investment']);

      // 0割り算回避
      if (issued_investment === 0) continue;

      // FFO/口数 = (当期純利益 - 不動産売却損益 + 減価償却費) * 100000000 / 発行済投資口数
      const ffo_per_unit = (current_net_profit - real_estate_sales_profit_or_loss + depreciation_expense) * 100_000_000 / issued_investment;
      d['ffo_per_unit'] = ffo_per_unit;

      // FFO倍率 = 株価 / FFO/口数
      if (d['stock_price'] === null) continue;
      if (ffo_per_unit === 0) continue;
      d['ffo_ratio'] = Number(d['stock_price']) / ffo_per_unit;      
    }
    return data;
  }

  /**
   * セクター(数字)からセクター(名称)に変換する。
   * (呼び出し元で多言語対応する)
   * 
   * @param sector セクター(数字)
   * @returns セクター(名称)
   */
   static convert_sector_name = (sector: InvestmentSectorType) => {
    switch (sector) {
      case InvestmentSectorType.Industrial:
        return 'インダストリアル';
      case InvestmentSectorType.Office:
        return 'オフィス';
      case InvestmentSectorType.Complex:
        return '複合';
      case InvestmentSectorType.Healthcare:
        return 'ヘルスケア';
      case InvestmentSectorType.Hotel:
        return 'ホテル';
      case InvestmentSectorType.Retail:
        return 'リテール';
      case InvestmentSectorType.Residential:
        return 'レジデンシャル';
      case InvestmentSectorType.Logistics:
        return 'ロジスティクス';
      default:
        // 不正値
        return '';
    }
  }

  /**
   * 格付(数値)を格付(記号)に変換する。
   * 
   * @param rating 格付(数値)
   * @returns 格付(符号)
   */
  static convert_rating = (rating: number | null): string => {
    if (rating === null) return '';

    switch (rating) {
      case 210: return 'AAA';
      case 200: return 'AA+';
      case 190: return 'AA';
      case 180: return 'AA-';
      case 170: return 'A+';
      case 160: return 'A';
      case 150: return 'A-';
      case 140: return 'BBB+';
      case 130: return 'BBB';
      case 120: return 'BBB-';
      case 110: return 'BB+';
      case 100: return 'BB';
      case 90:  return 'BB-';
      case 80:  return 'B+';
      case 70:  return 'B';
      case 60:  return 'B-';
      case 50:  return 'CCC+';
      case 40:  return 'CCC';
      case 30:  return 'CCC-';
      case 20:  return 'CC';
      case 10:  return 'C';
      case 0:   return 'D';
      default:  return '';
    }
  }

  /**
   * 単数または複数指定された決算期を表示用に加工する。
   * データ「6|12」 → 日本語「6月 12月」、英語「Jun Dec」
   * 
   * @param fiscal_period 決算期
   * @param lang 言語
   * @returns 加工した決算期
   */
  static convert_fiscal_period = (fiscal_period: string, lang: string): string => {
    if (fiscal_period === null) return '';

    let text = '';
    const splits = fiscal_period.split('|');
    for (let i=0; i<splits.length; i++) {
      if (i > 0) {
        text += ' ';
      }
      const month = Number(splits[i]);
      switch (lang) {
        case MyLanguage.JA:
          // ex. 5月
          text += `${Number(month)}月`;
          break;
        case MyLanguage.EN:
          // ex. May
          text += `${CommonUtil.convert_month_3letters(month-1)}`;
          break;
      }
    }

    return text;
  }

  /**
   * レートを%に変換し、小数点以下x桁までにする。
   * 
   * @param ratio レート
   * @param decimal_place 小数点以下何桁まで表示するか。
   * @returns 変換後のレート
   */
  static convert_ratio_percentage = (ratio: number, decimal_place: number): string => {
    return (100 * ratio).toFixed(decimal_place).toLocaleString();
  }

  /**
   * 投資情報の平均値を算出する。
   * 
   * @param investmentData 投資情報 
   * @param callback 呼び出し元でどの投資情報の平均値を取得するか指定する。
   * @returns 平均値
   */
  static calc_average = (investmentData: InvestmentDataType[], callback: (d: InvestmentDataType) => number | null) => {
    let sum_data = 0;
    let valid_data_count = 0;
    for (let d of investmentData) {
      const value = callback(d);
      if (value === null) continue;

      sum_data += value;
      valid_data_count++;
    }
    if (valid_data_count === 0) return null;
    return sum_data / valid_data_count;
  }

  static create_blank_data = (stock_code: number, stock_name: string, stock_name_en: string) => {
    const data: InvestmentDataType = {
      stock_name: stock_name,
      stock_name_en: stock_name_en,
      stock_price: 0,
      stock_price_previous_day: 0,
      stock_price_change: 0,
      investment_company_name: '',
      investment_company_name_en: '',
      sector: InvestmentSectorType.Industrial,
      asset_management_company_name: '',
      asset_management_company_name_en: '',
      sponsor: '',
      sponsor_en: '',
      latest_forecast_dividend: 0,
      rating: 0,
      fiscal_period: '',
      listing_date: 0,
      stock_code: stock_code,
      data_update_date: 0,
      stock_price_update_date: 0,
      next_settlement_date: 0,
      hp_url: '',
      ir_url: '',
      hp_en_url: '',              //20231105 Add
      ir_en_url: '',              //20231105 Add
      trading_view_url: '',
      issued_investment: 0,
      noi: 0,
      interest_bearing_debt: 0,
      cash_and_deposits: 0,
      deposits_and_guarantees: 0,
      net_assets: 0,
      balance_sheet_amount: 0,
      current_net_profit: 0,
      real_estate_sales_profit_or_loss: 0,
      depreciation_expense: 0,
      purchase_price_M: 0,        //20231105 Add
      appraisal_value_M: 0,       //20231105 Add
      appraisal_gain_ratio_M: 0,  //20231105 Add
      building_age_M: 0,          //20231105 Add
      occupancy_M: 0,             //20231105 Add
      property_num_M: 0,          //20231229 Add
      purchase_price: 0,
      book_value: 0,
      appraisal_value: 0,
      appraisal_gain_ratio: 0,
      property_num: 0,
      building_age: 0,
      market_cap: 0,
      dividend: 0,
      dividend_yield: 0,
      occupancy: 0,
      noi_yield: 0,
      implied_cr: 0,
      nav_ratio: 0,
      ffo_ratio: 0,
      payout_ratio: 0,
      roe: 0,
      ltv: 0,
      icr_dscr: 0,
      purchase_price_share: 0,
      market_cap_share: 0,
      nav_per_unit: 0,
      ffo_per_unit: 0,
      tokyo_5_main_ward: 0,
      tokyo_23_ward: 0,
      tokyo_area: 0,
      osaka_area: 0,
      nagoya_area: 0,
      sapporo_city: 0,
      fukuoka_city: 0,
      others_area: 0,
      property_ratio: {
        super_high_office: 0,
        high_office: 0,
        middle_office: 0,
        super_high_resi: 0,
        high_resi: 0,
        middle_resi: 0,
        large_senior_resi: 0,
        middle_senior_resi: 0,
        urban_retail: 0,
        suburban_retail: 0,
        large_log: 0,
        middle_log: 0,
        factory: 0,
        large_hotel: 0,
        middle_hotel: 0,
        others_property: 0,  
      },
      exp: {
        expense_ratio: 0,
        pm_bm: 0,
        bm: 0,
        pm: 0,
        utility: 0,
        repair: 0,
        tax: 0,
        insurance: 0,
        brokerage: 0,
        trust: 0,
        others_exp: 0,
      },
      debt: {
        long_term_debt: 0,
        short_term_debt: 0,
      },
      fee: {
        am_fee: 0,
        am_fee_pp: 0,
        related_party_exp_ratio: 0,
      },
      sourcing: {
        related_party: 0,
        non_related_party: 0,
      },
      investor: {
        financial_institution: 0,
        foreign_corporation: 0,
        individual: 0,
        others_investor: 0,
      },
    }
    return data;
  }

  /**
   * 日本ビルファンドの投資情報か否か
   * 
   * @param d 投資情報
   * @returns 日本ビルファンドの投資情報か否か
   */
    static is_Nippon_Building = (d: InvestmentDataType): boolean => {
      if (d.stock_code !== 8951) return false;
      return true;
    }
  
}

export default InvestmentDataUtil